import store from '@/store'
import { Api } from '@/core/api.js'
import { TYPE } from '@/constants'

const api = new Api()

const getDefaultState = () => {
    return {
        reportsToExport: {
            ids: null,
            displayOptions: [
                { name: 'answers', key: 1, value: true, label: 'nvgt.report.hide_no_answered' },
                { name: 'details', key: 2, value: true, label: 'nvgt.report.hide_details' },
                { name: 'show_notes', key: 3, value: true, label: 'nvgt.report.hide_notes' },
                { name: 'no_apply', key: 4, value: true, label: 'nvgt.report.hide_noApply' },
                { name: 'images_resume', key: 5, value: true, label: 'nvgt.report.hide_imageResume' }
            ],
            recordDisplayOptions: [
                { name: 'answers', key: 1, value: true, label: 'nvgt.report.hide_no_answered' },
                { name: 'details', key: 2, value: true, label: 'nvgt.report.hide_details' },
                { name: 'show_notes', key: 3, value: true, label: 'nvgt.report.hide_notes' },
                { name: 'no_apply', key: 4, value: true, label: 'nvgt.report.hide_noApply' },
                { name: 'images_resume', key: 5, value: true, label: 'nvgt.report.hide_imageResume' }
            ],
            issueDisplayOptions: [
                { name: 'answers', key: 1, value: true, label: 'nvgt.report.hide_no_answered' },
                { name: 'details', key: 2, value: true, label: 'nvgt.report.hide_details' },
                { name: 'show_notes', key: 3, value: true, label: 'nvgt.report.hide_notes' },
                { name: 'no_apply', key: 4, value: true, label: 'nvgt.report.hide_noApply' },
                { name: 'images_resume', key: 5, value: true, label: 'nvgt.report.hide_imageResume' }
            ],
            auditDisplayOptions: [
                { name: 'answers', key: 1, value: true, label: 'nvgt.report.hide_no_answered' },
                { name: 'details', key: 2, value: true, label: 'nvgt.report.hide_details' },
                { name: 'show_notes', key: 3, value: true, label: 'nvgt.report.hide_notes' },
                { name: 'no_apply', key: 4, value: true, label: 'nvgt.report.hide_noApply' },
                { name: 'images_resume', key: 5, value: true, label: 'nvgt.report.hide_imageResume' },
                { name: 'show_point', key: 6, value: true, label: 'nvgt.report.hide_point' }
            ]
        },
        checklistsFilters: null,
        recordsFilters: null,
        incidentsFilters: null,
        auditsFilters: null
    }
}

// initial state
const state = getDefaultState()

// GETTERS
const getters = {
    getDownloadReportsDisplayOptions: (state, getters, rootState) => {
        return state.reportsToExport.displayOptions
    },
    getDownloadRecordReportsDisplayOptions: (state, getters, rootState) => {
        return state.reportsToExport.recordDisplayOptions
    },
    getDownloadIssueReportsDisplayOptions: (state, getters, rootState) => {
        return state.reportsToExport.issueDisplayOptions
    },
    getDownloadAuditReportsDisplayOptions: (state, getters, rootState) => {
        return state.reportsToExport.auditDisplayOptions
    }
}

// ACTIONS
const actions = {
    exportReports(context, values) {
        let options = {}
        let displayOptions = {};
        if (values.options) {
            options = values.options
        } else {
            // format PDF report display options for API:
            if (values.type == 2) {
                 displayOptions = state.reportsToExport.auditDisplayOptions;
            } else if (values.type == 3) {
                 displayOptions = state.reportsToExport.recordDisplayOptions;
            } else if (values.type == 4) {
                 displayOptions = state.reportsToExport.issueDisplayOptions;
            } else {
                 displayOptions = state.reportsToExport.displayOptions;
            }
        
            for (const option of displayOptions) {
                options[option.name] = option.value
            }
        }

        // define params (send ids OR filters)
        let params = {
            options: JSON.stringify(options),
            type: values.type
        }

        let reportsQuantity

        if (state.reportsToExport.ids) {
            params.ids = JSON.stringify(state.reportsToExport.ids)

            reportsQuantity = state.reportsToExport.ids.length
        } else {
            const type = values.type
            let filters
            if (type == TYPE.TASK) {
                filters = state.checklistsFilters
            } else if (type == TYPE.RECORD) {
                filters = state.recordsFilters
            } else if (type == TYPE.ISSUE) {
                filters = state.incidentsFilters
            } else {
                filters = state.auditsFilters
            }

            const filtersWithMoreInfo = {
                ...filters,
                pagenum: 0,
                number_items: false
            }

            params.filters = JSON.stringify(filtersWithMoreInfo)

            reportsQuantity = state.numItems
        }

        return api
            .post('checklist/admin/reports/pdf', params)
            .then((response) => {
                return response

                // if (response.status && response.data && response.data.url) {
                //     const endpoint = response.data.url

                //     // const urlTEST = 'http://localhost:3000/' + endpoint
                //     // return urlTEST

                //     return api
                //         .get(endpoint)
                //         .then((response) => {
                //             const pdfReportsURL = response.data

                //             return pdfReportsURL // returns the final URL with the reports in PDF
                //         })
                //         .catch((error) => console.error('Error in PDF reports URL:', error))
                // }
            })
            .catch((error) => {
                console.error('Error exporting reports: ', error)
            })
    }
}

// MUTATIONS
const mutations = {
    setDownloadReportsDisplayOptions: (state, displayOptions) => {
        state.reportsToExport.displayOptions = displayOptions
    },
    setDownloadReportsRecordDisplayOptions: (state, displayOptions) => {
        state.reportsToExport.recordDisplayOptions = displayOptions
    },
    setDownloadReportsIssueDisplayOptions: (state, displayOptions) => {
        state.reportsToExport.issueDisplayOptions = displayOptions
    },
    setDownloadReportsAuditDisplayOptions: (state, displayOptions) => {
        state.reportsToExport.auditDisplayOptions = displayOptions
    },

    setReportsToExportIds: (state, idsList) => {
        state.reportsToExport.ids = idsList
    },

    setReportsToExportChecklistsFilters: (state, filters) => {
        state.checklistsFilters = filters
    },

    setReportsToExportRecordsFilters: (state, filters) => {
        state.recordsFilters = filters
    },

    setReportsToExportIncidentsFilters: (state, filters) => {
        state.incidentsFilters = filters
    },

    setReportsToExportAuditsFilters: (state, filters) => {
        state.auditsFilters = filters
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
