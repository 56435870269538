import Vue from 'vue'
import { Api } from '@/core/api.js'
import i18n from '@/i18n'
import { Uploader } from '@/core/uploader.js'
const api = new Api()

// initial state
const getDefaultState = () => {
    return {
        library: {},
        libraryCategories: {},
        categories: {},
        newLibraryCategory: {
            id: false,
            name: '',
            order: '',
            color: '',
            description: '',
            parent_id: false,
            roles: [],
            status: 1,
            download: 0
        },
        categoryFilters: {
            roles: {},
            status: {}
        },
        parents: [],
        resourcesFilters: {
            categories: {},
            status: {}
        },
        categoriesForm: {},
        currentResource: {},
        pullFiles: [],
        pullResources: [],
        uploading: false,
        numFilesUpload: 0,
        filtersLibraryActive: {
            highlight: false,
            status: [{ id: 1, name: 'tools.groups.status.active' }],
            type_date: 'noDate'
        },
        filtersLibraryCategoryActive: {
            status: [{ id: 1, name: 'tools.groups.status.active' }],
            roles: []
        },
        itemsLoaded: false,
        itemsCategoryLoaded: false,
        resources: {}
    }
}
const state = getDefaultState()

// getters
const getters = {
    getLibrary: (state, getters, rootState) => (id) => {
        if (id) {
            return state.library[id]
        }
        return state.library
    },
    getCurrentResource: (state, getters, rootState) => {
        return state.currentResource
    },
    getLibraryCategories: (state, getters, rootState) => (id) => {
        if (id) {
            var category = state.libraryCategories[id]
            // if (category.status == -2) {
            //     category.status = 0
            // }
            if (typeof category != 'undefined') {
                if (category.roles && category.roles.length > 0) {
                    var arrRoles = []
                    if (typeof category.roles == 'string') {
                        category.roles = category.roles.split(',')
                    }
                    for (var role in category.roles) {
                        var idx = category.roles[role]
                        // console.log(idx)
                        if (idx) {
                            arrRoles.push(getters.getRolesCategoryFilters(idx))
                        }
                    }
                    category.roles = arrRoles
                }
            }
            return category
        }
        return state.libraryCategories
    },
    getCategoriesRecursive: (state, getters, rootState) => {
        return state.categories
    },

    getCategoriesForm: (state, getters, rootState) => (id) => {
        if (id) {
            var category = state.categoriesForm[id]
            if (category) {
                if (category.status == -2) {
                    category.status = 0
                }
                if (category.roles && category.roles.length > 0) {
                    var arrRoles = []
                    if (typeof category.roles == 'string') {
                        category.roles = category.roles.split(',')
                    }
                    for (var role in category.roles) {
                        var idx = category.roles[role]
                        // console.log(idx)
                        if (idx) {
                            arrRoles.push(getters.getRolesCategoryFilters(idx))
                        }
                    }
                    category.roles = arrRoles
                }
            }
            return category
        }
        return state.categoriesForm
    },

    getRolesCategoryFilters: (state, getters, rootState) => (id) => {
        if (id) {
            return state.categoryFilters.roles[id]
        }
        return state.categoryFilters.roles
    },
    getStatusCategoryFilters: (state, getters, rootState) => (id) => {
        if (id) {
            return state.categoryFilters.status[id]
        }
        return state.categoryFilters.status
    },
    getParentsCategoryFilters: (state, getters, rootState) => (id) => {
        if (id) {
            return state.parents[id]
        }
        return state.parents
    },

    getCategoriesResourcesFilters: (state, getters, rootState) => (id) => {
        return id ? state.resourcesFilters.categories[id] : state.resourcesFilters.categories
    },

    getStatusResourcesFilters: (state, getters, rootState) => (id) => {
        return id ? state.resourcesFilters.status[id] : state.resourcesFilters.status
    },

    getUploading: (state) => {
        return state.uploading
    },

    getPullUploads: (state) => {
        return state.pullFiles
    },

    getUploadsLength: (state, getter, rootState) => {
        // console.log('getUploadsLength', state.pullFiles)
        var n = 0
        if (state.uploading) n++

        return n + state.pullFiles.length
    },
    getNumFilesUpload: (state, getter, rootState) => {
        return state.numFilesUpload
    },

    getFiltersLibraryActive: (state, getters, rootState) => {
        return state.filtersLibraryActive
    },

    getFiltersLibraryCategoryActive: (state, getters, rootState) => {
        return state.filtersLibraryCategoryActive
    },
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },
    getItemsCategoryLoaded: (state, getters, rootState) => {
        return state.itemsCategoryLoaded
    },
    getResources: (state, getters, rootState) => (id) => {
        if (id) {
            return state.resources[id]
        }
        return state.resources
    }
}

// actions
const actions = {
    loadLibrary(context, params) {
        return api.post('admin/library/resources', params).then(function (response) {
            if (response) {
                context.dispatch('format', { data: response.data, type: '', commit: true })
            }
        })
    },
    editLibrary(context, params) {
        if (params.start_date) {
            params.start_date = moment(params.start_date).format('YYYY-MM-DD')
        }
        if (params.end_date) {
            params.end_date = moment(params.end_date).format('YYYY-MM-DD')
        }
        var uploadPreview = false
        var uploadFiles = false
        var numFilesToUpload = params.file.length

        let sFiles = ''
        params.file.forEach((file) => {
            if (typeof file !== 'string') {
                context.commit('addFileToUpload', { file: file, name: params.name })
                uploadFiles = true
            } else {
                sFiles += (sFiles != '' ? '|' : '') + file
            }
        })
        // console.log(params)
        if (params.preview.length > 0) {
            if (typeof params.preview[0] !== 'string') {
                uploadPreview = true
                context.commit('addFileToUpload', { file: params.preview[0], preview: true, name: params.name })
                params.preview = ''
            }
        }

        params.file = sFiles
        return api.post('admin/library/resources/' + params.id + '/edit', params).then(function (response) {
            if (response.status) {
                response.data.file = sFiles.split('|')
                response.data.uploadPreview = uploadPreview
                response.data.numFilesToUpload = numFilesToUpload
                response.data.auxStatus = params.auxStatus
                context.commit('setEditLibrary', response.data)
                if (uploadFiles || uploadPreview) {
                    context.commit('addResource', response.data)
                }
                return context.dispatch('processPullUploads')
            }
        })
    },
    addLibrary(context, params) {
        // console.log(params)
        var uploadPreview = false
        var numFilesToUpload = params.file.length

        params.file.forEach((file) => {
            context.commit('addFileToUpload', { file: file, name: params.name })
        })

        if (params.preview.length > 0) {
            uploadPreview = true
            context.commit('addFileToUpload', { file: params.preview[0], preview: true, name: params.name })
        }

        params.file = ''
        params.preview = ''
        if (!params.highlight) {
            params.highlight = '0'
        }

        return api.post('admin/library/resources/add', params).then(function (response) {
            if (response.status) {
                response.data.file = []
                response.data.uploadPreview = uploadPreview
                response.data.numFilesToUpload = numFilesToUpload
                response.data.auxStatus = params.auxStatus
                context.commit('addLibrary', response.data)
                context.commit('addResource', response.data)
                return context.dispatch('processPullUploads')
            } else {
                return false
            }
        })
    },

    updateFilesLibrary(context, params) {
        if (params.start_date) {
            params.start_date = moment(params.start_date).format('YYYY-MM-DD')
        }
        if (params.end_date) {
            params.end_date = moment(params.end_date).format('YYYY-MM-DD')
        }

        if (typeof params.file !== 'string') {
            var files = ''
            params.file.forEach((file) => {
                files += files != '' ? '|' + file : file
            })
            params.file = files
        }

        var files = params.file.split('|').length
        if ((typeof params.preview === 'string' || !params.uploadPreview) && files == params.numFilesToUpload) {
            // console.log('preview subida ********', JSON.parse(JSON.stringify(params)))
            params.status = params.auxStatus
            var resources = [...state.pullResources].reverse()
            resources.pop()
            context.commit('setPullResources', resources.reverse())
        }
        return api.post('admin/library/resources/' + params.id + '/edit', params).then(function (response) {
            if (response.status) {
                context.commit('setEditLibrary', response.data)
                context.dispatch('processPullUploads')
                return true
            } else {
                context.dispatch('processPullUploads')
                return false
            }
        })
    },

    addLibraryCategory(context, params) {
        return api.post('admin/library/category/add', params).then(function (response) {
            if (response.status) {
                context.commit('addLibraryCategory', response.data)
                return true
            } else {
                return false
            }
        })
    },
    addLibraryCategory(context, params) {
        var category = context.getters['getLibraryCategories'](params)
        if (category.status == 0) {
            category.status = -2
        }
        // Send good format roles to API
        var roles = []
        for (var i in category.roles) {
            var idx = category.roles[i]
            if (idx) {
                roles.push(idx.id)
            }
        }
        category.roles = roles.join()

        if (!category.parent_id) {
            category.parent_id = -1
        }

        return api.post('admin/library/category/add', category).then(function (response) {
            if (response.status) {
                context.commit('addLibraryCategory', response.data)
                return true
            } else {
                return false
            }
        })
    },
    addNewLibraryCategory(context, params) {
        var newCategory = Object.assign({}, context.state.newLibraryCategory)
        newCategory.id = 'tmpitw' + moment().unix()

        var categoriesActive = Object.values(context.state.libraryCategories).filter((obj) => {
            return obj.status > 0
        })

        newCategory.order =
            categoriesActive.length > 0
                ? Math.max.apply(
                      Math,
                      categoriesActive.map(function (o) {
                          return o.order
                      })
                  ) + 1
                : 1
        context.commit('createNewLibraryCategory', newCategory)
        return newCategory.id
    },
    loadCategoriesRecursive(context, params) {
        return api.post('admin/library/categories/list', params).then(function (response) {
            context.commit('setCategoriesRecursive', response.data)
        })
    },
    loadLibraryCategories(context, params) {
        // console.log(params);
        return api.post('admin/library/categories', params).then(function (response) {
            context.commit('setLibraryCategories', response.data)
        })
    },
    loadLibraryCategoriesForm(context, params) {
        // console.log(params);
        return api.post('admin/library/categories', params).then(function (response) {
            context.commit('setCategoriesForm', response.data)
        })
    },
    editLibraryCategory(context, params) {
        var category = context.getters['getLibraryCategories'](params)
        if (category.status == 0) {
            category.status = -2
        }
        // Send good format roles to API
        var roles = []
        for (var i in category.roles) {
            var idx = category.roles[i]
            if (idx) {
                roles.push(idx.id)
            }
        }
        category.roles = roles.join()

        if (!category.parent_id) {
            category.parent_id = -1
        }

        return api.post('admin/library/category/' + params + '/edit', category).then(function (response) {
            if (response.status) {
                context.commit('setEditLibraryCategory', response.data)
                return true
            } else {
                return false
            }
        })
    },

    validateLibraryCategory(context, params) {
        var categoryToValidate = context.getters['getLibraryCategories'](params.id)
        var errors = {
            name: false,
            status: false
        }
        var sendForm = true
        errors = _.mapValues(errors, () => false)
        if (categoryToValidate.name === '') {
            sendForm = false
            errors.name = true
        }
        if (categoryToValidate.status == 0 || categoryToValidate.status == false) {
            categoryToValidate.status = -2
        }
        if (categoryToValidate.status == true) {
            categoryToValidate.status = 1
        }

        // Validate switch parent category
        if (params.parentCategory) {
        } else {
            if (categoryToValidate.parent_id == false || categoryToValidate.parent_id == -1 || categoryToValidate.parent_id == null || categoryToValidate.parent_id.length == 1 || categoryToValidate.parent_id === true) {
                sendForm = false
                errors.parent_id = true
            }
        }

        // Validate switch roles
        if (params.roleSwitch) {
            if (categoryToValidate.roles.length == 0 || categoryToValidate.roles == null || Object.keys(categoryToValidate.roles).length == 0) {
                sendForm = false
                errors.roles = true
            }
        }
        return {
            status: sendForm,
            errors: errors
        }
    },

    loadFiltersLibraryCategories(context) {
        // console.log(params);
        return api.post('admin/library/categories/filter', {}).then(function (response) {
            var filters = response.data
            context.commit('setFiltersLibraryCategories', filters)
        })
    },
    deleteLibrary(context, params) {
        return api.get('admin/library/resources/' + params.id + '/delete').then(function (response) {
            context.commit('setDeleteLibrary', params.id)
        })
    },
    deleteLibraryCategory(context, params) {
        return api.get('admin/library/category/' + params.id + '/delete').then(function (response) {
            context.commit('setDeleteLibraryCategory', params.id)
        })
    },

    loadFiltersLibraryResources(context, params) {
        return api.post('admin/library/resources/filter', params).then(function (response) {
            return context.commit('setFiltersLibraryResources', response.data)
        })
    },

    format({ state, context, commit, dispatch, rootState }, params = {}) {
        let items = {}

        for (let key in params.data) {
            let item = params.data[key]

            item.start_date = item.start_date ? (moment.isMoment(item.start_date) ? item.start_date : moment(item.start_date * 1000)) : false
            item.end_date = item.end_date ? (moment.isMoment(item.end_date) ? item.end_date : moment(item.end_date * 1000)) : false
            item.upload_date = item.upload_date ? (moment.isMoment(item.upload_date) ? item.upload_date : moment(item.upload_date * 1000)) : false
            item.last_update = item.last_update ? (moment.isMoment(item.last_update) ? item.last_update : moment(item.last_update)) : false
            items[item.id] = item
        }
        commit('setLibrary', items)
    },

    processPullUploads: ({ commit, dispatch, state }) => {
        // console.log(state.uploading)
        if (!state.uploading) {
            var pull = [...state.pullFiles].reverse()
            var pullResources = [...state.pullResources].reverse()
            //   console.log('processPullUploads', pull, pullResources)
            if (pull.length > 0) {
                var nextUploadFile = pull.pop()
                var nextInfoFile = pullResources.pop()
                // console.log(nextUploadFile, pull)
                commit('setPullFiles', pull.reverse())
                commit('setPullResources', pullResources.reverse())

                commit('setUploading', nextUploadFile)
                var uploaderFile = new Uploader()
                commit('updateUpload', { param: 'status', value: 1 })
                if (typeof nextUploadFile.file !== 'string') {
                    // The substituted value will be contained in the result variable
                    // const result = str.replace(/[\(\)\| ]/gm, '');
                    //   console.log(nextUploadFile.file.name.replace(/[\(\)\|+ ]/gm, ''))
                    var fileUploadName = moment().valueOf() + '_' + cleanString(nextUploadFile.file.name.replace(/[\(\)\|+ ]/gm, ''))

                    // console.log('NEXT UPLOAD FILE: ' + fileUploadName)
                    //   console.log('NEXT UPLOAD RESOURCE: ', JSON.parse(JSON.stringify(nextInfoFile)))
                    uploaderFile.upload(
                        nextUploadFile.file,
                        { path: 'library', filename: fileUploadName },
                        function (data) {
                            commit('updateUpload', { param: 'file', value: data.Location })
                            // dispatch('editResource', state.uploading)
                            if (nextUploadFile.preview) {
                                nextInfoFile.preview = data.Location
                            } else {
                                if (typeof nextInfoFile.file === 'string') nextInfoFile.file = nextInfoFile.file.split('|')
                                nextInfoFile.file.push(data.Location)
                            }

                            pullResources = [...state.pullResources].reverse()
                            pullResources.push(nextInfoFile)
                            commit('setPullResources', pullResources.reverse())
                            commit('setUploading', false)
                            dispatch('updateFilesLibrary', nextInfoFile)
                        },
                        function () {},
                        function (progress) {
                            // console.log('PROGRESS', progress)
                            commit('updateUpload', { param: 'file_upload', value: progress })
                        }
                    )
                } else {
                    if (nextUploadFile.preview) {
                        nextInfoFile.preview = nextUploadFile.file
                    } else {
                        if (typeof nextInfoFile.file === 'string') nextInfoFile.file = nextInfoFile.file.split('|')
                        nextInfoFile.file.push(nextUploadFile.file)
                    }

                    pullResources = [...state.pullResources].reverse()
                    pullResources.push(nextInfoFile)
                    commit('setPullResources', pullResources.reverse())
                    commit('setUploading', false)

                    dispatch('updateFilesLibrary', nextInfoFile)
                    dispatch('processPullUploads')
                }
            } else {
            }
        }
    },
    loadResources(context, params) {
        return api.post('admin/library/resources/checklist', {}).then(function (response) {
            context.commit('setResources', response.data)
        })
    }
}

// mutations
const mutations = {
    setLibrary(state, response) {
        var library = state.library
        for (let key in response) {
            var resource = response[key]
            // console.log(library[key], ' ---------- ', resource.last_update);
            if (typeof library[key] == 'undefined' || library[key].last_update.valueOf() < resource.last_update.valueOf()) {
                library[key] = resource
            }
        }
        state.library = response
        Vue.set(state, 'library', { ...response })
        state.itemsLoaded = true
    },
    setCurrentResource(state, response) {
        var currentResource = state.currentResource
        var files = []
        if (typeof response.file === 'string' && response.file.trim() != '') {
            var fileSplit = response.file.split('|')
            fileSplit.forEach((file) => {
                files.push({ img: file })
            })
            response.file = files
        }
        currentResource = response
        state.currentResource = currentResource
        Vue.set(state, 'currentResource', { ...currentResource })
    },
    setCurrentResourceFiles(state, response) {
        // console.log(response)
        var currentResource = state.currentResource
        var files = []
        if (typeof response === 'string' && response.trim() != '') {
            var fileSplit = response.split('|')
            fileSplit.forEach((file) => {
                files.push({ img: file })
            })
            response = files
        }
        currentResource.file = response
        state.currentResource = currentResource
        Vue.set(state, 'currentResource', { ...currentResource })
    },
    addLibrary(state, params) {
        var library = { ...state.library }
        params.start_date = params.start_date ? (moment.isMoment(params.start_date) ? params.start_date : moment(params.start_date * 1000)) : false
        params.end_date = params.end_date ? (moment.isMoment(params.end_date) ? params.end_date : moment(params.end_date * 1000)) : false
        params.upload_date = params.upload_date ? (moment.isMoment(params.upload_date) ? params.upload_date : moment(params.upload_date * 1000)) : false
        params.last_update = params.last_update ? (moment.isMoment(params.last_update) ? params.last_update : moment(params.last_update)) : false
        library[params.id] = params
        state.library = library
        Vue.set(state, 'library', { ...library })
    },

    createNewLibraryCategory(state, payload) {
        var categories = { ...state.libraryCategories }
        categories[payload.id] = payload
        Vue.set(state, 'libraryCategories', { ...categories })
    },

    addLibraryCategory(state, params) {
        var libraryCategories = { ...state.libraryCategories }
        libraryCategories[params.id] = params
        state.libraryCategories = libraryCategories
        Vue.set(state, 'libraryCategories', { ...libraryCategories })
        Vue.set(state, 'parents', [])
        var parents = []
        for (var item in state.libraryCategories) {
            var idx = state.libraryCategories[item]
            if (!idx.parent_id) {
                parents.push(idx)
            }
        }
        Vue.set(state, 'parents', parents)
    },

    setEditLibrary(state, params) {
        var library = { ...state.library }
        params.start_date = params.start_date ? (moment.isMoment(params.start_date) ? params.start_date : moment(params.start_date * 1000)) : false
        params.end_date = params.end_date ? (moment.isMoment(params.end_date) ? params.end_date : moment(params.end_date * 1000)) : false
        params.upload_date = params.upload_date ? (moment.isMoment(params.upload_date) ? params.upload_date : moment(params.upload_date * 1000)) : false
        params.last_update = params.last_update ? (moment.isMoment(params.last_update) ? params.last_update : moment(params.last_update)) : false
        if (typeof state.library[params.id] == 'undefined' || state.library[params.id].last_update.valueOf() < params.last_update.valueOf()) {
            library[params.id] = params
        }
        state.library = { ...library }
        Vue.set(state, 'library', { ...library })
    },
    setEditLibraryCategory(state, params) {
        var libraryCategories = { ...state.libraryCategories }
        libraryCategories[params.id] = params
        state.libraryCategories = { ...libraryCategories }
        Vue.set(state, 'libraryCategories', { ...libraryCategories })
        Vue.set(state, 'parents', [])
        var parents = []
        for (var item in state.libraryCategories) {
            var idx = state.libraryCategories[item]
            if (!idx.parent_id) {
                parents.push(idx)
            }
        }
        Vue.set(state, 'parents', parents)
    },
    setDeleteLibraryCategory(state, params) {
        let libraryCategories = state.libraryCategories
        delete libraryCategories[params]
        state.libraryCategories = libraryCategories
        Vue.set(state, 'libraryCategories', { ...libraryCategories })

        let parents = []
        let categories = {}
        for (let item in state.libraryCategories) {
            let category = state.libraryCategories[item]

            if (category.parent_id == params) {
                category.parent_id = null
            }

            !category.parent_id ? parents.push(category) : (categories[category.id] = category)
        }
        Vue.set(state, 'parents', parents)
        Vue.set(state, 'categories', { ...categories })
    },
    setDeleteLibrary(state, params) {
        var library = state.library
        delete library[params]
        state.library = library
        Vue.set(state, 'library', { ...library })
    },
    setLibraryCategories(state, payload) {
        var libraryCategories = state.libraryCategories
        libraryCategories = payload
        state.libraryCategories = libraryCategories
        Vue.set(state, 'libraryCategories', { ...libraryCategories })
        if (typeof state.categoryFilters.categories === 'undefined') {
            for (var item in payload) {
                var idx = payload[item]
                if (!idx.parent_id) {
                    state.parents.push(idx)
                }
            }
        }
        state.itemsCategoryLoaded = true
    },
    setCategoriesRecursive(state, payload) {
        state.categories = payload
        Vue.set(state, 'categories', { ...payload })
    },

    setCategoriesForm(state, payload) {
        var libraryCategories = state.categoriesForm
        libraryCategories = payload
        Vue.set(state, 'categoriesForm', { ...libraryCategories })
        if (typeof state.categoryFilters.categories === 'undefined') {
            for (var item in payload) {
                var idx = payload[item]
                if (!idx.parent_id) {
                    state.parents.push(idx)
                }
            }
        }
        state.itemsCategoryLoaded = true
    },
    // TODO updatelibrary category hacer copia con los roles
    updateLibraryCategory(state, payload) {
        var categories = { ...state.libraryCategories }
        categories[payload.id] = payload
        Vue.set(state, 'libraryCategories', { ...categories })
    },
    updateLibraryCategoryRoles(state, payload) {
        var categories = { ...state.libraryCategories }
        // console.log(payload.roles)
        categories[payload.id].roles = payload.roles
        // Object.assign({}, payload)
        Vue.set(state, 'libraryCategories', { ...categories })
        Vue.set(state.libraryCategories[payload.id], 'roles', { ...payload.roles })
    },
    deleteLibraryCategory(state, payload) {
        var categories = { ...state.libraryCategories }
        delete categories[payload]
        Vue.set(state, 'libraryCategories', { ...categories })
    },
    setFiltersLibraryCategories(state, filters) {
        var filter = state.categoryFilters
        filter = filters
        state.categoryFilters = filter
        Vue.set(state, 'categoryFilters', { ...filter })
    },

    setFiltersLibraryResources(state, filters) {
        state.resourcesFilters = filters
        Vue.set(state, 'resourcesFilters', { ...filters })
    },

    addFileToUpload(state, file) {
        var files = state.pullFiles
        // console.log(typeof file.file)
        if (typeof file.file !== 'string') {
            state.numFilesUpload = state.numFilesUpload + 1
            Vue.set(state, 'numFilesUpload', state.numFilesUpload)
        }

        files.push(file)

        state.pullFiles = files
        Vue.set(state, 'pullFiles', files)
    },

    setPullFiles(state, pull) {
        state.pullFiles = pull
        Vue.set(state, 'pullFiles', pull)
    },

    setUploading(state, upload) {
        state.uploading = upload
        Vue.set(state, 'uploading', upload)
    },

    updateUpload(state, update) {
        var uploading = { ...state.uploading }

        uploading[update.param] = update.value
        state.uploading = uploading
        Vue.set(state, 'uploading', { ...uploading })
    },

    addResource(state, resource) {
        var resources = state.pullResources
        resources.push(resource)

        state.pullResources = resources
        Vue.set(state, 'pullResources', resources)
    },

    setPullResources(state, resources) {
        if (resources.length == 0) {
            state.numFilesUpload = 0
        }
        state.pullResources = resources
        Vue.set(state, 'pullResources', resources)
    },

    setFiltersLibraryActive(state, filters) {
        state.filtersLibraryActive = filters
        Vue.set(state, 'filtersLibraryActive', { ...filters })
    },

    setFiltersLibraryCategoryActive(state, filters) {
        state.filtersLibraryCategoryActive = filters
        Vue.set(state, 'filtersLibraryCategoryActive', { ...filters })
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    setResources(state, resources) {
        state.resources = resources
        Vue.set(state, 'resources', resources)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
