<template>
    <div id="submenu" :class="{ expanded: isExpanded, 'with-trial': trialEndDate, 'hidden-submenu': hiddenSubmenu, 'left-submenu': isOnTopLeft }">
        <template v-if="Object.keys(submenus).length > 0">
            <ul class="submenu hide-scrollbar">
                <li
                    class="tool"
                    :class="{ selected: tool.selected }"
                    :key="i"
                    v-for="(tool, i) in submenus"
                    v-if="hasAccess(tool) && hasAccess2(tool)"
                    @click="navTool(tool.href)"
                >
                    {{ $t(tool.name) }}
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Submenu',
    components: {},
    data() {
        return {
            pageTitleSubmenu: '',
            isOnTopLeft: false,
            hiddenSubmenu: false
        }
    },
    computed: {
        visibleTools() {
            let visible = []
            if (localStorage.config) {
                const config = JSON.parse(localStorage.config)
                if (config.tools) {
                    visible = config.tools
                }
            }
            return visible
        },
        pageTitle() {
            // navigation.js
            const page = this.$store.getters['navigation/getCurrentPage']
            // console.log(page);
            return page ? this.$t(page.label) : ''
        },
        submenus() {
            const tools = this.$store.getters['getTools']
            const page = this.$route.path

            let result = {}

            for (let i in tools) {
                let tool = tools[i]

                if (tool.access) {
                    for (let j in tool.access) {
                        let subtool = tool.access[j]

                        if (subtool.access) {
                            for (let k in subtool.access) {
                                let child = subtool.access[k]
                                subtool.access[k].selected = false
                                // console.log(child.href, page);
                                if ((child.href && child.href == page && child.submenu) || (child.href && this.location() == child.href && child.submenu)) {
                                    subtool.access[k].selected = true
                                    this.pageTitleSubmenu = this.$t(subtool.name)
                                    result = subtool.access
                                }
                            }
                        } else {
                            // console.log(child.href.startsWith(page));
                            // console.log(child.href, page);
                            let child = tool.access[j]
                            tool.access[j].selected = false
                            if ((child.href && child.href == page && child.submenu) || (child.href && this.location() == child.href && child.submenu)) {
                                tool.access[j].selected = true
                                this.pageTitleSubmenu = this.$t(tool.name)
                                result = tool.access
                            }
                        }
                    }
                }
            }

            return result
        },

        isExpanded() {
            return this.$store.state['menu_tools_is_expanded']
        },

        roles() {
            return this.$store.getters['employee/getRoles']()
        },
        userRole() {
            const user = this.$store.getters['login/getLocalEmployee']
            const roles = this.$store.getters['employee/getRol'](user.rol)

            if (roles != undefined) {
                return parseInt(roles.level)
            }
            return false
        },
        tools() {
            if (this.userRole) {
                return this.$store.getters['getToolsByRoleLevel'](this.userRole)
            }
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        }
    },

    methods: {
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },
        location() {
            let result = this.$route.path
            if (this.$route.params.id) {
                let index = result.lastIndexOf(this.$route.params.id)
                result = result.substring(0, index - 1)
                return result
            } else if (result.includes('add')) {
                let index = result.lastIndexOf('add')
                result = result.substring(0, index - 1)
                return result
            }
            return result
        },
        navTool(path) {
            let self = this

            if (path.includes('http')) {
                window.open(path, '_blank')
            } else {
                self.$router.push(self.redirectPath(path)).catch((err) => {
                    if (err.name == 'NavigationDuplicated') {
                        self.$router.push('/reset' + self.redirectPath(path))
                    }
                })
            }
        },
        hasAccess(tool) {
            if (this.userRole) {
                if (tool.roles.includes(this.userRole)) {
                    return true
                } else {
                    return false
                }
            }
        },
        hasAccess2(tool) {
            if (tool.href == '/availability') {
                const productAvailability = parseInt(this.$store.getters['login/getProductAvailability'])
                return productAvailability == 1
            }
            if (tool.href == '/designer') {
                return false
            }
            if (tool.required_config && tool.required_config == 'labelBlock') {
                return this.$store.getters.getConfigLabelBlock
            }
            if (tool.required_tool == false) {
                return true
            }
            if (this.visibleTools.indexOf(parseInt(tool.required_tool)) != -1) {
                return true
            }
            return false
        },

        isSelected(href) {
            return this.location() == href
        },

        handleHiddenAndLeftSubmenuOnRefresh() {
            if (this.$route.meta && this.$route.meta.hiddenSubmenu) {
                this.hiddenSubmenu = true
            } else {
                this.hiddenSubmenu = false
            }

            if (this.$route.meta && this.$route.meta.submenuOnTheLeft) {
                this.isOnTopLeft = true
            } else {
                this.isOnTopLeft = false
            }
        }
    },

    watch: {
        $route(to, from) {
            if (to.meta && to.meta.hiddenSubmenu) {
                this.hiddenSubmenu = true
            } else {
                this.hiddenSubmenu = false
            }

            if (to.meta && to.meta.submenuOnTheLeft) {
                this.isOnTopLeft = true
            } else {
                this.isOnTopLeft = false
            }
        }
    },

    mounted() {
        this.handleHiddenAndLeftSubmenuOnRefresh()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#submenu {
    position: fixed;
    // top: $menu-top-height + 15px;
    // left: $menu-tools-min + 15px;
    transition-duration: 0.2s;
    // z-index: $z-barActionMenus;
    // left: 385px;
    left: 400px;
    // top: 80px;
    top: 74px;

    &.hidden-submenu {
        display: none !important;
    }

    &.left-submenu {
        left: 70px !important;
    }

    .submenu {
        @include display-inline-flex();
        overflow-y: hidden;
        overflow-x: auto;
        padding-left: 0;

        @media (max-width: 767px) {
            display: none;
        }

        .tool {
            @include font-size(ml);
            color: $color-neutral-600;
            text-align: center;
            display: inline-block;
            cursor: pointer;
            transition-duration: 0.2s;
            border-bottom: 3px solid $color-neutral-300;
            display: flex;
            margin-right: 16px;
            box-sizing: border-box;
            font-display: swap;
            font-family: $text;

            // &:after {
            //     content: '';
            //     display: block;
            //     border-bottom: solid 3px transparent;
            //     transform: scaleX(0);
            //     transition: transform 250ms ease-in-out;
            // }

            // &:hover:after {
            //     transform: scaleX(1);
            //     border-bottom-color: $color-neutral-300;
            // }

            &:hover,
            &.selected {
                color: $color-black;
                border-bottom: 3px solid $color-secondary-500;
            }
            // &.selected:after {
            //     transform: scaleX(1);
            //     border-bottom-color: $color-primary-500;
            // }
        }
    }

    &.expanded {
        left: 10px + $menu-tools-max;
        transition-duration: 0.2s;
    }

    &.with-trial {
        top: $menu-top-height + 15px + 55px;
    }
}
</style>
