import Vue from 'vue'
import { Api } from '@/core/api.js'
import i18n from '@/i18n'

const api = new Api()

const defaultFilters = {
    sites: '',
}

const getDefaultState = () => {
    return {
        sites: '',
        locations: {},
        groups: {}
    }
}

const state = getDefaultState()

const getters = {
    getFilterLocation: (state, getters, rootState) => (id) => {
        if (id) {
            return state.locations[id]
        }
        return state.locations
    },
    getFilterGroups: (state, getters, rootState) => (id) => {
        if (id) {
            return state.groups[id]
        }
        return state.groups
    },

}

const actions = {
    loadLocations(context) {
        return api.get('admin/location').then(function (response) {
            let activeLocations = Object.values(response.data).filter(location => location.status == 1);
            context.commit('setFilterLocations', activeLocations);
        })
    },
    loadGroups(context) {
        return api.get('admin/group').then(function (response) {
            let activeGroups = Object.values(response.data).filter(group => group.status == 1);
            context.commit('setFilterGroups', activeGroups);
        })
    }    
}   

const mutations = {
    setFilterLocations(state, payload) {
        state.locations = payload
        Vue.set(state, 'locations', { ...state.locations })
    },
    setFilterGroups(state, payload) {
        state.groups = payload
        Vue.set(state, 'groups', { ...state.groups })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}