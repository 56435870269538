import { Api } from '@/core/api.js'
import Vue from 'vue'

const api = new Api()

const state = {
    gallery: {}
}

const getters = {
    getGallery: (state, getters, rootState) => {
        return state.gallery
    }
}

const actions = {
    loadGallery(context, params) {
        return api.get('admin/account/onboarding/videos').then(function (response) {
            context.commit('setGallery', response.data)
        })
    }
}

const mutations = {
    setGallery(state, data) {
        state.gallery = data
        Vue.set(state, 'gallery', data)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
