import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store.js'
import i18n from '@/i18n'

const Login = () => import('@/views/Login')
const AnalyticsHome = () => import('@/views/AnalyticsHome')
const Config = () => import('@/views/Config')
const Notifications = () => import('@/views/Notifications')
const DesignSystem = () => import('@/views/DesignSystem')
const NewDesignSystem = () => import('@/views/NewDesignSystem')
const TemplateCategories = () => import('@/views/TemplateCategories')
const OperationalTimersCategories = () => import('@/views/OperationalTimersCategories')
const TemplateChecklist = () => import('@/views/TemplateChecklist')
const TemplateIssue = () => import('@/views/TemplateIssue')
const TemplateActionplan = () => import('@/views/TemplateActionplan')
const TemplateAudit = () => import('@/views/TemplateAudit')
const TemplateRecord = () => import('@/views/TemplateRecord')
const SuperviseChecklist = () => import('@/views/SuperviseChecklist')
const SuperviseIssue = () => import('@/views/SuperviseIssue')
const SuperviseAudit = () => import('@/views/SuperviseAudit')
const SuperviseLabel = () => import('@/views/SuperviseLabel')
const Traceability = () => import('@/views/Traceability')
const SuperviseRecords = () => import('@/views/SuperviseRecords')
const ListViewManagement = () => import('@/views/ListViewManagement')
const CheckListManagement = () => import('@/views/CheckListManagement')
const User = () => import('@/views/User')
const Roles = () => import('@/views/Roles')
const Sites = () => import('@/views/Sites')
const Groups = () => import('@/views/Groups')
const ProductState = () => import('@/views/ProductState')
const ProductCategory = () => import('@/views/ProductCategory')
const ProductAvailability = () => import('@/views/ProductAvailability')
const Product = () => import('@/views/Product')
const ProductBlock = () => import('@/views/ProductBlock')
const LibraryCategory = () => import('@/views/LibraryCategory')
const Library = () => import('@/views/Library')
const ExternalTools = () => import('@/views/ExternalTools')
const Onboarding = () => import('@/views/Onboarding')
const Settings = () => import('@/views/Settings')
const Profile = () => import('@/views/UserProfile')
const SignUp = () => import('@/views/SignUp')
const LogBook = () => import('@/views/Logbook')
const News = () => import('@/views/News')
const ConfigTemplate = () => import('@/views/ConfigTemplate')
const ConfigIngredients = () => import('@/views/ConfigIngredients')
const EmptyPage = () => import('@/views/EmptyPage')
const IssueStates = () => import('@/views/IssueStates')
const Modules = () => import('@/views/Modules')
const TimeFrames = () => import('@/views/Timeframes')
const OperationalTimers = () => import('@/views/OperationalTimers')
const SuperviseOperationalTimers = () => import('@/views/SuperviseOperationalTimers')
const Communication = () => import('@/components/domain/logbook/communication/communication')
const Multiaccount = () => import('@/components/domain/multiaccount/layout.vue')
const Analytics = () => import('@/views/BetaAnalytics')

Vue.use(Router)

export default new Router({
    mode: process.env.VUE_APP_URL_TYPE == 'HASH' ? 'hash' : 'history',
    routes: [
        {
            path: '/blank',
            name: 'Blank',
            meta: {
                layout: 'default'
            }
        },
        {
            path: '/reset/:page',
            name: 'Reset',
            component: EmptyPage,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/login',
            name: 'Login',
            alias: '/logout',
            component: Login,
            meta: {
                layout: 'login'
            },
            beforeEnter: loginLoadLanguage
        },
        {
            path: '/login/:account',
            name: 'LoginCustom',
            component: Login,
            meta: {
                layout: 'login'
            },
            beforeEnter: loginLoadLanguage
        },
        {
            path: '/analytics',
            name: 'Analytics',
            component: Analytics,
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/r/:id',
            name: 'ResetPassword',
            component: Login,
            meta: {
                layout: 'login'
            },
            beforeEnter: checkLanguage
        },
        {
            path: '/d/:id',
            name: 'dashboardAccess',
            component: Login,
            meta: {
                layout: 'login'
            },
            beforeEnter: checkLanguage
        },
        {
            path: '/request-demo',
            name: 'SignUp',
            component: SignUp,
            meta: {
                layout: 'login'
            },
            beforeEnter: checkLanguage
        },
        {
            path: '/request-demo/:lang',
            name: 'SignUp',
            component: SignUp,
            meta: {
                layout: 'login'
            },
            beforeEnter: checkLanguage
        },
        {
            path: '/',
            //   name: 'Home', alias: '/home',
            //   component: Home,
            name: 'AnalyticsHome',
            alias: '/home',
            component: AnalyticsHome,
            props: { page: 'AnalyticsHome' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/onboarding',
            //   name: 'Home', alias: '/home',
            //   component: Home,
            name: 'Onboarding',
            alias: '/onboarding',
            component: Onboarding,
            props: { page: 'Onboarding' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/tools',
            name: 'ExternalTools',
            component: ExternalTools,
            props: { page: 'ExternalTools' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/news',
            name: 'News',
            component: News,
            props: { page: 'News' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/logbook',
            name: 'Logbook',
            component: LogBook,
            props: { page: 'Logbook' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/logbook/communication',
            name: 'Communication',
            component: Communication,
            props: { page: 'Communication' },
            meta: {
                layout: 'default',
                submenuOnTheLeft: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/logbook/communication/add',
            name: 'AddCommunication',
            component: Communication,
            props: { page: 'AddCommunication' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/logbook/communication/:id',
            name: 'EditCommunication',
            component: Communication,
            props: { page: 'EditCommunication' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/settings',
            name: 'Settings',
            component: Settings,
            props: { page: 'Settings' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/tools/add',
            name: 'AddExternalTools',
            component: ExternalTools,
            props: { page: 'AddExternalTools' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/tools/:id',
            name: 'EditExternalTools',
            component: ExternalTools,
            props: { page: 'EditExternalTools' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/library',
            name: 'Library',
            component: Library,
            props: { page: 'Library' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/library/add',
            name: 'AddLibrary',
            component: Library,
            props: { page: 'AddLibrary' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/library/category',
            name: 'LibraryCategory',
            component: LibraryCategory,
            props: { page: 'LibraryCategory' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/library/category/add',
            name: 'AddLibraryCategory',
            component: LibraryCategory,
            props: { page: 'AddLibraryCategory' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/library/category/:id',
            name: 'EditLibraryCategory',
            component: LibraryCategory,
            props: { page: 'EditLibraryCategory' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/library/:id',
            name: 'EditLibrary',
            component: Library,
            props: { page: 'EditLibrary' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/product',
            name: 'Product',
            component: Product,
            props: { page: 'Product' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/product/export',
            name: 'ExportProduct',
            component: Product,
            props: { page: 'Product' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/product/add',
            name: 'AddProduct',
            component: Product,
            props: { page: 'AddProduct' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/product/category',
            name: 'ProductCategory',
            component: ProductCategory,
            props: { page: 'ProductCategory' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/product/category/add',
            name: 'AddProductCategory',
            component: ProductCategory,
            props: { page: 'AddProductCategory' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/product/category/:id',
            name: 'EditProductCategory',
            component: ProductCategory,
            props: { page: 'EditProductCategory' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/product/state',
            name: 'ProductState',
            component: ProductState,
            props: { page: 'ProductState' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/product/state/add',
            name: 'AddProductState',
            component: ProductState,
            props: { page: 'AddProductState' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/product/state/:id',
            name: 'EditProductState',
            component: ProductState,
            props: { page: 'EditProductState' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/availability',
            name: 'ProductAvailability',
            component: ProductAvailability,
            props: { page: 'ProductAvailability' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/availability/:id',
            name: 'EditProductAvailability',
            component: ProductAvailability,
            props: { page: 'EditProductAvailability' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/product/:id',
            name: 'EditProduct',
            component: Product,
            props: { page: 'EditProduct' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/sites',
            name: 'Sites',
            component: Sites,
            props: { page: 'Sites' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/sites/add',
            name: 'AddSite',
            component: Sites,
            props: { page: 'AddSite' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/sites/:id',
            name: 'EditSite',
            component: Sites,
            props: { page: 'EditSite' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/users',
            name: 'Users',
            component: User,
            props: { page: 'Users' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/roles',
            name: 'Roles',
            component: Roles,
            props: { page: 'Roles' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/roles/add',
            name: 'AddRole',
            component: Roles,
            props: { page: 'AddRole' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/roles/:id',
            name: 'EditRole',
            component: Roles,
            props: { page: 'EditRole' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/users/add',
            name: 'AddUser',
            component: User,
            props: { page: 'AddUser' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/users/:id',
            name: 'EditUser',
            component: User,
            props: { page: 'EditUser' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/groups',
            name: 'Groups',
            component: Groups,
            props: { page: 'Groups' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/groups/add',
            name: 'AddGroup',
            component: Groups,
            props: { page: 'AddGroup' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/groups/:id',
            name: 'EditGroup',
            component: Groups,
            props: { page: 'EditGroup' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/categories',
            name: 'TemplateCategories',
            component: TemplateCategories,
            props: { page: 'TemplateCategories' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/categories/add',
            name: 'AddTemplateCategories',
            component: TemplateCategories,
            props: { page: 'TemplateCategories' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/categories/:id',
            name: 'EditTemplateCategories',
            component: TemplateCategories,
            props: { page: 'EditTemplateCategories' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/checklist',
            name: 'TemplateChecklist',
            component: TemplateChecklist,
            props: { page: 'TemplateChecklist' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/checklist/add',
            name: 'AddChecklistDetail',
            component: TemplateChecklist,
            props: { page: 'AddChecklist' },
            meta: {
                templateType: 1,
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/checklist/:id',
            name: 'ChecklistDetail',
            component: TemplateChecklist,
            props: { page: 'Checklist' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/issue',
            name: 'TemplateIssue',
            component: TemplateIssue,
            props: { page: 'TemplateIssue' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/issue/add',
            name: 'AddIssueDetail',
            component: TemplateIssue,
            props: { page: 'AddIssue' },
            meta: {
                templateType: 4,
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/issue/:id',
            name: 'IssueDetail',
            component: TemplateIssue,
            props: { page: 'Issue' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/actionplan',
            name: 'TemplateActionplan',
            component: TemplateActionplan,
            props: { page: 'TemplateActionplan' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/actionplan/add',
            name: 'AddActionplanDetail',
            component: TemplateActionplan,
            props: { page: 'AddActionplan' },
            meta: {
                templateType: 5,
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/actionplan/:id',
            name: 'ActionplanDetail',
            component: TemplateActionplan,
            props: { page: 'Actionplan' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/audit',
            name: 'TemplateAudit',
            component: TemplateAudit,
            props: { page: 'TemplateAudit' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/audit/add',
            name: 'AddAuditDetail',
            component: TemplateAudit,
            props: { page: 'AddAudit' },
            meta: {
                templateType: 2,
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/audit/:id',
            name: 'AuditDetail',
            component: TemplateAudit,
            props: { page: 'Audit' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/record',
            name: 'TemplateRecord',
            component: TemplateRecord,
            props: { page: 'TemplateRecord' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/record/add',
            name: 'AddRecordDetail',
            component: TemplateRecord,
            props: { page: 'AddRecord' },
            meta: {
                templateType: 3,
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/template/record/:id',
            name: 'RecordDetail',
            component: TemplateRecord,
            props: { page: 'Record' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/checklist',
            name: 'SuperviseChecklist',
            component: SuperviseChecklist,
            props: { page: 'SuperviseChecklist' },
            meta: {
                layout: 'default',
                tool: 1
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/checklist/:id',
            name: 'ReportChecklist',
            component: SuperviseChecklist,
            props: { page: 'SuperviseChecklist' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/issue',
            name: 'SuperviseIssue',
            component: SuperviseIssue,
            props: { page: 'SuperviseIssue' },
            meta: {
                layout: 'default',
                tool: 2
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/issue/:id',
            name: 'ReportIssue',
            component: SuperviseIssue,
            props: { page: 'SuperviseIssue' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/audit',
            name: 'SuperviseAudit',
            component: SuperviseAudit,
            props: { page: 'SuperviseAudit' },
            meta: {
                layout: 'default',
                tool: 7
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/audit/:id',
            name: 'ReportAudit',
            component: SuperviseAudit,
            props: { page: 'SuperviseAudit' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/records',
            name: 'SuperviseRecords',
            component: SuperviseRecords,
            props: { page: 'SuperviseRecords' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/records/:id',
            name: 'ReportRecords',
            component: SuperviseRecords,
            props: { page: 'SuperviseRecords' },
            meta: {
                layout: 'default',
                tool: 4
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/records/:id/:iditem',
            name: 'ReportRecordsDetail',
            component: SuperviseRecords,
            props: { page: 'SuperviseRecords' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/traceability',
            name: 'Traceability',
            component: Traceability,
            props: { page: 'Traceability' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/label',
            name: 'SuperviseLabel',
            component: SuperviseLabel,
            props: { page: 'SuperviseLabel' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/traceability/filters/:startDate/:endDate',
            name: 'TraceabilityFilters',
            component: Traceability,
            props: { page: 'Traceability' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/manage/employee',
            name: 'ManageEmployee',
            component: ListViewManagement,
            props: { page: 'ManageEmployee' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/manage/product',
            name: 'ManageProduct',
            component: ListViewManagement,
            props: { page: 'ManageProduct' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/manage/label/category',
            name: 'ManageLabelCategory',
            component: CheckListManagement,
            props: { page: 'ManageLabelCategory' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/manage/label/state',
            name: 'ManageLabelState',
            component: CheckListManagement,
            props: { page: 'ManageLabelState' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/configuration',
            name: 'Config',
            component: Config,
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/notifications',
            name: 'Notifications',
            component: Notifications,
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/designsystem',
            name: 'DesignSystem',
            meta: {
                layout: 'default'
            },
            component: DesignSystem,
            beforeEnter: checkLanguage
        },
        {
            path: '/designsystem-v2',
            name: 'DesignSystem',
            meta: {
                layout: 'default'
            },
            component: NewDesignSystem,
            beforeEnter: checkLanguage
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/designer',
            name: 'ConfigTemplate',
            component: ConfigTemplate,
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/ingredients',
            name: 'ConfigIngredients',
            component: ConfigIngredients,
            meta: {
                layout: 'default',
                submenuOnTheLeft: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/block',
            name: 'ProductBlock',
            component: ProductBlock,
            meta: {
                layout: 'default',
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/block/add',
            name: 'ProductBlockAdd',
            component: ProductBlock,
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/block/edit/:id',
            name: 'ProductBlockEdit',
            component: ProductBlock,
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/timeframes',
            name: 'TimeFrames',
            component: TimeFrames,
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/timeframes/add',
            name: 'AddTimeFrames',
            component: TimeFrames,
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/timeframes/:id',
            name: 'EditTimeFrames',
            component: TimeFrames,
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/issue_states',
            name: 'IssueStates',
            component: IssueStates,
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/issue_states/add',
            name: 'AddIssueStates',
            component: IssueStates,
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/issue_states/:id',
            name: 'EditIssueStates',
            component: IssueStates,
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/modules',
            name: 'Modules',
            component: Modules,
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/operationalTimers',
            name: 'OperationalTimers',
            component: OperationalTimers,
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/supervise/operationalTimers',
            name: 'SuperviseOperationalTimers',
            component: SuperviseOperationalTimers,
            meta: {
                layout: 'default',
                tool: 15
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/operationalTimers/categories',
            name: 'CategoryOperationalTimers',
            component: OperationalTimersCategories,
            props: { page: 'OperationalTimersCategories' },
            meta: {
                layout: 'default'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/operationalTimers/categories/add',
            name: 'AddOperationalTimersCategories',
            component: OperationalTimersCategories,
            props: { page: 'OperationalTimersCategories' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/operationalTimers/categories/:id',
            name: 'EditOperationalTimersCategories',
            component: OperationalTimersCategories,
            props: { page: 'EditOperationalTimersCategories' },
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/operationalTimers/add',
            name: 'AddOperationalTimers',
            component: OperationalTimers,
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/operationalTimers/:id',
            name: 'EditOperationalTimers',
            component: OperationalTimers,
            meta: {
                layout: 'default',
                hiddenSubmenu: true
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/lead/access/:token',
            name: 'LeadAccess',
            beforeEnter: leadAccess,
            meta: {
                layout: 'login'
            }
        },
        {
            path: '/multiaccount',
            name: 'Multiaccount',
            component: Multiaccount,
            beforeEnter: checkLanguage,
            props: { page: 'Multiaccount' },
            meta: {
                layout: 'default'
            }
        },
        {
            path: '/inituserlogin/:accountId/:id',
            name: 'LoginUserCallback',
            beforeEnter: loginUserCallbackAccess,
            meta: {
                layout: 'login'
            }
        },
        {
            path: '/logoutexternal',
            name: 'LoginUserCallback',
            beforeEnter: logoutUserCallbackAccess,
            meta: {
                layout: 'login'
            }
        }
    ]
})

function checkBeforeEnter(to, from, next) {
    Vue.prototype.$popup.close()
    // if( to.name == 'Home' ){
    //   Vue.prototype.$bar.hide();
    //   store.commit('setAlexVisible', false);
    //   store.commit('setMenuToolsExpanded', true);
    // } else {
    //   Vue.prototype.$bar.show();
    //   store.commit('setAlexVisible', true);
    //   store.commit('setMenuToolsExpanded', false);
    // }

    store.commit('setAlexVisible', true)
    // store.commit('setMenuToolsExpanded', false);

    // Vue.prototype.$overlay.hide()

    if (['ReportChecklist', 'ReportIssue', 'ReportRecordsDetail', 'ReportAudit'].includes(to.name)) {
        reportAccess(to, from, next)
    } else {
        requireAuth(to, from, next)
    }
}

/*
  This will cehck to see if the user is authenticated or not.
*/
function requireAuth(to, from, next) {
    /*
      Determines where we should send the user.
    */
    function proceed() {
        /*
          If the user has been loaded determine where we should
          send the user.
        */
        if (store.getters['login/getHasLogin']) {
            /*
              If the user is not empty, that means there's a user
              authenticated we allow them to continue. Otherwise, we
              send the user back to the home page.
            */
            // decir a que pagina voy
            //   console.log('THEN')
            if (!store.getters['login/getHasLogin']) {
                // console.log('!getHasLogin - Go LOGIN')
                next('Login')
            } else {
                if (store.getters['login/employeeMultiaccount']) {
                    return next('/multiaccount')
                }

                // if account is in trial default home is onboarding

                // TODO IS COMMENT FOR ONBOARDING RELEASE
                if (store.getters['login/getType'] == 1 && to.path == '/') {
                    next('/onboarding')
                } else {
                    if (!(to.path == '/home' || to.path == '/' || to.path == '/configuration' || to.path == '/profile') && !store.getters['hasAccess'](to)) {
                        // IF USER HAS NO ROLE LEVEL PERMISION -> GET TO HOME
                        // console.log('ROLE ACCESS LEVEL DENIED')
                        if (process.env.VUE_APP_URL_TYPE == 'HASH') {
                            next('/')
                        } else next('home')
                    } else {
                        store.dispatch('navigation/setPage', to).then(function () {
                            next()
                        })
                    }
                }
            }
        }
    }

    // check to load language
    let language = localStorage.getItem('language') ? localStorage.getItem('language') : 'init'
    i18n.loadLanguageAsync(language).then(function () {
        /*
          Confirms the user has been loaded
        */
        if (!store.getters['login/getHasLogin']) {
            /*
              If not, load the user
            */
            store.dispatch('login/initAccount').then(function () {
                const hasLogin = store.getters['login/getHasLogin']
                const multiaccount = store.getters['login/getEmployeeMultiAccount']

                if (!hasLogin && multiaccount) next('/multiaccount')
                else if (!hasLogin && !multiaccount) next('Login')
                else proceed()
            })
        } else {
            /*
              User call completed, so we proceed
            */
            proceed()
        }
    })
}

function reportAccess(to, from, next) {
    //Si esta logueado que utilice el requireAuth
    const hasLogin = JSON.parse(localStorage.getItem('hasLogin'))
    if (store.getters['login/getHasLogin'] || hasLogin) {
        requireAuth(to, from, next)
    } else {
        //Moficar el meta layout
        to.meta.layout = 'report'
        //Si no esta logueado va a utilizar un login temporal
        if (!store.getters['login/getReportAcess']) {
            const id = to.params.iditem ? to.params.iditem : to.params.id
            store.dispatch('login/initReportAccess', id).then(function () {
                next()
            })
        } else {
            next()
        }
    }
}

function loginUserCallbackAccess(to, from, next) {
    if (to.params.id == 'error') {
        next({ name: 'login', params: { account: to.params.accountId } })
    }
    store
        .dispatch('login/validateExternalAccess', to.params)
        .then(function () {
            log('finish load loginUserCallback access')

            next({
                name: 'AnalyticsHome'
            })
        })
        .catch((error) => {
            logError(error)
        })
}

function logoutUserCallbackAccess() {}

/**
 * Login load language
 */
function loginLoadLanguage(to, from, next) {
    let language = localStorage.getItem('language') ? localStorage.getItem('language') : 'init'
    i18n.loadLanguageAsync(language).then(function () {
        next()
    })
}

/**
 * Load language for new password and reset password
 */
function checkLanguage(to, from, next) {
    // get language from url
    let lang = to.params.lang

    // validate language
    if (!['es', 'en', 'pt', 'de'].includes(lang)) {
        lang = 'es'
    }

    // get language
    i18n.loadLanguageAsync(lang).then(function (response) {
        next()
    })
}

function leadAccess(to, from, next) {
    localStorage.removeItem('user')
    localStorage.removeItem('roles')
    localStorage.setItem('token', to.params.token)
    store
        .dispatch('login/initAccount')
        .then(function () {
            next('/')
        })
        .catch(function (error) {
            console.error(error)
            next('login')
        })
}
