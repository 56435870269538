import { Api } from '@/core/api.js'
const api = new Api()

// initial state
const getDefaultState = () => {
    return {
        hasLoginUser: !!localStorage.user,
        local: {
            token: localStorage.token,
            account: localStorage.account ? JSON.parse(localStorage.account) : {},
            user: localStorage.user ? JSON.parse(localStorage.user) : {},
            location: localStorage.location ? JSON.parse(localStorage.location) : {},
            employee_id: localStorage.employee_id ? localStorage.employee_id : {}
        },
        superadmin: false
    }
}
const state = getDefaultState()

// getters
const getters = {
    getLocalToken: (state, getters, rootState) => {
        return state.local.token
    },
    getLocalLocation: (state, getters, rootState) => {
        return state.local.location
    },
    getLocalAccount: (state, getters, rootState) => {
        return state.local.account
    },
    getLocalUser: (state, getters, rootState) => {
        if (typeof state.local.user !== 'undefined' && state.local.user != false) {
            return state.local.user
        } else {
            return false
        }
    },
    getLocalEmployee: (state, getters, rootState, rootGetters) => {
        return rootGetters['login/getLocalEmployee'].employee_id

        // .getters["employee/getEmployee"](employeeId)
    },
    // getLocalLocation: (state, getters, rootState, rootGetters) => {
    //     // return { id: rootGetters['notes/getLocationFiltered'] }
    //     return JSON.parse(localStorage.account)
    // },
    getUserInformation: (state, getters, rootState) => {
        return typeof localStorage.user !== 'undefined' ? JSON.parse(localStorage.user) : {}
    },
    isSuperAdmin: (state, getters, rootState) => {
        return state.superadmin
    }
}

// actions
const actions = {
    checkLogin(context, params) {
        return api.post('employee/login', { employee: params.employee, password: params.password }).then(function (response) {
            response.emp_id = params.employee
            // context.commit('login', response)
        })
    },

    doLogin(context, params) {
        if (fnCheckConnection()) {
            return api
                .post('employee/login', { employee: params.employee, password: params.password })
                .then(function (response) {
                    response.emp_id = params.employee
                    response.token = response.data
                    response.user = context.rootState.employee.employees[params.employee]
                    context.commit('login', response)
                })
                .then(function () {
                    if (context.state.hasLoginUser) {
                        return true
                    } else {
                        return false
                    }
                })
        } else {
            // Offline
            var employees = context.rootState.employee.employees
            var logged = false
            Object.keys(employees).forEach(function (key) {
                var e = employees[key]

                if (e.id == params.employee && e.password == params.password) {
                    logged = e
                }
            })

            if (logged) {
                var employee = {
                    id: context.state.local.location.id,
                    account_id: context.state.local.account ? JSON.parse(context.state.local.account).id : false,
                    name: context.state.local.location.name,
                    employee: {
                        id: params.employee,
                        name: logged.name,
                        avatar: logged.avatar
                    },
                    exp: moment().add(86400, 'minutes').unix()
                }

                var jwt = require('jwt-simple')
                var token = jwt.encode(employee, 'ingDLMRuGe9UKHRNjs7cYckS2yul4lc3')
                var response = {
                    status: true,
                    data: token,
                    emp_id: params.employee,
                    user: context.rootState.employee.employees[params.employee]
                }

                context.commit('login', response)
                return true
            }

            return false
        }
    },

    doLogout(context, params) {
        return api
            .post('employee/logout', { employee: params.employee })
            .then(function (response) {
                context.commit('logout', response)
            })
            .then(function () {
                if (!context.state.hasLoginUser) {
                    return true
                } else {
                    return false
                }
            })
    }
}

// mutations
const mutations = {
    login(state, response) {
        if (response.status) {
            state.hasLoginUser = true

            if (localStorage) {
                alert('entraaqui')
                var local = { ...state.local }
                local.token = response.data
                local.employee_id = response.emp_id
                local.user = response.user
                Vue.set(state, 'local', { ...local })
                localStorage.setItem('token', response.data)
                localStorage.setItem('employee_id', response.emp_id)
                localStorage.setItem('user', JSON.stringify(response.user))
            }
        } else {
            state.hasLoginUser = false
        }
    },

    logout(state, response) {
        state.local.token = response.data
        state.local.employee_id = false
        state.local.user = false
        localStorage.setItem('token', response.data)
        localStorage.removeItem('employee_id')
        localStorage.removeItem('user')

        state.hasLoginUser = false
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    setIsSuperAdmin(state, data) {
        state.superadmin = data == 1
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
