<template>
    <div id="data-container">
        <div class="data-container">
            <div class="data-description" v-if="message" v-html="message"></div>
            <div class="data" v-if="data">
                <!-- <iframe v-if="data.includes('youtube')" class="player" rel="0" :src="videoParse() + '?modestbranding=1&autoplay=1&iv_load_policy=3&rel=0&autohide=1&showinfo=0&controls=0&modestbranding=1&rel=0&mute=1&loop=1&playlist=' + getYTVideoId()" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen iv_load_policy="3"></iframe> -->

                <iframe v-if="data.includes('youtube')" class="player" rel="0" :src="videoParse() + '?rel=0&autoplay=1&modestbranding=1&autohide=1&showinfo=0&color=white&controls=0&modestbranding=1&rel=0'" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen iv_load_policy="3"></iframe>

                <iframe v-else-if="data.includes('vimeo')" class="player" :src="videoParse() + '?autoplay=1&title=0&byline=0&portrait=0&sidedock=0'" frameborder="0" allowfullscreen></iframe>
                <video class="player" autoplay :src="data" v-else controls></video>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        data: {
            type: undefined,
            default: false
        },
        title: { type: undefined, default: '' },
        message: { type: undefined, default: false }
    },
    data() {
        return {
            selectedEmails: [],
            linkList: false,
            videoString: false,
            mounted: false
        }
    },
    computed: {},
    methods: {
        translation(translation) {
            return i18n.t(translation)
        },
        getYTVideoId() {
            if (this.data.includes('youtube')) {
                var url = this.data
                var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                var match = url.match(regExp)
                if (match && match[2].length == 11) {
                    return match[2]
                } else {
                    return false
                }
            }
        },
        videoParse() {
            if (this.data.includes('youtube')) {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                const match = this.data.match(regExp)
                var videoID = match && match[2].length === 11 ? match[2] : null
                return 'https://www.youtube-nocookie.com/embed/' + videoID
            } else if (this.data.includes('vimeo')) {
                var regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/
                var match = this.data.match(regExp)
                return 'https://player.vimeo.com/video/' + match[3]
            }
            return ''
        }
    },
    created() {},
    mounted() {
        this.videoParse()
    },
    watch: {
        videoString(oldVal, newVal) {
            if (oldVal) {
                this.mounted = true
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#data-container {
    width: 100%;
    height: 100%;
    height: 90%;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 5%;

    .data-container {
        width: 100%;
        height: 100%;

        .data-description {
            @include font-size(md);
            text-align: center;
            font-family: $text-light;
            padding-bottom: 20px;

            a {
                color: $color-primary-500;
                text-decoration: underline;
            }
        }
        .data {
            width: 100%;
            height: 100%;
            .player {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
