import Vue from 'vue'
import { Api } from '@/core/api.js'
import i18n from '@/i18n'
const api = new Api()

// initial state
const getDefaultState = () => {
    return {
        external_tools: {},
        filters: {},
        organization: {
            account: {}
        },
        filtersExtToolsActive: {
            status: [{ id: 1, name: 'tools.groups.status.active' }],
            type_date: 'week'
        },
        itemsLoaded: false,
        timezones: {}
    }
}
const state = getDefaultState()

// getters
const getters = {
    getList: (state, getters, rootState) => (id) => {
        if (id) {
            return state.external_tools[id]
        }
        return state.external_tools
    },
    getOrganization: (state, getters, rootState) => {
        return state.organization
    },

    getFilterExternalTools: (state, getters, rootState) => {
        return state.filters
    },

    getFiltersExtToolsActive: (state, getters, rootState) => {
        return state.filtersExtToolsActive
    },
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },
    getTimeZones: (state, getters, rootState) => {
        return state.timezones
    },
    getTemporalTemplatesFlag: (state, getters, rootState) => {
        if (state.organization.account.config && state.organization.account.config.tplTemporary) {
            return state.organization.account.config.tplTemporary
        } else return 0 // in case config doesn't have tplTemporary
    },
    getScreenlockFlag: (state, getters, rootState) => {
        if (state.organization.account.config && state.organization.account.config.screenlock) {
            return state.organization.account.config.screenlock
        } else return 0 
    },
    getReportPdf: (state, getters, rootState) => {
        if (state.organization.account.config && state.organization.account.config.reportPdf) {
            return state.organization.account.config.reportPdf
        } else return 0 // in case config doesn't have reportPdf
    }
}

// actions
const actions = {
    loadExtToolList(context, params) {
        return api.post('admin/account/externaltools', params).then(function (response) {
            if (response) {
                context.commit('setExternalTools', response.data)
            }
        })
    },
    loadListOrganization(context, params) {
        return api.post('admin/account/organization', params).then(function (response) {
            if (response) {
                context.commit('setOrganization', response.data)
            }
        })
    },
    updateModules(context, params) {
        return api.post('admin/account/tools/update', params).then(function (response) {
            if (response) {
                return true
            }
        })
    },
    editExternalTool(context, params) {
        return api.post('admin/account/externaltools/' + params.id + '/edit', params).then(function (response) {
            if (response.status) {
                // response.data.locations = Object.values(response.data.locations)
                context.commit('editExternalTool', response.data)
            } else {
                // console.log(response.status);
            }
        })
    },
    editOrganization(context, params) {
        return api.post('admin/account/organization/edit', params).then(function (response) {
            if (response.status) {
                context.commit('setEditOrganization', response.data)
                return response.data
            }
        })
    },
    addExternalTool(context, params) {
        return api.post('/admin/account/externaltools/add', params).then(function (response) {
            if (response.status) {
                var external_tools = response.data
                if (external_tools.locations) {
                }
                context.commit('addExternalTool', external_tools)
                return response
            }
        })
    },
    loadFilters(context) {
        // console.log(params);
        return api.get('admin/account/externaltools/filter').then(function (response) {
            context.commit('setFilterexternalTools', response.data)
        })
    },
    deleteExternalTool(context, params) {
        return api.get('admin/account/externaltools/' + params + '/delete').then(function (response) {
            context.commit('setDeleteExternalTool', params)
        })
    },
    loadTimeZones(context) {
        return api.get('timezones').then((response) => {
            context.commit('setTimeZones', response.data)
        })
    }
}

// mutations
const mutations = {
    setExternalTools(state, response) {
        if (response) {
            Vue.set(state, 'external_tools', { ...response })
            state.itemsLoaded = true
        }
    },
    setOrganization(state, response) {
        if (response) {
            state.organization = response
            Vue.set(state, 'organization', { ...response })
        }
    },
    addExternalTool(state, params) {
        var external_tools = { ...state.external_tools }
        external_tools[params.id] = params
        state.external_tools = external_tools
        Vue.set(state, 'external_tools', { ...external_tools })
    },
    editExternalTool(state, params) {
        var external_tools = { ...state.external_tools }
        external_tools[params.id] = params
        state.external_tools = { ...external_tools }
        Vue.set(state, 'external_tools', { ...external_tools })
    },
    setEditOrganization(state, params) {
        var organization = { ...state.organization }
        organization.account = params
        state.organization = { ...organization }
        // rootState.commit("changeLanguage", organization.lang);
        Vue.set(state, 'organization', { ...organization })
    },
    setDeleteExternalTool(state, params) {
        var external_tools = state.external_tools
        delete external_tools[params]
        state.external_tools = external_tools
        Vue.set(state, 'external_tools', { ...external_tools })
    },
    setFilterexternalTools(state, payload) {
        var filters = state.filters
        filters = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },

    setFiltersExtToolsActive(state, filters) {
        state.filtersExtToolsActive = filters
        Vue.set(state, 'filtersExtToolsActive', { ...filters })
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    setTimeZones(state, timezones) {
        state.timezones = timezones
        Vue.set(state, 'timezones', { ...timezones })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
