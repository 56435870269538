<template>
    <div id="release-container">
        <!-- <div class="image-release" v-if="image">
            <img :src="image" />
        </div> -->
        <div class="title-release">
            {{ data.title }}

            <div class="content-release" v-html="data.text"></div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import router from '@/router'

export default {
    props: {
        data: { type: undefined, default: '' },
        image: { type: undefined, default: false }
    },
    data() {
        return {
            selectedEmails: [],
            linkList: false
        }
    },
    computed: {},
    methods: {
        translation(translation) {
            return i18n.t(translation)
        },
        linkAction(a) {
            var nameref = a.href
            if (a.className == 'panel') {
                nameref = nameref.replace(window.location.origin, '')
                if (a.target == '_blank') {
                    window.open(nameref, 'blank')
                } else {
                    router.push(nameref)
                }
            } else {
                if (a.target == '_blank') {
                    window.open(a.href, '_blank')
                } else {
                    location.href = a.href
                }
            }
        }
    },
    created() {},
    mounted() {
        var self = this
        setTimeout(function () {
            self.linkList = document.getElementsByTagName('a')
            for (var i = 0; i < self.linkList.length; i++) {
                self.linkList[i].onclick = function (event) {
                    event.preventDefault()
                    self.linkAction(event.target)
                }
            }
        }, 300)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#release-container {
    @include display-flex();
    width: 100%;

    .image-release {
        width: 300px;
        height: auto;
        margin-right: 30px;
    }
    .title-release {
        @include font-size(xl);
        font-family: $text-bold;
        color: $color-black;
        padding-bottom: 20px;
        height: auto;
        max-height: 100%;

        .content-release {
            img {
                width: 100%;
                height: auto;
                max-width: 400px;
            }
            div {
                @include font-size(md);
                font-family: $text-medium;
                color: $color-black;
                padding-top: 6px;
                padding-bottom: 6px;
            }
            p {
                @include font-size(md);
                font-family: $text-medium;
                color: $color-black;
                padding: 6px 0px;
            }
            ul {
                padding-top: 6px;
                padding-bottom: 6px;
                margin-left: 30px;
                li {
                    @include font-size(md);
                    font-family: $text-medium;
                    color: $color-black;
                    list-style: disc;
                }
            }
            ul li::marker {
                font-size: 0.8em;
            }
            ol {
                padding-top: 6px;
                padding-bottom: 6px;
                margin-left: 30px;
                li {
                    @include font-size(md);
                    font-family: $text-medium;
                    color: $color-black;
                    list-style: decimal;
                }
            }
            h1 {
                @include font-size(lg);
                font-family: $text-bold;
                color: $color-black;
            }
            h2 {
                @include font-size(ml);
                font-family: $text-bold;
                color: $color-black;
            }
            a {
                font-size: inherit;
                color: $color-primary-500;
                text-decoration: underline;
                text-decoration-color: $color-primary-500;
            }
            .link-buttons {
                @include align-items(center);
                @include background($image: img('right_arrow_secondary.svg'), $position: right 6px center, $size: 12px);
                @include border-radius(4px);
                @include font-size(xs);
                background-color: $color-neutral-300;
                padding-right: 25px;
                padding-left: 12px;
                box-shadow: 0px 0px 6px rgba(119, 119, 119, 0.1);
                height: 40px !important;
                width: fit-content;
                display: inline-flex;
                margin-right: 6px;
                color: $color-primary-500;
                font-family: $text-bold;
                text-decoration: none;

                &:hover {
                    background-color: hover(#fff);
                }
            }
            .footer {
                @include font-size(xs);
                color: $color-neutral-600;
                font-family: $text-medium;
            }
        }
    }
}
</style>
