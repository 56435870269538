<template>
    <div class="input text-editor">
        <bubble-menu v-if="editor && type == 'inline'" :editor="editor">
            <span v-if="actions.bold" @click="editor.chain().focus().toggleBold().run()" v-tooltip="{ content: $t('assets.text_editor.bold') }" class="opt bold" :class="{ 'is-active': editor.isActive('bold') }"> </span>
            <span v-if="actions.italic" @click="editor.chain().focus().toggleItalic().run()" v-tooltip="{ content: $t('assets.text_editor.italic') }" class="opt italic" :class="{ 'is-active': editor.isActive('italic') }"> </span>
            <span v-if="actions.underline" @click="editor.chain().focus().toggleUnderline().run()" v-tooltip="{ content: $t('assets.text_editor.underline') }" class="opt underline" :class="{ 'is-active': editor.isActive('underline') }"> </span>
            <span v-if="actions.clear" @click="editor.chain().focus().unsetAllMarks().run()" v-tooltip="{ content: $t('assets.text_editor.clear') }" class="opt clear"> </span>
            <span v-if="actions.list_bullet" @click="editor.chain().focus().toggleBulletList().run()" v-tooltip="{ content: $t('assets.text_editor.list_bullet') }" class="opt list-bullet" :class="{ 'is-active': editor.isActive('bulletList') }"> </span>
            <span v-if="actions.list_ordered" @click="editor.chain().focus().toggleOrderedList().run()" v-tooltip="{ content: $t('assets.text_editor.list_ordered') }" class="opt list-ordered" :class="{ 'is-active': editor.isActive('orderedList') }"> </span>
            <span v-if="actions.undo" @click="editor.chain().focus().undo().run()" v-tooltip="{ content: $t('assets.text_editor.undo') }" class="opt undo"> </span>
            <span v-if="actions.redo" @click="editor.chain().focus().redo().run()" v-tooltip="{ content: $t('assets.text_editor.redo') }" class="opt redo"> </span>
        </bubble-menu>
        <div v-if="editor && type == 'full'" class="editor-actions">
            <span v-if="actions.bold" @click="editor.chain().focus().toggleBold().run()" v-tooltip="{ content: $t('assets.text_editor.bold') }" class="opt bold" :class="{ 'is-active': editor.isActive('bold') }"> </span>
            <span v-if="actions.italic" @click="editor.chain().focus().toggleItalic().run()" v-tooltip="{ content: $t('assets.text_editor.italic') }" class="opt italic" :class="{ 'is-active': editor.isActive('italic') }"> </span>
            <span v-if="actions.underline" @click="editor.chain().focus().toggleUnderline().run()" v-tooltip="{ content: $t('assets.text_editor.underline') }" class="opt underline" :class="{ 'is-active': editor.isActive('underline') }"> </span>
            <span v-if="actions.clear" @click="editor.chain().focus().unsetAllMarks().run()" v-tooltip="{ content: $t('assets.text_editor.clear') }" class="opt clear"> </span>
            <span v-if="actions.list_bullet" @click="editor.chain().focus().toggleBulletList().run()" v-tooltip="{ content: $t('assets.text_editor.list_bullet') }" class="opt list-bullet" :class="{ 'is-active': editor.isActive('bulletList') }"> </span>
            <span v-if="actions.list_ordered" @click="editor.chain().focus().toggleOrderedList().run()" v-tooltip="{ content: $t('assets.text_editor.list_ordered') }" class="opt list-ordered" :class="{ 'is-active': editor.isActive('orderedList') }"> </span>
            <span v-if="actions.undo" @click="editor.chain().focus().undo().run()" v-tooltip="{ content: $t('assets.text_editor.undo') }" class="opt undo"> </span>
            <span v-if="actions.redo" @click="editor.chain().focus().redo().run()" v-tooltip="{ content: $t('assets.text_editor.redo') }" class="opt redo"> </span>
        </div>

        <editor-content class="editor" :editor="editor" />
        <div v-if="limit" class="character-count" :class="{ error: (editor ? editor.getCharacterCount() : 0) >= limit }">{{ editor ? editor.getCharacterCount() : '0' }}/{{ limit }}</div>
    </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import { defaultExtensions } from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import Placeholder from '@tiptap/extension-placeholder'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'

export default {
    components: {
        EditorContent,
        BubbleMenu
    },
    props: {
        value: {
            type: undefined,
            default: ''
        },
        gActions: { type: [String, Array], default: 'all' },
        type: { type: String, default: 'full' },
        placeholder: { type: String, default: '' },
        limit: { type: [Number, Boolean], default: false }
    },
    data() {
        return {
            editor: null,
            editorTypes: ['full', 'inline'],
            actionGroups: ['all', 'text', 'list', 'util'],
            editorActions: { bold: false, italic: false, underline: false, clear: false, list_bullet: false, list_ordered: false, undo: false, redo: false }
        }
    },
    computed: {
        actions() {
            var result = JSON.parse(JSON.stringify(this.editorActions))
            var actions = []
            let givenActions = []

            if (typeof this.gActions == 'string') {
                givenActions.push(this.gActions)
            } else {
                givenActions = this.gActions
            }

            givenActions.forEach(function (element) {
                switch (element) {
                    case 'bold':
                        actions.push('bold')
                        break
                    case 'italic':
                        actions.push('italic')
                        break
                    case 'underline':
                        actions.push('underline')
                        break
                    case 'clear':
                        actions.push('clear')
                        break
                    case 'list_bullet':
                        actions.push('list_bullet')
                        break
                    case 'list_ordered':
                        actions.push('list_ordered')
                        break
                    case 'undo':
                        actions.push('undo')
                        break
                    case 'redo':
                        actions.push('redo')
                        break
                    case 'text':
                        actions.push('bold')
                        actions.push('italic')
                        actions.push('underline')
                        actions.push('clear')
                        break
                    case 'list':
                        actions.push('list_bullet')
                        actions.push('list_ordered')
                        break
                    case 'util':
                        actions.push('undo')
                        actions.push('redo')
                        break
                }
            })

            if (givenActions.indexOf('all') > -1) {
                for (const key in result) {
                    const act = result[key]
                    result[key] = true
                }
            }

            for (const key in actions) {
                const act = actions[key]

                result[act] = !result[act]
            }

            // for (const key in result) {
            //     const act = result[key]

            //     if (givenActions.indexOf('all') > -1) {
            //         result[key] = true
            //     }
            //     if (actions.indexOf(key) > -1) {
            //         console.log(key)
            //         console.log(actions.indexOf(key))
            //         console.log('----')
            //         result[key] = !act
            //     }
            // }

            return result
        }
    },
    methods: {},
    watch: {
        value(value) {
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = this.editor.getJSON().toString() === value.toString()

            if (isSame) {
                return
            }

            this.editor.commands.setContent(this.value, false)
        }
    },
    mounted() {
        this.editor = new Editor({
            extensions: [
                ...defaultExtensions(),
                Typography,
                Underline,
                Placeholder.configure({
                    placeholder: this.placeholder
                }),
                CharacterCount.configure({
                    limit: this.limit
                })
            ],
            content: this.value,
            onUpdate: () => {
                // HTML
                var result = this.editor.getHTML()

                if (result.indexOf('</strong>')) {
                    result = result.replace(new RegExp('</strong>', 'g'), '</b>')
                    result = result.replace(new RegExp('<strong', 'g'), '<b')

                    this.$emit('input', result)
                } else {
                    this.$emit('input', result)
                }

                // JSON
                // this.$emit('input', this.editor.getJSON())
            }
        })
    },
    beforeDestroy() {
        this.editor.destroy()
    }
}
</script>

<style lang="scss">
.input.text-editor {
    height: auto !important;
    max-height: unset;

    .editor {
        width: auto;
        height: auto;
    }

    /* Basic editor styles */
    .ProseMirror {
        &,
        > * {
            border: unset !important;
            outline: unset !important;
        }

        > * {
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
        }

        // HTML Content styles
        ul,
        ol {
            padding: 0 0 0 24px;
        }
        ul {
            list-style: disc;
        }
        ol {
            list-style: decimal;
        }

        strong,
        b {
            font-family: $text-bold;
            // font-weight: bold;
            font-style: inherit;
            text-decoration: inherit;
        }
        em,
        i {
            font-family: inherit;
            // font-weight: inherit;
            font-style: italic;
            text-decoration: inherit;
        }
        u {
            font-family: inherit;
            // font-weight: inherit;
            font-style: inherit;
            text-decoration: underline;
        }
    }

    // Acciones (Bold, italic...)
    .editor-actions,
    .tippy-box {
        @include display-flex();
        @include border-radius(3px);
        @include default-box-shadow();
        background-color: #fff;
        padding: 6px 3px 3px 3px;

        .opt {
            @extend .interaction;
            @include border-radius(3px);
            @include background($color: #fff, $size: 12px);
            display: inline-block;
            width: 30px;
            height: 30px;
            margin: 0 3px;

            &.is-active {
                background-color: $color-primary-100;
                @include bgHover($color-primary-100);
            }
            @include bgHover(#fff);

            &.bold {
                background-image: img('editor_bold_inactive_s90.svg');
            }
            &.italic {
                background-image: img('editor_italic_inactive_s90.svg');
            }
            &.underline {
                background-image: img('editor_underline_inactive_s90.svg');
            }
            &.clear {
                background-image: img('editor_clear_inactive_s90.svg');
            }
            &.list-bullet {
                background-image: img('editor_list_bullet_inactive_s90.svg');
            }
            &.list-ordered {
                background-image: img('editor_list_ordered_inactive_s90.svg');
            }
            &.undo {
                background-image: img('editor_undo_inactive_s90.svg');
            }
            &.redo {
                background-image: img('editor_redo_inactive_s90.svg');
            }
        }
    }
    // Acciones en FULL editor
    .editor-actions {
        box-shadow: unset;
        background-color: transparent;
        height: 30px;
        width: 100%;
        padding: 0;
        margin: 3px 0 6px 0;

        .opt {
            // @include default-box-shadow($b: 6px, $o: 0.1);
            border: 1px solid $color-neutral-50;
            margin: 0 6px 0 0;
        }
    }

    .character-count {
        @include font-size(xs);
        font-family: $text-bold;
        text-align: right;
        color: $color-neutral-300;

        &.error {
            color: $color-error-500;
        }
    }

    /* Placeholder (at the top) */
    .ProseMirror p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: $color-neutral-300;
        font-family: $text-medium;
        pointer-events: none;
        height: 0;
    }
    /* Placeholder (on every new line) */
    // .ProseMirror p.is-empty::before {
    //     content: attr(data-placeholder);
    //     float: left;
    //     color: #ced4da;
    //     pointer-events: none;
    //     height: 0;
    // }
}
</style>
