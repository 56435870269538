import i18n from '@/i18n'
import Vue from 'vue'
import { Api } from '@/core/api.js'

import store from '@/store'
import localforage from 'localforage'
const api = new Api()

var db_employees = localforage.createInstance({ name: 'ALEXCENTRAL', storeName: 'employee' })

// initial state
const getDefaultState = () => {
    return {
        employees: {},
        account_employees: {},
        filtersActive: {
            status: [
                { id: 1, name: 'tools.groups.status.active' },
                { id: 2, name: 'user.filters.pending' }
            ],
            type_date: 'week',
            dashboard_access: 2
        },
        filters: {
            roles: typeof localStorage.roles !== 'undefined' ? JSON.parse(localStorage.roles) : {},
            sites: {},
            groups: {},
            status: {}
        },
        rolesFiltersActive: {
            name: ''
        },
        tableRoles: {},
        roles: {},
        itemsLoaded: false,
        itemsRolesLoaded: false,
        hasLoadFilters: true,
        notifications: {},
        newRole: {
            id: false,
            name: '',
            rol_id: ''
        },
        defaultRoles: {},
        sameLowerLevelRoleUser: {}
    }
}
const state = getDefaultState()

// getters
const getters = {
    getList: (state, getters, rootState) => {
        return state.employees
    },
    getDefaultRoles: (state, getters, rootState) => {
        return state.defaultRoles
    },
    getSameLowerLevelRoleUser: (state, getters, rootState) => {
        return state.sameLowerLevelRoleUser
    },
    getHasLoadFilters: (state, getters, rootState) => {
        return state.hasLoadFilters
    },
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },
    getItemsRolesLoaded: (state, getters, rootState) => {
        return state.itemsRolesLoaded
    },

    getAllEmployees: (state, getters, rootState) => {
        return state.employees
    },
    getFilterRoles: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.roles[id]
        }
        return state.filters.roles
    },
    getFilterSites: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.sites[id]
        }
        return state.filters.sites
    },
    getFilterGroups: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.groups[id]
        }
        return state.filters.groups
    },

    getFilterStatus: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.status[id]
        }
        return state.filters.status
    },

    getRoles: (state, getters, rootState) => (id) => {
        if (id) {
            return state.roles[id]
        }
        return state.roles
    },
    getTableRoles: (state, getters, rootState) => (id) => {
        if (id) {
            return state.tableRoles[id]
        }
        return state.tableRoles
    },
    getRolesFilters: (state, getters, rootState) => {
        return state.rolesFiltersActive
    },

    getRol: (state, getters, rootState) => (id) => {
        if (id) {
            return state.roles[id]
        }

        return state.roles
    },

    getNameRoles: (state, getters, rootState) => {
        var roles = []

        Object.keys(state.roles).map(function (id, i) {
            var val = state.roles[id]
            roles.push(val.name)
        })

        return roles
    },

    getClockEmployees: (state, getters, rootState) => {
        var result = state.employees
        return result
    },

    getEmployee: (state, getters, rootState) => (id) => {
        return state.employees[id]
    },

    getAccountEmployee: (state, getters, rootState) => (id) => {
        return state.account_employees[id]
    },
    getAllEmployees: (state, getters, rootState) => {
        return state.account_employees
    },

    getAllUsers: (state, getters, rootState) => {
        var employees = []
        for (var k in state.employees) {
            state.employees[k].fullname = `${state.employees[k].name} ${state.employees[k].surname}`
            if (parseInt(state.employees[k].visible) != 0) {
                employees[k] = state.employees[k]
            }
        }
        return employees
    },

    getEmployeeAccess: (state, getters, rootState) => (id, level) => {
        if (state.employees[id].type >= level) {
            return true
        } else {
            return false
        }
    },

    validateAccess: (state, getters, rootState) => (user_id, pass) => {
        var user = state.employees[user_id]

        if (user && user.password == sha256(pass)) return true
        else return false
    },

    getFiltersActive: (state, getters, rootState) => {
        return state.filtersActive
    },

    getNotifications: (state, getters, rootState) => {
        return state.notifications
    }
}

// actions
const actions = {
    loadList(context, params) {
        const url = typeof params.visible !== undefined ? `admin/employee/${params.visible}` : 'admin/employee'
        api.post(url, params).then(function (response) {
            var data = format_employees(response)
            context.commit('setEmployees', data)

            _.forEach(data, function (value, key) {
                db_employees.setItem(key, value)
            })

            context.rootState.refresh.employee = false
        })
    },
    loadLevelTwoOrMoreRoles(context) {
        var roles = []
        if (Object.values(state.roles).length > 0) {
            for (var role in state.roles) {
                var idx = state.roles[role]
                if (idx.level >= 2 && idx.level <= 8) {
                    roles.push(idx)
                }
            }
        }
        roles = roles.reduce((obj, item) => {
            obj[item.id] = item
            return obj
        }, {})
        context.commit('serRolesLevelTwoOrMore', roles)
    },

    addEmployee(context, params) {
        // console.log('addEmployee');

        return api.post('admin/employee/add', params).then(function (response) {
            if (response.status) {
                context.commit('setEmployees', response)
            } else {
                // console.log(response.status);
            }
        })
    },
    editEmployee(context, params) {
        // console.log('editEmployee');

        return api.post('admin/employee/edit/' + params.id, params).then(function (response) {
            if (response.status) {
                context.commit('setEmployees', response)
            } else {
                // console.log(response.status);
            }
        })
    },
    emailForgot(context, params) {
        return api.post('admin/employee/rememberpassword', params).then(function (response) {
            if (response.status) {
                return response
            } else {
                return response
            }
        })
    },
    employeeNewInfo(context, params) {
        return api.post('welcome/employee/' + params.id + '/information', { resetPassword: params.resetPassword }).then(function (response) {
            return response
        })
    },

    sendInvitation(context, params) {
        return api.post('admin/employee/' + params.id + '/dashboardaccess/' + params.lang, {}).then(function (response) {
            return response
        })
    },
    requestResetPass(context, params) {
        return api.get('admin/employee/' + params.id + '/rememberpassword/' + params.lang, {}).then(function (response) {
            return response
        })
    },

    // TODO
    updateLocation(context, emp_id, locations) {
        // console.log('updateLocation');

        // id: employee_id, locations: array con ids de locations
        return api.post('admin/employee/location/update/' + emp_id, locations).then(function (response) {
            if (response.status) {
                context.commit('setEmployees', response)
            } else {
                // console.log(response.status);
            }
        })
    },

    doClock(context, params) {
        var responseState = false

        return api
            .post('employee/clock/' + params.type, { employee: params.employee, password: params.password })
            .then(function (response) {
                context.commit('clocked', params)
                if (response.status) responseState = true
            })
            .then(function () {
                return responseState
            })
    },
    addUser(context, params) {
        return api.post('employee/add', params).then(function (response) {
            if (response.status) {
                // console.log(response)
                context.commit('addUser', response.data)
                return response
            }
        })
    },
    addRole(context, params) {
        var role = context.getters['getTableRoles'](params)
        return api.post('admin/employee/role/add', role).then(function (response) {
            if (response.status) {
                context.commit('addRole', response.data)
                return response
            }
        })
    },
    editRole(context, params) {
        var role = context.getters['getTableRoles'](params)
        return api.post('admin/employee/role/' + params + '/edit', role).then(function (response) {
            if (response.status) {
                context.commit('editRole', response.data)
                return response
            }
            return true
        })
    },
    editUser(context, params) {
        return api.post('employee/edit/' + params.id, params).then(function (response) {
            if (response.status) {
                context.commit('editUser', response.data)
                return response
            }
            return true
        })
    },
    editUserLogged(context, params) {
        return api.post('employee/edit/' + params.id, params).then(function (response) {
            if (response.status) {
                var employee = response.data
                context.commit('setEditUserLogged', employee)

                if (response.token) {
                    localStorage.setItem('token', response.token)
                }

                if (response.data && response.data.language != null) {
                    const aLanguage = response.data.language.split('_')
                    context.commit('changeLanguage', aLanguage[0], { root: true })
                }
            }
            return response.data
        })
    },
    identificationUsed(context, params) {
        return api.post('admin/employee/identification', params).then(function (response) {
            if (response.status) {
                return response.data
            }
        })
    },
    emailCheck(context, params) {
        return api.post('admin/employee/email/disponibility', params).then(function (response) {
            if (response.status) {
                return response.data
            }
        })
    },

    loadEmployees(context, params) {
        return api.get('admin/employee', {}).then(function (response) {
            var data = format_employees(response)
            context.commit('setEmployees', data)

            //   _.forEach(data, function (value, key) {
            //     db_employees.setItem(key, value)
            //   })

            context.rootState.refresh.employee = false
            return true
        })
    },
    loadAccountEmployees(context, params) {
        return api.get('admin/employee/0', {}).then(function (response) {
            var data = format_employees(response)
            context.commit('setAccountEmployees', data)

            //   _.forEach(data, function (value, key) {
            //     db_employees.setItem(key, value)
            //   })

            context.rootState.refresh.employee = false
            return true
        })
    },
    loadFilters(context) {
        return api.get('admin/employee/filter').then(function (response) {
            context.commit('setFilterRoles', response.data.roles)
            context.commit('setFilterSites', response.data.sites)
            context.commit('setFilterGroups', response.data.groups)
            context.commit('setFilterStatus', response.data.status)
        })
    },
    loadRolesList(context, params) {
        return api.post('admin/employee/roles', params).then(function (response) {
            context.commit('setFilterRoles', response.data)
            // context.commit('setRoles', response.data)
            context.commit('setTablesRoles', response.data)
        })
    },
    loadDefaultRolesList(context) {
        return api.get('admin/employee/defaultroles').then(function (response) {
            context.commit('setDefaultRoles', response.data)
        })
    },
    loadTableRolesList(context, params) {
        return api.post('admin/employee/roles', params).then(function (response) {
            context.commit('setTablesRoles', response.data)
        })
    },
    addNewRole(context, params) {
        var newRoleTable = Object.assign({}, context.state.newRole)
        newRoleTable.id = 'tmpitw' + moment().unix()
        context.commit('createNewRole', newRoleTable)
        return newRoleTable.id
    },
    validateRole(context, params) {
        var roleToValidate = context.getters['getTableRoles'](params.id)
        var realRoles = context.getters['getRol'](params.id)
        var errors = {
            name: false,
            rol_id: false,
            rol_changed: false,
            new_same_name: false
        }
        var sendForm = true
        errors = _.mapValues(errors, () => false)

        if (roleToValidate.name === '') {
            sendForm = false
            errors.name = true
        }
        if (roleToValidate.rol_id === '') {
            sendForm = false
            errors.rol_id = true
        }
        if (params.validateName) {
            var roles = context.getters['getRoles']()
            for (var rol in roles) {
                var idx = roles[rol]
                if (idx.name == roleToValidate.name && idx.id != roleToValidate.id) {
                    errors.new_same_name = true
                    sendForm = false
                }
            }
        }
        if (realRoles != undefined) {
            if (roleToValidate.rol_id != realRoles.rol_id) {
                errors.rol_changed = true
            }
        }
        return {
            status: sendForm,
            errors: errors
        }
    },
    deleteRole(context, params) {
        return api.get('admin/employee/role/' + params + '/delete').then(function (response) {
            context.commit('setdeleteRole', params)
        })
    },
    deleteUser(context, params) {
        return api.get('admin/employee/' + params + '/delete').then(function (response) {
            context.commit('setdeleteUser', params)
        })
    },

    loadNotifications(context) {
        return api.get('admin/employee/notification', {}).then(function (response) {
            if (response) {
                context.commit('setNotifications', response.data)
            }
        })
    },
    editNotifications(context, params) {
        return api.post('admin/employee/notification/save', params).then(function (response) {
            if (response.status) {
                context.commit('setNotifications', response.data)
            }
            return response.data
        })
    }
}

// mutations
const mutations = {
    setEmployees(state, response) {
        state.employees = response
        Vue.set(state, 'employees', { ...response })
        state.itemsLoaded = true
    },

    setAccountEmployees(state, response) {
        state.account_employees = response
        Vue.set(state, 'account_employees', { ...response })
    },

    addRole(state, params) {
        // var roles = state.roles
        var rolesTables = state.tableRoles
        // roles[params.id] = params
        rolesTables[params.id] = params
        // state.roles = roles
        state.tableRoles = rolesTables
        // Vue.set(state, 'roles', { ...roles })
        Vue.set(state, 'tableRoles', { ...rolesTables })
    },
    serRolesLevelTwoOrMore(state, params) {
        Vue.set(state, 'sameLowerLevelRoleUser', { ...params })
    },
    editRole(state, params) {
        // var roles = state.roles
        var rolesTables = state.tableRoles
        // roles[params.id] = params
        rolesTables[params.id] = params
        // state.roles = roles
        state.tableRoles = rolesTables
        // Vue.set(state, 'roles', { ...roles })
        Vue.set(state, 'tableRoles', { ...rolesTables })
    },
    setdeleteRole(state, params) {
        // var roles = state.roles
        var rolesTables = state.tableRoles
        // delete roles[params]
        delete rolesTables[params]
        // state.roles = roles
        state.tableRoles = rolesTables
        // Vue.set(state, 'roles', { ...roles })
        Vue.set(state, 'tableRoles', { ...rolesTables })
    },
    addUser(state, params) {
        var employees = { ...state.employees }
        var account_employees = { ...state.account_employees }

        params.avatar = params.avatar ? css_image_url + 'avatar/' + params.avatar : ''

        employees[params.id] = params
        state.employees = employees
        Vue.set(state, 'employees', { ...employees })

        account_employees[params.id] = params
        state.account_employees = account_employees
        Vue.set(state, 'account_employees', { ...account_employees })
    },
    editUser(state, params) {
        var employees = { ...state.employees }
        var account_employees = { ...state.account_employees }

        params.avatar = params.avatar ? css_image_url + 'avatar/' + params.avatar : ''

        employees[params.id] = params
        state.employees = employees
        Vue.set(state, 'employees', { ...employees })

        account_employees[params.id] = params
        state.account_employees = account_employees
        Vue.set(state, 'account_employees', { ...account_employees })
    },
    setEditUserLogged(state, params) {
        var employees = { ...state.account_employees }
        params.avatar = params.avatar ? css_image_url + 'avatar/' + params.avatar : ''
        employees[params.id] = params
        state.account_employees = employees
        Vue.set(state, 'account_employees', { ...employees })
    },
    setdeleteUser(state, params) {
        var employees = state.employees
        delete employees[params]
        state.employees = employees
        Vue.set(state, 'employees', { ...employees })
    },
    setFilterRoles(state, payload) {
        var filters = state.filters
        filters.roles = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },
    setFilterSites(state, payload) {
        var filters = state.filters
        filters.sites = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },
    setFilterGroups(state, payload) {
        var filters = state.filters
        filters.groups = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },
    setFilterStatus(state, payload) {
        let filters = state.filters
        filters.status = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },
    setHasLoadFilters(state, value) {
        state.hasLoadFilters = value
    },

    clocked(state, params) {
        var emp = state.employees[params.employee]
        if (params.type == 0) {
            emp.last_session.end_date = moment().unix()
        } else {
            emp.last_session.end_date = false
            emp.last_session.start_date = moment().unix()
        }
    },
    setRoles(state, payload) {
        let roles = { ...payload }
        state.roles = roles
        Vue.set(state, 'roles', roles)
        localStorage.setItem('roles', JSON.stringify(roles))
    },
    setTablesRoles(state, payload) {
        var roles = { ...payload }
        state.tableRoles = roles
        state.itemsRolesLoaded = true
        Vue.set(state, 'tableRoles', roles)
    },
    createNewRole(state, payload) {
        var roles = state.tableRoles
        roles[payload.id] = payload
        Vue.set(state, 'tableRoles', roles)
    },
    setDefaultRoles(state, payload) {
        var roles = { ...state.defaultRoles }
        roles = payload
        Vue.set(state, 'defaultRoles', roles)
    },

    setFiltersActive(state, filters) {
        state.filtersActive = filters
        Vue.set(state, 'filtersActive', { ...filters })
    },
    setRolesFilters(state, filters) {
        state.rolesFiltersActive = filters
        Vue.set(state, 'rolesFiltersActive', { ...filters })
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    setNotifications(state, response) {
        state.notifications = response
        Vue.set(state, 'notifications', { ...response })
    }
}

function format_employees(response) {
    console.log('employees', response)
    var employees = {}
    Object.keys(response.data).map(function (key, i) {
        var e = response.data[key]
        e.avatar = e.avatar ? css_image_url + 'avatar/' + e.avatar : ''
        e.image = 'url(' + e.avatar + ')'

        // console.log(e.name);
        // console.log(e);
        // if (e.locations) {
        //   var aux = [];
        //   for(var index in e.locations) {
        //     var loc = e.locations[index];
        //     if (typeof store.getters['location/getLocation'](loc.id) != 'undefined') {
        //       loc.name = store.getters['location/getLocation'](loc.id).name;
        //     aux.push(loc);
        //     }

        //   }
        //   e.locations = aux;
        // }
        if (typeof e.type !== 'undefined') {
            // console.log( e.type )
            e.type_name = state.roles[e.type] ? state.roles[e.type].name : ''
        }
        employees[e.id] = e
    })

    // console.log("--------------------");
    // console.log(employees);
    return employees
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
