<template>
    <div id="sites-popup-container">
        <div class="description">
            {{ translation('sites.popup.description') }} <b>{{ locationName }}</b
            >?
        </div>
        <div v-if="alertMsg" class="info-box warning">
            {{ alertMsg }}
        </div>
        <div class="emails">
            <div class="email" v-for="email in sendEmails" @click="toggleEmail(email.email)" :class="{ selected: selectedEmails.includes(email.email) }" :key="email.email">
                {{ translation(email.msg) + '‎‎‏‏‎‎' }} <b>{{ email.email }}</b>
            </div>
            <!-- <div class="email" @click="toggleEmail('a@a.com')" :class="{ selected: selectedEmails.includes('a@a.com') }">Enviar una copia a mi correo a@a.com</div> -->
            <div class="email" @click="toggleEmailOther" :class="{ selected: isOtherMails }">
                {{ translation('sites.popup.send_access_copy_other') }}
            </div>
            <div class="other-email-input" v-if="isOtherMails">
                <Input gType="text" :inputIcon="false" :gPlaceholder="translation('sites.popup.placeholder_other_mails')" name="mails" @blur="updateEmails" v-model="otherMailsText" :customClass="mailError"></Input>
            </div>
        </div>
        <div class="subdescription">{{ translation('sites.popup.subdescription') }}</div>
        <div class="access-box">
            <div class="row">
                <div class="label">{{ translation('sites.popup.id_location') }}</div>
                <div class="info">{{ locationId }}</div>
            </div>
            <div class="row">
                <div class="label">{{ translation('sites.form.access_key') }}</div>
                <div class="info">{{ locationKey }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        sendEmails: { type: Array, default: [] },
        alertMsg: { type: [String, Boolean], default: false },
        locationName: { type: String, default: null },
        locationId: { type: String, default: null },
        locationKey: { type: String, default: null }
    },
    data() {
        return {
            selectedEmails: [],
            otherMailsText: '',
            otherMails: [],
            isOtherMails: false,
            mailError: ''
        }
    },
    computed: {},
    methods: {
        translation(translation) {
            return i18n.t(translation)
        },

        load() {
            this.toggleEmail(this.sendEmails[0].email)
        },

        toggleEmail(email) {
            if (this.selectedEmails.includes(email)) {
                this.selectedEmails.splice(this.selectedEmails.indexOf(email), 1)
            } else {
                this.selectedEmails.push(email)
            }
            this.$emit('sendEmails', [...this.selectedEmails, ...this.otherMails])
        },

        toggleEmailOther() {
            this.isOtherMails = !this.isOtherMails

            if (!this.isOtherMails) {
                this.otherMails = []
                this.otherMailsText = ''
            }
        },
        splittedMails() {
            const splittedText = this.otherMailsText.split(',').map((mail) => mail.trim())
            this.otherMails = splittedText
        },

        updateEmails() {
            this.splittedMails()
            this.otherMails = this.otherMails.filter((mail) => mail !== '')
            const isError = !this.otherMails.every((mail) => validEmail(mail))
            this.mailError = isError ? 'error-field' : ''
            this.$emit('sendEmails', [...this.selectedEmails, ...this.otherMails])
        }
    },
    created() {
        this.load()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#sites-popup-container {
    width: 100%;
    .emails {
        margin-bottom: 20px;

        .email {
            @include display-flex();
            @include align-items();
            @include border-radius(3px);
            @include background($image: img('unchecked_aaaaaa.svg'), $position: right 12px center, $size: 16px);
            margin: 6px auto;
            width: 100%;
            padding: 9px 12px;
            padding-right: 30px;
            background-color: $color-neutral-300;
            font-family: $text-bold;
            cursor: pointer;

            &.selected {
                @include background($image: img('checked_second.svg'), $position: right 12px center, $size: 16px);
                background-color: $color-primary-100;
            }
        }

        .other-email-input {
            overflow-x: hidden;

            &.error {
                border: 1px solid red;
            }
        }
    }

    .subdescription {
        @include font-size(xs);
    }

    .access-box {
        @include border-radius(3px);
        width: fit-content;
        max-width: 100%;
        padding: 6px 12px;
        background-color: $color-neutral-50;
        margin: 9px 0;

        .row {
            @include display-flex();
            @include flex-direction(column);
            @include flex-wrap(wrap);
            margin: 9px auto;

            .label {
                @include font-size(xs);
                @include display-flex();
                font-family: $text-medium;
                color: $color-black;
            }

            .info {
                @include display-flex();
                @include font-size(md);
                font-family: $text-bold;
                color: $color-black;
                // MAKE SELECTABLE
                -moz-user-select: text;
                -khtml-user-select: text;
                -webkit-user-select: text;
                -ms-user-select: text;
                user-select: text;
            }
        }
    }
}
</style>
