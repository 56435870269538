<template>
    <div id="translateButton" v-if="organizationRender && organizationRender.availableLangs && organizationRender.currentLangs && (Object.keys(organizationRender.availableLangs).length > 0 || Object.keys(organizationRender.currentLangs).length > 0)">
        <!-- BUTTON -->
        <div v-if="selectedLang" :class="['button', 'edit']" @click="backToEdit()" v-html="$t('multi-language.back_to_edit')"></div>
        <div :class="[{ 'no-icon': selectedLang }, 'button']" @click="showPopup = !showPopup" v-html="(!selectedLang ? '' : '<b>' + selectedLang.slice(0, 2).toUpperCase() + '</b> ') + $t('multi-language.translate')"></div>
        <!-- POPUP -->
        <div v-if="showPopup" class="popup">
            <div class="row">
                <div class="left">
                    <div class="label">{{ $t('multi-language.original_language') }}:‏‏‎ ‎</div>
                </div>
                <div class="right">
                    <div :class="[{ selected: false && !selectedLang }, 'tag', 'original']">
                        {{ organizationRender.accountLanguage.name }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="left">
                    <div class="label">{{ $t('multi-language.translated_to') }}:‏‏‎ ‎</div>
                </div>
                <div class="right">
                    <template>
                        <div v-for="lang in organizationRender.currentLangs" :class="[{ selected: selectedLang == lang.code }, 'tag']" @click="selectTranslation(lang.code)" :key="lang.code">
                            <span v-html="lang.name + ' ' + (translationLangsResume && translationLangsResume[lang.code] ? '' + translationLangsResume[lang.code].translated + '/' + (parseInt(translationLangsResume[lang.code].translated) + parseInt(translationLangsResume[lang.code].missing)) + '‏‏‎ ‎<b>(' + parseInt((translationLangsResume[lang.code].translated / (translationLangsResume[lang.code].missing + translationLangsResume[lang.code].translated)) * 100) + '%) </b>' : '')"></span>

                            <div v-if="selectedLang == lang.code" @click.stop="deleteTranslation(lang.code)" class="delete"></div>
                        </div>
                    </template>
                    <v-select v-if="Object.keys(organizationRender.availableLangs).length > 0" hide-details class="select" v-model="newLangSelected" :items="Object.values(organizationRender.availableLangs)" item-text="name" item-value="code" dense :no-data-text="$t('supervise.filter.no_results')" :placeholder="$t('multi-language.select_language')" @change="newTranslation()"></v-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        entityId: String,
        translationLangs: [Array, Object]
    },
    name: 'TranslateButton',
    data() {
        return {
            // VARIABLES OF CONTROL
            showPopup: false,
            newLangSelected: false
        }
    },
    computed: {
        organizationRender() {
            var org = this.$store.getters['account/getOrganization']
            if (org && org.account && org.languages && this.translationLangs) {
                var account = org.account
                var languages = org.languages
                var availableTranslationLanguages = account.config.languages.split(',')

                var result = {
                    allLangs: languages,
                    accountLanguage: languages[account.lang],
                    currentLangs: {},
                    availableLangs: Object.values(languages).filter((language) => availableTranslationLanguages.includes(language.code) && (!Object.keys(this.translationLangs).includes(language.code) || Object.keys(this.translationLangs).length == 0) && language.code != account.lang)
                }

                if (account.config && account.config.languages && [...account.config.languages.split(',')].length > 0) {
                    Object.keys(this.translationLangs).forEach((element) => {
                        if (availableTranslationLanguages.includes(element)) {
                            result.currentLangs[element] = languages[element]
                        }
                    })
                }

                return result
            }
            return false
        },

        selectedLang() {
            return this.$store.getters['multilanguage/getTranslationLang']
        },

        translationLangsResume() {
            return this.$store.getters['multilanguage/getTranslationLangsResume']
        },

        hasBeenTranslated() {
            return this.$store.getters['multilanguage/getHasBeenTranslated']
        }
    },
    methods: {
        // TRANSLATION METHODS
        backToEdit() {
            this.$store.dispatch('multilanguage/saveTranslations')
            this.selectTranslation(false)
        },

        selectTranslation(lang) {
            this.$store.commit('multilanguage/setTranslationLang', lang)
            // this.showPopup = false

            window.addEventListener('beforeunload', (event) => {
                if (!this.hasBeenTranslated) return
                event.preventDefault()
                event.returnValue = ''
            })
        },

        newTranslation(e) {
            var self = this
            this.$store.commit('multilanguage/setAutoTranslateMode', false)
            this.$popup.confirm({
                title: self.$t('multi-language.help.title'),
                message: self.$t('multi-language.help.description'),
                textCancel: self.$t('multi-language.help.cancel'),
                textSave: self.$t('multi-language.help.save'),
                callSave: function () {
                    self.$store.commit('multilanguage/setAutoTranslateMode', true)
                    self.selectTranslation(self.newLangSelected)
                    self.$emit('newTranslation', self.newLangSelected)
                    self.newLangSelected = false
                    self.showPopup = false
                    self.$forceUpdate()
                    self.$popup.close()
                },
                callCancel: function () {
                    self.selectTranslation(self.newLangSelected)
                    self.$emit('newTranslation', self.newLangSelected)
                    self.newLangSelected = false
                    self.showPopup = false
                    self.$forceUpdate()
                    self.$popup.close()
                }
            })
            setInterval(() => {
                self.$store.commit('multilanguage/setAutoTranslateMode', false)
            }, 5000)
        },

        deleteTranslation(code) {
            var self = this

            this.$popup.confirm({
                title: self.$t('multi-language.delete.title', { s: self.organizationRender && self.organizationRender.allLangs && self.organizationRender.allLangs[code] ? self.organizationRender.allLangs[code].name : '' }),
                message: self.$t('multi-language.delete.description'),
                textSave: self.$t('multi-language.delete.save'),
                callSave: function () {
                    self.$store.dispatch('multilanguage/saveTranslations').then(function () {
                        self.$emit('deleteTranslation', code)
                        self.selectTranslation(false)
                        self.$store.dispatch('multilanguage/deleteTranslation', { type: self.entityId.split('.')[0], id: self.entityId.split('.')[1], language: code }).then(function () {
                            self.load()
                        })
                    })
                }
            })
        },

        // GENERAL METHODS
        load() {
            var self = this
            if (!this.organizationRender) {
                this.$overlay.show()
                this.$store.dispatch('account/loadListOrganization', {}).then(function () {
                    self.$overlay.hide()
                })
            }

            if (this.entityId) {
                this.$store.commit('multilanguage/setKeyname', this.entityId)
                this.$store.dispatch('multilanguage/loadTranslations', { entity: this.entityId.split('.')[0], id: this.entityId.split('.')[1] })
            }
        }
    },
    created() {
        this.load()
    },
    beforeDestroy() {
        this.$store.commit('multilanguage/setTranslationLang', false)
        window.removeEventListener('beforeunload', (event) => {})
    }
}
</script>

<style lang="scss">
#translateButton {
    display: flex;
    align-items: center;
    flex-direction: row;

    .button {
        @include display-inline-flex();
        @include border-radius(4px);
        @include font-size(sm);
        @include background($image: img('translate.svg'), $color: $color-neutral-200, $size: 18px, $position: 11px center);
        @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-600, 0.05));
        cursor: pointer;
        padding: 8px 9px 8px 35px;
        margin: 0 0 0 12px;
        height: auto;
        width: max-content;
        max-width: unset;
        font-family: $text-bold;
        color: $color-black;
        line-height: initial;
        // background-image: img('translate.svg');
        background-color: $color-white;
        border: 1px solid $color-neutral-300;

        &:hover {
            background-color: $color-neutral-300;
            border: 1px solid $color-neutral-500;
        }

        &.no-icon {
            background-image: none;
            padding: 8px 9px;

            b {
                margin-right: 5px;
            }
        }

        &:hover {
            background-color: $color-neutral-200;
        }

        &.edit {
            background-image: img('edit_neutro_s90.svg');
            background-color: $color-white;
            color: $color-black;

            &:hover {
                background-color: $color-neutral-300;
            }
        }
    }

    .popup {
        $leftWidth: 150px;
        $rightWidth: 250px;
        @include align-items(flex-start);
        background-color: #fff;
        color: black;
        position: absolute;
        top: 55px;
        right: 150px;
        width: calc($leftWidth + $rightWidth);
        max-width: unset;
        height: auto;
        max-height: unset;
        padding: 20px;
        overflow: visible;
        @include box-shadow($h: 0px, $v: 2px, $b: 15px, $s: 2px, $c: rgba($color-neutral-200, 1));

        .row {
            @include display-flex();
            @include align-items(flex-start);
            width: calc($leftWidth + $rightWidth);
            margin: 0 auto;

            &:first-child {
                margin-bottom: 15px;
            }

            .left {
                // width: 50%;
                // min-width: 50%;
                width: $leftWidth;
                // min-width: 50%;
                float: left;

                .label {
                    margin-top: 5px;
                }
            }
            .right {
                // width: 50%;
                // min-width: 50%;
                width: $rightWidth;
                // min-width: 50%;
                float: right;
            }

            .tag {
                @include border-radius(4px);
                @include display-flex();
                background-color: $color-neutral-200;
                width: max-content;
                padding: 5px 10px;
                margin-bottom: 10px;
                cursor: pointer;

                &.selected {
                    background-color: $color-neutral-300;
                }

                .delete {
                    top: 0;
                    margin: 0 auto;
                    margin-left: 10px;
                    position: relative;
                }

                &.original {
                    color: $color-neutral-600;
                    cursor: default;
                }
            }

            .select {
                max-width: 200px;
            }
        }
    }
}
</style>
