<template>
    <div class="translateInput">
        <!-- NORMAL INPUT -->
        <template v-if="!selectedLang">
            <template v-if="!gType">
                <textarea
                    v-if="_type == 'textarea'"
                    :maxlength="maxLength"
                    :class="[_class]"
                    v-model="gValue"
                    :placeholder="_placeholder"
                    @change="custom_config.includes('get_$event') ? $emit('change', $event) : $emit('change', $event.target.value)"
                    @input="custom_config.includes('get_$event') ? $emit('input', $event) : $emit('input', $event.target.value)"
                    @click="custom_config.includes('get_$event') ? $emit('click', $event) : $emit('click', $event.target.value)"
                ></textarea>
                <input
                    v-else
                    type="text"
                    :class="[_class]"
                    v-model="gValue"
                    :placeholder="_placeholder"
                    @change="custom_config.includes('get_$event') ? $emit('change', $event) : $emit('change', $event.target.value)"
                    @input="custom_config.includes('get_$event') ? $emit('input', $event) : $emit('input', $event.target.value)"
                    @click="custom_config.includes('get_$event') ? $emit('click', $event) : $emit('click', $event.target.value)"
                />
            </template>
            <template v-else>
                <Input
                    :gType="gType"
                    v-model="gValue"
                    :gState="gState"
                    :gPlaceholder="gPlaceholder"
                    :gMessage="gMessage"
                    :gLabel="gLabel"
                    :gHelp="gHelp"
                    :gOptions="gOptions"
                    @input="updateValue"
                    :inputIcon="inputIcon"
                    :customClass="customClass"
                ></Input>
            </template>
        </template>

        <!-- TRANSLATE INPUT -->
        <template v-else>
            <div
                :class="[
                    'translating',
                    { incomplete: gValue && (!translation || translation == '<p></p>') },
                    { complete: translation && translation != '‏‏‎ ‎' && translation != '<p></p>' }
                ]"
            >
                <template v-if="gLabel">
                    <div class="label-form">{{ gLabel }}</div>
                </template>
                <div class="index">[</div>
                <div class="inputs">
                    <div class="up">
                        <input
                            type="text"
                            disabled
                            :class="[_class, 'disabled']"
                            :value="
                                !gValue
                                    ? (_placeholder ? _placeholder : '') + ' ' + $t('multi-language.original_language_na')
                                    : gType
                                    ? gValue.replace(/<[^>]*>?/gm, '')
                                    : gValue
                            "
                            :placeholder="_placeholder"
                        />
                    </div>
                    <div class="down">
                        <div class="translate-btn" @click="autoTranslate()"></div>
                        <template v-if="!gType">
                            <input
                                type="text"
                                :class="[_class, 'translate-input']"
                                v-model="translation"
                                @change="updateTranslationValue($event.target.value)"
                            />
                        </template>
                        <template v-else>
                            <Input
                                :key="selectedLang + reRender"
                                :gType="gType"
                                v-model="translation"
                                :gState="gState"
                                :gMessage="gMessage"
                                :gOptions="gOptions"
                                @change="updateTranslationValue"
                                :inputIcon="inputIcon"
                                :customClass="customClass"
                            ></Input>
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: [String, Number, Boolean], default: '' },
        translation_keyname: { type: [String, Number, Boolean], default: false },
        _class: { type: [String, Number, Array, Boolean], default: false },
        _placeholder: { type: [String, Number, Boolean], default: false },
        _type: { type: [String, Array, Boolean], default: 'text' },
        custom_config: { type: [String, Number, Array, Boolean], default: '[]' },
        maxLength: { type: [String, Number] },

        // INPUT COMPONENT
        gItems: { type: [Array, Object], default: undefined }, // Array de objetos para los tipos select etc etc
        gType: { type: [String, Number, Boolean], default: '' }, // Tipo de input. es decir, si es texto, fecha... (Ver inputTypes en el data)
        gLabel: { type: String, default: '' }, // Label del input
        gPlaceholder: { type: String, default: '' },
        gHelp: { type: String, default: '' }, // Es la info que aparece en un icono de ayuda con un snackbar
        gState: { type: String, default: 'default' }, // Es para indicar la clase del estado del input (error, alerta...) (Ver inputStates en el data)
        gSize: { type: String, default: '' }, // Podemos indicar un tamaño (Ver inputSizes en el data)
        gMessage: { type: [String, Object], default: '' }, // Texto informativo siempre visible. Puede variar segun el estado
        gOptions: { type: Object, default: () => ({}) }, // Configuraciones del input
        customClass: { type: [String, Array, Object, Function], default: '' },
        autocompleteValue: { type: undefined, default: false },
        refreshComponent: { type: undefined, default: '' },
        inputIcon: { type: Boolean, default: true }
    },
    name: 'TranslateInput',
    data() {
        return {
            // VARIABLES OF CONTROL
            gValue: this.value,
            tValue: '',
            reRender: 0
        }
    },
    computed: {
        selectedLang() {
            return this.$store.getters['multilanguage/getTranslationLang']
        },

        autoTranslateMode() {
            return this.$store.getters['multilanguage/getAutoTranslateMode']
        },

        translation: {
            get() {
                if (this.translation_keyname) {
                    var stringTranslation = this.$store.getters['multilanguage/getSingleTranslations'](this.translation_keyname)
                    if (this.gValue == '' && stringTranslation == '') {
                        stringTranslation = '‏‏‎ ‎'
                    }
                    return stringTranslation
                } else return ''

                // return this.$store.getters['multilanguage/getSingleTranslations'](this.translation_keyname)
                // var translation = this.$store.getters['multilanguage/getTranslations'](this.translation_keyname)
                // return translation && typeof translation === 'string' ? translation : ''
            },
            set(value) {
                if (this.translation_keyname && value != undefined) {
                    if (value == '' && this.gValue == '') {
                        value = '‏‏‎ ‎'
                    }
                    this.$store.commit('multilanguage/setSingleTranslation', {
                        keyname: this.translation_keyname,
                        translation: value,
                        originalText: this.gValue
                    })
                }
            }
        }
    },

    methods: {
        updateValue(inputValue) {
            this.$emit('input', inputValue)
        },

        updateTranslationValue(inputValue) {
            this.$store.commit('multilanguage/setHasBeenTranslated', true)
        },

        autoTranslate() {
            let self = this
            if (this.gValue) {
                this.$store
                    .dispatch('multilanguage/translate', { text: this.gType ? this.gValue.replace(/<[^>]*>?/gm, '') : this.gValue })
                    .then(function (response) {
                        if (response.status) {
                            self.translation = Object.values(response)[1][0]['translations'][0].text
                            self.reRender++
                        }
                    })
            }
        }
    },

    created() {},

    updated() {},

    beforeDestroy() {},
    watch: {
        value(newValue) {
            this.gValue = newValue
        },
        selectedLang(newValue, oldValue) {
            if (newValue != oldValue) {
                if (!this.translation && this.translation_keyname) {
                    if (this.autoTranslateMode) {
                        this.autoTranslate()
                    }
                    this.translation = ''
                }
            }
        }
    }
}
</script>

<style lang="scss">
// GLOBAL STYLES AT "style.css" LINE ~2214
.translateInput {
    overflow: auto;
    width: 100%;
    margin-bottom: 5px;
    height: fit-content;

    textarea::placeholder {
        color: $color-neutral-600 !important;
    }

    * {
        pointer-events: all !important;
    }

    &:last-child {
        margin-bottom: 0;
    }

    input {
        width: 100%;
        pointer-events: default !important;
        background-color: $color-white !important;
        font-family: $text;

        &.disabled {
            background-color: $color-neutral-300 !important;
        }

        &.w85 {
            width: 85% !important;
        }
    }

    .translating {
        .index {
            font-family: $text-light;
            font-size: 55px;
            float: left;
            width: 18px;
            color: $color-neutral-600;
        }
        .inputs {
            width: calc(100% - 18px);
            float: right;

            .down {
                @include display-flex();
                margin-top: 5px;
                width: 100%;

                .translate-btn {
                    @include background($image: img('translate_neutro_s40.svg'), $size: 22px, $position: left center);
                    width: 30px;
                    height: 25px;
                    cursor: pointer !important;
                }

                input.translate-input,
                .input-container {
                    width: calc(100% - 30px);
                    margin-right: 0;

                    &.w85 {
                        width: 80% !important;
                    }
                }
            }
        }

        &.incomplete {
            .index {
                color: $color-error-500 !important;
            }
            .up {
                input,
                .input,
                .input * {
                    color: $color-error-500 !important;
                }
            }
            .down {
                .translate-btn {
                    @include background($image: img('translate.svg'), $size: 22px, $position: left center);
                }
            }
        }

        &.complete {
            .index {
                color: $color-success-500 !important;
            }
            .up {
                input,
                .input,
                .input * {
                    color: $color-success-500 !important;
                }
            }
        }
    }
}
</style>
