import Vue from 'vue'
import { Api } from '@/core/api.js'
const api = new Api()

// initial state
const getDefaultState = () => {
    return {
        locations: {},
        filtersActive: {
            type_date: 'week',
            name: '',
            identification: '',
            status: [{ id: 1, name: 'tools.groups.status.active' }]
        },
        filters: {
            groups: {},
            states: {},
            cities: {},
            status: {}
        },
        locationsTable: {},
        itemsLoaded: false,
        licenses: {},
        licenseEndDate: null //Es la licencia mas cercana a la expiración de todos los locales que carga
    }
}
const state = getDefaultState()

// getters
const getters = {
    getEndDate: (state, getters, rootState) => {
        return state.licenseEndDate
    },
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },

    getList: (state, getters, rootState) => id => {
        if (id) {
            return state.locations[id]
        }
        return state.locations
    },
    getListTable: (state, getters, rootState) => id => {
        if (id) {
            return state.locationsTable[id]
        }
        return state.locationsTable
    },

    getFilterGroups: (state, getters, rootState) => id => {
        if (id) {
            return state.filters.groups[id]
        }
        return state.filters.groups
    },
    getFilterStates: (state, getters, rootState) => id => {
        if (id) {
            return state.filters.states[id]
        }
        return state.filters.states
    },
    getFilterCities: (state, getters, rootState) => id => {
        if (id) {
            return state.filters.cities[id]
        }
        return state.filters.cities
    },
    getFilterStatus: (state, getters, rootState) => id => {
        if (id) {
            return state.filters.status[id]
        }
        return state.filters.status
    },

    getFiltersActive: (state, getters, rootState) => {
        return state.filtersActive
    },

    getLicenses: (state, getters, rootState) => {
        return state.licenses
    }
}

// actions
const actions = {
    loadList (context, params) {
        return api.post('admin/location', params).then(function (response) {
            if (response) {
                var locations = { ...response.data }
                var territories = []
                var locations_save = {}
                for (var location in locations) {
                    var idx = locations[location]
                    if (idx && parseInt(idx.demo) != 1) {
                        if (idx.groups) {
                            territories = []
                            for (var group in idx.groups) {
                                var idxG = idx.groups[group]
                                territories.push(idxG)
                            }
                            idx.groups = territories
                        }

                        locations_save[location] = Object.assign({}, idx)
                    }

                    if(idx.license_end_date){
                        if(!state.licenseEndDate){
                            state.licenseEndDate = idx.license_end_date
                        }else{
                            if(state.licenseEndDate > idx.license_end_date){
                                state.licenseEndDate = idx.license_end_date
                            }
                        }
                    }
                }

                context.commit('setLocations', locations_save)
            }
        })
    },
    loadListTable (context, params) {
        return api.post('admin/location', params).then(function (response) {
            if (response) {
                var locations = { ...response.data }
                var territories = []
                var locations_save = {}
                for (var location in locations) {
                    var idx = locations[location]
                    if (idx) {
                        if (idx.groups) {
                            territories = []
                            for (var group in idx.groups) {
                                var idxG = idx.groups[group]
                                territories.push(idxG)
                            }
                            idx.groups = territories
                        }
                    }
                }

                context.commit('setLocationsTable', locations)
            }
        })
    },
    editLocation (context, params) {
        return api.post('admin/location/' + params.id + '/edit', params).then(function (response) {
            if (response.status) {
                response.data.groups = Object.values(response.data.groups)
                context.commit('editLocation', response.data)
            }
            return response
        })
    },
    addLocation (context, params) {
        return api.post('admin/location/add', params).then(function (response) {
            if (response.status) {
                response.data.groups = Object.values(response.data.groups)
                // console.log(response)
                context.commit('addLocation', response.data)
                return response
            }
            return response
        })
    },
    identificationUsed (context, params) {
        return api.post('admin/location/identification', params).then(function (response) {
            if (response.status) {
                return response.data
            }
        })
    },

    validateEmailLocation (context, params) {
        return api.post('lead/validate/site/email', params).then(function (response) {
            return response
        })
    },
    loadFilters (context) {
        // console.log(params);
        return api.get('admin/location/filter').then(function (response) {
            context.commit('setFilterGroups', response.data.groups)
            context.commit('setFilterStates', response.data.states)
            context.commit('setFilterCities', response.data.cities)
            context.commit('setFilterStatus', response.data.status)
        })
    },
    deleteLocation (context, params) {
        return api.get('admin/location/' + params.id + '/delete').then(function (response) {
            context.commit('setdeleteLocation', params.id)
        })
    },
    loadLicenses (context, params) {
        return api.get('admin/account/licenses').then(function (response) {
            context.commit('setLicenses', response.data)
        })
    },
    getPwdLocation (context, params) {
        return api.get('admin/location/' + params + '/pwd').then(function (response) {
            return response
        })
    },
    sendAccessNotification (context, params) {
        return api.post('admin/location/access', params).then(function (response) {
            return response.status
        })
    },
    resendAccessNotification (context, params) {
        return api.post('admin/location/' + params.location_id + '/access/resend', params).then(function (response) {
            return response.status
        })
    },
    downloadReportListExcel(context, params) {
        return api
            .post(`sites/admin/reports/excel`, params)
            .then(function (response) {
                return response.file
            })
            .catch(function (error) {
                console.error(error)
                return false
            })
    },
}

// mutations
const mutations = {
    setLocations (state, response) {
        if (response) {
            Vue.set(state, 'locations', { ...response })
            state.itemsLoaded = true
        }
    },
    setLocationsTable (state, response) {
        if (response) {
            Vue.set(state, 'locationsTable', { ...response })
        }
    },
    addLocation (state, params) {
        var locations = { ...state.locations }
        var locationsTable = { ...state.locationsTable }
        locations[params.id] = params
        locationsTable[params.id] = params
        state.locations = locations
        state.locationsTable = locationsTable
        Vue.set(state, 'locations', { ...locations })
        Vue.set(state, 'locationsTable', { ...locationsTable })
    },
    editLocation (state, params) {
        var locations = { ...state.locations }
        var locationsTable = { ...state.locationsTable }
        locations[params.id] = params
        locationsTable[params.id] = params
        state.locations = { ...locations }
        state.locationsTable = { ...locationsTable }
        Vue.set(state, 'locations', { ...locations })
        Vue.set(state, 'locationsTable', { ...locationsTable })
    },
    setdeleteLocation (state, params) {
        var locations = state.locations
        var locationsTable = state.locationsTable
        delete locations[params]
        delete locationsTable[params]
        state.locations = locations
        state.locationsTable = locationsTable
        Vue.set(state, 'locations', { ...locations })
        Vue.set(state, 'locationsTable', { ...locationsTable })
    },
    setFilterGroups (state, payload) {
        var filters = state.filters
        filters.groups = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFilterStates (state, payload) {
        var filters = state.filters
        filters.states = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFilterCities (state, payload) {
        var filters = state.filters
        filters.cities = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFilterStatus (state, payload) {
        var filters = state.filters
        filters.status = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },

    setFiltersActive (state, filters) {
        state.filtersActive = filters
        Vue.set(state, 'filtersActive', { ...filters })
    },

    setLicenses (state, licenses) {
        state.licenses = licenses
        Vue.set(state, 'licenses', { ...licenses })
    },

    resetState (state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
