<template>
    <input @keyup="$emit('keyup', $event)" @change="$emit('change', $event)" type="number" class="input" :value="value" @input="sendValue($event.target.value)" :placeholder="placeholder" :disabled="inputOptions.disabled" @blur="$emit('blur', $event)" :required="inputOptions.mandatory" :min="inputOptions.min" :max="inputOptions.max" :step="inputOptions.step" />
</template>

<script>
export default {
    props: {
        value: { type: [String, Number, Boolean, Array, Object], default: undefined },
        cValue: { type: [String, Number, Boolean, Array, Object], default: undefined },
        cPlaceholder: { type: String, default: '' },
        cOptions: { type: Object, default: undefined }
    },
    data() {
        return {
            tempValue: this.cValue,
            options: {
                min: false,
                max: false,
                step: false
            }
        }
    },
    computed: {
        inputOptions() {
            // Mix de options que llegan vs defaults
            return {
                ...this.options,
                ...(this.cOptions ? this.cOptions : {})
            }
        },
        placeholder() {
            return this.cPlaceholder
        }
    },

    methods: {
        sendValue(value) {
            if (this.inputOptions.max || this.inputOptions.min) {
                if (this.inputOptions.max && this.inputOptions.max < value) {
                    value = this.inputOptions.max
                } else if (this.inputOptions.min && this.inputOptions.min > value) {
                    value = this.inputOptions.min
                }
            }

            this.$emit('input', value)
            this.$forceUpdate()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
