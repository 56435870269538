<template>
    <div class="galery-container">
        <div class="column-left">
            <div class="selected-image-container">
                <div class="title">{{ translation('product.gallery_icons.selected_image') }}</div>
                <div
                    v-if="selectedImage"
                    class="gallery-image-selected"
                    :style="{
                        backgroundImage: 'url(' + selectedImage + ')'
                    }"
                ></div>
                <div v-else class="default-image" :class="{ selected: selectedImage }"></div>
                <div class="remove-image" @click="removeImage()" :class="{ disabled: !selectedImage }">
                    {{ translation('product.gallery_icons.remove') }}
                </div>
            </div>
            <div class="category-column scrollbar">
                <div v-if="Object.values(bestImages).length > 0" @click="selectCategory('suggestionCategory')" class="category best-images" :class="{ selected: 'suggestionCategory' == catSelected }">
                    <div class="icon suggestion"></div>
                    <div class="name">{{ translation('product.gallery_icons.suggestion') }}</div>
                </div>

                <div @click="selectCategory(category.id)" class="category" v-for="category in items" :key="category.id" :class="{ selected: category.id == catSelected }">
                    <div
                        class="icon"
                        :style="{
                            backgroundImage: 'url(' + category.icon + ')'
                        }"
                    ></div>
                    <div class="name">{{ category.name }}</div>
                </div>
            </div>
        </div>
        <div class="images-container">
            <div class="add-image" @click="addImage()">
                {{ translation('product.gallery_icons.upload') }}
                <input type="file" id="icon_selector" style="display: none" ref="icon_product_selector" accept="image/*" @change="previewFiles($event, 'icon_product_selector')" />
            </div>
            <div class="galery-image-container scrollbar" id="galleryImage" ref="galleryImageContainer">
                <div v-if="Object.values(bestImages).length > 0" class="category-title" :class="{ highlight: selectedClass && 'suggestionCategory' == catSelected }" id="suggestionCategory">
                    <div class="title-category">{{ `${translation('product.gallery_icons.suggestion_for')} ${productName}` }}</div>
                    <div class="image-container">
                        <div class="gallery-images" v-for="image in bestImages" :key="image.id" :style="{ backgroundImage: `url(${image.image})` }" @click="selectImage(image.image)"></div>
                    </div>
                </div>

                <div class="category-title" :class="{ highlight: selectedClass && category.id == catSelected }" :id="category.id" v-for="category in items" :key="category.id">
                    <div class="title-category">
                        {{ category.name }}
                    </div>
                    <div class="image-container" v-if="Object.keys(category.images).length > 0">
                        <div
                            class="gallery-images"
                            :style="{
                                backgroundImage: 'url(' + imageCategory.icon + ')'
                            }"
                            @click="selectImage(imageCategory.icon)"
                            v-for="imageCategory in category.images"
                            :key="imageCategory.id"
                        ></div>
                    </div>

                    <div class="subcategories-container" v-if="Object.keys(category.subcategories).length > 0">
                        <div class="sub-category-title" v-for="subcategory in category.subcategories" :key="subcategory.id">
                            <div class="title-subcategory">
                                {{ subcategory.name }}
                            </div>
                            <div class="image-container" v-if="subcategory.images">
                                <div
                                    :style="{
                                        backgroundImage: 'url(' + image.icon + ')'
                                    }"
                                    class="gallery-images"
                                    @click="selectImage(image.icon)"
                                    v-for="image in subcategory.images"
                                    :key="image.id"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import store from '@/store'

export default {
    props: {
        imageSelected: { type: undefined, default: false },
        productName: { type: undefined, default: false },
        // Data es el objeto que contiene categorias, subcategorías...etc...
        data: { type: undefined, default: [] }
    },
    data() {
        return {
            selectedImage: this.imageSelected,
            catSelected: false,
            scrolling: false,
            scrollWait: false,
            selectedClass: false
        }
    },
    computed: {
        items() {
            var items = { ...this.data }
            if (items) {
                for (var i = 0; i < Object.values(items).length; i++) {
                    if (i == 0) {
                        this.catSelected = Object.values(this.bestImages).length > 0 ? 'suggestionCategory' : Object.values(items)[i].id
                    }
                }
            }
            return items
        },
        bestImages() {
            return store.getters['label/getGalleryBestImages']
        }
    },
    methods: {
        // CUANDO HACES SCROLL TE SELECCIONE LA CATEGORÍA QUE VES
        handleScroll() {
            var self = this
            document.addEventListener('wheel', function (event) {
                const callback = (entries, observer) =>
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            observer.unobserve(entry.target)
                            self.catSelected = entry.target.id
                            observer.disconnect()
                        }
                    })

                var heightDiv = document.querySelector('#galleryImage').offsetHeight
                const observer = new IntersectionObserver(callback, {
                    rootMargin: heightDiv + 'px ' + '0px ' + '-' + (heightDiv - 50) + 'px 0px',
                    root: document.querySelector('#galleryImage')
                })

                document.querySelectorAll('.category-title').forEach((item) => observer.observe(item))
            })
        },

        addImage() {
            this.$refs.icon_product_selector.click()
        },
        previewFiles(e, name) {
            this.filenames = this.$refs[name].files[0].name
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            this.createImage(files[0], name)
        },
        createImage(file, name) {
            var image = new Image()
            var reader = new FileReader()
            var self = this

            reader.onload = (e) => {
                image.onload = () => {
                    this.$emit('changeImg', resizeImage(image))
                    self.selectedImage = resizeImage(image)
                }
                image.src = e.target.result
            }
            reader.readAsDataURL(file)
        },
        translation(translation) {
            return i18n.t(translation)
        },
        selectCategory(category) {
            this.catSelected = category
            document.getElementById(category).scrollIntoView({
                behavior: 'smooth'
            })
            var element = document.getElementById(category)
            this.selectedClass = true
            var self = this
            setTimeout(function () {
                self.selectedClass = false
            }, 1000)
        },
        selectImage(img) {
            this.selectedImage = img
            this.$emit('changeImg', img)
        },
        removeImage() {
            this.selectedImage = false
            this.$emit('changeImg', false)
        }
    },
    mounted() {
        this.handleScroll()
    },
    beforeDestroy() {
        window.removeEventListener('wheel', {})
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.galery-container {
    @include display-flex();
    width: 100%;
    height: 100%;

    .column-left {
        @include display-flex();
        @include flex-direction(column);
        width: 100%;
        height: 100%;
        max-width: 230px;
        margin-right: 30px;
        min-width: 140px;

        .selected-image-container {
            @include display-flex();
            @include flex-direction(column);
            @include align-items(center);
            height: 160px;
            .title {
                @include font-size(md);
                font-family: $text-bold;
                color: $color-black;
                padding-bottom: 9px;
            }
            .gallery-image-selected {
                @include border-radius(4px);
                width: 80px;
                height: 80px;
                background-color: $color-neutral-200;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                transform: scale(1);
                transition: 0.2s ease-in-out;
            }

            .default-image {
                @include border-radius(4px);
                @include background($image: img('image_neutro_s10.svg'), $size: 50px);
                width: 80px;
                height: 80px;
                background-color: $color-neutral-200;
                transform: scale(1);
                transition: 0.2s ease-in-out;
            }

            .remove-image {
                @include border-radius(4px);
                @include background($image: img('close_neutro_s60.svg'), $size: 13px, $position: left 10px center);
                padding: 8px 9px;
                padding-left: 30px;
                margin-top: 9px;
                width: fit-content;
                background-color: $color-neutral-200;

                &.disabled {
                    @include background($image: img('close_inactive_s10.svg'), $size: 13px, $position: left 10px center);
                    background-color: $color-neutral-300;
                    color: $color-neutral-300;
                }
                &:hover:not(.disabled) {
                    background-color: hover($color-neutral-200);
                    cursor: pointer;
                }
            }
        }
        .category-column {
            margin-top: 10px;
            height: calc(100% - 160px);
            overflow-y: auto;
            padding-right: 13px;
            .category {
                @include border-radius(4px);
                @include display-flex();
                @include justify-content(flex-start);
                padding: 8.5px 10px;
                margin: 3px;
                cursor: pointer;
                .icon {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin-right: 10px;

                    &.suggestion {
                        background-image: url('../../../public/img/suggestion.svg');
                    }
                }
                .name {
                    @include align-self(center);
                    @include font-size(md);
                    font-family: $text-medium;
                    color: $color-neutral-600;
                    text-transform: capitalize;
                }
                &.selected {
                    background-color: $color-primary-500;
                    -webkit-transition: background-color 300ms linear;
                    -ms-transition: background-color 300ms linear;
                    transition: background-color 300ms linear;

                    .name {
                        font-family: $text-bold;
                        color: #fff;
                    }
                }
                &:hover:not(.selected) {
                    background-color: hover($color-neutral-200);
                }
            }
        }
    }

    .images-container {
        @include display-flex();
        @include flex-direction(column);
        width: calc(100% - 170px);
        height: 100%;

        .add-image {
            @include align-self(flex-end);
            @include font-size(md);
            @include border-radius(4px);
            @include background($image: img('add_neutro_s90.svg'), $size: 10px, $position: left 10px center);
            background-color: $color-neutral-200;
            padding: 8px 9px;
            padding-left: 30px;
            margin-top: 9px;
            width: fit-content;
            box-shadow: 0px 0px 4px rgba(117, 115, 119, 0.05);
            border-radius: 4px;
            color: $color-primary-800;
            font-family: $text-bold;
            margin-bottom: 12px;
            cursor: pointer;

            &:hover {
                background-color: hover($color-neutral-200);
            }
        }

        .galery-image-container {
            @include border-radius(6px);
            background-color: $color-neutral-50;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            padding: 20px 25px;

            .gallery-images {
                @include border-radius(4px);
                background-color: #fff;
                border: 1px solid $color-primary-100;
                box-shadow: 0px 0px 15px rgba(221, 221, 221, 0.4);
                width: 80px;
                height: 80px;
                cursor: pointer;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                transform: scale(1);
                transition: 0.2s ease-in-out;

                &:hover {
                    transform: scale(0.95);
                    transition: 0.2s ease-in-out;

                    background-color: hover(#fff);
                }
            }
            .image-container {
                display: grid;
                width: 100%;
                grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
                grid-gap: 6px;
                margin-bottom: 30px;
            }
            .category-title {
                transition: background-color 300ms linear;
                .title-category {
                    @include font-size(md);
                    font-family: $text-bold;
                    color: $color-black;
                    text-transform: capitalize;
                    padding-bottom: 9px;
                }

                .sub-category-title {
                    .title-subcategory {
                        @include font-size(sm);
                        font-family: $text-medium;
                        color: $color-neutral-600;
                        padding-bottom: 12px;
                    }
                }

                &.highlight {
                    background-color: $color-neutral-200;
                    transition: background-color 300ms linear;
                }
            }
        }
    }
}
</style>
