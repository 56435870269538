import { getListOfIds } from './utils.js'

function sortByOrderAndName(arr) {
    arr.sort((a, b) => {
        if (a.order < b.order) {
            return -1
        } else if (a.order > b.order) {
            return 1
        } else {
            return a.name < b.name ? -1 : 1
        }
    })

    return arr
}

export function findDefaultView(views) {
    const standardViews = views.filter((view) => view.type === 1)

    let defaultView

    if (standardViews.length > 0) {
        defaultView = sortByOrderAndName(standardViews)[0]
    } else {
        const brandViews = views.filter((view) => view.type === 2)
        defaultView = sortByOrderAndName(brandViews)[0]
    }

    return defaultView
}

// transforms for DB SUPERVISE CHECKLISTS FILTERS
export function transformSuperviseChecklistViewFiltersForDB(data) {
    const viewFilters = {
        template: data.template ? getListOfIds([...data.template]) : [],
        timeframe: data.timeframe ? getListOfIds([...data.timeframe]) : [],
        category: data.category ? getListOfIds([...data.category]) : [],
        type_date: data.type_date,
        start_date: data.start_date ? moment(data.start_date).format('YYYY-MM-DD') : null,
        end_date: data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : null,
        demora: data.demora === true ? 'true' : 'false',
        score: JSON.stringify(data.score) !== '[0, 100]' ? data.score : [0, 100],
        tplType: data.tplType ? data.tplType : ''
    }

    if (data.sites) {
        viewFilters.sites = data.sites
    }

    if (data.locations) {
        viewFilters.location = getListOfIds([...data.locations])
    }

    if (data.territories) {
        viewFilters.groups = getListOfIds([...data.territories])
    }

    if (viewFilters.location) {
        viewFilters.groups = undefined
    }

    if (viewFilters.groups) {
        viewFilters.location = undefined
    }

    return viewFilters
}

// transforms for DB SUPERVISE RECORDS FILTERS
export function transformSuperviseRecordsViewFiltersForDB(data) {
    const viewFilters = {
        location: data.location ? getListOfIds([...data.location]) : [],
        employees: data.employees ? getListOfIds([...data.employees]) : [],
        type_date: data.type_date,
        start_date: data.start_date ? moment(data.start_date).format('YYYY-MM-DD') : null,
        end_date: data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : null
    }

    return viewFilters
}

// transforms for DB SUPERVISE INCIDENTS FILTERS
export function transformSuperviseIncidentsViewFiltersForDB(data) {
    const viewFilters = {
        location: data.location ? getListOfIds([...data.location]) : [],
        template: data.template ? getListOfIds([...data.template]) : [],
        status: data.status ? getListOfIds([...data.status]) : [],
        category: data.category ? getListOfIds([...data.category]) : [],
        type_date: data.type_date,
        start_date: data.start_date ? moment(data.start_date).format('YYYY-MM-DD') : null,
        end_date: data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : null,
        complete_date: data.complete_date ? data.complete_date : null,
        complete_date_start: data.complete_date_start ? moment(data.complete_date_start).format('YYYY-MM-DD') : null,
        complete_date_end: data.complete_date_end ? moment(data.complete_date_end).format('YYYY-MM-DD') : null
    }

    return viewFilters
}

export function transformSuperviseAuditsFiltersViewForDB(data) {
    const viewFilters = {
        location: data.location ? getListOfIds([...data.location]) : [],
        template: data.template ? getListOfIds([...data.template]) : [],
        employees: data.employees ? getListOfIds([...data.employees]) : [],
        type_date: data.type_date,
        start_date: data.start_date ? moment(data.start_date).format('YYYY-MM-DD') : null,
        end_date: data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : null,
        score: JSON.stringify(data.score) !== '[0, 100]' ? data.score : [0, 100],
        finalNote: data.finalNote ? data.finalNote : ''
    }

    return viewFilters
}
