<template>
    <v-combobox @keyup="$emit('keyup', $event)" @change="$emit('change', $event)" :attach="true" @blur="$emit('blur', $event)" hide-details v-model="gValue" :placeholder="placeholder" @input="values()" :item-text="inputOptions.itemText" :item-value="inputOptions.itemValue" :items="Object.values(items)" :disabled="inputOptions.disabled" :required="inputOptions.mandatory" :multiple="inputOptions.multiple" :no-data-text="$t('supervise.filter.no_results')" :return-object="inputOptions.returnObject"></v-combobox>
</template>

<script>
import Tags from '../ui/Tags.vue'
export default {
    components: { Tags },
    props: {
        value: { type: [String, Number, Boolean, Array, Object], default: undefined },
        cItems: { type: [Array, Object], default: [] },
        cPlaceholder: { type: String, default: '' },
        cOptions: { type: Object, default: undefined },
        comboboxModel: { type: undefined, default: false }
    },
    data() {
        return {
            gValue: this.value ? this.value : false,
            items: this.cItems,
            options: {
                multiple: false,
                returnObject: true,
                itemValue: 'id',
                itemText: 'name'
            }
        }
    },
    computed: {
        inputOptions() {
            // Mix de options que llegan vs defaults
            return {
                ...this.options,
                ...(this.cOptions ? this.cOptions : {})
            }
        },
        placeholder() {
            return this.cPlaceholder
        }
    },
    methods: {
        values() {
            this.$emit('input', this.gValue)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
