var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"galery-container"},[_c('div',{staticClass:"column-left"},[_c('div',{staticClass:"selected-image-container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.translation('product.gallery_icons.selected_image')))]),(_vm.selectedImage)?_c('div',{staticClass:"gallery-image-selected",style:({
                    backgroundImage: 'url(' + _vm.selectedImage + ')'
                })}):_c('div',{staticClass:"default-image",class:{ selected: _vm.selectedImage }}),_c('div',{staticClass:"remove-image",class:{ disabled: !_vm.selectedImage },on:{"click":function($event){return _vm.removeImage()}}},[_vm._v("\n                "+_vm._s(_vm.translation('product.gallery_icons.remove'))+"\n            ")])]),_c('div',{staticClass:"category-column scrollbar"},[(Object.values(_vm.bestImages).length > 0)?_c('div',{staticClass:"category best-images",class:{ selected: 'suggestionCategory' == _vm.catSelected },on:{"click":function($event){return _vm.selectCategory('suggestionCategory')}}},[_c('div',{staticClass:"icon suggestion"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.translation('product.gallery_icons.suggestion')))])]):_vm._e(),_vm._l((_vm.items),function(category){return _c('div',{key:category.id,staticClass:"category",class:{ selected: category.id == _vm.catSelected },on:{"click":function($event){return _vm.selectCategory(category.id)}}},[_c('div',{staticClass:"icon",style:({
                        backgroundImage: 'url(' + category.icon + ')'
                    })}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(category.name))])])})],2)]),_c('div',{staticClass:"images-container"},[_c('div',{staticClass:"add-image",on:{"click":function($event){return _vm.addImage()}}},[_vm._v("\n            "+_vm._s(_vm.translation('product.gallery_icons.upload'))+"\n            "),_c('input',{ref:"icon_product_selector",staticStyle:{"display":"none"},attrs:{"type":"file","id":"icon_selector","accept":"image/*"},on:{"change":function($event){return _vm.previewFiles($event, 'icon_product_selector')}}})]),_c('div',{ref:"galleryImageContainer",staticClass:"galery-image-container scrollbar",attrs:{"id":"galleryImage"}},[(Object.values(_vm.bestImages).length > 0)?_c('div',{staticClass:"category-title",class:{ highlight: _vm.selectedClass && 'suggestionCategory' == _vm.catSelected },attrs:{"id":"suggestionCategory"}},[_c('div',{staticClass:"title-category"},[_vm._v(_vm._s(((_vm.translation('product.gallery_icons.suggestion_for')) + " " + _vm.productName)))]),_c('div',{staticClass:"image-container"},_vm._l((_vm.bestImages),function(image){return _c('div',{key:image.id,staticClass:"gallery-images",style:({ backgroundImage: ("url(" + (image.image) + ")") }),on:{"click":function($event){return _vm.selectImage(image.image)}}})}),0)]):_vm._e(),_vm._l((_vm.items),function(category){return _c('div',{key:category.id,staticClass:"category-title",class:{ highlight: _vm.selectedClass && category.id == _vm.catSelected },attrs:{"id":category.id}},[_c('div',{staticClass:"title-category"},[_vm._v("\n                    "+_vm._s(category.name)+"\n                ")]),(Object.keys(category.images).length > 0)?_c('div',{staticClass:"image-container"},_vm._l((category.images),function(imageCategory){return _c('div',{key:imageCategory.id,staticClass:"gallery-images",style:({
                            backgroundImage: 'url(' + imageCategory.icon + ')'
                        }),on:{"click":function($event){return _vm.selectImage(imageCategory.icon)}}})}),0):_vm._e(),(Object.keys(category.subcategories).length > 0)?_c('div',{staticClass:"subcategories-container"},_vm._l((category.subcategories),function(subcategory){return _c('div',{key:subcategory.id,staticClass:"sub-category-title"},[_c('div',{staticClass:"title-subcategory"},[_vm._v("\n                            "+_vm._s(subcategory.name)+"\n                        ")]),(subcategory.images)?_c('div',{staticClass:"image-container"},_vm._l((subcategory.images),function(image){return _c('div',{key:image.id,staticClass:"gallery-images",style:({
                                    backgroundImage: 'url(' + image.icon + ')'
                                }),on:{"click":function($event){return _vm.selectImage(image.icon)}}})}),0):_vm._e()])}),0):_vm._e()])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }