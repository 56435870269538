<template>
    <div id="splashscreen" :class="[$mq]">
        <transition name="fade">
            <div name="modal" id="overlay" v-if="show">
                <div v-if="avatar" class="image">
                    <div class="avatar" :style="{ backgroundImage: 'url(' + avatar + ')' }"></div>
                </div>
                <div class="splash">
                    <div class="alex-face" v-if="alexIcon" :style="{ backgroundImage: 'url(' + alexIcon + ')' }"></div>
                    <LottieAnimation v-else :timeToRepeat="0" :loopAnimation="true" :animationName="'Andy-logo.json'"></LottieAnimation>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import LottieAnimation from '../../components/layout/LottieAnimation.vue'
import store from '@/store'
import route from '@/router'

export default {
    name: 'splashScreen',
    components: { LottieAnimation },
    computed: {
        avatar() {
            const account = store.getters['loginUser/getLocalAccount']

            if (route.history.current.name === 'Multiaccount' || !account) {
                return false
            } else return account.avatar
        },
        alexIcon() {
            return store.getters['login/getAppIcon']
        }
    },
    data() {
        return {
            show: true
        }
    },
    created() {},
    mounted() {}
}
</script>

<style lang="scss" scoped>
#splashscreen {
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease-in-out, transform 0.3s ease;
    }
    .fade-enter-active {
        transition-delay: 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-to,
    .fade-leave {
        opacity: 1;
    }

    #overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $color-neutral-50;
        width: 100%;
        height: 100%;
        // z-index: $z-overlay-default + 2;
        z-index: 100;

        .image {
            width: 360px;
            height: auto;
            min-height: 100px;

            .avatar {
                // @include opacity(0.8);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                width: 360px;
                height: rem(400px);
                cursor: auto;
            }
        }

        .splash {
            width: 45%;
            max-width: 400px;
            height: auto;
            min-width: 300px;

            .alex-face {
                @include background($image: '', $size: 150px, $position: center);
                position: relative;
                cursor: pointer;
                left: -2px;
                height: 150px;
                display: block;

                &:hover {
                    background-size: 150px + 3px;
                }
            }
        }
    }

    &.portrait {
        #overlay {
            .image {
                width: 250px;

                .avatar {
                    width: 250px;
                }
            }
        }
    }
}
</style>
