import Vue from 'vue'
import Vuex from 'vuex'

// Carga de modulos
import login from '@/store/modules/login'
import loginUser from '@/store/modules/loginUser'
import alexAlert from '@/store/modules/alexAlert'
import employee from '@/store/modules/employee'
import checklist from '@/store/modules/checklist'
import navigation from '@/store/modules/navigation'
import location from '@/store/modules/location'
import tasks from '@/store/modules/tasks'
import issues from '@/store/modules/issues'
import actionplan from '@/store/modules/actionplan'
import audits from '@/store/modules/audits'
import label from '@/store/modules/label'
import records from '@/store/modules/records'
import groups from '@/store/modules/groups'
import analytics from '@/store/modules/analytics'
import analyticsHome from '@/store/modules/analyticsHome'
import library from '@/store/modules/library'
import account from '@/store/modules/account'
import template from '@/store/modules/template'
import lead from '@/store/modules/lead'
import notes from '@/store/modules/notes'
import onboarding from '@/store/modules/onboarding'
import issueStates from '@/store/modules/issueStates'
import multilanguage from '@/store/modules/multilanguage'
import timeframes from '@/store/modules/timeframes'
import operationalTimers from './store/modules/operationalTimers'
import filtersViews from '@/store/modules/filtersViews'
import reports from '@/store/modules/reports'
import filterLocations from '@/store/modules/filterLocations'
import block from '@/store/modules/block'

import { Api } from '@/core/api.js'

import i18n from '@/i18n'

import localforage from 'localforage'

localforage.config({
    name: 'ALEXCENTRAL',
    version: 1.0
})
var db_updates = localforage.createInstance({ name: 'ALEXCENTRAL', storeName: 'updates' })

Vue.use(Vuex)
const api = new Api()

// const debug = process.env.NODE_ENV !== 'production'
const debug = false

const getDefaultState = () => {
    return {
        availableLanguages: false,
        language: typeof localStorage.language !== 'undefined' ? localStorage.language : 'es',
        locale: 'es-es',
        title: 'ALEXCENTRAL',
        view: '',
        printer: false,

        alex_is_visible: true,

        menu_top_is_visible: true,
        menu_tools_is_visible: true,
        menu_tools_is_expanded: false,

        // para poner un nuevo item en el submenú se debe de poner submenu:true en el segundo nivel, fijaros de nuevo en la home que en el segundo nivel tiene submenu false para que no se vea un submenu en la home y para ocultar un item del menú lateral se pone visible:false

        tools: {
            0: {
                name: 'tools.dashboard_title',
                id: 'navDashboard',
                href: '/home',
                required_tool: '0',
                roles: [3, 4, 5, 6, 7, 8, 9]
            },
            1: {
                name: 'tools.onboarding_title',
                id: 'onboarding',
                href: '/onboarding',
                required_tool: '0',
                roles: [3, 4, 5, 6, 7, 8, 9]
            },
            2: {
                name: 'tools.supervise_sub.checklist_title',
                href: '/supervise/checklist',
                id: 'navSuperviseChecklist',
                // Numero 1 para checklists
                required_tool: '1',
                submenu: true,
                roles: [3, 4, 5, 6, 7, 8, 9]
            },
            3: {
                name: 'tools.supervise_sub.records_title',
                href: '/supervise/records',
                id: 'navSuperviseRecords',
                // Numero 9 para registros
                required_tool: '4',
                submenu: true,
                roles: [3, 4, 5, 6, 7, 8, 9]
            },
            4: {
                name: 'tools.supervise_sub.action_title',
                href: '/supervise/issue',
                id: 'navSuperviseIssues',
                // Numero 2 para actionplans
                required_tool: '2',
                submenu: true,
                roles: [3, 4, 5, 6, 7, 8, 9]
            },
            5: {
                name: 'tools.supervise_sub.audits_title',
                href: '/supervise/audit',
                id: 'navSuperviseAudits',
                // Numero 5 para audits
                required_tool: '7',
                submenu: true,
                roles: [3, 4, 5, 6, 7, 8, 9]
            },
            6: {
                name: 'tools.analytics',
                id: 'navAnalytics',
                href: '/analytics',
                required_tool: '14',
                submenu: true,
                roles: [3, 4, 5, 6, 7, 8, 9]
            },
            7: {
                name: 'tools.supervise_sub.label_title',
                href: '/supervise/label',
                id: 'navSuperviseLabels',
                required_tool: '3',
                roles: [3, 4, 5, 6, 7, 8, 9],
                access: [
                    {
                        name: 'tools.supervise_sub.label_title',
                        href: '/supervise/label',
                        visible: true,
                        submenu: true,
                        required_tool: '3',
                        roles: [3, 4, 5, 6, 7, 8, 9]
                    },
                    {
                        name: 'tools.traceability',
                        href: '/traceability',
                        visible: true,
                        submenu: true,
                        required_tool: '3',
                        roles: [3, 4, 5, 6, 7, 8, 9]
                    }
                ]
            },
            8: {
                name: 'tools.logbook_title',
                id: 'navLogbook',
                href: '/logbook',
                submenu: false,
                access: [
                    {
                        name: 'tools.logbook_activity',
                        href: '/logbook',
                        id: 'navLogbook',
                        required_tool: '10',
                        visible: true,
                        submenu: true,
                        roles: [3, 4, 5, 6, 7, 8, 9]
                    },
                    {
                        name: 'tools.logbook_comunications',
                        href: '/logbook/communication',
                        visible: true,
                        // Numero 3 para labelling
                        required_tool: '10',
                        submenu: true,
                        roles: [6, 7, 8, 9]
                    }
                ]
            },
            10: {
                name: 'tools.organization_sub.settings_title',
                id: 'navSettings',
                href: '/configuration',
                access: [
                    {
                        name: 'tools.template_title',
                        id: 'navTemplate',
                        visible: true,
                        access: [
                            {
                                name: 'tools.template_sub.checklist_title',
                                href: '/template/checklist',
                                // Numero 1 para checklists
                                required_tool: '1',
                                visible: true,
                                submenu: true,
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.template_sub.records_title',
                                href: '/template/record',
                                // Numero 7 para registros
                                visible: true,
                                required_tool: '4',
                                submenu: true,
                                roles: [7, 8, 9]
                            },

                            {
                                name: 'tools.template_sub.issue_title',
                                href: '/template/issue',
                                // Numero 2 para actionplans
                                required_tool: '2',
                                visible: true,
                                submenu: true,
                                roles: [7, 8, 9]
                            },

                            {
                                name: 'tools.template_sub.actionplan_title',
                                href: '/template/actionplan',
                                // Numero 2 para actionplans
                                required_tool: '2',
                                visible: true,
                                submenu: true,
                                roles: [7, 8, 9]
                            },

                            {
                                name: 'tools.template_sub.audits_title',
                                href: '/template/audit',
                                // Numero 5 para audits
                                visible: true,
                                required_tool: '7',
                                submenu: true,
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.template_sub.categories_title',
                                href: '/template/categories',
                                visible: true,
                                required_tool: false,
                                submenu: true,
                                roles: [7, 8, 9]
                            }
                        ]
                    },
                    {
                        name: 'tools.organization_sub.library_title',
                        id: 'navLibrary',
                        visible: true,
                        //  required_tool false para que se vea por defecto sin permisos
                        // required_tool: '5',
                        access: [
                            {
                                name: 'tools.organization_sub.library_sub.library_resources',
                                href: '/library',
                                id: 'navLibrary',
                                // Numero 3 para labelling
                                required_tool: '5',
                                submenu: true,
                                visible: true,
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.organization_sub.library_sub.library_category',
                                href: '/library/category',
                                visible: true,
                                // Numero 3 para labelling
                                required_tool: '5',
                                submenu: true,
                                roles: [7, 8, 9]
                            }
                        ]
                    },
                    {
                        name: 'tools.supervise_sub.labels_title',
                        id: 'navProduct',
                        visible: true,
                        //  required_tool false para que se vea por defecto sin permisos
                        required_tool: false,
                        access: [
                            {
                                name: 'tools.organization_sub.products_sub.product',
                                href: '/product',
                                visible: true,
                                submenu: true,
                                // Numero 3 para labelling
                                required_tool: '3',
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.organization_sub.products_sub.product_category',
                                href: '/product/category',
                                visible: true,
                                submenu: true,
                                // Numero 3 para labelling
                                required_tool: '3',
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.organization_sub.products_sub.product_state',
                                href: '/product/state',
                                visible: true,
                                submenu: true,
                                // Numero 3 para labelling
                                required_tool: '3',
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.organization_sub.products_sub.product_availability',
                                href: '/availability',
                                visible: true,
                                submenu: true,
                                // Numero 3 para labelling
                                required_tool: '3',
                                roles: [3, 4, 5, 6, 7, 8, 9]
                            },
                            {
                                name: 'tools.organization_sub.products_sub.label_designer',
                                href: '/designer',
                                visible: false,
                                submenu: true,
                                // Numero 3 para labelling
                                required_tool: '3',
                                roles: [8, 9]
                            },
                            {
                                name: 'tools.organization_sub.products_sub.ingredients',
                                href: '/ingredients',
                                visible: true,
                                submenu: true,
                                // Numero 3 para labelling
                                required_tool: '12',
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.organization_sub.products_sub.block',
                                href: '/block',
                                visible: true,
                                submenu: true,
                                // Numero 3 para labelling
                                required_tool: '3',
                                required_config: 'labelBlock',
                                roles: [7, 8, 9]
                            }
                        ]
                    },
                    {
                        name: 'tools.ubication_title',
                        id: 'navSites',
                        visible: true,
                        access: [
                            {
                                name: 'tools.organization_sub.sites_title',
                                href: '/sites',
                                visible: true,
                                submenu: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: false,
                                roles: [3, 4, 5, 6, 7, 8, 9],
                                role_edition: 5
                            },
                            {
                                name: 'tools.organization_sub.groups_title',
                                href: '/groups',
                                submenu: true,
                                visible: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: false,
                                roles: [6, 7, 8, 9]
                            }
                        ]
                    },
                    {
                        name: 'tools.organization_sub.users_title',
                        id: 'navUsers',
                        visible: true,
                        access: [
                            {
                                name: 'tools.organization_sub.users_title',
                                href: '/users',
                                visible: true,
                                submenu: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: false,
                                roles: [3, 4, 5, 6, 7, 8, 9]
                            },
                            {
                                name: 'tools.organization_sub.roles_title',
                                href: '/roles',
                                submenu: true,
                                visible: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: false,
                                roles: [7, 8, 9]
                            }
                        ]
                    },
                    {
                        name: 'tools.organization_title',
                        id: 'navOrganization',
                        visible: true,
                        access: [
                            {
                                name: 'tools.organization_sub.settings',
                                href: '/settings',
                                visible: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: false,
                                submenu: true,
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.organization_sub.billing',
                                href: 'https://billing.andyapp.io/portal/andyapp/login ',
                                visible: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: false,
                                submenu: true,
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.organization_sub.tools_title',
                                href: '/tools',
                                visible: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: '8',
                                submenu: true,
                                roles: [7, 8, 9]
                            }
                        ]
                    },
                    {
                        name: 'tools.personalization_title',
                        id: 'navPersonalization',
                        visible: true,
                        access: [
                            {
                                name: 'tools.personalization_sub.issueStates_title',
                                href: '/issue_states',
                                visible: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: false,
                                submenu: true,
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.personalization_sub.modules_title',
                                href: '/modules',
                                visible: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: false,
                                submenu: true,
                                roles: [8, 9]
                            },
                            {
                                name: 'tools.personalization_sub.timeframes_title',
                                href: '/timeframes',
                                visible: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: false,
                                submenu: true,
                                roles: [7, 8, 9]
                            }
                        ]
                    },
                    {
                        name: 'tools.timers_title',
                        id: 'navTimers',
                        visible: true,
                        access: [
                            {
                                name: 'tools.timers_sub.operationalTimers_title',
                                href: '/operationalTimers',
                                visible: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: '16',
                                submenu: true,
                                roles: [7, 8, 9]
                            },
                            {
                                name: 'tools.timers_sub.categories_title',
                                href: '/operationalTimers/categories',
                                visible: true,
                                //  required_tool false para que se vea por defecto sin permisos
                                required_tool: '16',
                                submenu: true,
                                roles: [7, 8, 9]
                            }
                        ]
                    }
                ]
            },
            9: {
                name: 'tools.operationalTimer.tool_title',
                href: '/supervise/operationalTimers',
                id: 'navSuperviseOperationalTimers',
                required_tool: '16',
                submenu: false,
                roles: [3, 4, 5, 6, 7, 8, 9]
            }
        },

        refresh: {
            employee: true,
            library: true,
            templates: true,
            products: true
        },

        datetime: moment(),

        current_page: 'home',
        show_uploads: false,
        reason_postpone: {},
        reason_justify: {},
        chat_active: false,
        canCreateUsers: true,
        productNextState: false,
        showSplashScreen: 1
    }
}

export default new Vuex.Store({
    state: getDefaultState(),
    modules: {
        login,
        loginUser,
        alexAlert,
        employee,
        checklist,
        navigation,
        location,
        label,
        tasks,
        issues,
        actionplan,
        audits,
        records,
        groups,
        analytics,
        analyticsHome,
        library,
        account,
        template,
        lead,
        notes,
        onboarding,
        issueStates,
        multilanguage,
        timeframes,
        operationalTimers,
        filtersViews,
        reports,
        filterLocations,
        block
    },
    strict: debug,
    getters: {
        getLanguage: (state, getters, rootState) => {
            return state.language
        },
        getAvailableLanguages: (state, getters, rootState) => {
            return state.availableLanguages
        },
        getView: (state, getters, rootState) => {
            return state.view
        },
        getLocale: (state, getters, rootState) => {
            return state.locale
        },
        getReasonPostpone: (state, getters, rootState) => {
            return state.reason_postpone
        },
        getSingleReasonPostpone: (state, getters, rootState) => (id) => {
            return state.reason_postpone[id]
        },
        getReasonJustify: (state, getters, rootState) => {
            return state.reason_justify
        },
        formatedDate: (state, getters, rootState) => {
            return state.datetime.format('DD MMMM YYYY')
        },
        formatedTime: (state, getters, rootState) => {
            return state.datetime.format('ddd HH:mm')
        },
        getMoment: (state, getters, rootState) => {
            return state.datetime
        },

        getTools: (state, getters, rootState) => {
            return state.tools
        },

        getToolsByRoleLevel: (state, getters, rootState) => (level) => {
            var available_tools = state.login.config.tools
            const labelBlock = typeof state.login.config.labelBlock !== 'undefined' ? state.login.config.labelBlock : false
            var result = {}
            var tools = { ...state.tools }

            // LEVEL 1
            for (var section_index in Object.values(tools)) {
                var section = { ...Object.values(tools)[section_index] }
                var sectionroles = section.roles
                var sectionreqtool = section.required_tool

                if (section.access != undefined) {
                    // LEVEL 2
                    for (var subsection_index in section.access) {
                        var subsection = { ...section.access[subsection_index] }
                        var subsectionroles = subsection.roles
                        var subsectionreqtool = subsection.required_tool
                        const subsectionreqconfig = subsection.required_config
                        if (subsectionreqconfig == 'labelBlock' && !labelBlock) {
                            continue
                        }

                        if (subsection.access != undefined) {
                            // LEVEL 3
                            for (var idxsection_index in subsection.access) {
                                var idxsection = { ...subsection.access[idxsection_index] }
                                var idxsectionroles = idxsection.roles
                                var idxsectionreqtool = idxsection.required_tool
                                const idxsectionreqconfig = idxsection.required_config

                                if (idxsectionreqconfig == 'labelBlock' && !labelBlock) {
                                    continue
                                }

                                if (
                                    (idxsection == undefined || idxsectionroles.includes(level)) &&
                                    (idxsectionreqtool == undefined || idxsectionreqtool == false || available_tools.includes(parseInt(idxsectionreqtool)))
                                ) {
                                    if (result[Object.keys(tools)[section_index]] == undefined) {
                                        result[Object.keys(tools)[section_index]] = { ...section }
                                        result[Object.keys(tools)[section_index]].access = []
                                    }
                                    if (result[Object.keys(tools)[section_index]].access[subsection_index] == undefined) {
                                        result[Object.keys(tools)[section_index]].access[subsection_index] = { ...subsection }
                                        result[Object.keys(tools)[section_index]].access[subsection_index].access = []
                                    }
                                    result[Object.keys(tools)[section_index]].access[subsection_index].access.push(idxsection)
                                }
                            }
                        } else if (
                            (subsectionroles == undefined || subsectionroles.includes(level)) &&
                            (subsectionreqtool == undefined || subsectionreqtool == false || available_tools.includes(parseInt(subsectionreqtool)))
                        ) {
                            if (result[Object.keys(tools)[section_index]] == undefined) {
                                result[Object.keys(tools)[section_index]] = { ...section }
                                result[Object.keys(tools)[section_index]].access = []
                            }
                            result[Object.keys(tools)[section_index]].access.push(subsection)
                        }
                    }
                } else if (
                    (sectionroles == undefined || sectionroles.includes(level)) &&
                    (sectionreqtool == undefined || sectionreqtool == false || available_tools.includes(parseInt(sectionreqtool)))
                ) {
                    result[Object.keys(tools)[section_index]] = section
                }
            }

            return result
        },

        currentPrinter: (state, getters, rootState) => {
            return state.printer
        },

        hasAccess: (state, getters, rootState) => (to) => {
            var destination_path = to.path

            if (state.login.hasLogin) {
                var role_id = false
                role_id = state.login.local.user.rol

                var role = state.employee.roles[role_id]
                var level = parseInt(role.level)
                var available_tools = state.login.config.tools

                var tools = { ...state.tools }

                // CUSTOM PAGES THAT REQUIRES TOOLS
                if (to.path == '/notifications') {
                    var requiredNotificationTools = [3]
                    return requiredNotificationTools.some((r) => Object.values(available_tools).includes(r))
                } else if (to.path == '/news') {
                    var requiredNewsTools = [3]
                    return requiredNewsTools.some((r) => Object.values(available_tools).includes(r))
                }
                // CHECK IF HAS ACCESS TO TOOLS
                else {
                    // TOOLS LEVEL 1
                    for (var section_index in Object.values(tools)) {
                        var section = { ...Object.values(tools)[section_index] }
                        var sectionroles = section.roles
                        var sectionhref = section.href
                        var sectionreqtool = section.required_tool
                        var sectioneqlevel = section.role_edition
                            ? section.role_edition
                            : typeof sectionroles !== 'undefined' && sectionroles.length > 0
                            ? sectionroles[0]
                            : 8

                        if (section.access != undefined) {
                            // TOOLS LEVEL 2
                            for (var subsection_index in section.access) {
                                var subsection = { ...section.access[subsection_index] }
                                var subsectionroles = subsection.roles
                                var subsectionhref = subsection.href
                                var subsectionreqtool = subsection.required_tool
                                var subsectioneqlevel = subsection.role_edition
                                    ? subsection.role_edition
                                    : typeof subsectionroles !== 'undefined' && subsectionroles.length > 0
                                    ? subsectionroles[0]
                                    : 8

                                if (subsection.access != undefined) {
                                    // TOOLS LEVEL 3
                                    for (var idxsection_index in subsection.access) {
                                        var idxsection = { ...subsection.access[idxsection_index] }
                                        var idxsectionroles = idxsection.roles
                                        var idxsectionhref = idxsection.href
                                        var idxsectionreqtool = idxsection.required_tool
                                        var idxsectionreqlevel = idxsection.role_edition
                                            ? idxsection.role_edition
                                            : typeof idxsectionroles !== 'undefined' && idxsectionroles.length > 0
                                            ? idxsectionroles[0]
                                            : 8

                                        if (destination_path.includes(idxsectionhref)) {
                                            if (
                                                (typeof idxsectionroles === 'undefined' || idxsectionroles.includes(level)) &&
                                                (idxsectionreqtool == undefined ||
                                                    idxsectionreqtool == false ||
                                                    available_tools.includes(parseInt(idxsectionreqtool)))
                                            ) {
                                                if (destination_path == idxsectionhref || level >= idxsectionreqlevel) {
                                                    return true
                                                } else {
                                                    return false
                                                }
                                            } else return false
                                        }
                                    }
                                } else if (destination_path.includes(subsectionhref)) {
                                    if (
                                        (typeof subsectionroles === 'undefined' || subsectionroles.includes(level)) &&
                                        (subsectionreqtool == undefined || subsectionreqtool == false || available_tools.includes(parseInt(subsectionreqtool)))
                                    ) {
                                        if (destination_path == subsectionhref || level >= subsectioneqlevel) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    } else return false
                                }
                            }
                        } else {
                            if (destination_path.includes(sectionhref)) {
                                if (
                                    (sectionroles == undefined || sectionroles.includes(level)) &&
                                    (sectionreqtool == undefined || sectionreqtool == false || available_tools.includes(parseInt(sectionreqtool)))
                                ) {
                                    if (destination_path == sectionhref || level >= sectioneqlevel) {
                                        return true
                                    } else {
                                        return false
                                    }
                                } else return false
                            }
                        }
                    }
                }
                // IF IS A TOOL THAT IS NOT IN TOOL'S ARRAY
                return false
            }
        },
        hasAccessUser: (state, getters, rootState) => (id, level) => {
            level = typeof level !== 'undefined' ? level : 3

            // LEyes "no escritas" sobre los permisos/roles ಠ_ಠ
            // 0 - Diria que sin permisos / Employee
            // 1 - Jefe de turno / Employee
            // 2 - JEfe de turno / Gerente
            // 3 - SUPERVISOR (si o si)

            var access = false
            if (state.loginUser.hasLoginUser) {
                var emp_id = false
                if (typeof state.loginUser.local.employee_id !== 'undefined') {
                    emp_id = state.loginUser.local.employee_id
                }

                var role_id = state.loginUser.local.user.rol

                if (state.employee.roles == undefined) {
                    var roles = state.employee.filters.roles
                } else var roles = state.employee.roles

                var role = roles[role_id]
                var levelRole = parseInt(role.level)

                if (emp_id) {
                    if (levelRole >= level) {
                        access = true
                    }
                } else {
                    if (levelRole >= level) {
                        access = true
                    }
                }
            }
            return access
        },

        uploads_show: (state, getter, rootState) => {
            return state.show_uploads
        },

        getIsChatActive: (state, getter, rootState) => {
            return state.chat_active
        },

        getCanCreateUsers: (state, getter, rootState) => {
            return state.canCreateUsers
        },

        getShowSplashScreen: (state, getters, rootstate) => {
            return state.showSplashScreen
        },

        getProductNextState: (state, getter, rootState) => {
            return state.productNextState
        },

        getLevelUser: (state, getters, rootState) => {
            const user = getters['login/getLocalEmployee']
            if (user) {
                const role = getters['employee/getRoles'](user.rol)
                return role.level ? parseInt(role.level) : false
            }
            return false
        },
        getConfigLabelBlock: (state, getters, rootState) => {
            return typeof state.login.config.labelBlock !== 'undefined' ? state.login.config.labelBlock : false
        }
    },
    actions: {
        languages(context, params) {
            return api.get('lang/list', {}).then(function (response) {
                context.commit('setAvailableLanguages', response.data)
            })
        },
        checkUpdates(context, params) {
            if (fnCheckConnection()) {
                return api.get('updates', {}).then(function (response) {
                    var data = {}

                    db_updates
                        .iterate(function (value, key, iterationNumber) {
                            data[key] = value ? response.data[key] > value : true
                        })
                        .then(function () {
                            _.forEach(response.data, function (value, key) {
                                db_updates.setItem(key, value)
                            })

                            context.commit('setUpdates', data)
                        })
                })
            }
        },

        resetStore({ dispatch, commit }) {
            // No tiene mucho sentido resetear el login, no? :(
            // dispatch('login/resetState');
            commit('account/resetState')
            commit('alexAlert/resetState')
            commit('analytics/resetState')
            commit('analyticsHome/resetState')
            commit('audits/resetState')
            commit('checklist/resetState')
            commit('employee/resetState')
            commit('groups/resetState')
            commit('issues/resetState')
            commit('label/resetState')
            commit('library/resetState')
            commit('location/resetState')
            commit('login/resetState')
            commit('loginUser/resetState')
            commit('navigation/resetState')
            commit('records/resetState')
            commit('tasks/resetState')
            commit('template/resetState')
            commit('resetState')
        }
    },
    mutations: {
        setSelectPopup(state, value) {
            state.selectPopup = value
            Vue.set(state, 'selectPopup', value)
        },
        setTitle(state, value) {
            state.title = value
            // Vue.set(state, 'title', {...state.title});
        },
        setAvailableLanguages(state, value) {
            state.availableLanguages = value
            // Vue.set(state, 'title', {...state.title});
        },
        setView(state, value) {
            state.view = value
        },
        setLanguage(state, value) {
            state.language = value
            // Vue.set(state, 'language', {...state.language});
        },
        setLocale(state, value) {
            state.locale = value
            // Vue.set(state, 'language', {...state.language});
        },
        setReasonPostpone(state, value) {
            state.reason_postpone = value
            Vue.set(state, 'reason_postpone', value)
        },
        setReasonJustify(state, value) {
            state.reason_justify = value
            Vue.set(state, 'reason_justify', value)
        },
        setUpdates(state, value) {
            Vue.set(state, 'refresh', { ...value })
        },

        setAlexVisible(state, value) {
            state.alex_is_visible = value
            // Vue.set(state, 'alex_is_visible', {...state.alex_is_visible});
        },

        setMenuTopVisible(state, value) {
            state.menu_top_is_visible = value
            // Vue.set(state, 'menu_top_is_visible', {...state.menu_top_is_visible});
        },
        setMenuToolsVisible(state, value) {
            state.menu_tools_is_visible = value
            // Vue.set(state, 'menu_tools_is_visible', {...state.menu_tools_is_visible});
        },
        setMenuToolsExpanded(state, value) {
            state.menu_tools_is_expanded = value
            // Vue.set(state, 'menu_tools_is_expanded', {...state.menu_tools_is_expanded});
        },

        setPrinter(state, value) {
            state.printer = value
        },

        setMoment: (state, getters, rootState) => {
            state.datetime = moment()

            if (Object.keys(checklist.state.checklist).length > 0) {
                checklist.actions.reloadChecklist()
            }
        },

        setTables(state, tables) {
            state.table_relatioship = true
        },

        setPage(state, page) {
            state.current_page = page

            state.navigation.page = state.navigation.routing[page]
        },

        changeLanguage(state, value) {
            state.language = value
            i18n.loadLanguageAsync(value)
            localStorage.setItem('language', value)
        },

        showUploads(state, value) {
            state.show_uploads = value
        },

        resetState(state) {
            Object.assign(state, getDefaultState())
        },

        setChatActive(state, value) {
            state.chat_active = value
        },

        setCanCreateUsers(state, value) {
            state.canCreateUsers = value
        },

        setShowSplashScreen(state, data) {
            state.showSplashScreen = data
        },

        setProductNextState(state, value) {
            state.productNextState = value
        }
    }
})
