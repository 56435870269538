// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'

import store from './store'
import router from './router'
import { Db } from '@/core/db.js'

import Lodash from 'lodash' // options is optional

// MIXINS
import mixin from './core/mixin'

// LANGUAGE
import i18n from './i18n'

// PLUGINS PROPIOS
import popup from './plugins/popup'
import overlay from './plugins/overlay'
import menuactions from './plugins/menuActions'
// import bottombar from './plugins/bottomBar'
import snackbar from './plugins/snackbar'
import columnFilter from './plugins/columnFilter'
import vueSignature from 'vue-signature'
import DisableAutocomplete from '@aacassandra/vue-disable-autocomplete'
import VueTypedJs from 'vue-typed-js'
import splashScreen from './plugins/splashScreen'

import * as Sentry from '@sentry/vue'

// VUETIFY
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify'
// export default new Vuetify({
//   theme: {disable: true },
//   icons: {
//     iconfont: 'md',
//   },
// })

// VUE CLIPPER CROP PHOTOS
import VuejsClipper from 'vuejs-clipper'
Vue.use(VuejsClipper)

// INACTIVITY PLUGIN
import IdleVue from 'idle-vue'

import 'es6-promise/auto'

// LAYOUTS
import Default from './layouts/Default-Layout.vue'
import Login from './layouts/Login-Layout.vue'
import Report from './layouts/Report-Layout.vue'

// GLOBAL COMPONENTS
import { VTooltip } from 'v-tooltip'
import Button from '@/components/ui/Button'
import TranslateButton from '@/components/domain/multi-language/translateButton'
import TranslateInput from '@/components/domain/multi-language/translateInput'
import Tags from '@/components/ui/Tags'

import VueGtag from 'vue-gtag'
import Hotjar from 'vue-hotjar'
//Allow to user calendar
import FunctionalCalendar from 'vue-functional-calendar'

const options = { name: 'lodash' } // customize the way you want to call it
Vue.use(Lodash, options)
Vue.use(i18n)

// PLUGINS PROPIOS
Vue.use(popup)
Vue.use(overlay)
Vue.use(menuactions)
// Vue.use(bottombar)
Vue.use(snackbar)
Vue.use(columnFilter)
Vue.use(splashScreen)

Vue.use(VueTypedJs)

// PLUGINS AJENOS
var VueTouch = require('vue-touch')
import VueMq from 'vue-mq'

Vue.use(VueMq, {
    breakpoints: {
        portrait: 901,
        landscape: 1200,
        desktop: Infinity
    }
})

Vue.use(FunctionalCalendar, {
    dayNames: i18n.t('answer.calendar.day_names'),
    monthNames: i18n.t('answer.calendar.months')
})

Vue.use(VueTouch, { name: 'v-touch' })
Vue.use(vueSignature)
Vue.use(DisableAutocomplete)
const vuetify = new Vuetify({
    theme: { disable: true },
    icons: {
        iconfont: 'md'
    }
})
Vue.use(Vuetify)

const eventsHub = new Vue()
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: idle_time
})

Vue.use(
    VueGtag,
    {
        config: { id: process.env.VUE_APP_GoogleAnaylticsKey },
        pageTrackerScreenviewEnabled: true,
        pageTrackerTemplate(to) {
            return {
                page_title: to.name,
                page_path: to.path
            }
        }
    },
    router
)

Vue.use(Hotjar, {
    id: process.env.VUE_APP_HotjarId,
    isProduction: true
})

// COMPONENTS
Vue.component('default-layout', Default)
Vue.component('login-layout', Login)
Vue.component('report-layout', Report)

Vue.config.productionTip = false
Vue.component('Button', Button)
Vue.component('TranslateButton', TranslateButton)
Vue.component('TranslateInput', TranslateInput)
Vue.component('Tags', Tags)

// DIRECTIVES
Vue.directive('tooltip', VTooltip)

// all Cloudinary components:
import Cloudinary from 'cloudinary-vue'
Vue.use(Cloudinary, {
    configuration: { cloudName: 'intowin' }
})

/*import VueAmplitude from 'vue-amplitude-plugin'
Vue.use(VueAmplitude, {
    apiKey: 'dc0e090f7888be0e453d849246f27982',
    userId: ''
})*/
import * as amplitude from '@amplitude/analytics-browser'

//Session tracker for amplitude, add more info to the session
const account_name = store.getters['login/getLocalAccount'].account_name
const user = store.getters['loginUser/getLocalUser']
let rol = null
if (user) {
    rol = store.getters['employee/getRoles'](user.rol)
}

if (account_name && user) {
    const indetify = new amplitude.Identify()
    indetify.set('enviroment', 'production')
    indetify.set('account', account_name)
    amplitude.reset()
    amplitude.init('dc0e090f7888be0e453d849246f27982')
    amplitude.setUserId(user.email)

    indetify.set('email', user.email)
    if (rol && rol.name) {
        indetify.set('rol', rol.name)
    }
    amplitude.identify(indetify)
}

Vue.prototype.$amplitude = amplitude

Sentry.init({
    Vue,
    dsn: 'https://b3e3f60e61e15b40e2eb94d8c9b3100f@o4506982251954176.ingest.us.sentry.io/4506982259425280',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/andyapp\.io/, /^https:\/\/labtowin\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

//Vue.use(VueAmplitude, {'dc0e090f7888be0e453d849246f27982'});

// import VuePictureSwipe from 'vue-picture-swipe';
// Vue.component('vue-picture-swipe', VuePictureSwipe);

// Componente de inputs global
import Input from '@/components/input/Input'
Vue.component('Input', Input)

/* eslint-disable no-new */
new Vue({
    el: '#app',
    i18n,
    store,
    router,
    moment,
    components: { App },
    template: '<App/>',
    mixins: [mixin],
    vuetify /* mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', '~@/core/store.js')
    document.head.appendChild(recaptchaScript)
  }, */
})

if (is_app) {
    document.addEventListener(
        'deviceready',
        function () {
            // console.log('DEVICE READY')
            function pdMoveInternalFilesToRootDirectory(aFilesToCopy, fCaallBack) {
                if (aFilesToCopy.length > 0) {
                    var oFile = aFilesToCopy.pop()
                    window.resolveLocalFileSystemURL(cordova.file.externalRootDirectory, function (fileSystem) {
                        fileSystem.getDirectory(oFile.root_dir, { create: true }, function (dirEntry) {
                            window.resolveLocalFileSystemURL(
                                oFile.current_dir,
                                function (fileEntry) {
                                    fileEntry.copyTo(
                                        dirEntry,
                                        oFile.name,
                                        function () {
                                            pdMoveInternalFilesToRootDirectory(aFilesToCopy, fCaallBack)
                                        },
                                        function (e) {
                                            pdMoveInternalFilesToRootDirectory(aFilesToCopy, fCaallBack)
                                        }
                                    )
                                },
                                function (e) {
                                    pdMoveInternalFilesToRootDirectory(aFilesToCopy, fCaallBack)
                                }
                            )
                        })
                    })
                } else {
                    localStorage.setItem('first_time', 1)
                }
            }

            if (typeof localStorage.first_time === 'undefined' || localStorage.first_time == '1') {
                var aFilesToCopy = [
                    { current_dir: cordova.file.applicationDirectory + 'www/extras/td2120_57mm.bin', root_dir: 'ALEX', name: 'td2120_57mm.bin' },
                    { current_dir: cordova.file.applicationDirectory + 'www/extras/product2.pdz', root_dir: 'ALEX', name: 'product2.pdz' }
                ]

                pdMoveInternalFilesToRootDirectory(aFilesToCopy)
            }
        },
        false
    )
}

// Shared functions

// $(document).on("focus", ".input-comment, .input-message, .edit-comment, input[name='text-search']", function(){
//     $("body").addClass('keyboard-showing');
//
//     if ( $("#content.logbook").hasClass("addedit") ){
//         if( $(".log-box.detailed").length > 0 ){
//             $(".log-box.detailed").scrollTop( $(this).offset().top + 40 + 50);
//         } else if( $(".log-box.addedit").length > 0 ){
//             $(".log-box.addedit").scrollTop( $(this).offset().top + 40 + 50);
//         }
//
//     } else {
//         $("#content.logbook > .content").scrollTop( $(this).offset().top + 40 + 50);
//     }
//
// });
//
// $(document).on("blur", ".input-comment, .input-message, .edit-comment, input[name='text-search']", function(){
//     $("body").removeClass('keyboard-showing');
// });
