import { Api } from '@/core/api.js'
import Vue from 'vue'

const api = new Api()

const state = {
    timeFrames: {},
    filtersStatus: {},
    filtersStatusActive: {
        status: [{ id: 1, name: 'tools.groups.status.active' }]
    },
    numItems: 0,
    itemsLoaded: false,
    newTimeFrame: {
        id: '',
        name: '',
        start_hour: 0,
        end_hour: 0,
        status: '1'
    }
}

const getters = {
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },
    getTimeFrames: (state, getters, rootState) => (id) => {
        if (id) {
            return state.timeFrames[id]
        }
        return state.timeFrames
    },
    getFiltersStatus: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersStatus.status[id]
        }
        return state.filtersStatus.status
    },
    getFiltersStatusActive: (state, getters, rootState) => {
        return state.filtersStatusActive
    },
    getNumItems: (state, getters, rootState) => {
        return state.numItems
    }
}

const actions = {
    addNewTimeFrame(context, params) {
        var newTimeFrame = Object.assign({}, context.state.newTimeFrame)
        newTimeFrame.id = 'tmpitw' + moment().unix()
        context.commit('createNewTimeFrame', newTimeFrame)
        return newTimeFrame.id
    },
    loadTimeFrames(context, params) {
        context.commit('setItemsLoaded', false)
        return api.post('admin/checklist/timeframes', params).then(function (response) {
            if (response) {
                context.commit('setTimeFrames', response.data.data)
                context.commit('setNumItems', response.data.results)
            }
        })
    },
    loadFiltersTimeFrames(context) {
        return api.get('admin/labels/category/filters').then(function (response) {
            context.commit('setFiltersStatus', response.data)
        })
    },
    removeTimeFrame(context, params) {
        return api.get('admin/checklist/timeframes/' + params + '/delete').then(function (response) {
            if (response) {
                context.commit('deleteTimeFrame', params)
            }
        })
    },
    validateTimeFrame(context, params) {
        var stateToValidate = context.getters['getTimeFrames'](params)
        var errors = {
            name: false,
            start_hour: false,
            end_hour: false
        }
        var sendForm = true
        errors = _.mapValues(errors, () => false)

        if (stateToValidate.name === '') {
            sendForm = false
            errors.name = true
        }
        if (stateToValidate.start_hour === null) {
            sendForm = false
            errors.start_hour = true
        }
        if (stateToValidate.end_hour === null) {
            sendForm = false
            errors.end_hour = true
        }
        return {
            status: sendForm,
            errors: errors
        }
    },
    addTimeFrames(context, params) {
        if (params.status == 0) {
            params.status = '-2'
        }
        // if params.end_hour is > than 24 and params.start hour is < than end_hour, then we need to substract 24 hours to params.end_hour
        if (params.end_hour >= 24 && params.start_hour < params.end_hour) {
            params.end_hour = parseFloat(params.end_hour) - 24
        }
        // if params.end_hour is > than params.start_hour, then we need to add 24 hours to params.end_hour
        if (params.end_hour <= params.start_hour) {
            params.end_hour = parseFloat(params.end_hour) + 24
        }
        return api.post('admin/checklist/timeframes/add', params).then(function (response) {
            if (response) {
                context.commit('createNewTimeFrame', response.data)
            }
        })
    },
    editTimeFrames(context, params) {
        if (params.status == 0) {
            params.status = '-2'
        }
        // if params.end_hour is > than 24 and params.start hour is < than end_hour, then we need to substract 24 hours to params.end_hour
        if (params.end_hour >= 24 && params.start_hour < params.end_hour) {
            params.end_hour = parseFloat(params.end_hour) - 24
        }
        if (params.end_hour <= params.start_hour) {
            params.end_hour = parseFloat(params.end_hour) + 24
        }
        return api.post('admin/checklist/timeframes/' + params.id + '/edit', params).then(function (response) {
            if (response) {
                context.commit('updateTimeFrame', response.data)
            }
        })
    }
}

const mutations = {
    setTimeFrames(state, data) {
        state.timeFrames = data
        state.itemsLoaded = true
    },
    deleteTimeFrame(state, id) {
        var timeFrames = { ...state.timeFrames }
        delete timeFrames[id]
        Vue.set(state, 'timeFrames', { ...timeFrames })
    },
    createNewTimeFrame(state, payload) {
        var timeFrames = { ...state.timeFrames }
        timeFrames[payload.id] = payload
        state.timeFrames = { ...timeFrames }
        Vue.set(state, 'timeFrames', { ...timeFrames })
    },
    updateTimeFrame(state, payload) {
        var timeFrames = { ...state.timeFrames }
        timeFrames[payload.id] = payload
        Vue.set(state, 'timeFrames', { ...timeFrames })
    },
    setFiltersStatus(state, filters) {
        state.filtersStatus = filters
        Vue.set(state, 'filtersStatus', { ...filters })
    },
    setFiltersStatusActive(state, filters) {
        state.filtersStatusActive = filters
        Vue.set(state, 'filtersStatusActive', { ...filters })
    },
    setNumItems(state, payload) {
        Vue.set(state, 'numItems', payload)
    },
    setItemsLoaded(state, payload) {
        Vue.set(state, 'itemsLoaded', payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
