import i18n from '@/i18n'

import Vue from 'vue'

import { Api } from '@/core/api.js'
import { update } from 'lodash'
const api = new Api()

const defaultFilters = {
    name: '',
    status: false,
    start_date: 'week',
    end_date: '',
    type_date: 'week',
}
// initial state
const getDefaultState = () => {
    return {
        blockList: [],
        ingredientsProduct: [],
        productsContainIngredient: [],
        currentFilter: structuredClone(defaultFilters),
        defaultFilters: structuredClone(defaultFilters),

        filters:{
            status: [
                { id: 1, name: 'tools.groups.status.active' },
                { id: -2, name: 'sites.form.inactive' }
            ],
        },

        pagination: {
            page: 1,
            itemsPerPage: 20
        }
    }
}
const state = getDefaultState()

// getters
const getters = {
    blockList: (state) => (id) =>{
        if(id){
            return state.blockList.find(block => block.id === id)
        }
        return state.blockList
    },
    getIngredientsProduct: (state) => {
        return state.ingredientsProduct
    },
    getProductsContainIngredient: (state) => {
        return state.productsContainIngredient
    },
    getOptionFilterStatus: (state, getters, rootState) => {
        return state.filters.status
    },
    getCurrentFilters: (state, getters, rootState) => {
        return state.currentFilters
    },
    getDefaultFilters: (state, getters, rootState) => {
        return state.defaultFilters
    },
    getPagination: (state, getters, rootState) => {
        return state.pagination
    },
}

// actions
const actions = {
    loadList(context, params){
        const pagination = context.getters.getPagination
        params.pagenum = pagination.page
        params.number_items = pagination.itemsPerPage
        return api.post('admin/blocks', params)
        .then(function (response) {
            context.commit('setBlockList', response.data)
        })
    },
    loadIngredientsFromProduct(context, params){
        return api.post('admin/blocks/ingredients', {id: params.product_id})
        .then(function (response) {
            context.commit('setIngredientsProduct', response.data)
        })
    },
    loadProductsFromIngredient(context, params){
        return api.post('admin/blocks/products-ingredients', {name: params.name})
        .then(function (response) {
            context.commit('setProductsContainIngredient', response.data)
        })
    },
    createBlock(context, params){
        return api.post('admin/blocks/add', params)
        .then(function (response) {
            return response
        })
    },
    deleteBlock(context, params){
        return api.post(`admin/blocks/delete/${params.id}`, {})
        .then(function (response) {
            return response
        })
    },
    updateBlock(context, params){
        return api.post(`admin/blocks/edit/${params.id}`, params)
        .then(function (response) {
            return response
        })
    },
    resetProductsContainIngredient(context){
        context.commit('setProductsContainIngredient', [])
    }
}

// mutations
const mutations = {
    setBlockList(state, data){
        const blockListFormated = Object.values(data).map((block) => {
            if(block.children){
                const tempChildren = {}
                Object.keys(block.children).forEach(key => {
                    tempChildren[block.children[key].id] = block.children[key].value
                })
                block.children = tempChildren
            }
            return block
        })

        state.blockList = blockListFormated

        Vue.set(state, 'blockList', blockListFormated)
    },
    setIngredientsProduct(state, data){
        state.ingredientsProduct = data

        Vue.set(state, 'ingredientsProduct', data)
    },
    setProductsContainIngredient(state, data){
        state.productsContainIngredient = data

        Vue.set(state, 'productsContainIngredient', data)
    },

    setPagination(state, data){
        state.pagination = data

        Vue.set(state, 'pagination', data)
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
