import Template from './bar.vue'
import i18n from '@/i18n'

let globalOptions = {
  show: true,
  buttons: [
    // { text: '', callback: function(){}, class: '' }
  ],
  groups: {
    label: '',
    buttons: [],
  },
  navBack: {
    text: '',
    class: '',
    callback: function(){},
  },
}

let instance

const Init = function (buttons, groups, navBack) {
  let Tpl = this.extend(Template)

  if (!instance) instance = new Tpl()

  if(typeof buttons !== 'undefined') globalOptions.buttons = buttons;
  if(typeof groups !== 'undefined') globalOptions.groups = groups;
  if(typeof navBack !== 'undefined') globalOptions.navBack = navBack;

  for (let key in globalOptions) {
    if (globalOptions.hasOwnProperty(key)) {
      instance.$data[key] = globalOptions[key]
    }
  }
}

const Reset = function (buttons, groups, navBack) {
  let Tpl = this.extend(Template)

  if (!instance) instance = new Tpl()

  if(typeof buttons !== 'undefined') globalOptions.buttons = [];
  if(typeof groups !== 'undefined') globalOptions.groups = {};
  if(typeof navBack !== 'undefined') globalOptions.navBack = {};

  for (let key in globalOptions) {
    if (globalOptions.hasOwnProperty(key)) {
      instance.$data[key] = globalOptions[key]
    }
  }
}

const Show = function (actions= {}, nav, navClass) {
  Init.call(this, actions, nav, navClass)

  instance.$data.show = true
  // document.body.style.overflow = 'hidden'
  document.body.appendChild(instance.$mount().$el)
}

const SetButtons = function (params= []) {

  var config = {};
  config.buttons = params;

  Init.call(this, config)
}
const SetGroups = function (params= {}) {

  var config = {};
  config.groups = params;

  Init.call(this, config)
}
const SetNavBack = function (params= {}) {

  var config = {};
  config.navBack = params;

  Init.call(this, config)
}

const Hide = function () {
  instance.$data.show = false
}

export default {
  install (Vue) {
    Vue.prototype.$bar = Init.bind(Vue)
    Vue.prototype.$bar.show = Show.bind(Vue)
    Vue.prototype.$bar.setButtons = SetButtons.bind(Vue)
    Vue.prototype.$bar.setGroups = SetGroups.bind(Vue)
    Vue.prototype.$bar.setNavBack = SetNavBack.bind(Vue)
    Vue.prototype.$bar.hide = Hide.bind(Vue)
    Vue.prototype.$bar.reset = Reset.bind(Vue)
  }
}
