import Template from './Popup.vue'
import i18n from '@/i18n'
import router from '@/router'

let globalOptions = {
    mood: 'normal',
    show: false,
    closable: true,
    blocking: true,
    type: '',
    customClass: '',
    title: '',
    message: '',
    messageError: '',
    showingError: false,
    list: {},
    listSec: {},
    listEmpty: i18n.t('popup.default.list_empty'),
    textLogout: '',
    textCancel: i18n.t('popup.default.text_cancel'),
    textSave: i18n.t('popup.default.text_save'),
    textSaveStart: '',
    textNext: '',
    textPrev: '',
    inputVal: '',
    productName: '',
    image: false,
    file: false,
    selected: false,
    selectedSec: false,
    selectedEl: undefined,
    selectedSecEl: undefined,
    selectedTab: '',
    selectedSecTab: '',
    disabledMessage: '',
    disNumpad: false,
    dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    dateStart: '',
    dateEnd: '',
    processTypes: [],
    currentStep: 0,
    data: [],
    cType: false,
    signOptions: {
        penColor: 'rgb(51, 51, 51)',
        backgroundColor: 'rgb(255,255,255)',
        format: 'png',
        watermark: ''
    },
    hasNegative: false,
    decimal: false,
    tempOptions: {
        maxRange: null,
        minRange: null,
        maxNum: 50,
        minNum: -50,
        stepNum: 1,
        numDec: 1,
        stepDec: 1,
        measure: 'ºC'
    },
    routePath: false,
    requireTyping: false,
    requiredWord: '',
    showErrorMessage: false,
    callLogout: function () {},
    callCancel: function () {
        if (self.closable) {
            self.$popup.close()
        }
    },
    callSave: function () {},
    callSaveStart: function () {},
    callRemoveAll: function () {},
    callAfterAccess: function (response) {
        var routePath = instance ? instance.routePath : '/home'
        if (routePath) {
            router.push('/blank')
            router.push(routePath)
        }
        if (instance) {
            instance.$popup.close()
        }
    }
}

const defCallLogin = function (self, store) {
    var validation = []
    var employee_id = self.selected
    var employee_pin = self.inputVal

    if (typeof employee_id === 'undefined') {
        validation.push(false)
    }
    if (!Number.isInteger(employee_pin.trim()) && employee_pin.trim().length != 4) {
        validation.push(false)
    }

    if (validation.indexOf(false) == -1) {
        self.showingError = false

        store.dispatch('loginUser/doLogin', { employee: employee_id, password: sha256(employee_pin) }).then(function (response) {
            if (response) {
                self.callAfterAccess(response)
            } else {
                self.showingError = true
                self.messageError = i18n.t('popup.login.error_access')
            }
        })
    } else {
        self.showingError = true
        self.messageError = i18n.t('popup.login.error_validation')
    }
}

let instance

const Init = function (config = {}) {
    let Tpl = this.extend(Template)

    if (!instance) {
        instance = new Tpl()
        config = {
            ...globalOptions,
            ...config
        }
    } else {
        config = {
            ...globalOptions,
            ...instance.$data,
            ...config
        }
    }

    for (let key in config) {
        if (config.hasOwnProperty(key)) {
            // console.log(key + "   " + config[key])
            instance.$data[key] = config[key]
        }
    }
}

const Open = function (config = {}) {
    if (!instance) {
        Init.call(this, config)

        instance.$data.show = true
        // document.body.style.overflow = 'hidden';
        document.body.appendChild(instance.$mount().$el)
    }
}
const Close = function (config = {}) {
    if (instance) {
        instance.$data.show = false
        document.body.removeChild(instance.$mount().$el)
        instance = undefined
    }
}

const Alert = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'alert',
        title: i18n.t('popup.alert.title'),
        textSave: i18n.t('popup.alert.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const AlertNewDesing = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'alertNewDesing',
        title: i18n.t('popup.alert.title'),
        textSave: i18n.t('popup.alert.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Confirm = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'confirm',
        title: i18n.t('popup.confirm.title'),
        textCancel: i18n.t('popup.confirm.text_cancel'),
        textSave: i18n.t('popup.confirm.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const TableColumn = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'tableColumn',
        mood: 'surprised',
        title: i18n.t('popup.confirm.title'),
        textCancel: i18n.t('popup.confirm.text_cancel'),
        textSave: i18n.t('popup.confirm.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const PreviewSignature = function (config = {}) {
    const defaults = {
        type: 'previewSignature',
        textCancel: i18n.t('popup.media.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}
const Calendar = function (config = {}) {
    var defaults = {
        type: 'date'
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const CalendarRange = function (config = {}) {
    var defaults = {
        type: 'dateRange'
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Postpone = function (config = {}) {
    var defaults = {
        type: 'postpone'
    }
    // console.warn('POSTPONE CONFIG OBJECT', config)
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Delete = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'confirm-delete',
        title: i18n.t('popup.delete.title'),
        message: i18n.t('popup.delete.message'),
        textSave: i18n.t('user.popup.delete_text_save'),
        textCancel: i18n.t('user.popup.delete_text_cancel'),

        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                if (!this.requireTyping) {
                    configCallSave(self, store)
                    self.$popup.close()
                }

                if (this.requireTyping) {
                    const requiredWordIsCorrect = this.requiredWord.trim().toLowerCase() === i18n.t('popup.delete.requireTypingInput')

                    if (!requiredWordIsCorrect) {
                        this.showErrorMessage = true
                    } else {
                        this.showErrorMessage = false
                        configCallSave(self, store)
                        self.$popup.close()
                    }
                }
            }
        }
    }

    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

const Login = function (config = {}) {
    var defaults = {
        type: 'login',
        title: i18n.t('popup.login.title'),
        listEmpty: i18n.t('popup.login.list_empty'),
        textLogout: i18n.t('popup.login.text_logout'),
        textCancel: i18n.t('popup.login.text_cancel'),
        textSave: i18n.t('popup.login.text_save'),
        disabledMessage: i18n.t('popup.login.select_user'),
        message: i18n.t('popup.login.inactivity_info', { s: '30 ' + i18n.tc('assets.timing.minute', 2) }),
        disNumpad: true,
        callSave: function (self, store) {
            defCallLogin(self, store)
        },
        callLogout: function (self, store) {
            if (localStorage.employee_id) {
                store.dispatch('loginUser/doLogout', { employee: localStorage.employee_id }).then(function (response) {
                    self.$popup.close()
                    // router.push('/blank');
                    // router.push( self.redirectPath('/home') );
                    router.push(self.redirectPath('/logout'))
                })
            } else {
                self.$popup.close()
                // router.push('/blank');
                // router.push( self.redirectPath('/home') );
                router.push(self.redirectPath('/logout'))
            }
        }
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Media = function (config = {}) {
    var defaults = {
        type: 'media',
        textCancel: i18n.t('popup.media.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const gallery = function (config = {}) {
    var defaults = {
        type: 'gallery',
        textCancel: i18n.t('popup.media.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const InvitationUser = function (config = {}) {
    var defaults = {
        type: 'invitationUser',
        textCancel: i18n.t('popup.media.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const PDFDisplayOptions = function (config = {}) {
    var defaults = {
        type: 'PDFDisplayOptions',
        textSave: i18n.t('button.confirm'),
        textCancel: i18n.t('button.cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const GaleryIcons = function (config = {}) {
    var defaults = {
        type: 'galeryIcons',
        textCancel: i18n.t('popup.media.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const GaleryTemplates = function (config = {}) {
    var defaults = {
        type: 'galeryTemplates',
        textCancel: i18n.t('popup.media.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const TemporalTpl = function (config = {}) {
    var defaults = {
        type: 'temporalTpl',
        textCancel: i18n.t('button.cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const DeleteItem = function (config = {}) {
    var defaults = {
        type: 'deleteItem',
        tool: '',
        textCancel: i18n.t('supervise.checklists.cancel_delete'),
        textSave: i18n.t('supervise.checklists.confirm_delete')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const siteSave = function (config = {}) {
    var defaults = {
        type: 'siteSave',
        textCancel: i18n.t('popup.media.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const release = function (config = {}) {
    var defaults = {
        type: 'release',
        textCancel: i18n.t('popup.media.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const mediaOnboarding = function (config = {}) {
    var defaults = {
        type: 'media-onboarding',
        textCancel: i18n.t('popup.media.text_cancel')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const labelPreview = function (config = {}) {
    var defaults = {
        type: 'labelPreview',
        textCancel: i18n.t('popup.media.text_cancel_preview')
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Numeric = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'numeric',
        textCancel: i18n.t('popup.sign.text_cancel'),
        textSave: i18n.t('popup.sign.text_save'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const Temperature = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'temperature',
        textCancel: i18n.t('popup.process.text_cancel'),
        textNotNow: i18n.t('popup.sign.text_cancel'),
        textSave: i18n.t('popup.access.text_save'),
        sensorType: 'manual',
        inputVal: '0,0',
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Sign = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'sign',
        title: i18n.t('popup.sign.title'),
        textCancel: i18n.t('popup.sign.text_cancel'),
        textSave: i18n.t('popup.sign.text_save'),
        disabledMessage: i18n.t('popup.sign.select_user'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}
const FreeSign = function (config = {}) {
    var configCallSave = config.callSave

    var defaults = {
        type: 'freeSign',
        title: i18n.t('popup.sign.title'),
        textCancel: i18n.t('popup.sign.text_cancel'),
        textSave: i18n.t('popup.sign.text_save'),
        disabledMessage: i18n.t('popup.sign.select_user'),
        callSave: function (self, store) {
            if (typeof configCallSave !== 'undefined') {
                configCallSave(self, store)
            }
            self.$popup.close()
        }
    }
    if (typeof configCallSave !== 'undefined') {
        config.callSave = defaults.callSave
    }

    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

const MessageWithButton = function (config = {}) {
    const defaults = {
        type: 'messageWithButton'
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

const ExportLoading = function (config = {}) {
    const defaults = {
        type: 'exportLoading'
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

const SelectorsHistory = function (config = {}) {
    const defaults = {
        type: 'selectorsHistory',
        title: i18n.t('popup.selectors-history.title'),
        message: i18n.t('popup.selectors-history.description'),
        help: i18n.t('popup.selectors-history.help'),
        textCancel: i18n.t('button.cancel'),
        textSave: i18n.t('button.confirm'),
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

const TemplateConvertTo = function (config = {}) {
    const defaults = {
        type: 'templateConvertTo',
        title: i18n.t('popup.templateConvertTo.title'),
        message: i18n.t('popup.templateConvertTo.message'),
        textCancel: i18n.t('button.cancel'),
        textSave: i18n.t('button.confirm'),
        tools : {
            1: i18n.t('tools.template_sub.checklist_title'),
            3: i18n.t('tools.template_sub.records_title'),
            4: i18n.t('tools.template_sub.issue_title'),
            2: i18n.t('tools.template_sub.audits_title'),
        }
    }

    config = {
        ...defaults,
        ...config
    }

    Open.call(this, config)
}

export default {
    install(Vue) {
        Vue.prototype.$popup = Init.bind(Vue)
        Vue.prototype.$popup.open = Open.bind(Vue)
        Vue.prototype.$popup.close = Close.bind(Vue)

        Vue.prototype.$popup.alert = Alert.bind(Vue)
        Vue.prototype.$popup.alertNewDesing = AlertNewDesing.bind(Vue)
        Vue.prototype.$popup.confirm = Confirm.bind(Vue)
        Vue.prototype.$popup.date = Calendar.bind(Vue)
        Vue.prototype.$popup.dateRange = CalendarRange.bind(Vue)
        Vue.prototype.$popup.delete = Delete.bind(Vue)
        Vue.prototype.$popup.tableColumn = TableColumn.bind(Vue)

        Vue.prototype.$popup.login = Login.bind(Vue)
        Vue.prototype.$popup.media = Media.bind(Vue)
        Vue.prototype.$popup.previewSignature = PreviewSignature.bind(Vue)
        Vue.prototype.$popup.gallery = gallery.bind(Vue)
        Vue.prototype.$popup.invitationUser = InvitationUser.bind(Vue)
        Vue.prototype.$popup.galeryIcons = GaleryIcons.bind(Vue)
        Vue.prototype.$popup.galeryTemplates = GaleryTemplates.bind(Vue)
        Vue.prototype.$popup.temporalTpl = TemporalTpl.bind(Vue)
        Vue.prototype.$popup.deleteItem = DeleteItem.bind(Vue)
        Vue.prototype.$popup.siteSave = siteSave.bind(Vue)
        Vue.prototype.$popup.release = release.bind(Vue)
        Vue.prototype.$popup.mediaOnboarding = mediaOnboarding.bind(Vue)

        Vue.prototype.$popup.numeric = Numeric.bind(Vue)
        Vue.prototype.$popup.temperature = Temperature.bind(Vue)
        Vue.prototype.$popup.sign = Sign.bind(Vue)
        Vue.prototype.$popup.freeSign = FreeSign.bind(Vue)
        Vue.prototype.$popup.postpone = Postpone.bind(Vue)
        Vue.prototype.$popup.labelPreview = labelPreview.bind(Vue)

        Vue.prototype.$popup.PDFDisplayOptions = PDFDisplayOptions.bind(Vue)
        Vue.prototype.$popup.messageWithButton = MessageWithButton.bind(Vue)
        Vue.prototype.$popup.exportLoading = ExportLoading.bind(Vue)
        Vue.prototype.$popup.selectorsHistory = SelectorsHistory.bind(Vue)
        Vue.prototype.$popup.templateConvertTo = TemplateConvertTo.bind(Vue)
    }
}
