<template>
    <nav v-if="show" id="menu-actions">
        <div class="aux-actions">
            <ul>
                <li v-for="(item, index) in actions" v-show="item.show" :class="[index, { opened: item.opened }, { expand: item.expand }]" class="act show" @click="item.callback" :style="{ backgroundImage: 'url(' + css_image_url + item.icon + ')' }">
                    {{ item.label }}
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            css_image_url: '/../../img/',
            buttons: [],
            groups: {
                label: '',
                buttons: []
            },
            navBack: {
                text: '',
                class: '',
                callback: function () {}
            }
        }
    },
    methods: {
        hide(event) {
            if (!this.closable) {
                return
            }
            this.show = false
            document.body.style.overflow = 'auto'
        }
    }
}
</script>

<style lang="scss">
#menu-actions {
    position: fixed;
    top: $menu-top-height;
    right: 0;
    z-index: $z-menu-aux;
    // width: calc( 70% - #{$menu-tools-min} + #{$scroll-width});
    max-width: calc(100% - #{$menu-tools-min});
    width: 100%;
    height: $menu-actions-height;
    overflow: hidden;
    background-color: $color-success-100;

    .aux-nav,
    .aux-actions {
        height: $menu-actions-height;
        overflow: hidden;
        ul {
            height: 100%;
            list-style: none;
            display: inline-block;

            li {
                cursor: pointer;
            }
        }
    }

    .aux-actions {
        float: right;

        ul {
            margin-right: 0px;

            li {
                $size: 50px;
                $rest: calc(#{$menu-actions-height} - #{$size});
                $ico: 24px;
                $icoS: 18px;

                @include background($size: 24px, $color: #666);
                @include text-ellipsis();
                @include font-size(24px);
                @include align-items();
                @include border-radius($size);
                display: none;
                line-height: 1;
                font-family: $text;
                color: #fff;
                height: calc(100% - #{$rest});
                margin: 0 6px $rest 6px;
                padding: 0 0 0 $size;
                position: relative;
                width: $size;
                overflow: hidden;
                transition-duration: 0.2s;
                transition-delay: 0.5s;

                &.save {
                    background-color: $color-success-500;
                    background-size: $ico + 8px;
                }
                &.create {
                    background-color: $color-success-500;
                    background-size: $ico;
                }
                &.edit {
                    background-color: $color-warning-500;
                    background-size: $ico;
                }
                &.delete {
                    background-color: $color-error-500;
                    background-size: $ico - 1px;
                }
                &.cancel {
                    background-color: $color-primary-500;
                    background-size: $ico - 2px;
                }
                &.back {
                    background-color: $color-primary-500;
                    background-size: $ico;
                    background-position: left 12px center;
                }

                &.disabled {
                    color: #888;
                    background-color: #555;
                    cursor: not-allowed;
                }
                &.show {
                    display: inline-flex;
                }

                a,
                span {
                    @include display-flex();
                    @include flex-direction(row);
                    @include flex-wrap();
                    @include justify-content(flex-end);
                    @include align-items();
                    @include align-content();
                    @include font-size(22px);
                    line-height: $size;
                    height: 100%;
                    padding: 15px;
                }

                &.expand:hover,
                &.opened {
                    $width: 100px;

                    height: calc(#{$size} - 10px);
                    margin-top: 5px;
                    margin-bottom: calc(#{$rest} - 5px);
                    padding: 0 12px 0 40px;
                    background-size: $icoS;
                    background-position: left 12px center;

                    &.save {
                        background-size: $icoS + 4px;
                        width: calc(#{$width} + 10px);
                    }
                    &.create {
                        background-size: $icoS;
                        width: calc(#{$width});
                    }
                    &.edit {
                        background-size: $icoS;
                        width: calc(#{$width});
                    }
                    &.delete {
                        background-size: $icoS - 1px;
                        width: calc(#{$width} + 15px);
                    }
                    &.cancel {
                        background-size: $icoS - 2px;
                        width: calc(#{$width} + 15px);
                    }
                    &.back {
                        background-size: $icoS;
                        width: calc(#{$width} + 5px);
                    }
                }
            }
        }
    }
}
</style>
