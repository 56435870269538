<template>
    <div class="popup-selector-history">
        <input type="text" :placeholder="translate('vtf.datatable.search')" @input="filterHistory" v-model="searched" v-if="history.length >= 5">
        <div v-for="selector in filteredHistory">
            <article class="item-template">
                <span class="select" @click="selectItem(selector.id)" :class="{'selected': selected === selector.id}"></span>
                <div class="action-box">
                    <span class="arrow" @click="toggleItem(selector.id)" :class="{'opened': opened.includes(selector.id)}"></span>
                    <span class="selector-history">{{selector.template_name}} - {{ selector.item_name }}</span>
                </div>
            </article>
            <article class="selector-config" :class="{'opened': opened.includes(selector.id)}">
                <div class="selector" v-for="config in selector.config.values">
                    <span :style="{'background-color': config.c}" class="color" :class="{'no-color': !config.c}"></span>
                    <span class="selector-values">{{config.v}}</span>
                </div>
            </article>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
export default {
    name: 'PopupSelectorHistory',
    props: {
        history: { Array }
    },
    data() {
        return {
            selected: null,
            opened: [],
            filteredHistory: [],
            searched: ''
        }
    },
    methods: {
        filterHistory(){
            if(!this.searched || this.searched === ''){
                this.filteredHistory = this.history
                return
            }
            this.filteredHistory = this.history.filter(h => h.template_name.toLowerCase().includes(this.searched.toLowerCase()) || h.item_name.toLowerCase().includes(this.searched.toLowerCase()))
        },
        selectItem(item) {
            if(this.selected === item){
                this.selected = null
                return
            }
            this.selected = item
        },
        toggleItem(item) {
            this.opened = this.opened.includes(item) ? this.opened.filter(i => i !== item) : [...this.opened, item]
        },
        translate(key) {
            return i18n.t(key)
        }
    },
    created() {
        this.filteredHistory = this.history
    }
    
}
</script>

<style lang="scss" scoped>
.popup-selector-history {
    width: 100%;
    @include display-flex();
    flex-direction: column;
    gap: 8px;
    padding-right:4px;
    .item-template{
        @include display-flex();
        flex-direction: row-reverse;
        @include justify-content(space-between);
        @include align-items(center);
        padding: 8px;
        @include border-radius(4px);
        background-color: rgba($color-neutral-600, 0.3);
        
        .select{
            width: 16px;
            height: 16px;
            border: 1px solid $color-neutral-600;
            border-radius: 100%;
            position: relative;
            cursor: pointer;

            &.selected{
                &::after{
                    content: '';
                    width: 10px;
                    height: 10px;
                    background-color: $color-neutral-600;
                    border-radius: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .action-box{
            @include display-flex();
            @include justify-content(flex-end);
            @include align-items(center);
            gap: 8px;

            .selector-history{
                width: 530px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                font-family: $text-bold;
            }

            .arrow {
                width: 16px;
                height: 16px;
                transform: rotate(-90deg);
                @include background($image: img('down_caret_neutro_600.svg'));
                cursor: pointer;
            }
            .opened {
                transform: rotate(0deg);
            }
        }
    }
    .selector-config{
        display: none;

        &.opened{
            @include display-flex();
        }

        padding: 4px 0 4px 16px;
        width: 100%;
        flex-direction: column;
        gap: 4px;
        position: relative;
        .selector{
            @include display-flex();
            @include align-items(center);
            gap: 8px;
            padding: 4px 8px;
            @include border-radius(4px);
            border: solid 1px $color-neutral-300;
            .color{
                width: 16px;
                height: 16px;
                border-radius: 100%;

                &.no-color{
                    border: solid 1px $color-neutral-300;
                }
            }

            .selector-values{
                width: 530px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &::after{
            content: '';
            width: 3px;
            height: 100%;
            background-color: $color-neutral-300;
            position: absolute;
            top: 0;
            left: 5px;
            @include border-radius(2px);
            transition: all 0.3s ease-in-out;
        }
    }
}
</style>
