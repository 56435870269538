<template>
    <TextEditor @keyup="$emit('keyup', $event)" @change="$emit('change', $event)" @blur="$emit('blur', $event)" @input="returnValue" :placeholder="placeholder" :disabled="inputOptions.disabled" :required="inputOptions.mandatory" :limit="inputOptions.limit" :type="inputOptions.type" :gActions="inputOptions.actions" v-model="tempValue" />
</template>

<script>
import TextEditor from '@/components/ui/TextEditor'

export default {
    components: { TextEditor },

    props: {
        value: { type: [String, Number, Boolean, Array, Object], default: undefined },
        cValue: { type: [String, Number, Boolean, Array, Object], default: undefined },
        cPlaceholder: { type: String, default: '' },
        cOptions: { type: Object, default: undefined }
    },
    data() {
        return {
            tempValue: this.value,
            options: {}
        }
    },
    computed: {
        inputOptions() {
            // Mix de options que llegan vs defaults
            return {
                ...this.options,
                ...(this.cOptions ? this.cOptions : {})
            }
        },
        returnValue($event) {
            this.$emit('input', $event.tempValue)
        },
        placeholder() {
            return this.cPlaceholder
        }
    },
    methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
