import i18n from '@/i18n'
import Vue from 'vue'
import store from '@/store'

import { Api } from '@/core/api.js'

import localforage from 'localforage'
import tasks from './tasks'
const api = new Api()

var modules = {
    tasks
}

var db_templates = localforage.createInstance({ name: 'ALEXCENTRAL', storeName: 'templates' })

// initial state
const getDefaultState = () => {
    return {
        checklist: { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} },
        templates: { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} },
        register: {},
        defaults: {
            default: {
                has_score: false,
                has_state: false,
                has_timing: false,
                is_autosave: true,
                can_create: true,
                item_search: false,
                has_calendar: true,
                temporal_view: false,
                close_when_completed: false,
                auth_users: {
                    pin: 2,
                    review: 2,
                    task: 1
                },
                can_edit: {
                    title: true,
                    state: false,
                    inPast: true,
                    inTime: true,
                    inFuture: true
                },
                item: {
                    itemsTitle: [8, 9],
                    itemsExcluded: [8, 9],
                    count_subtask: false, // Esto por si se quieren poder puntuar tambien las subtareas
                    rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                    has_periodicity: false
                }
            },
            1: {
                has_score: true,
                has_state: true,
                has_timing: true,
                can_create: false,
                can_edit: { inPast: false },
                item: { has_periodicity: true }
            },
            2: {
                has_score: true,
                has_state: true,
                has_timing: true,
                can_edit: { inPast: false, inFuture: false },
                item: { rate: true },
                close_when_completed: true,
                temporal_view: true
            },
            3: {
                is_autosave: false,
                can_edit: { title: false }
            },
            4: {
                has_state: true,
                can_edit: { state: true }
            },
            5: {
                has_score: true,
                has_state: true,
                has_timing: true,
                can_edit: { inPast: false },
                item_search: true,
                has_calendar: false,
                temporal_view: true
            }
        }
    }
}
const state = getDefaultState()
// getters
const getters = {
    getList: (state, getters, rootState, rootGetters) => (type) => {
        if (type == 1) {
            return rootGetters['tasks/getList'](type)
        }
        return state.checklist[type]
    },

    getDefaults: (state, getters, rootState) => (cType) => {
        var def = {}
        def = { ...state.defaults['default'], ...state.defaults[cType] }
        def.item = { ...state.defaults['default'].item, ...state.defaults[cType].item }
        def.auth_users = { ...state.defaults['default'].auth_users, ...state.defaults[cType].auth_users }
        def.can_edit = { ...state.defaults['default'].can_edit, ...state.defaults[cType].can_edit }

        return def
    },

    getTemplates: (state, getters, rootState) => (cType) => {
        return state.templates[cType]
    },
    getTemplate: (state, getters, rootState) => (cType, id) => {
        return state.templates[cType][id]
    },

    getChecklist: (state, getters, rootState) => (cType, id) => {
        return state.checklist[cType][id]
    },

    getRegister: (state, getters, rootState) => {
        return state.register
    },

    getAnswer: (state, getters, rootState) => (cType, check, answer) => {
        return state.checklist[cType][check].items[answer]
    },

    getChecklistProp: (state, getters, rootState) => (cType, check, prop) => {
        return state.checklist[cType][check][prop]
    },

    getAnswerProp: (state, getters, rootState) => (cType, check, answer, prop) => {
        return state.checklist[cType][check].items[answer][prop]
    },
}

// actions
const actions = {
    loadList(context, params) {
        var type = params.type

        if (type == 1) {
            return context.dispatch('tasks/loadList', params, { root: true })
        } else {
            if (params.date) {
                if (typeof params.date === 'string') {
                    params.start_date = params.date
                } else {
                    params.start_date = params.date[2]

                    if (params.date[2] == 'range') {
                        params.start_date = moment(params.date[0]).startOf('day').unix()
                        params.end_date = moment(params.date[1]).endOf('day').unix()
                    }
                }
            }
            // console.log(params);
            return api.post('checklist/admin/' + params.type, params).then(function (response) {
                context.commit('formatChecklist', { data: response.data, type: type })
            })
        }
    },

    loadPendingIssues(context, params) {
        var cType = 4

        return api.get('issues', {}).then(function (response) {
            context.commit('formatChecklist', { data: response.data, cType: cType })
        })
    },

    loadSingleChecklist(context, params) {
        var cType = params.cType

        return api.get('checklist/id/' + params.id, {}).then(function (response) {
            context.commit('formatChecklist', { data: response.data, cType: cType })
        })
    },

    /* loadTemplates (context, params) {

    return api.get('checklist/templates/'+ params.cType, {})
      .then(function (response) {
        context.commit('setTemplates', { data: response.data, cType: params.cType })
      })
  }, */

    loadTemplates(context, params) {
        var templates = []
        var count = 0

        if (context.rootState.refresh.templates && fnCheckConnection()) {
            return api.get('checklist/templates', {}).then(function (response) {
                context.commit('setTemplates', { data: response.data })

                _.forEach(_.values(response.data), function (value, key) {
                    db_templates.setItem(value.id, value)
                })

                context.rootState.refresh.templates = false
            })
        } else {
            db_templates
                .iterate(function (value, key, iterationNumber) {
                    templates.push(value)
                    count++
                })
                .then(function () {
                    if (count > 0) {
                        context.commit('setTemplates', { data: templates })
                    }
                })
        }
    },

    loadRegister(context, params) {
        var cType = 3

        return api.get('checklist/resume/' + params.template, {}).then(function (response) {
            context.commit('formatChecklist', { data: response.data.values, cType: cType })
            context.commit('formatRegister', { data: response.data })
        })
    },

    addAnswerComment(context, params) {
        var cType = params.cType
        var sended = { checklist: params.check, task: params.answer, message: params.message }
        if (typeof params.file !== 'undefined') sended.file = params.file
        return api.post('checklist/task/comment', sended).then(function (response) {
            var returned = { check: params.check, answer: params.answer, message: response.data.message, cType: cType }
            if (typeof response.data.image !== 'undefined') returned.image = response.data.image

            context.commit('setAnswerComment', returned)
        })
    },

    addAnswer(context, params) {
        var cType = params.cType

        return api.post('checklist/task/answer', { checklist: params.check, task: params.answer, employee: params.emp, complete_date: params.date, value: params.value }).then(function (response) {
            context.commit('setAnswer', { cType: cType, check: params.check, answer: params.answer, emp: params.emp, date: params.date, value: params.value })
        })
    },

    resetAnswer(context, params) {
        var cType = params.cType
        return api.post('checklist/task/reset', { checklist: params.check, task: params.answer }).then(function (response) {
            context.commit('setReset', { cType: cType, check: params.check, answer: params.answer })
        })
    },

    validateAnswer(context, params) {
        var cType = params.cType

        return api.post('checklist/task/validate', { checklist: params.check, task: params.answer, employee: params.emp, complete_date: params.date }).then(function (response) {
            context.commit('setValidation', { cType: cType, check: params.check, answer: params.answer, emp: params.emp, date: params.date })
        })
    },
    unvalidateAnswer(context, params) {
        var cType = params.cType
        return api.post('checklist/task/unvalidate', { checklist: params.check, task: params.answer }).then(function (response) {
            context.commit('setUnvalidation', { cType: cType, check: params.check, answer: params.answer })
        })
    },

    planAnswer(context, params) {
        var cType = params.cType

        return api.post('checklist/task/plan', { checklist: params.check, task: params.answer, date: params.date }).then(function (response) {
            // console.log(response);
            context.commit('setValidation', { cType: cType, check: params.check, answer: params.answer, date: params.date })
        })
    },

    addChecklist(context, params) {
        var cType = params.cType
        var oData = { template: params.tpl_id }
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }
        return api.post('checklist/add', oData).then(function (response) {
            context.commit('formatChecklist', { data: response.data, append: true, cType: cType })
            return response.data[0]
        })
    },

    complete(context, params) {
        var cType = params.cType

        return api.post('checklist/complete', { checklist: params.check, employee: params.emp, complete_date: params.date, values: params.values }).then(function (response) {
            params.values = JSON.parse(params.values)
            Object.keys(params.values).map(function (id, i) {
                var val = params.values[id]
                context.commit('setAnswer', { cType: cType, check: params.check, answer: val.item_id, emp: params.emp, date: params.date, value: val.value })
            })

            return response
        })
    },

    update(context, params) {
        var oData = { checklist: params.check }
        if (params.title) oData.title = params.title
        if (params.status) oData.status = params.status
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }
        var cType = params.cType

        return api.post('checklist/update', oData).then(function (response) {
            if (params.title) {
                context.commit('setChecklistProp', { check: oData.checklist, prop: 'title', value: oData.title, cType: cType })
            }
            if (params.status) {
                context.commit('setChecklistProp', { check: oData.checklist, prop: 'status', value: oData.status, cType: cType })
            }
            if (params.emp_ref) {
                context.commit('setChecklistProp', { check: oData.checklist, prop: 'employee_ref', value: oData.employee_ref, cType: cType })
            }
        })
    },

    delete(context, params) {
        try {
            return api.post('checklist/update/multiple', {checklist: params.checklist, employee_ref: params.employee_ref, status: params.status})
                .then(response => {
                    console.log(response)
                    if(response.status) {
                        if(params.cType == 1)
                        context.commit('checklist/setChecklist', {})
                    }
                    return response
                })
        } catch (error) {
            console.log(error)
        }
    },

    reloadChecklist() {
        Object.keys(state.checklist).map(function (cType, i) {
            var checklist = state.checklist[cType]

            if (cType == 1 || cType == 2 || cType == 5) {
                store.commit('checklist/formatChecklist', { data: checklist, cType: cType })
            }
        })
        // store.commit('checklist/formatChecklist', { data: state.checklist })
    }
}

// mutations
const mutations = {
    toggleAnswer(state, payload) {
        var id = payload.checklist
        var answer = payload.answer
        var prop = payload.prop
        var cType = payload.cType

        for (var i in state.checklist[cType][id].items) {
            var item = state.checklist[cType][id].items[i]
            if (item.item_id == answer.item_id) item[prop] = !item[prop]
            else item[prop] = false
        }

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },

    formatChecklist(state, payload) {
        var append = typeof payload.append !== 'undefined' ? payload.append : false
        var type = payload.type
        if (append) {
            var bChecklist = state.checklist[type]
            var bIntime = state.cIntime[type]
            var bPast = state.cPast[type]
        } else {
            var bChecklist = {}
            var bIntime = {}
            var bPast = {}
        }

        // console.log(payload.data);
        if (typeof payload.data !== 'undefined') {
            Object.keys(payload.data).map(function (ins_id, i) {
                var ins = payload.data[ins_id]
                // console.log(ins);
                ins.def = { ...state.defaults['default'], ...state.defaults[type] }
                ins.def.item = { ...state.defaults['default'].item, ...state.defaults[type].item }
                ins.def.auth_users = { ...state.defaults['default'].auth_users, ...state.defaults[type].auth_users }
                ins.def.can_edit = { ...state.defaults['default'].can_edit, ...state.defaults[type].can_edit }

                ins.end_date = !moment.isMoment(ins.end_date) && ins.end_date != false ? moment(ins.end_date).set({ s: 0, ms: 0 }) : ins.end_date
                ins.start_date = !moment.isMoment(ins.start_date) && ins.start_date != false ? moment(ins.start_date) : ins.start_date
                ins.created_date = !moment.isMoment(ins.created_date) && ins.created_date != false ? moment(ins.created_date) : ins.created_date
                ins.complete_date = !moment.isMoment(ins.complete_date) && ins.complete_date != false ? moment(ins.complete_date) : ins.complete_date

                // ins.uncompleted = 0
                // ins.completed = 0
                // ins.unanswered = 0
                // ins.total = 0
                // ins.score = 0
                var drtn = ins.complete_date ? moment.duration(ins.complete_date.diff(ins.start_date)) : false
                ins.duration = ins.complete_date && drtn ? drtn.hours() + 'h ' + drtn.minutes() + 'min' : '-'
                // ins.duration = (ins.complete_date)? moment(ins.complete_date.diff(ins.start_date)).format("HH:mm") : '-' ;

                var now = moment()

                if (ins.def.has_timing) {
                    ins.timing = {}
                    if (ins.start_date.isAfter(now)) {
                        bPast[ins.id] = ins
                        ins.timing.type = 'future'
                        // ins.timing.info = i18n.t('checklist.timing.future', {s: now.to(ins.start_date, true)} );
                        ins.timing.info = 'hola'
                    }
                    if (ins.start_date.isSameOrBefore(now) && ins.end_date.isSameOrAfter(now)) {
                        bIntime[ins.id] = ins
                        ins.timing.type = 'intime'
                        ins.timing.info = timeLeft(ins.end_date)
                    }

                    if (ins.def.close_when_completed) {
                        if (ins.end_date.isSameOrBefore(now) || ins.complete_date) {
                            bPast[ins.id] = ins
                            ins.timing.type = 'past'
                            // ins.timing.info = Object.keys(ins.employees).length > 0 ? i18n.t('checklist.detail.completed') : i18n.t('checklist.detail.uncompleted');
                        }
                    } else {
                        if (ins.end_date.isSameOrBefore(now)) {
                            bPast[ins.id] = ins
                            ins.timing.type = 'past'
                            // ins.timing.info = ins.total == ins.completed ? i18n.t('checklist.detail.completed') : i18n.t('checklist.detail.uncompleted');
                        }
                    }
                }

                ins.employees = []
                var temp = []

                if (typeof ins.items !== 'undefined') {
                    Object.keys(ins.items).map(function (item_id, i) {
                        var item = ins.items[item_id]

                        item.alerts = []

                        item.complete_date = item.complete_date * 1000
                        item.complete_date = !moment.isMoment(item.complete_date) && item.complete_date != false ? moment(item.complete_date) : item.complete_date

                        if (item.review) {
                            item.review.complete_date = !moment.isMoment(item.review.complete_date) && item.review.complete_date != false ? moment(item.review.complete_date) : item.review.complete_date
                        }

                        item.available = false
                        if (item.margin > 0) {
                            var start = moment()

                            if (item.planned_date) {
                                start = moment(item.planned_date)
                            } else if (!item.planned_date) {
                                start = moment(ins.start)
                            }

                            var av = moment(start).add(item.margin - 1, 'days')
                            item.available = Math.round(moment.duration(av.diff(moment())).asDays(), 0)
                        }

                        if (item.type == 13 && item.value) {
                            item.value = item.value.replace('T', ' ')
                        }

                        if (ins.def.has_score && (!item.parent_id || ins.def.item.count_subtask) && ins.def.item.itemsExcluded.indexOf(item.type) == -1) {
                            switch (item.type) {
                                case 2: // SI/NO
                                case 3: // SI/NO/NSNC
                                    if (ins.def.item.rate) {
                                        if (item.complete_date != false) {
                                            if (item.value == 1 || item.value == 'true' || item.value == '1') {
                                                ins.completed++
                                            } else if (parseInt(item.value) == 0 || parseInt(item.value) == 2) {
                                                ins.uncompleted++
                                            }
                                        } else {
                                            ins.unanswered++
                                        }
                                    } else {
                                        if (item.complete_date != false) {
                                            ins.completed++
                                        } else {
                                            ins.unanswered++
                                        }
                                    }
                                    break

                                case 1: // CHECKS
                                case 4: // TEXTO
                                case 5: // FOTO
                                case 6: // NUMBER
                                case 7: // DATE
                                case 10: // TEXTAREA
                                case 11: // MULTIPLE SELECT
                                case 12: // TIME
                                case 13: // DATETIME
                                case 14: // SIGNATURE
                                case 15: // TEMPERATURE
                                    if (item.complete_date != false) {
                                        ins.completed++
                                    } else {
                                        ins.unanswered++
                                    }
                                    break

                                default:
                                    break
                            }
                            ins.total++
                        }

                        item.hasComment = false
                        if (item.message != false || item.image != false) item.hasComment = true
                        item.message = item.message != false ? item.message : ''

                        if (item.employee) {
                            if (temp.indexOf(item.employee.id) == -1) {
                                temp.push(item.employee.id)
                                ins.employees.push(item.employee)
                            }
                        }

                        item.showComment = !!item.hasComment
                        item.editComment = false
                        ins.items[item_id] = item
                    })
                }

                // if (ins.def.has_score) {
                //     ins.score = Math.round((parseInt(ins.completed) * 100) / parseInt(ins.total))
                // }

                bChecklist[ins.id] = ins
            })
        }
        state.checklist[type] = bChecklist

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + type + ']', { ...state.checklist[type] })
    },

    setTemplates(state, payload) {
        var templates = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} }

        if (payload.data != undefined) {
            Object.keys(payload.data).map(function (tpl_id, i) {
                var tpl = { ...payload.data[tpl_id] }
                tpl.created_date = tpl.created_date != false ? moment(tpl.created_date) : moment()

                templates[tpl.type][tpl.id] = tpl
            })
        }

        Vue.set(state, 'templates', { ...templates })
    },

    formatRegister(state, payload) {
        state.register = payload.data
        Vue.set(state, 'register', { ...state.register })
    },

    setChecklistProp(state, payload) {
        var check = payload.check
        var prop = payload.prop
        var value = payload.value
        var cType = payload.cType

        state.checklist[cType][check][prop] = value

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },

    // setChecklist(state, payload) {
    //     console.log('state_ ', state)
    //     console.log('payload', payload)
    //     // Vue.set(state)
    // },

    setAnswerProp(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var prop = payload.prop
        var value = payload.value
        var cType = payload.cType

        state.checklist[cType][check].items[answer][prop] = value

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },

    setAnswerComment(state, payload) {
        var cType = payload.cType
        var check = payload.check
        var answer = payload.answer
        var message = payload.message
        var image = payload.image

        state.checklist[cType][check].items[answer].message = message
        if (typeof image !== 'undefined') state.checklist[cType][check].items[answer].image = image

        state.checklist[cType][check].items[answer].editComment = false
        if (image || message != '') {
            state.checklist[cType][check].items[answer].showComment = true
        } else {
            state.checklist[cType][check].items[answer].showComment = false
        }

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },

    setAnswer(state, payload) {
        var cType = payload.cType
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var value = payload.value
        var date = payload.date

        state.checklist[cType][check].items[answer].value = value

        if (date) {
            var item = state.checklist[cType][check].items[answer]
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date) : item.complete_date
        }
        if (emp) state.checklist[cType][check].items[answer].employee = emp

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    setValidation(state, payload) {
        var cType = payload.cType
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var date = payload.date

        state.checklist[cType][check].items[answer].review = {}
        if (date) {
            var item = state.checklist[cType][check].items[answer].review
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date) : item.complete_date
        }
        if (emp) {
            var employee = store.getters['employee/getAccountEmployee'](emp)
            state.checklist[cType][check].items[answer].review.employee = employee
        }
        state.checklist[cType][check].items[answer].review.value = 1

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    setUnvalidation(state, payload) {
        var cType = payload.cType
        var check = payload.check
        var answer = payload.answer

        state.checklist[cType][check].items[answer].review = false

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },
    setReset(state, payload) {
        var cType = payload.cType
        var check = payload.check
        var answer = payload.answer

        state.checklist[cType][check].items[answer].complete_date = null
        state.checklist[cType][check].items[answer].employee = false
        state.checklist[cType][check].items[answer].value = null

        Vue.set(state, 'checklist', { ...state.checklist })
        Vue.set(state, 'checklist[' + cType + ']', { ...state.checklist[cType] })
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

function timeLeft(deadtime) {
    // // var now = store.getters['getMoment'];
    // var now = moment();
    // var text="";
    //
    // var years = deadtime.diff(now, 'year');
    // now.add(years, 'years');
    //
    // var months = deadtime.diff(now, 'months');
    // now.add(months, 'months');
    //
    // var days = deadtime.diff(now, 'days');
    // now.add(days, 'days');
    //
    // var hours = deadtime.diff(now, 'hours');
    // now.add(hours, 'hours');
    //
    // var minutes = deadtime.diff(now, 'minutes');
    // now.add(minutes, 'minutes');
    //
    // var seconds = deadtime.diff(now, 'seconds');
    // now.add(seconds, 'seconds');
    //
    // if( years > 0 ){
    //   if (years == 1) {     text += years+" "+i18n.tc('checklist.timing.year', 1); }
    //   else if (years > 1) { text += years+" "+i18n.tc('checklist.timing.year', 2); }
    //
    // } else if( years<=0 && months > 0 ){
    //     if (months == 1) {     text += months+" "+i18n.tc('checklist.timing.month', 1); }
    //     else if (months > 1) { text += months+" "+i18n.tc('checklist.timing.month', 2); }
    //
    //     if( text.length > 1 && days != 0 ) {
    //         text += " "+i18n.t('checklist.timing.connector')+" ";
    //     }
    //
    //     if (days == 1) {     text += days+" "+i18n.tc('checklist.timing.day', 1); }
    //     else if (days > 1) { text += days+" "+i18n.tc('checklist.timing.day', 2); }
    //
    // } else if( years<=0 && months<=0 && days > 0 ){
    //     if (days == 1) {     text += days+" "+i18n.tc('checklist.timing.day', 1); }
    //     else if (days > 1) { text += days+" "+i18n.tc('checklist.timing.day', 2); }
    //
    //     if( text.length > 1 && hours != 0 ) {
    //         text += " "+i18n.t('checklist.timing.connector')+" ";
    //     }
    //
    //     if (hours == 1) {     text += hours+" "+i18n.tc('checklist.timing.hour', 1); }
    //     else if (hours > 1) { text += hours+" "+i18n.tc('checklist.timing.hour', 2); }
    //
    // } else if( years<=0 && months<=0 && days<=0 && hours > 0 ){
    //     if (hours == 1) {     text += hours+" "+i18n.tc('checklist.timing.hour', 1); }
    //     else if (hours > 1) { text += hours+" "+i18n.tc('checklist.timing.hour', 2); }
    //
    //     if( text.length > 1 && minutes != 0 ) {
    //         text += " "+i18n.t('checklist.timing.connector')+" ";
    //     }
    //
    //     if (minutes == 1) {     text += minutes+" "+i18n.tc('checklist.timing.minute', 1); }
    //     else if (minutes > 1) { text += minutes+" "+i18n.tc('checklist.timing.minute', 2); }
    //
    // } else if( years<=0 && months<=0 && days<=0 && hours<=0 && minutes > 0 ){
    //     if (minutes == 1) {     text += minutes+" "+i18n.tc('checklist.timing.minute', 1); }
    //     else if (minutes > 1) { text += minutes+" "+i18n.tc('checklist.timing.minute', 2); }
    //
    // } else if( years<=0 && months<=0 && days<=0 && hours<=0 && minutes<=0 && seconds > 0 ){
    //      text+= i18n.t('checklist.timing.inminent');
    // }
    //
    // return i18n.t('checklist.timing.remind', { s: text });
    return '!timeLeft'
}

export default {
    namespaced: true,
    state,
    modules,
    getters,
    actions,
    mutations
}
