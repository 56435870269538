// receives an array of ids and an array of objects,
// from the list of objects, returns only the objects whose ids are present in the array of ids
export function getObjectsByIds(listOfIds, listOfObjects) {
    if (!listOfIds || !listOfObjects) {
        // logError('There is a missing param on getObjectsByIds')
        return
    }

    const filteredListOfObjects = listOfObjects.filter((obj) => listOfIds.includes(obj.id))

    return filteredListOfObjects
}

export function getListOfIds(listOfObjects) {
    if (!listOfObjects) {
        logError('No params for getListOfIds')
        return
    }

    const idsList = listOfObjects.map((obj) => {
        if (typeof obj === 'object' && obj !== null && obj.hasOwnProperty('id')) {
            return obj.id
        }
    })

    return idsList
}
