import Vue from 'vue'
import { FORMAT } from '@/constants'

import { Api } from '@/core/api.js'

const api = new Api()

// initial state
const getDefaultState = () => {
    return {
        data: false,
        filters: {
            locations: {},
            groups: {},
            categories: {},
            timeframe: {},
            template: {},
            date: {},
            total: {}
        },
        activeFilters: {}
    }
}
const state = getDefaultState()

// getters
const getters = {
    getData: (state, getters, rootState) => {
        return state.data
    },

    getActiveFilters: (state, getters, rootState) => {
        return state.activeFilters
    },

    getFilterLocations: (state, getters, rootState) => (id) => {
        if (id) return state.filters.locations[id]
        return state.filters.locations
    },

    getFilterGroups: (state, getters, rootState) => (id) => {
        if (id) return state.filters.groups[id]
        return state.filters.groups
    },
    getFilterCategories: (state, getters, rootState) => (id) => {
        if (id) return state.filters.categories[id]
        return state.filters.categories
    },
}

// actions
const actions = {
    loadData(context, params) {
        let hasToSetData = params.data.type == 'total' ? true : false
        return api.post('admin/resume/1', params.data).then(function (response) {
            if (hasToSetData) {
                context.commit('setData', response.data)
            }
            return response.data
        })
    },

    loadFilters(context, params) {
        return api.get('resume/filters').then(function (response) {
            context.commit('setFilters', response.data)
            return true
        })
    }
}

// mutations
const mutations = {
    setFilters(state, data) {
        state.filters.locations = data.locations
        state.filters.groups = data.groups
        state.filters.categories = data.categories
    },
    setData(state, data) {
        state.data = data
        Vue.set(state, 'data', { ...data })
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setActiveFilters(state, data) {
        state.activeFilters = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
