<template>
    <div class="copyright" :class="[{ condensed: isSmall }, customClass]">
        <a class="copy-link" target="_blank" :href="intowinUrl">
            {{ $t('assets.copyright_intowin_1') }}
            <span class="icon-heart"></span>
            {{ $t('assets.copyright_intowin_2') }}
        </a>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        isSmall: { type: Boolean, default: false },
        customClass: { type: [String, Array, Object, Function], default: '' }
    },
    data() {
        return {
            intowinUrl: 'https://intowin.io/'
        }
    },
    computed: {},
    methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.copyright {
    @include display-flex();
    @include align-items();
    @include justify-content(flex-end);
    @include font-size(sm);
    font-family: $text-light;
    color: $color-neutral-600;
    width: 100%;
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 6px;
    right: 15px;
    z-index: $z-copyright;
    overflow: hidden;

    .copy-link {
        @include inheritFont();
        display: inline-block;
        height: 100%;
        white-space: initial;
    }
    .icon-heart {
        @include background($image: img('heart_neutro_s30.svg'));
        display: inline-block;
        width: 9px;
        height: 9px;
    }

    &.condensed {
        @include font-size(xs);
        background-color: $color-neutral-50;
        height: 20px;
        position: fixed;
        bottom: 0px;
        right: 0;
        overflow: hidden;

        .copy-link {
            height: 20px;
            line-height: 20px;
            padding-right: 15px;
            color: $color-neutral-600;
        }
    }

    // CUSTOM CLASSES
    &.login-copyright {
        @include font-size(sm);
        font-family: $text-medium;
        color: $color-neutral-300;
        position: unset;
        display: inline-block;
        width: auto;
        height: 30px;
        line-height: 30px;

        .copy-link {
            height: inherit;
            line-height: inherit;
        }
    }
}
</style>
