import Vue from 'vue'
import { FORMAT } from '@/constants'

import { Api } from '@/core/api.js'

const api = new Api()

const defaultFilters = {
    location: [],
    template: [],
    status: [],
    employees: [],
    type_date: 'week',
    score: [0, 100],
    // for custom dates:
    start_date: '',
    end_date: '',
    finalNote:'',
}

const getDefaultState = () => {
    return {
        itemsLoaded: false,
        currentFilters: structuredClone(defaultFilters),
        filtersActive: structuredClone(defaultFilters),
        filters: {
            templates: {},
            locations: {},
            group: {},
            states: {},
            employees: {}
        },
        filtersTemplateActive: {},
        filtersStatus: {},
        // filtersActionPlans: {},
        items: {},
        reportsItems: {},
        type: 2,
        config: {
            date_format: 'YYYY-MM-DD',
            hour_format: 'HH:mm',
            datetime_format: 'YYYY-MM-DD HH:mm',
            has_score: true,
            has_state: true,
            has_timing: true,
            has_mandatory: false,
            is_autosave: true,
            can_create: false,
            item_search: false,
            has_calendar: true,
            temporal_view: false,
            close_when_completed: false,
            auth_users: {
                pin: 2,
                review: 2,
                task: 1
            },
            can_edit: {
                title: true,
                state: false,
                inPast: false,
                inTime: true,
                inFuture: true
            },
            item: {
                itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK],
                itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE],
                can_pospone: true,
                rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                can_review: true,
                has_periodicity: true,
                has_score: false
            }
        },
        can_load_list: false,
        numItems: 0,
        galleryTemplates: false,
        resetFiltersState: false
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    getResetFiltersState: (state, getters, rootState) => {
        return state.resetFiltersState
    },
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },

    getList: (state, getters, rootState) => (type) => {
        return state.items
    },
    getListReports: (state, getters, rootState) => (ids) => {
        var result = {}
        ids.forEach((id) => {
            result[id] = state.reportsItems[id]
        })
        return result
    },
    getFilterTemplate: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.templates[id]
        }
        return state.filters.templates
    },
    getFilterStates: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.states[id]
        }
        return state.filters.states
    },
    getfiltersActionPlans: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.actionPlans[id]
        }
        return state.filters.actionPlans
    },
    getFilterEmployees: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.employees[id]
        }
        return state.filters.employees
    },
    getCurrentFilters: (state, getters, rootState) => {
        return state.currentFilters
    },
    getFiltersActive: (state, getters, rootState) => {
        return state.filtersActive
    },
    getFiltersTemplateActive: (state, getters, rootState) => {
        return state.filtersTemplateActive
    },
    getFiltersStatus: (state, getters, rootState) => {
        return state.filtersStatus
    },
    getCanLoadList: (state, getters, rootState) => {
        return state.can_load_list
    },
    getNumItems: (state, getters, rootState) => {
        return state.numItems
    },
    getGalleryTemplates: (state, getters, rootState) => {
        return state.galleryTemplates
    },
    getSingleItemStatusId: (state) => (id) => {
        if (id) {
            if (state.items[id]) {
                return state.items[id].status
            } else if (state.reportsItems[id]) {
                return state.reportsItems[id].status
            }
        }
        return null
    }
}

// actions
const actions = {
    loadList(context, params) {
        context.commit('setItemsLoaded', false)
        if (params.date) {
            if (typeof params.date === 'string') {
                params.start_date = params.date
            } else {
                params.start_date = params.date[2]

                if (params.date[2] == 'range') {
                    params.start_date = moment(params.date[0]).startOf('day').unix()
                    params.end_date = moment(params.date[1]).endOf('day').unix()
                }
            }
        }

        return api.post('checklist/admin/' + state.type, params).then(function (response) {
            try {
                context.dispatch('format', { data: response.data.data, type: state.type })
                context.dispatch('format', { data: response.data.data, type: state.type, commit: 'report' })
                context.commit('setNumItems', response.data.results)
            } catch (error) {
                console.error(error)
            }
        })
    },

    loadGalleryTemplates(context, params) {
        return api.post('checklist/admin/' + state.type + '/gallery/list', {}).then(function (response) {
            if (response.status) {
                context.commit('setGalleryTemplates', response.data)
            }
        })
    },
    importGalleryTemplate(context, params) {
        return api.post('checklist/admin/' + state.type + '/gallery/' + params.tpl_id + '/import', {}).then(function (response) {
            return response
        })
    },

    loadReportList(context, params) {
        return api.post('checklist/admin/' + state.type + '/reports', params).then(function (response) {
            for (var index in response.data) {
                if (state.items[index]) {
                    var item = response.data[index]
                    state.items[index].items = item.items
                }
            }
            context.dispatch('format', { data: response.data, type: state.type, commit: true })
        })
    },

    downloadReportListExcel(context, params) {
        return api
            .post(`checklist/admin/${state.type}/reports/excel`, params)
            .then(function (response) {
                return response.file
            })
            .catch(function (error) {
                console.error(error)
                return false
            })
    },

    downloadReportListCsv(context, params) {
        return api
            .post(`checklist/admin/${state.type}/reports/csv`, params)
            .then(function (response) {
                return response.file
            })
            .catch(function (error) {
                console.error(error)
                return false
            })
    },

    loadAuditTemplates(context, params) {
        state.items = []
        return api.post('admin/checklist/templates/' + state.type, params).then(function (response) {
            context.commit('setFormat', { items: response.data })
        })
    },

    loadFilters(context) {
        return api.get('checklist/admin/' + state.type + '/filter').then(function (response) {
            context.commit('setFilterTemplates', response.data.templates)
            context.commit('setFilterStates', response.data.states)
            context.commit('setFilterLocations', response.data.locations)
            context.commit('setFilterEmployees', response.data.employees)
            context.commit('setFilterStatus', response.data.status)
            context.commit('setFilterActionPlans', response.data.actionPlans)
        })
    },

    format({ state, context, commit, dispatch, rootState }, params = {}) {
        // SETTER
        var aItems = {}

        if (typeof params.data !== 'undefined') {
            Object.keys(params.data).map(function (item_id, i) {
                var tframe = params.data[item_id]

                tframe.alert_pending_count = 0
                tframe.alert_pending_show = false
                tframe.alert_important_show = false

                // Timezone set
                tframe.business_date = tframe.business_date
                    ? moment.isMoment(tframe.business_date)
                        ? tframe.business_date
                        : moment(tframe.business_date * 1000)
                    : false
                tframe.created_date = tframe.created_date
                    ? moment.isMoment(tframe.created_date)
                        ? tframe.created_date
                        : moment(tframe.created_date * 1000)
                    : false
                tframe.complete_date = tframe.complete_date
                    ? moment.isMoment(tframe.complete_date)
                        ? tframe.complete_date
                        : moment(tframe.complete_date * 1000)
                    : false
                // Timezone set TASKS
                if (tframe.schedule) {
                    tframe.schedule['start'] = tframe.schedule.start
                        ? moment.isMoment(tframe.schedule.start)
                            ? tframe.schedule.start
                            : moment(tframe.schedule.start * 1000)
                        : false
                    tframe.schedule['end'] = tframe.schedule.end
                        ? moment.isMoment(tframe.schedule.end)
                            ? tframe.schedule.end
                            : moment(tframe.schedule.end * 1000)
                        : false
                }
                if (tframe.limit) {
                    tframe.limit['start'] = tframe.limit.start
                        ? moment.isMoment(tframe.limit.start)
                            ? tframe.limit.start
                            : moment(tframe.limit.start * 1000)
                        : false
                    tframe.limit['end'] = tframe.limit.end ? (moment.isMoment(tframe.limit.end) ? tframe.limit.end : moment(tframe.limit.end * 1000)) : false
                }

                tframe.def = state.config
                tframe.timeframe_id = tframe.id

                tframe.need_justification = false

                aItems[tframe.id] = tframe

                // format Final Score
                const item = params.data[item_id]

                if (item.hasOwnProperty('resume')) {
                    if (item.resume.ev) {
                        item.finalScore = {
                            color: item.resume.ev.c ? item.resume.ev.c : null,
                            name: item.resume.ev.n
                        }
                    }
                }
            })
        }

        // SETTER
        if (params.commit) {
            commit('setFormatReport', { items: aItems })
        } else {
            commit('setFormat', { items: aItems })
        }
    },

    supervisePdfPrintlog({ state, context, commit, dispatch, rootState }, params = {}) {
        return api.get(`admin/checklist/${params.id}/pdf/print`).then(function (response) {
            return response
        })
    },

    superviseReportLog({ state, context, commit, dispatch, rootState }, params = {}) {
        return api.post(`admin/checklist/report/log`, { checklists: params.checklists }).then(function (response) {
            return response
        })
    },
    changeState(context, params) {
        return api.post('checklist/update', params).then(function (response) {
            if (response.status) {
                context.state.items[params.checklist].status = params.status
            }
        })
    },
}

// mutations
const mutations = {
    setResetFiltersState(state, payload) {
        state.resetFiltersState = payload
    },
    // Common but may change in other tools
    setFormat(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
        state.itemsLoaded = true
    },
    setFormatReport(state, payload) {
        state.reportsItems = payload.items
        Vue.set(state, 'reportsItems', { ...payload.items })
    },
    setItemsLoaded(state, payload) {
        Vue.set(state, 'itemsLoaded', payload)
    },

    setFilterStates(state, payload) {
        var filters = state.filters
        filters.states = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFilterActionPlans(state, payload) {
        var filters = state.filters
        filters.actionPlans = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFilterTemplates(state, payload) {
        var filters = state.filters
        filters.templates = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },

    setFilterLocations(state, payload) {
        var filters = state.filters
        filters.locations = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFilterEmployees(state, payload) {
        var filters = state.filters
        filters.employees = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setCurrentFilters(state, filters) {
        state.currentFilters = filters
        Vue.set(state, 'currentFilters', { ...filters })
    },
    setFiltersActive(state, filters) {
        state.filtersActive = filters
        Vue.set(state, 'filtersActive', { ...filters })
    },
    setFiltersTemplateActive(state, filters) {
        state.filtersTemplateActive = filters
        Vue.set(state, 'filtersTemplateActive', { ...filters })
    },
    setFilterStatus(state, filters) {
        state.filtersStatus = filters
        Vue.set(state, 'filtersStatus', { ...filters })
    },
    canLoadList(state, value) {
        state.can_load_list = value
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setNumItems(state, payload) {
        Vue.set(state, 'numItems', payload)
    },

    setGalleryTemplates(state, data) {
        state.galleryTemplates = data
        Vue.set(state, 'galleryTemplates', { ...data })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
