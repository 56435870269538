<template>
    <div id="galery-container" v-if="data">
        <div class="column-left">
            <template v-if="!selectedTemplate">
                <div class="categories hide-scrollbar">
                    <div class="category" :class="{ selected: catSelected == false }" @click="selectCategory(false)">
                        <div class="label">{{ translation('public_templates.gallery.all_categories') }}</div>
                    </div>
                    <div
                        class="category"
                        :class="{ selected: catSelected == category.id }"
                        @click="selectCategory(category.id)"
                        v-for="category in data.categories"
                        :key="category.id"
                    >
                        <div class="ball-color" :style="{ backgroundColor: category.color }"></div>
                        <div class="label">{{ category.name }}</div>
                    </div>
                </div>
                <div class="own-template">
                    <div class="label">{{ translation('public_templates.gallery.own.or') }}</div>
                    <div class="own-button" @click="selectTemplate()">{{ translation('public_templates.gallery.own.label') }}</div>
                </div>
            </template>
            <template v-else>
                <div class="categories detail hide-scrollbar">
                    <div class="category selected" @click="selectedTemplate = false">
                        <div class="back"></div>
                        <div
                            v-if="data.templates[selectedTemplate].category_id && data.categories[data.templates[selectedTemplate].category_id]"
                            class="ball-color"
                            :style="{ backgroundColor: data.categories[data.templates[selectedTemplate].category_id].color }"
                        ></div>
                        <div class="label">
                            {{
                                data.templates[selectedTemplate].category_id
                                    ? data.categories[data.templates[selectedTemplate].category_id].name
                                    : translation('public_templates.gallery.all_categories')
                            }}
                        </div>
                    </div>
                </div>
                <div class="meta-container">
                    <div class="meta">
                        <div class="label">{{ translation('public_templates.gallery.created_by') }}</div>
                        <div class="content">{{ translation('public_templates.gallery.andy_team') }}</div>
                    </div>
                    <div class="meta">
                        <div class="label">{{ translation('public_templates.gallery.category') }}</div>
                        <div class="content">
                            <div
                                class="ball-color"
                                v-if="data.templates[selectedTemplate].category_id && data.categories[data.templates[selectedTemplate].category_id]"
                                :style="{ backgroundColor: data.categories[data.templates[selectedTemplate].category_id].color }"
                            ></div>
                            {{
                                data.templates[selectedTemplate].category_id
                                    ? data.categories[data.templates[selectedTemplate].category_id].name
                                    : translation('public_templates.gallery.all_categories')
                            }}
                        </div>
                    </div>
                </div>

                <div class="select-template">
                    <div class="select-button" @click="selectTemplate()">{{ translation('public_templates.gallery.import_template') }}</div>
                    <div class="label">{{ translation('public_templates.gallery.import_template_description') }}</div>
                </div>
            </template>
        </div>
        <div class="templates-container hide-scrollbar">
            <template v-if="!selectedTemplate">
                <div class="searcher">
                    <input v-model="searcher" type="text" class="input-search" />
                    <div class="clear" :class="{ hide: !searcher }" @click.self="searcher = ''"></div>
                </div>

                <div class="templates hide-scrollbar">
                    <div class="template" v-for="template in templates" @click="selectedTemplate = template.id" :key="template.id">
                        <div class="icon"></div>
                        <div class="meta">
                            <div class="category" v-if="template.category_id && data.categories[template.category_id]">
                                <div class="ball-color" :style="{ backgroundColor: data.categories[template.category_id].color }"></div>
                                <div class="label">{{ data.categories[template.category_id].name }}</div>
                            </div>
                            <div class="label">
                                {{ template.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="templates detail hide-scrollbar">
                    <div class="preview hide-scrollbar">
                        <div class="header">
                            <div class="title" @click="selectedTemplate = false">{{ data.templates[selectedTemplate].name }}</div>
                            <div
                                class="description"
                                v-if="data.templates[selectedTemplate].description"
                                v-html="data.templates[selectedTemplate].description"
                            ></div>
                        </div>

                        <div class="content">
                            <template v-for="section in data.templates[selectedTemplate].sections">
                                <div class="section" :class="{ 'no-section': section.id == -1 }" :key="section.id">
                                    <div class="title">{{ section.name }}</div>

                                    <template v-for="item in section.items">
                                        <div class="item" :key="item.id">
                                            <div class="item-box">
                                                <div class="meta">
                                                    <div class="title">{{ item.title }}</div>
                                                    <div class="description" v-html="item.description"></div>
                                                </div>
                                                <div class="icon" :data-type="item.type"></div>
                                            </div>

                                            <div class="conditions" v-if="item.conditions && item.conditions.values">
                                                <div class="condition" v-for="condition in item.conditions.values" :key="condition.id">
                                                    <div
                                                        class="label"
                                                        v-html="conditionOptions(condition.operator, condition.value, item.type, item.config)"
                                                    ></div>

                                                    <div class="item-box" v-for="subitem in condition.items" :key="subitem.id">
                                                        <div class="meta">
                                                            <div class="title">{{ subitem.title }}</div>
                                                            <div class="description" v-html="subitem.description"></div>
                                                        </div>
                                                        <div class="icon" :data-type="subitem.type"></div>
                                                    </div>

                                                    <template v-for="action in condition.actions">
                                                        <div class="alert-box" v-if="action.type == 'info'">
                                                            <div class="icon" :data-type="action.class"></div>
                                                            <div class="meta">
                                                                <div class="title" v-html="action.msg"></div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { FORMAT } from '@/constants'
import store from '@/store'

export default {
    props: {
        data: { type: undefined, default: false }
    },
    data() {
        return {
            catSelected: false,
            searcher: '',
            selectedTemplate: false,
            FORMAT: FORMAT
        }
    },
    computed: {
        templates() {
            if (this.data && this.data.templates) {
                var self = this
                var result = Object.values(this.data.templates).filter(function (tpl) {
                    return (self.catSelected ? tpl.category_id == self.catSelected : true) &&
                        (self.searcher != '' ? cleanString(tpl.name).toLowerCase().includes(cleanString(self.searcher).toLowerCase().trim()) : true)
                        ? true
                        : false
                })
                // var resultSorted = result.sort((a, b) => a.name.localeCompare(b.name))
                return result
            } else return []
        }
    },
    methods: {
        translation(translation) {
            return i18n.t(translation)
        },
        selectCategory(category) {
            this.catSelected = category
        },
        selectTemplate() {
            this.$emit('selectTemplate', this.selectedTemplate)
        },

        conditionOptions(operator, value, type, config) {
            var condition = ''

            switch (operator) {
                case '!empty':
                    condition += i18n.t('template.general.condition_options.has_value')
                    break
                case '=':
                    condition += i18n.t('template.general.condition_options.same')
                    break
                case '!=':
                    condition += i18n.t('template.general.condition_options.different')
                    break
                case '>':
                    condition += i18n.t('template.general.condition_options.more_than')
                    break
                case '<':
                    condition += i18n.t('template.general.condition_options.less_than')
                    break
                case '>=':
                    condition += i18n.t('template.general.condition_options.more_equal_than')
                    break
                case '<=':
                    condition += i18n.t('template.general.condition_options.less_equal_than')
                    break
                case '<>':
                    condition += i18n.t('template.general.condition_options.between')
                    break
                case '!<>':
                    condition += i18n.t('template.general.condition_options.not_between')
                    break
            }

            if (operator == '!<>' || operator == '<>') {
                condition += ' <b>' + value.split(',')[0] + '</b>'
                condition += ' ' + i18n.t('template.general.condition_text_connector')
                condition += ' <b>' + value.split(',')[1] + '</b>'
            } else {
                condition += ' <b>' + value + '</b>'
            }

            // var value1, value2
            // if(type == FORMAT.TEMPERATURE) {
            //     value1 = value + config.units
            // }
            //  else {
            //     value1 = value
            //  }

            return condition
        }
    },
    mounted() {},
    beforeDestroy() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#galery-container {
    position: relative;
    @include display-flex();
    width: 100%;
    height: 100%;

    .column-left {
        @include display-flex();
        @include flex-direction(column);
        width: 100%;
        height: 100%;
        max-width: 230px;
        margin-right: 30px;
        min-width: 140px;
        padding: 0 10px;

        .categories {
            height: calc(50%);
            margin-bottom: 50px;
            overflow: auto;

            .category {
                @include display-flex();
                @include align-items();
                @include border-radius(4px);
                height: 40px;
                margin-bottom: 10px;
                cursor: pointer;
                padding: 10px;

                .ball-color {
                    @include border-radius(100%);
                    height: 15px;
                    width: 15px;
                    min-width: 15px;
                }

                .label {
                    @include font-size(md);
                    margin-left: 9px;
                    font-family: $text-bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }

                &.selected {
                    @include background($color: $color-neutral-200);
                }

                &:nth-last-child(1) {
                    margin-bottom: 0px;
                }
            }

            &.detail {
                height: auto;
                margin-bottom: 20px;

                .category {
                    @include background($image: img('left_inactive_s10.svg'), $size: 10px, $position: left 10px center);
                    padding-left: 25px;
                }
            }
        }

        .meta-container {
            .meta {
                @include display-inline-flex();
                margin-bottom: 10px;

                .label,
                .content {
                    @include display-inline-flex();
                    @include font-size(xs);
                    color: $color-black;
                    font-family: $text-light;
                }

                .label {
                    padding-right: 5px;
                }

                .content {
                    @include display-flex();
                    @include align-items();
                    font-family: $text-bold;

                    .ball-color {
                        @include border-radius(100%);
                        height: 10px;
                        width: 10px;
                        margin-right: 5px;
                    }
                }
            }
        }

        .own-template {
            @include display-flex();
            @include flex-direction(column);
            @include align-items();
            @include justify-content();
            height: 100px;
            .label {
                text-align: center;
                margin-bottom: 10px;
            }

            .own-button {
                @include border-radius(4px);
                @include background($color: $color-primary-700);
                padding: 10px 12px;
                text-align: center;
                color: #fff;
                cursor: pointer;

                &:hover {
                    background-color: $color-primary-800;
                }
            }
        }

        .select-template {
            @include display-flex();
            @include flex-direction(column);
            @include align-items();
            @include justify-content();
            margin-top: 40px;
            height: auto;

            .select-button {
                @include font-size(ml);
                @include background($image: img('more_ffffff.svg'), $size: 15px, $position: left 12px center, $color: $color-primary-500);
                @include border-radius(4px);
                padding: 10px 12px;
                text-align: center;
                color: $color-white;
                cursor: pointer;
                font-family: $text-bold;
                padding-left: 35px;
                width: 100%;

                &:hover {
                    background-color: $color-primary-800;
                }
            }

            .label {
                @include font-size(xs);
                margin-top: 10px;
                color: $color-black;
                font-family: $text-light;
            }
        }
    }

    .templates-container {
        @include display-flex();
        @include flex-direction(column);
        width: calc(100% - 170px);
        height: 100%;

        .searcher {
            @include display-flex();
            @include border-radius(4px);
            @include justify-content();
            @include align-items();
            @include background($image: img('search_aaaaaa.svg'), $size: 20px, $position: left 10px center);
            border: 1px solid $color-neutral-600;
            height: 45px;
            margin-bottom: 5px;

            .input-search {
                @include font-size(ml);
                border: none;
                width: calc(100% - 40px - 40px);
                height: 100%;
                margin-left: 35px;
                font-family: $text;
                color: $color-black;
            }

            .clear {
                @include background($image: img('close_inactive_s10.svg'), $size: 15px, $position: center center, $color: $color-neutral-600);
                height: 35px;
                width: 35px;
                cursor: pointer;

                &.hide {
                    opacity: 0;
                    cursor: default;
                }
            }
        }

        .templates {
            @include border-radius(4px);
            @include background($color: #f4f2ed);
            padding: 15px;
            height: calc(100% - 50px);
            overflow: auto;

            .template {
                @include display-flex();
                @include border-radius(4px);
                @include background($image: img('right_neutro_s10.svg'), $size: 18px, $position: right 10px center, $color: #fff);
                height: 70px;
                margin-bottom: 10px;
                padding: 10px;
                cursor: pointer;

                &:nth-last-child(1) {
                    margin-bottom: 0px;
                }

                .icon {
                    width: 60px;
                    height: 100%;
                    @include background($image: img('file_666666.svg'), $size: 25px, $position: center center);
                    margin-right: 5px;
                }

                .meta {
                    @include display-flex();
                    @include align-items(flex-start);
                    @include justify-content();
                    @include flex-direction(column);
                    width: calc(100% - 65px - 30px);

                    .category {
                        @include display-flex();
                        @include align-items();

                        margin-bottom: 3px;

                        .ball-color {
                            @include border-radius(100%);
                            height: 12px;
                            width: 12px;
                        }

                        .label {
                            @include font-size(sm);
                            margin-top: 1.5px;
                            margin-left: 9px;
                            text-transform: uppercase;
                            font-family: $text;
                        }
                    }

                    .label {
                        @include font-size(ml);
                        color: $color-black;
                        font-family: $text-bold;
                    }
                }
            }

            &.detail {
                height: 100%;

                .preview {
                    @include background($color: #fff);
                    @include border-radius(4px);
                    padding: 25px;
                    min-height: 100%;

                    .header {
                        margin-bottom: 30px;

                        .title,
                        .description {
                            color: $color-black;
                            padding: 0 0 0 30px;
                        }

                        .title {
                            @include background($image: img('left_dark.svg'), $size: 18px, $position: left center);
                            @include font-size(lg);
                            font-family: $text-bold;
                            cursor: pointer;
                        }
                        .description {
                            @include font-size(xs);
                            margin-top: 10px;
                            font-family: $text-light;
                        }
                    }

                    .content {
                        .section {
                            width: 100%;
                            margin-bottom: 25px;

                            > .title {
                                @include font-size(ml);
                                font-family: $text-bold;
                                width: 100%;
                                margin-bottom: 10px;
                                // border-bottom: 2px solid $color-black;
                            }

                            .item {
                                margin-left: 30px;

                                .item-box {
                                    @include display-flex();
                                    @include align-items();
                                    @include justify-content(flex-start);
                                    @include align-items(flex-start);
                                    @include border-radius(4px);
                                    padding: 10px;
                                    border: 1px solid $color-neutral-600;
                                    min-height: 50px;
                                    margin-bottom: 10px;

                                    > .meta {
                                        @include display-flex();
                                        @include justify-content();
                                        @include align-items(flex-start);
                                        @include flex-direction(column);
                                        width: calc(100% - 45px);
                                        min-height: 45px;

                                        > .title {
                                            @include font-size(md);
                                            font-family: $text-bold;
                                            color: $color-black;
                                        }

                                        > .description {
                                            @include font-size(xs);
                                            padding: 0;
                                            margin-top: 5px;
                                            color: $color-black;
                                        }
                                    }

                                    .icon {
                                        @include border-radius(4px);
                                        border: 2px dashed $color-neutral-300;
                                        height: 45px;
                                        width: 45px;

                                        &[data-type='1'],
                                        &[data-type='17'] {
                                            // CHECK, YES/NO, DOCUMENT + CHECK
                                            @include background($size: 30px, $image: img('format_check_inactive.svg'));
                                        }
                                        &[data-type='2'],
                                        &[data-type='3'] {
                                            // YES/NO/NC
                                            @include background($size: 30px, $image: img('format_multiple_inactive.svg'));
                                            background-size: 35px;
                                        }
                                        &[data-type='4'],
                                        &[data-type='10'] {
                                            // TEXT, TEXTAREA
                                            @include background($size: 30px, $image: img('format_text_inactive.svg'));
                                        }
                                        &[data-type='5'] {
                                            // FOTO
                                            @include background($size: 30px, $image: img('format_image_inactive.svg'));
                                        }
                                        &[data-type='6'] {
                                            // NUMERIC
                                            @include background($size: 30px, $image: img('format_number_inactive.svg'));
                                        }
                                        &[data-type='7'],
                                        &[data-type='12'],
                                        &[data-type='13'] {
                                            // DATE, TIME, DATETIME
                                            @include background($size: 30px, $image: img('format_datetime_inactive.svg'));
                                        }
                                        &[data-type='11'] {
                                            // MULTIPLE
                                            @include background($size: 35px, $image: img('format_select_inactive.svg'));
                                        }
                                        &[data-type='14'] {
                                            // SIGNATURE
                                            @include background($size: 30px, $image: img('format_sign_inactive.svg'));
                                        }
                                        &[data-type='15'] {
                                            // TEMPERATURE
                                            @include background($size: 30px, $image: img('format_temperature_inactive.svg'));
                                        }
                                        &[data-type='16'],
                                        &[data-type='18'] {
                                            // EMAIL, MESSAGE
                                            @include background($size: 30px, $image: img('format_mail_inactive.svg'));
                                        }
                                        &[data-type='19'] {
                                            @include background($size: 30px, $image: img('format_link_inactive.svg'));
                                        }
                                        &[data-type='20'] {
                                            @include background($size: 30px, $image: img('library_second_inactive.svg'));
                                        }
                                    }
                                }

                                .condition {
                                    margin-left: 50px;
                                    // border-left: 5px solid $color-primary-100;
                                    // @include border-radius(10px);

                                    .label {
                                        @include border-radius(4px);
                                        @include background($color: $color-primary-100);
                                        @include font-size(xs);
                                        @include font-size(s70);
                                        padding: 5px 10px;
                                        font-family: $text-light;
                                        color: $color-black;
                                        width: max-content;
                                        margin-bottom: 4px;

                                        &:first-letter {
                                            text-transform: uppercase;
                                        }

                                        b {
                                            @include font-size(xs);
                                        }
                                    }

                                    .alert-box {
                                        @include display-flex();
                                        @include align-items();
                                        @include justify-content(flex-start);
                                        @include align-items(flex-start);
                                        @include border-radius(4px);
                                        padding: 10px;
                                        border: 1px solid $color-neutral-600;
                                        min-height: 50px;
                                        margin-bottom: 10px;

                                        > .icon {
                                            @include border-radius(4px);
                                            @include background($size: 30px, $image: img('task_issue_inactive.svg'));
                                            border: 2px dashed $color-neutral-300;
                                            height: 45px;
                                            width: 45px;
                                            margin-right: 10px;

                                            // &[data-type='info'] {
                                            //     @include background($size: 30px, $image: img('task_issue_info_s10.svg'));
                                            //     border-color: $color-info-500;
                                            // }

                                            // &.alert {
                                            //     @include background($size: 30px, $image: img('task_issue_warning_s10.svg'));
                                            //     border-color: $color-warning-500;
                                            // }

                                            // &.error {
                                            //     @include background($size: 30px, $image: img('task_issue_error_s10.svg'));
                                            //     border-color: $color-error-500;
                                            // }
                                        }

                                        > .meta {
                                            @include display-flex();
                                            @include justify-content();
                                            @include align-items(flex-start);
                                            @include flex-direction(column);
                                            width: calc(100% - 55px);
                                            min-height: 45px;

                                            > .title {
                                                @include font-size(sm);
                                                font-family: $text;
                                                color: $color-black;
                                            }
                                        }
                                    }
                                }
                            }

                            &.no-section {
                                > .title {
                                    border: none;
                                    display: none;
                                }

                                .item {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
