<template>
    <div class="require-typing">
        <label class="input-label">{{ translation('popup.delete.requireTypingLabel') }}</label>
        <div class="input-container">
            <input type="text" v-model="inputValue" @change="onInputChange" :placeholder="translation('popup.delete.requireTypingInput')" maxlength="10" />
            <p v-if="showErrorMessage" class="error-message">{{ translation('popup.delete.requireTypingErrorMessage') }}</p>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: { showErrorMessage: { type: Boolean, default: false } },
    data() {
        return {
            inputValue: ''
        }
    },
    methods: {
        translation(translation) {
            return i18n.t(translation)
        },
        onInputChange() {
            this.$emit('required-word-typed', this.inputValue)
        }
    }
}
</script>

<style lang="scss" scoped>
.require-typing {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    .input-label {
        font-size: 18px;
        color: #161616;
    }

    .input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.4em;
    }

    input::placeholder {
        text-align: center;
        font-size: 16px;
    }

    .error-message {
        padding-top: 0;
        color: #bd1f1e;
    }
}
</style>
