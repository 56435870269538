import store from '../../store.js'
import i18n from '@/i18n'
import { Api } from '@/core/api.js'
const api = new Api()

// initial state
const getDefaultState = () => {
    return {
        routing: {
            AnalyticsHome: {
                id: 'AnalyticsHome',
                label: 'tools.dashboard_menu_top',
                url: '/home'
            },
            Onboarding: {
                id: 'Onboarding',
                label: 'tools.onboarding_title',
                url: '/onboarding'
            },
            TemplateCategories: {
                id: 'TemplateCategories',
                // label: 'template.categories.title',
                label: 'template.checklists.title',
                url: '/template/categories',
                parent_id: -1
            },
            AddTemplateCategories: {
                id: 'TemplateCategories',
                // label: 'template.categories.title',
                label: 'template.categories.title_create',
                url: '/template/categories/add',
                parent_id: -1
            },
            EditTemplateCategories: {
                id: 'EditTemplateCategories',
                // label: 'template.categories.title',
                label: 'template.categories.title_edit',
                url: '/template/categories/:id',
                parent_id: -1
            },
            OperationalTimersCategories: {
                id: 'OperationalTimersCategories',
                // label: 'template.categories.title',
                label: 'template.checklists.title',
                url: '/operationalTimers/categories',
                parent_id: -1
            },
            AddOperationalTimersCategories: {
                id: 'OperationalTimersCategories',
                // label: 'template.categories.title',
                label: 'template.categories.title_create',
                url: '/operationalTimers/categories/add',
                parent_id: -1
            },
            EditOperationalTimersCategories: {
                id: 'EditOperationalTimersCategories',
                // label: 'template.categories.title',
                label: 'template.categories.title_edit',
                url: '/operationalTimers/categories/:id',
                parent_id: -1
            },
            TemplateChecklist: {
                id: 'TemplateChecklist',
                label: 'template.checklists.title',
                url: '/template/checklist',
                parent_id: -1
            },
            AddChecklistDetail: {
                id: 'AddChecklistDetail',
                label: 'template.general.title_create',
                url: '/template/checklist/add',
                parent_id: '/template/checklist'
            },
            ChecklistDetail: {
                id: 'ChecklistDetail',
                label: 'template.general.title_edit',
                url: '/template/checklist/:id',
                parent_id: '/template/checklist'
            },
            TemplateIssue: {
                id: 'TemplateIssue',
                label: 'template.issues.title',
                url: '/template/issue',
                parent_id: -1
            },
            AddIssueDetail: {
                id: 'AddIssueDetail',
                label: 'template.general.title_create',
                url: '/template/issue/add',
                parent_id: '/template/issue'
            },
            IssueDetail: {
                id: 'IssueDetail',
                label: 'template.general.title_edit',
                url: '/template/issue/:id',
                parent_id: '/template/issue'
            },
            TemplateActionplan: {
                id: 'TemplateActionplan',
                label: 'template.issues.title',
                url: '/template/actionplan',
                parent_id: -1
            },
            AddActionplanDetail: {
                id: 'AddActionplanDetail',
                label: 'template.general.title_create',
                url: '/template/actionplan/add',
                parent_id: '/template/actionplan'
            },
            ActionplanDetail: {
                id: 'ActionplanDetail',
                label: 'template.general.title_edit',
                url: '/template/actionplan/:id',
                parent_id: '/template/actionplan'
            },
            TemplateAudit: {
                id: 'TemplateAudit',
                label: 'template.audits.title',
                url: '/template/audit',
                parent_id: -1
            },
            AddAuditDetail: {
                id: 'AddAuditDetail',
                label: 'template.general.title_create',
                url: '/template/audit/add',
                parent_id: '/template/audit'
            },
            AuditDetail: {
                id: 'AuditDetail',
                label: 'template.general.title_edit',
                url: '/template/audit/:id',
                parent_id: '/template/audit'
            },
            TemplateRecord: {
                id: 'TemplateRecord',
                label: 'template.records.title',
                url: '/template/record',
                parent_id: -1
            },
            AddRecordDetail: {
                id: 'AddRecordDetail',
                label: 'template.general.title_create',
                url: '/template/record/add',
                parent_id: '/template/record'
            },
            RecordDetail: {
                id: 'RecordDetail',
                label: 'template.general.title_edit',
                url: '/template/record/:id',
                parent_id: '/template/record'
            },
            SuperviseChecklist: {
                id: 'SuperviseChecklist',
                label: 'supervise.checklists.title',
                url: '/supervise/checklist',
                roles: [3, 4, 5, 6],
                parent_id: -1
            },
            SuperviseIssue: {
                id: 'SuperviseIssue',
                label: 'supervise.issues.title',
                url: '/supervise/issue',
                roles: [3, 4, 5, 6],
                parent_id: -1
            },
            SuperviseAudit: {
                id: 'SuperviseAudit',
                label: 'supervise.audits.title',
                url: '/supervise/audit',
                roles: [3, 4, 5, 6],
                parent_id: -1
            },
            SuperviseRecords: {
                id: 'SuperviseRecords',
                label: 'supervise.records.title',
                url: '/supervise/records',
                roles: [3, 4, 5, 6],
                parent_id: -1
            },
            SuperviseLabel: {
                id: 'SuperviseLabel',
                label: 'supervise.label.title',
                url: '/supervise/label',
                roles: [3, 4, 5, 6],
                parent_id: -1
            },
            Traceability: {
                id: 'Traceability',
                label: 'supervise.label.title',
                url: '/traceability',
                roles: [3, 4, 5, 6],
                parent_id: -1
            },
            ReportRecords: {
                id: 'ReportRecords',
                label: 'supervise.records.title',
                url: '/supervise/records/:id',
                roles: [3, 4, 5, 6],
                parent_id: -1
            },
            ReportChecklist: {
                id: 'ReportChecklist',
                label: 'supervise.checklists.title',
                url: '/supervise/checklist/:id',
                roles: [3, 4, 5, 6],
                parent_id: 'SuperviseChecklist'
            },
            ReportIssue: {
                id: 'ReportIssue',
                label: 'supervise.issues.title',
                url: '/supervise/issue/:id',
                roles: [3, 4, 5, 6],
                parent_id: '/supervise/issues'
            },
            Profile: {
                id: 'Profile',
                label: 'tools.organization_sub.my_profile_title',
                url: '/logbook',
                roles: [3, 4, 5, 6, 7, 8],
                parent_id: -1
            },
            Logbook: {
                id: 'Logbook',
                label: 'tools.logbook_title',
                url: '/logbook',
                roles: [3, 4, 5, 6, 7, 8],
                parent_id: -1
            },
            Communication: {
                id: 'Communication',
                label: 'tools.logbook_title',
                url: '/logbook/communication',
                roles: [3, 4, 5, 6, 7, 8],
                parent_id: '/logbook'
            },
            EditCommunication: {
                id: 'EditCommunication',
                label: 'tools.logbook_title',
                url: '/logbook/communication/:id',
                roles: [6, 7, 8],
                parent_id: '/logbook/communication'
            },
            AddCommunication: {
                id: 'AddCommunication',
                label: 'tools.logbook_title',
                url: '/logbook/communication/add',
                roles: [6, 7, 8],
                parent_id: '/logbook/communication'
            },
            Users: {
                id: 'Users',
                label: 'user.user_title',
                url: '/users',
                roles: [3, 4, 5, 6],
                parent_id: -1
            },
            Roles: {
                id: 'Roles',
                label: 'roles.roles_title',
                url: '/roles',
                roles: [3, 4, 5, 6],
                parent_id: -1
            },
            AddRole: {
                id: 'AddRole',
                label: 'roles.add_roles_title',
                url: '/roles/add',
                roles: [7, 8],
                parent_id: '/roles'
            },
            EditRole: {
                id: 'EditRole',
                label: 'roles.edit_roles_title',
                url: '/roles/:id',
                roles: [7, 8],
                parent_id: '/roles'
            },
            AddUser: {
                id: 'AddUser',
                label: 'user.form.title',
                url: '/users/add',
                roles: [3, 4, 5, 6],
                parent_id: '/users'
            },
            EditUser: {
                id: 'EditUser',
                label: 'user.form.edit_title',
                url: '/users/:id',
                roles: [3, 4, 5, 6],
                parent_id: '/users'
            },
            Sites: {
                id: 'Sites',
                label: 'sites.title',
                url: '/sites',
                roles: [4, 5, 6],
                parent_id: -1
            },
            AddSite: {
                id: 'AddSite',
                label: 'sites.add_title',
                url: '/sites/add',
                roles: [4, 5, 6],
                parent_id: '/Sites'
            },
            EditSite: {
                id: 'EditSite',
                label: 'sites.edit_title',
                url: '/sites/:id',
                roles: [4, 5, 6],
                parent_id: '/Sites'
            },
            Groups: {
                id: 'Groups',
                label: 'groups.title',
                roles: [6],
                url: '/groups'
            },
            AddGroup: {
                id: 'AddSite',
                label: 'groups.add_title',
                url: '/groups/add',
                roles: [6],
                parent_id: '/Groups'
            },
            EditGroup: {
                id: 'EditSite',
                label: 'groups.edit_title',
                url: '/groups/:id',
                roles: [6],
                parent_id: '/Groups'
            },
            ProductState: {
                id: 'ProductState',
                label: 'product.product_state.title',
                roles: [6],
                url: '/product_state'
            },
            AddProductState: {
                id: 'AddProductState',
                label: 'product.product_state.add_title',
                url: '/product_state/add',
                roles: [6],
                parent_id: '/product_state'
            },
            EditProductState: {
                id: 'EditProductState',
                label: 'product.product_state.edit_title',
                url: '/product_state/:id',
                roles: [6],
                parent_id: '/product_state'
            },
            ProductCategory: {
                id: 'ProductCategory',
                label: 'product.product_category.title',
                url: '/product_category',
                roles: [6]
            },
            AddProductCategory: {
                id: 'AddProductCategory',
                label: 'product.product_category.add_title',
                url: '/product_category/add',
                roles: [6],
                parent_id: '/product_category'
            },
            EditProductCategory: {
                id: 'EditProductCategory',
                label: 'product.product_category.edit_title',
                url: '/product_category/:id',
                roles: [6],
                parent_id: '/product_category'
            },
            ProductAvailability: {
                id: 'ProductAvailability',
                label: 'product.product_availability.title',
                url: '/availability',
                roles: [3, 4, 5, 6]
            },
            EditProductAvailability: {
                id: 'EditProductAvailability',
                label: 'product.product_availability.title',
                url: '/availability/:id',
                roles: [3, 4, 5, 6],
                parent_id: '/availability'
            },
            Product: {
                id: 'Product',
                label: 'product.title',
                url: '/product',
                roles: [6],
                parent_id: -1
            },
            AddProduct: {
                id: 'AddProduct',
                label: 'product.add_title',
                url: '/product/add',
                roles: [6],
                parent_id: '/product'
            },
            EditProduct: {
                id: 'EditProduct',
                label: 'product.edit_title',
                url: '/product/:id',
                roles: [6],
                parent_id: '/product'
            },
            ExternalTools: {
                id: 'ExternalTools',
                label: 'tools.organization_title',
                url: '/tools',
                roles: [6]
            },
            Settings: {
                id: 'Settings',
                label: 'tools.organization_title',
                url: '/settings'
            },
            Modules: {
                id: 'Modules',
                label: 'tools.personalization_sub.modules_title',
                url: '/modules'
            },
            TimeFrames: {
                id: 'TimeFrames',
                label: 'tools.personalization_sub.timeframes_title',
                url: '/timeframes'
            },
            AddTimeFrames: {
                id: 'AddTimeFrames',
                label: 'personalization.addTimeFrames.title',
                url: '/timeframes/add'
            },
            EditTimeFrames: {
                id: 'EditTimeFrames',
                label: 'personalization.editTimeFrames.title',
                url: '/timeframes/:id'
            },
            IssueStates: {
                id: 'IssueStates',
                label: 'personalization.personalization.title',
                url: '/issue_states'
            },
            AddIssueStates: {
                id: 'AddIssueStates',
                label: 'personalization.addIssueState.title',
                url: '/issue_states/add'
            },
            EditIssueStates: {
                id: 'EditIssueStates',
                label: 'personalization.editIssueState.title',
                url: '/issue_states/:id'
            },
            OperationalTimers: {
                id: 'OperationalTimers',
                label: 'tools.timers_title',
                url: '/operationalTimers'
            },
            AddOperationalTimers: {
                id: 'AddOperationalTimers',
                label: 'tools.operationalTimer.addoperationalTimer',
                url: '/operationalTimers/add'
            },
            EditOperationalTimers: {
                id: 'EditOperationalTimers',
                label: 'tools.operationalTimer.editOperationalTimer',
                url: '/operationalTimers/:id'
            },
            Config: {
                id: 'Configuration',
                label: 'settings.title',
                url: '/configuration'
            },
            AddExternalTools: {
                id: 'AddExternalTools',
                label: 'external_tools.add_title',
                url: '/tools/add',
                roles: [6],
                parent_id: '/tools'
            },
            EditExternalTools: {
                id: 'EditExternalTools',
                label: 'external_tools.edit_title',
                url: '/tools/:id',
                roles: [6],
                parent_id: '/tools'
            },
            Library: {
                id: 'Library',
                label: 'library.title',
                url: '/library',
                roles: [6]
            },
            AddLibrary: {
                id: 'AddLibrary',
                label: 'library.add_title',
                url: '/library/add',
                roles: [6],
                parent_id: '/library'
            },
            EditLibrary: {
                id: 'EditLibrary',
                label: 'library.edit_title',
                url: '/library/:id',
                roles: [6],
                parent_id: '/library'
            },
            LibraryCategory: {
                id: 'LibraryCategory',
                label: 'library.categories.title',
                url: '/library/category',
                roles: [6]
            },
            AddLibraryCategory: {
                id: 'AddLibraryCategory',
                label: 'library.categories.add_title',
                url: '/library/category/add',
                roles: [6],
                parent_id: '/library/category'
            },
            EditLibraryCategory: {
                id: 'EditLibraryCategory',
                label: 'library.categories.edit_title',
                url: '/library/category/:id',
                roles: [6],
                parent_id: '/library'
            },
            Analytics: {
                id: 'Analytics',
                label: 'tools.analytics',
                url: '/analytics'
            },
            Notifications: {
                id: 'notifications',
                label: 'notifications.title',
                url: '/notifications'
            },
            News: {
                id: 'News',
                label: 'news.title',
                url: '/news'
            },
            Multiaccount: {
                id: 'Multiaccount',
                label: 'multiaccount.menu.title',
                url: '/multiaccount'
            },
            SuperviseOperationalTimers: {
                id: 'SuperviseOperationalTimers',
                label: 'tools.operationalTimer.tool_title',
                url: '/supervise/operationalTimers'
            }
        },
        page: {}
    }
}
const state = getDefaultState()

// getters
const getters = {
    getCurrentPage: (state, getters, rootState, rootGetters) => {
        var page = state.page
        // IF REPORT RECORDS TEMPLATE SELECTED
        if (typeof page !== 'undefined' && page.id == 'ReportRecords') {
            var template = rootGetters['records/getTemplateSelected']
            var templateName = template.name
            if (template.status == '-2') {
                templateName = template.name + ' ' + '<span class="inactive-card">' + i18n.t('template.inactive') + '</span>'
            }
            return template ? { label: i18n.t('supervise.title') + ' ' + templateName } : state.page
        }
        // ELSE IF IS TRANSLATING
        else if (typeof page !== 'undefined' && rootGetters['multilanguage/getTranslationLang']) {
            return { label: i18n.t('multi-language.tools.translate_product') }
        } else {
            return state.page
        }
    },

    getRouting: (state, getters, rootState) => {
        return state.routing
    }
}

// actions
const actions = {
    setPage({ state, commit, rootState }, params) {
        // console.log('params ', params)
        commit('setPage', params)
        return true
    }
}

// mutations
const mutations = {
    setPage(state, page) {
        if (state.routing[page.name] != undefined) {
            var page = state.routing[page.name]
            page.label = page.label
            state.page = page
        }
    },

    setHiddenColumns(hidden_columns) {
        // console.log( store );
        state.routing[store.state.current_page].hidden_columns = hidden_columns
        state.page.hidden_columns = hidden_columns
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
