<template>
    <div
        v-if="$data.show"
        class="popup-container"
        :class="[customClass, $mq, { blocking: blocking }, { tablePopup: type === 'tableColumn' }]"
        @click.self="cancel"
    >
        <div class="popup" v-bind:class="type">
            <!-- <div
                class="pop-alex"
                :class="[{'error':showingError}, $data.mood]"
                v-if="!disabledAlexHead.includes(type)"
            ></div> -->

            <div class="pop-andy" v-if="type === 'messageWithButton' || 'exportLoading'"></div>

            <div class="pop-header actions" v-if="type === 'galeryTemplates'">
                <p class="title-message">{{ title }}</p>
                <div class="buttons">
                    <button @click="cancel" class="act cancel" v-html="textCancel" v-if="type != 'alert'"></button>
                </div>
            </div>
            <div class="pop-header temporal-temp" v-if="type === 'tplTemporary'">
                <h1>{{ getString('template.general.selectTplType.popup_title') }}</h1>
            </div>
            <div class="pop-header delete-item" v-if="type === 'deleteItem'">
                <!-- <h1 v-if="$data.tool == 'checklist'">{{ getString('supervise.checklists.deleteTasks') }}</h1>
                <p v-if="$data.tool == 'checklist'">{{ getString('supervise.checklists.warningTask') }}</p> -->
                <h1 v-if="$data.tool == 'register'">{{ getString('supervise.checklists.deleteRegister') }}</h1>
                <p v-if="$data.tool == 'register' && $data.items.length == 0">{{ getString('supervise.checklists.warningRegister') }}</p>
                <p v-if="$data.tool == 'register' && $data.items.length > 0">{{ getString('supervise.checklists.warningRegisterInvalidRol') }}</p>
                <h1 v-else-if="$data.tool == 'issues'">{{ getString('supervise.checklists.deleteIssue') }}</h1>
                <p v-if="$data.tool == 'issues' && $data.items.length == 0">{{ getString('supervise.checklists.warningIssue') }}</p>
                <p v-if="$data.tool == 'issues' && $data.items.length > 0">{{ getString('supervise.checklists.warningIssueInvalidRol') }}</p>
                <h1 v-else-if="$data.tool == 'audit'">{{ getString('supervise.checklists.deleteAudit') }}</h1>
                <p v-if="$data.tool == 'audit' && $data.items.length == 0">{{ getString('supervise.checklists.warningAudit') }}</p>
                <p v-else-if="$data.tool == 'audit' && $data.items.length > 0">{{ getString('supervise.checklists.warningAudit') }}</p>
            </div>
            <div class="pop-header actions" v-else-if="type === 'postpone'" style="margin: -30px"></div>
            <div class="pop-header preview" v-else-if="type === 'labelPreview'">
                {{ title }}
            </div>
            <div class="pop-header gallery" v-else-if="type === 'gallery'">
                <div class="description">
                    <div v-if="descri && descri.title" class="title">
                        {{ descri.title }}
                    </div>
                    <div v-if="descri && descri.message" class="message" v-html="descriptionParse(descri.message)"></div>
                    <span class="por">{{ getString('nvgt.report.done_by') }}</span>
                    <span v-if="descri && descri.employee && descri.employee.avatar" class="avatar">
                        <img class="main-img" v-bind:src="cssImage + 'avatar/' + descri.employee.avatar" />
                    </span>

                    <span v-if="descri && descri.employee" class="name">{{ descri.employee.name + ' ' + descri.employee.surname + ' ' }}</span>

                    <span class="datetime" v-if="descri && descri.complete_date"> {{ dateParse(descri.complete_date) }} </span>
                    <span class="bold" v-else>-</span>
                </div>
                <div class="cancelIcon">
                    <button @click="cancel" class="act cancel" v-if="type != 'alert'"></button>
                </div>
            </div>

            <div v-if="type === 'exportLoading'" class="pop-header">
                <h1 class="title-message">{{ getString('supervise.reports.export.popup_loading.title') }}</h1>
            </div>

            <div class="pop-header" v-else-if="type === 'selectorsHistory'">
                <div class="title">
                    <p class="title-message">{{ title }}</p>
                    <div class="tool-tip-box">
                        <span class="selector-info"></span>
                        <span class="tool-tip">{{ help }}</span>
                    </div>
                </div>
                <p class="selector-description">{{ message }}</p>
            </div>

            <div class="pop-header" v-else-if="type === 'templateConvertTo'">
                <div class="title">
                    <p class="title-message">{{ title }}</p>
                </div>
                <p class="selector-description">{{ message }}</p>
            </div>

            <div class="pop-header" v-else>
                <p class="title-message">{{ title }}</p>
            </div>

            <div class="pop-body">
                <!-- tableColumn -->
                <!-- <h1 v-if="type == 'tableColumn'">{{ title }}</h1> -->

                <!-- PERSONALIZAR COLUMNAS / CUSTOMIZE COLUMNS -->
                <div class="checkbox-list-container" v-if="type == 'tableColumn'">
                    <draggable v-model="columnHeaders" @start="drag = true" @end="drag = false">
                        <div v-if="header.text != ''" v-for="header in columnHeaders" :key="header.id" @click="checkHeader(header)" class="check-box-container">
                            <div :class="{ activeHeader: header.visible === true }" class="check-box">
                                <div class="check-input"></div>
                                <p class="check-title">{{ header.text }}</p>
                            </div>
                        </div>
                    </draggable>
                </div>

                <!-- Selectors history -->
                <PopupSelectorHistory v-else-if="type === 'selectorsHistory'" :history="selectorHistory"></PopupSelectorHistory>

                <!-- Template convert to -->
                <PopupTemplateConvertTo v-else-if="type === 'templateConvertTo'" :tools="tools" :type="tool"></PopupTemplateConvertTo>

                <!-- media -->
                <div class="kind-of preview hide-scrollbar" v-else-if="type == 'media' || type == 'previewSignature'">
                    <!-- <img v-if="image" :src="image" style="object-fit: contain; width: 100%; height: 100%" /> -->
                    <cld-image v-if="image" :publicId="image" type="fetch" loading="lazy">
                        <cld-transformation crop="fill" fetchFormat="webp" />
                    </cld-image>
                    <p v-else class="empty"></p>
                </div>
                <!-- gallery -->
                <div class="kind-of preview hide-scrollbar" v-else-if="type == 'gallery'">
                    <span class="backward" @click="goBackward()"></span>
                    <cld-image v-if="imageUrl" :publicId="imageUrl" type="fetch" loading="lazy" class="image">
                        <cld-transformation crop="fill" fetchFormat="webp" />
                    </cld-image>
                    <p v-else class="empty"></p>
                    <span class="forward" @click="goForward()"></span>
                </div>
                <!-- Seleccionable fecha -->
                <div class="kind-of" v-else-if="type == 'date'">
                    <!-- :allowed-dates="allowedDates" -->
                    <v-date-picker
                        v-model="inputVal"
                        :locale="aLocale"
                        landscape
                        reactive
                        :min="dateStart"
                        :max="dateEnd"
                        first-day-of-week="1"
                    ></v-date-picker>
                </div>

                <!-- Seleccionable fecha RANGO -->
                <div class="kind-of" v-else-if="type == 'dateRange'">
                    <!-- :allowed-dates="allowedDates" -->
                    <v-date-picker v-model="dateRange[0]" :locale="aLocale" reactive :min="dateStart" :max="dateEnd" first-day-of-week="1"></v-date-picker>

                    <v-date-picker v-model="dateRange[1]" :locale="aLocale" reactive :min="dateRange[0]" :max="dateEnd" first-day-of-week="1"></v-date-picker>

                    <!--
                    <div class="fcalendar-container">
                        <functional-calendar
                        v-model="dateRange[0]"
                        @input="setRangeResult(dateRange[0])"
                        :is-multiple="true"
                        :is-date-range="true"
                        :calendars-count="2"
                        :isDatePicker="true"
                        :change-month-function="true"
                        :change-year-function="true"
                        :markedDates="[{date: dateEnd, class: 'expiration'},{date: dateRange[0], class: 'vfc-marked'}]"
                        :limits="{min: dateStart, max: ''}"
                        ></functional-calendar>
                    </div>
                    <p
                        v-else
                        class="empty"
                    ></p> -->
                </div>

                <!-- Select, -->
                <div class="kind-of" v-else-if="type == 'select'">
                    <p v-show="showingError" class="message-error" v-html="messageError"></p>

                    <ul v-if="Object.keys(list).length > 0" class="list select-list hide-scrollbar">
                        <!-- <li
                            v-for="item in list"
                            v-if="item.default"
                            class="item default"
                            :class="[{ selected : selectedEl == item.id }]"
                            :id="item.id"
                            @click="selectMe( item.id )"
                            
                        >
                            <span class="name">{{item.name}}</span>
                        </li>
                        <li
                            v-for="item in list"
                            v-if="!item.default"
                            class="item"
                            :class="[{ selected : selectedEl == item.id }]"
                            :id="item.id"
                            @click="selectMe( item.id )"
                        >
                            <span class="name">{{item.name}}</span>
                        </li> -->
                        <li
                            v-for="(item, key) in list"
                            :key="key"
                            :class="{ item: true, default: item.default, selected: selectedEl === item.id }"
                            :data-id="item.id"
                            @click="selectMe(item.id, key)"
                        >
                            <span class="name">{{ item.name }}</span>
                        </li>
                    </ul>
                    <p v-else class="empty" v-html="title"></p>
                </div>

                <!-- Select double -->
                <div class="kind-of" v-else-if="type == 'selectDouble'">
                    <p v-show="showingError" class="message-error" v-html="messageError"></p>

                    <div class="left hide-scrollbar">
                        <ul v-if="Object.keys(list).length > 0" class="list users-list hide-scrollbar">
                            <li v-for="item in list" class="item user" :class="{ selected: selectedEl == item.id }" @click="selectMe(item.id)">
                                <span class="user-color"><img class="main-img" v-bind:src="item.avatar" /> "></span>
                                <span class="user-name">{{ item.name }} {{ item.surname }}</span>
                            </li>
                        </ul>
                        <p v-if="Object.keys(list).length == 0" class="empty" v-html="listEmpty"></p>
                    </div>

                    <div class="right hide-scrollbar" :class="{ disabled: selectedEl == undefined }">
                        <ul v-if="Object.keys(listSec).length > 0" class="list select-list hide-scrollbar" :data-msg="disabledMessage">
                            <li
                                v-for="item in listSec"
                                v-if="item.default"
                                class="item default"
                                :class="[{ selected: selectedSecEl == item.id }]"
                                :id="item.id"
                                @click="selectMeSec(item.id)"
                            >
                                <span class="name">{{ item.name }}</span>
                            </li>
                            <li
                                v-for="item in listSec"
                                v-if="!item.default"
                                class="item"
                                :class="[{ selected: selectedSecEl == item.id }]"
                                :id="item.id"
                                @click="selectMeSec(item.id)"
                            >
                                <span class="name">{{ item.name }}</span>
                            </li>
                        </ul>
                        <p v-if="Object.keys(listSec).length == 0" class="empty" v-html="listEmpty"></p>
                    </div>
                </div>
                <!-- numeric -->
                <Numpad
                    class="kind-of"
                    v-else-if="type == 'numeric'"
                    @sendValue="changeValue"
                    @saveAndClose="save"
                    :givenValue="inputVal"
                    :decimal="$data.decimal"
                    :hasNegative="$data.hasNegative"
                    :pinType="'numeric'"
                ></Numpad>

                <!-- temperature -->
                <PopTemperature
                    class="hide-scrollbar"
                    v-else-if="type == 'temperature'"
                    @sendValue="changeValue"
                    @saveAndClose="save"
                    :givenValue="inputVal"
                    :stepNum="tempOptions.stepNum"
                    :numDec="tempOptions.numDec"
                    :stepDec="tempOptions.stepDec"
                    :mesure="tempOptions.mesure"
                    :hasNegative="true"
                ></PopTemperature>

                <!-- Ticket -->
                <PopupTicket class="kind-of" v-else-if="type == 'ticket'" @sendValue="changeValue" @saveAndClose="save" ref="popupTicket"></PopupTicket>

                <!-- DateTime -->
                <PopupDateTime
                    class="kind-of"
                    v-else-if="type == 'dateTime'"
                    :date="date"
                    :time="time"
                    @sendValue="changeValue"
                    @saveAndClose="save"
                    ref="dateTime"
                >
                </PopupDateTime>

                <!-- Geolocation -->
                <PopupGeolocation v-else-if="type == 'geolocation'" class="geolocation" ref="popupGeolocation"> </PopupGeolocation>

                <!-- Resource -->
                <PopupResource v-else-if="type == 'resource'" class="resource" :resource="resource" ref="popupResource" @close="cancel('cross')">
                </PopupResource>

                <!-- quantity -->
                <div class="kind-of" v-else-if="type == 'quantity'">
                    <div class="left hide-scrollbar">
                        <p class="message">{{ message }}</p>
                        <h6>{{ productName }}</h6>

                        <!-- <button v-if="inputVal > 0" class="act rem-amount" @click="removeAll">
                      {{ getString('popup.quantity.remove_all') }}
                    </button> -->
                    </div>

                    <div class="right hide-scrollbar">
                        <Numpad @sendValue="changeValue" :decimal="decimal" :givenValue="inputVal" :max="100"></Numpad>
                    </div>
                </div>

                <!-- timeClock -->
                <div class="kind-of" v-else-if="type == 'timeclock'">
                    <div class="left hide-scrollbar">
                        <div class="place">
                            <div class="item" :class="selectedTab == 'all' ? 'selected' : ''" @click="selectTab('all')" data-val="all">Todos</div>
                            <div class="item" :class="selectedTab == 'in' ? 'selected' : ''" @click="selectTab('in')" data-val="in">En Local</div>
                            <div class="item" :class="selectedTab == 'out' ? 'selected' : ''" @click="selectTab('out')" data-val="out">Fuera</div>
                        </div>
                        <p v-show="showingError" class="message-error" v-html="messageError"></p>

                        <ul v-if="Object.keys(list).length > 0" class="list users-list hide-scrollbar">
                            <li
                                v-for="item in list"
                                :key="itme.id"
                                class="item user"
                                :class="[item.clockClass ? item.clockClass : '', selectedEl == item.id ? 'selected' : '']"
                                v-bind:data-id="item.id"
                                v-if="
                                    selectedTab == 'all' ||
                                    (item.clockClass == 'clocked-in' && selectedTab == 'in') ||
                                    (item.clockClass == 'clocked-out' && selectedTab == 'out')
                                "
                                @click="selectMe(item.id)"
                            >
                                <span class="user-color"><img class="main-img" v-bind:src="item.avatar" /></span>
                                <span class="user-name">{{ item.name }} {{ list.surname }}</span>
                            </li>
                        </ul>
                        <p v-if="Object.keys(list).length == 0" class="empty" v-html="listEmpty"></p>
                    </div>

                    <div class="right hide-scrollbar" :class="{ disabled: selectedEl == undefined }">
                        <Numpad @sendValue="changeValue" :pinType="'password'" :givenValue="inputVal" :disabled="disNumpad"></Numpad>
                    </div>
                </div>

                <!-- labelPreview -->
                <div class="kind-of" v-else-if="type == 'labelPreview'">
                    <Preview :data="data" :maximized="true"></Preview>
                </div>

                <!-- login, access -->
                <div class="kind-of" v-else-if="type == 'login' || type == 'access'">
                    <div class="left hide-scrollbar">
                        <p v-show="message" class="message-info" v-html="message"></p>
                        <p v-show="showingError" class="message-error" v-html="messageError"></p>

                        <ul v-if="Object.keys(list).length > 0" class="list users-list hide-scrollbar">
                            <li
                                v-for="item in list"
                                class="item user"
                                :class="{ selected: selectedEl == item.id }"
                                v-bind:data-id="item.id"
                                @click="selectMe(item.id)"
                            >
                                <span class="user-icon" v-bind:style="{ backgroundImage: item.image }"></span>
                                <span class="user-name">{{ item.name }}</span>
                            </li>
                        </ul>
                        <p v-if="Object.keys(list).length == 0" class="empty" v-html="listEmpty"></p>
                    </div>

                    <div class="right hide-scrollbar" :class="{ disabled: selectedEl == undefined }">
                        <!-- <PopupNumpad @sendValue="changeValue" v-bind:pinType="'password'" v-bind:givenValue="inputVal" v-bind:disabled="disNumpad"></PopupNumpad> -->
                        <Numpad @sendValue="changeValue" v-bind:pinType="'password'" v-bind:givenValue="inputVal" v-bind:disabled="disNumpad"></Numpad>
                    </div>
                </div>

                <div v-else-if="type == 'invitationUser'">
                    <PopupInvitationUser :inputVal="inputVal" :description="message" />
                </div>

                <PopupPDFDisplayOptions v-else-if="type == 'PDFDisplayOptions'" :quantity="$data.config.quantity" :tool="$data.config.tool" />

                <div class="galery-icon-container-main" v-else-if="type == 'galeryIcons'">
                    <PopupGaleryIcons :data="data" @changeImg="changeImg" :imageSelected="image" :productName="productName"></PopupGaleryIcons>
                </div>
                <div class="galery-templates-container-main" v-else-if="type == 'galeryTemplates'">
                    <PopupGaleryTemplates :data="data" @selectTemplate="selectTemplate"></PopupGaleryTemplates>
                </div>

                <div class="full hide-scrollbar" v-else-if="type == 'siteSave'">
                    <PopupSiteSave
                        :sendEmails="$data.sendEmails"
                        :alertMsg="$data.alertMsg"
                        :locationName="$data.locationName"
                        :locationKey="$data.locationKey"
                        :locationId="$data.locationId"
                        @sendEmails="updateEmails($event)"
                        :description="message"
                    ></PopupSiteSave>
                </div>
                <div class="release-container hide-scrollbar" v-else-if="type == 'release'">
                    <PopupRelease :image="image" :data="data"></PopupRelease>
                </div>
                <div class="media-container hide-scrollbar" v-else-if="type == 'media-onboarding'">
                    <PopupMedia :data="data" :title="title" :message="message"></PopupMedia>
                </div>

                <!-- Detalle de libreria -->
                <LibraryDetail class="kind-of" v-else-if="type == 'library'" :file="file"></LibraryDetail>

                <!-- Seleccionable fecha -->
                <div class="kind-of" v-else-if="type == 'date'">
                    <!-- :allowed-dates="allowedDates" -->

                    <functional-calendar
                        v-model="inputVal"
                        :dayNames="getStringObject('calendar.day_names')"
                        :monthNames="getStringObject('calendar.months')"
                        :shortMonthNames="getStringObject('calendar.months')"
                        :isDatePicker="true"
                        :change-month-function="true"
                        :date-format="'yyyy/mm/dd'"
                        :change-year-function="true"
                        :limits="{ min: '', max: '' }"
                    ></functional-calendar>
                    <!-- <v-date-picker
                        v-model="inputVal"
                        :locale="aLocale"
                        landscape
                        reactive
                        :min="dateStart"
                        :max="dateEnd"
                        first-day-of-week="1"
                    ></v-date-picker> -->
                </div>

                <!-- Seleccionable fecha RANGO -->
                <div class="kind-of hide-scrollbar" v-else-if="type == 'dateRange'">
                    <!-- :allowed-dates="allowedDates" -->

                    <!-- :is-multiple="true"
                                    :calendars-count="2" -->
                    <functional-calendar
                        v-model="dateRange"
                        :dayNames="getStringObject('calendar.day_names')"
                        :monthNames="getStringObject('calendar.months')"
                        :shortMonthNames="getStringObject('calendar.months')"
                        :isDatePicker="true"
                        :key="updatedDayCalendarTwo"
                        @input="dayClickedCalendarOne"
                        :change-month-function="true"
                        :date-format="'yyyy/mm/dd'"
                        :change-year-function="true"
                        :limits="{ min: '', max: dateRangeTwo.selectedDate ? dateRangeTwo.selectedDate : dateEnd }"
                    ></functional-calendar>
                    <!-- :limits="{min: '', max: dateRangeTwo.selectedDate ? dateRangeTwo.selectedDate : dateEnd}" -->

                    <!-- <v-date-picker
                        v-model="dateRange[0]"
                        :locale="aLocale"
                        reactive
                        :min="dateStart"
                        :max="dateEnd"
                        first-day-of-week="1"
                    ></v-date-picker> -->

                    <functional-calendar
                        :key="updatedDayCalendarOne"
                        v-model="dateRangeTwo"
                        :dayNames="getStringObject('calendar.day_names')"
                        :monthNames="getStringObject('calendar.months')"
                        :shortMonthNames="getStringObject('calendar.months')"
                        :isDatePicker="true"
                        :change-month-function="true"
                        :change-year-function="true"
                        :date-format="'yyyy/mm/dd'"
                        :limits="{ min: dateRangeFirstCalendar.selectedDate ? dateRangeFirstCalendar.selectedDate : '', max: dateEnd }"
                    ></functional-calendar>
                    <!-- @input="dayClickedCalendarTwo" -->

                    <!-- <v-date-picker
                        v-model="dateRange[1]"
                        :locale="aLocale"
                        reactive
                        :min="dateRange[0]"
                        :max="dateEnd"
                        first-day-of-week="1"
                    ></v-date-picker> -->

                    <!-- <div class="fcalendar-container">
                  <functional-calendar
                    v-model="dateRange[0]"
                    @input="setRangeResult(dateRange[0])"
                    :is-multiple="true"
                    :is-date-range="true"
                    :calendars-count="2"
                    :isDatePicker="true"
                    :change-month-function="true"
                    :change-year-function="true"
                    :markedDates="[{date: dateEnd, class: 'expiration'},{date: dateRange[0], class: 'vfc-marked'}]"
                    :limits="{min: dateStart, max: ''}"
                  ></functional-calendar>
                </div> -->
                </div>

                <!-- Input -->
                <div class="kind-of" v-else-if="type == 'input'">
                    <p v-show="showingError" class="message-error" v-html="messageError"></p>
                    <input type="text" v-model="inputVal" required />
                </div>

                <!-- Signature (SIGN)-->
                <div class="kind-of" v-else-if="type == 'sign'">
                    <div class="left hide-scrollbar">
                        <p v-show="message" class="message-info" v-html="message"></p>
                        <p v-show="showingError" class="message-error" v-html="messageError"></p>

                        <ul v-if="Object.keys(list).length > 0" class="list users-list hide-scrollbar">
                            <!-- TODO REVIEW EL IF -->
                            <li
                                v-for="item in list"
                                class="item user"
                                :class="{ selected: selectedEl == item.id }"
                                v-bind:data-id="item.id"
                                @click="selectMe(item.id)"
                            >
                                <span class="user-color"><img class="main-img" v-bind:src="item.avatar" /></span>
                                <span class="user-name">{{ item.name }} {{ item.surname }}</span>
                            </li>
                        </ul>
                        <p v-if="Object.keys(list).length == 0" class="empty" v-html="listEmpty"></p>
                    </div>
                    <div class="right hide-scrollbar" :class="{ disabled: selectedEl == undefined }">
                        <div class="toolbar">
                            <div flat class="btn undo" @click="signUndo">
                                <div class="andy-icon undo"></div>
                                {{ getString('popup.sign.text_undo') }}
                            </div>
                            <div flat class="btn clear" @click="signClear">
                                <div class="andy-icon cancel"></div>
                                {{ getString('popup.sign.text_clear') }}
                            </div>
                        </div>
                        <vueSignature
                            class="signature"
                            ref="ref_sign"
                            :sigOption="signOptions"
                            :data-msg="disabledMessage"
                            :w="['portrait'].includes($mq) ? '300px' : '600px'"
                            :h="'300px'"
                        ></vueSignature>
                    </div>
                </div>

                <!-- Free Signature -->
                <div class="kind-of" v-else-if="type == 'freeSign'">
                    <div class="toolbar">
                        <div flat class="btn undo" @click="signUndo">
                            <div class="andy-icon undo"></div>
                            {{ getString('popup.sign.text_undo') }}
                        </div>
                        <div flat class="btn clear" @click="signClear">
                            <div class="andy-icon cancel"></div>
                            {{ getString('popup.sign.text_clear') }}
                        </div>
                    </div>
                    <vueSignature
                        class="signature"
                        ref="ref_sign"
                        :sigOption="signOptions"
                        :data-msg="disabledMessage"
                        :w="['portrait'].includes($mq) ? '300px' : '600px'"
                        :h="'300px'"
                    ></vueSignature>
                </div>
                <!-- Proceso -->
                <div class="kind-of" v-else-if="type == 'process'">
                    <div class="product-info">
                        <span class="product-title">{{ productName }}</span>
                    </div>

                    <div class="resume hide-scrollbar">
                        <div
                            v-for="(pType, i) in processTypes"
                            @click="changeStep(i)"
                            class="resume-step"
                            :class="[data[i].result ? 'done' : 'undone', { current: currentStep == i }]"
                        >
                            <h6 class="title">{{ data[i].title }}</h6>
                            <span class="subtitle">{{ data[i].result ? data[i].result : '' }}</span>
                        </div>
                    </div>

                    <div class="content">
                        <div
                            v-for="(pType, i) in processTypes"
                            class="step hide-scrollbar"
                            :class="[data[i].result ? 'done' : 'undone', { current: currentStep == i }]"
                        >
                            <label v-if="typeof data[i].message != 'undefined'" class="step-message" v-html="data[i].message"></label>

                            <div v-if="pType == 'state'" class="tags" :class="'p-' + pType">
                                <span
                                    v-for="tag in data[i].tags"
                                    class="tag"
                                    :key="tag.id"
                                    :class="{ selected: data[i].result == tag.name }"
                                    @click="setProcessResult(pType, i, tag)"
                                >
                                    {{ tag.name }}
                                </span>
                            </div>

                            <div v-else-if="pType == 'date'" class="fcalendar-container" :class="'p-' + pType">
                                <functional-calendar
                                    v-model="data[i].input"
                                    @input="setProcessResult(pType, i, data[i].input)"
                                    :is-multiple="true"
                                    :calendars-count="2"
                                    :isDatePicker="true"
                                    :change-month-function="true"
                                    :change-year-function="true"
                                    :markedDates="[
                                        { date: data[i].maxDate, class: 'expiration' },
                                        { date: data[i].result, class: 'vfc-marked' }
                                    ]"
                                    :limits="{ min: data[i].minDate, max: '' }"
                                ></functional-calendar>
                            </div>

                            <div v-else-if="pType == 'datetime'" class="fcalendar-container" :class="'p-' + pType">
                                <div class="calendar">
                                    <functional-calendar
                                        v-model="data[i].date"
                                        @input="setProcessResult(pType, i, data[i].date)"
                                        :is-multiple="true"
                                        :calendars-count="1"
                                        :isDatePicker="true"
                                        :change-month-function="true"
                                        :change-year-function="true"
                                        :markedDates="[
                                            { date: data[i].maxDate, class: 'expiration' },
                                            { date: data[i].result, class: 'vfc-marked' }
                                        ]"
                                        :limits="{ min: data[i].minDate, max: '' }"
                                    ></functional-calendar>
                                </div>
                                <div class="time">
                                    <span class="message">{{ getString('label.popup.process.pick_time') }}</span>
                                    <input type="time" v-model="data[i].time" @input="setProcessResult(pType, i, data[i].time)" />
                                </div>
                            </div>

                            <div v-else-if="pType == 'string'" class="input-text" :class="'p-' + pType">
                                <input
                                    type="text"
                                    v-model="data[i].result"
                                    v-focus="currentStep == i"
                                    required
                                    @change="setProcessResult(pType, i, data[i].result)"
                                />
                            </div>

                            <div v-else-if="pType == 'number'" class="input-number" :class="'p-' + pType">
                                <Numpad
                                    @sendValue="setProcessValue(pType, i, $event)"
                                    :givenValue="data[i].result"
                                    :max="data[i].max ? data[i].max : null"
                                ></Numpad>
                            </div>

                            <div v-else-if="pType == 'quantity'" class="input-number" :class="'p-' + pType">
                                <Numpad
                                    @sendValue="setProcessValue(pType, i, $event)"
                                    :givenValue="data[i].result"
                                    :max="data[i].max ? data[i].max : null"
                                ></Numpad>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Postponer (en tareas) -->
                <div class="kind-of" v-else-if="type === 'postpone'">
                    <div v-if="!showingError" class="postpone-info">
                        <p class="title-date">
                            <!-- {{ data.selectedDate.selectedDate }} -->
                            {{
                                data.selectedDate === postponeTomorrow
                                    ? getString('answer.postpone.pretext_tomorrow')
                                    : getStringVariable('answer.postpone.pretext', momentFormat(data.selectedDate.selectedDate))
                            }}
                        </p>

                        <div class="step-date">
                            <functional-calendar
                                v-model="data.selectedDate"
                                :dayNames="getStringObject('calendar.day_names')"
                                :monthNames="getStringObject('calendar.months')"
                                :shortMonthNames="getStringObject('calendar.months')"
                                :isDatePicker="true"
                                :change-month-function="true"
                                :date-format="'yyyy-mm-dd'"
                                :change-year-function="true"
                                :limits="{ min: dateStart, max: dateEnd }"
                            ></functional-calendar>
                        </div>

                        <div class="step">
                            <p class="title">
                                <span v-if="data.showErrorReason" class="error">{{ data.errorReason }}</span>
                                {{ getString('answer.postpone.what_reason') }}
                            </p>
                            <ul class="reasons">
                                <li
                                    class="item"
                                    :class="{ selected: selected === item.id }"
                                    v-for="item in list"
                                    @click="
                                        selected = item.id
                                        data.showReason = item.id == '0' ? true : false
                                    "
                                >
                                    {{ item.name }}
                                </li>
                            </ul>
                            <p v-if="data.showReason" class="title">{{ getString('answer.postpone.explain_reason') }}</p>
                            <textarea v-if="data.showReason" class="reason_message" v-model="message"></textarea>
                        </div>
                    </div>
                    <div v-else class="postpone-error empty">{{ messageError }}</div>
                </div>
                <div v-else-if="type == 'alertNewDesing'">
                    <p class="message" v-html="message"></p>
                </div>

                <!-- basico, confirm, confirmUpload, alert, delete, -->
                <!-- Alerta de Incidencia (en tareas) -->
                <div class="kind-of" v-else-if="type == 'issueAlert'">
                    <!-- <p class="alert-title">{{ getString('popup.issue_alert.alert_message') }}</p> -->
                    <div class="message" v-html="message"></div>
                    <div class="suggest" v-if="selectedEl && !selected">
                        <p class="issue-title">{{ getString('popup.issue_alert.suggested_issue') }}</p>
                        <select class="issues" v-model.lazy="selectedEl" disabled>
                            <option v-for="(value, index) in list" :value="value.id" :key="index" :class="{ selected: value.id == selectedEl }">
                                {{ value.name }}
                            </option>
                        </select>
                    </div>
                    <div class="suggest" v-else-if="!selectedEl && !selected">
                        <p class="issue-title">{{ getString('popup.issue_alert.suggested_new') }}</p>
                    </div>
                </div>

                <div class="kind-of" v-else-if="type == 'messageWithButton'">
                    <p class="message" v-html="message"></p>
                </div>

                <!--Temporal templates-->
                <div class="temporal-container hide-scrollbar" v-else-if="type == 'tplTemporary'">
                    <PopupTemplateTemp />
                </div>

                <div class="delete-item" v-else-if="type == 'deleteItem'">
                    <PopupDelete :items="$data.items" @enableDelete="deleteItem()" />
                </div>

                <div v-else-if="type === 'exportLoading'" class="kind-of">
                    <p class="message">{{ getString('supervise.reports.export.popup_loading.message').split('|')[0] }}</p>
                    <p class="message">{{ getString('supervise.reports.export.popup_loading.message').split('|')[1] }}</p>
                </div>

                <div class="kind-of" v-else>
                    <p class="message" v-html="message"></p>

                    <PopupRequireTyping
                        v-if="type === 'confirm-delete' && requireTyping"
                        @required-word-typed="updateRequiredWord"
                        :showErrorMessage="showErrorMessage"
                    />
                </div>

                <div class="url-container" v-if="$data.config && $data.config.confirmType && $data.config.confirmType === 'share-url'">
                    <button class="url" @click="copyURL">{{ currentURL }}</button>
                </div>
            </div>
            <div class="pop-footer" v-if="type != 'resource' && type != 'exportLoading'">
                <div v-if="type === 'deleteItem'" class="buttons">
                    <button @click="cancel" class="act cancel" v-html="textCancel" v-if="type != 'alert'"></button>
                    <button @click="save" v-if="type != 'media'" class="act save" :disabled="cantDelete" v-html="textSave"></button>
                </div>
                <div v-if="type === 'media-onboarding'" class="buttons media">
                    <button @click="cancel" class="act cancelMedia" v-html="textCancel"></button>
                </div>

                <div v-else-if="type === 'gallery'" class="gallery">
                    <span v-if="startIndex > 0" class="backward" @click="thumbGoBackward"></span>
                    <div v-for="img in visibleImages" class="gallery-image-container" @click="selectImage(img.value)">
                        <cld-image v-if="img.value" class="thumbnail" :publicId="img.value" type="fetch" loading="lazy">
                            <cld-transformation crop="fill" fetchFormat="webp" />
                        </cld-image>
                    </div>
                    <span v-if="endIndex < allImages.length - 1" class="forward" @click="thumbGoForward"></span>
                </div>

                <div v-else-if="type === 'media'" class="buttons">
                    <button @click="cancel('button')" class="act cancel">{{ getString('menu_aux.action.cancel') }}</button>
                    <!-- <button @click="imgEdit" class="act save">{{ getString('menu_aux.action.edit') }}</button> -->
                    <button @click="imgDelete" class="act save">{{ getString('menu_aux.action.delete') }}</button>
                </div>

                <div class="buttons labelPreview" v-else-if="type === 'labelPreview'">
                    <button @click="cancel" class="act cancelPreview" v-html="textCancel"></button>
                </div>
                <div v-else-if="type === 'previewSignature'" class="buttons">
                    <button @click="cancel('button')" class="act cancel">{{ getString('popup.media.text_cancel') }}</button>
                </div>
                <!-- TO-DO: TRADUCIR -->
                <div v-else-if="type === 'messageWithButton'" class="buttons">
                    <button @click="cancel('button')" class="act cancel">{{ getString('supervise.reports.export.popup_email.button') }}</button>
                </div>
                <div class="buttons" v-else-if="type === 'selectorsHistory'">
                    <button @click="cancel" class="act cancel" v-html="textCancel"></button>
                    <button @click="save" class="act save" v-html="textSave"></button>
                </div>

                <div class="buttons" v-else-if="(type != 'galeryTemplates') & (type != 'deleteItem')">
                    <button @click="cancel" class="act cancel" v-html="textCancel" v-if="type != 'alert'"></button>
                    <button @click="save" v-if="type != 'media'" class="act save" v-html="textSave"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import i18n from '@/i18n'
import draggable from 'vuedraggable'
import store from '@/store'
import vueZoomer from 'vue-zoomer'
import PopupInvitationUser from '@/plugins/popup/PopupInvitationUser'
import PopupGaleryIcons from '@/plugins/popup/PopupGaleryIcons'
import PopupGaleryTemplates from '@/plugins/popup/PopupGaleryTemplates'
import PopupSiteSave from '@/plugins/popup/PopupSiteSave'
import PopupRelease from '@/plugins/popup/PopupRelease'
import PopupMedia from '@/plugins/popup/PopupMedia.vue'
import Numpad from '@/components/input/Numpad'
import PopTemperature from '@/plugins/popup/PopTemperature'
import PopupDateTime from '@/plugins/popup/PopupDateTime'
import PopupRequireTyping from '@/plugins/popup/PopupRequireTyping'
import Preview from '@/components/domain/label/preview'
import vueSignature from 'vue-signature'
import PopupPDFDisplayOptions from './PopupPDFDisplayOptions.vue'
import PopupTemplateTemp from '@/plugins/popup/PopupTemplateTemp'
import PopupDelete from '@/plugins/popup/PopupDelete.vue'
import PopupSelectorHistory from '@/plugins/popup/PopupSelectorHistory.vue'
import PopupTemplateConvertTo from '@/plugins/popup/PopupTemplateConvertTo.vue'
Vue.use(vueSignature)

export default {
    components: {
        Numpad,
        PopTemperature,
        PopupDateTime,
        PopupInvitationUser,
        PopupSiteSave,
        PopupGaleryIcons,
        PopupGaleryTemplates,
        PopupRelease,
        PopupMedia,
        PopupRequireTyping,
        Preview,
        draggable,
        PopupPDFDisplayOptions,
        PopupTemplateTemp,
        PopupDelete,
        PopupSelectorHistory,
        PopupTemplateConvertTo
    },
    props: {
        description: {
            type: Object
        }
    },
    data() {
        return {
            show: false,
            cantDelete: true,
            closable: true,
            blocking: true,
            type: '',
            customClass: '',
            title: '',
            message: '',
            messageError: '',
            showingError: false,
            list: {},
            listSec: {},
            imageUrl: '',
            allImages: [],
            descri: {},
            startIndex: 0,
            endIndex: 8,
            selectedImage: null,
            completeDate: '',
            listEmpty: '',
            textLogout: '',
            textCancel: '',
            textCancel2: '',
            textSave: '',
            textSaveStart: '',
            textNext: '',
            textPrev: '',
            inputVal: '',
            help: '',
            tools: {},
            tool: '',
            selectorHistory: [],
            productName: '',
            image: false,
            file: false,
            selected: false,
            selectedSec: false,
            selectedEl: undefined,
            selectedSecEl: undefined,
            selectedTab: '',
            selectedSecTab: '',
            disabledMessage: '',
            disNumpad: false,
            dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            dateRangeTwo: {},
            dateStart: '',
            dateEnd: '',
            emails: [],
            processTypes: [],
            currentStep: 0,
            data: [],
            cType: false,
            signOptions: {
                penColor: '',
                backgroundColor: '',
                format: '',
                watermark: ''
            },
            tempOptions: {
                maxRange: null,
                minRange: null,
                maxNum: 50,
                minNum: -50,
                stepNum: 1,
                numDec: 1,
                stepDec: 1,
                measure: 'ºC'
            },
            routePath: false,
            requireTyping: false,
            requiredWord: '',
            showErrorMessage: false,
            updatedDayCalendarOne: 0,
            updatedDayCalendarTwo: 0,
            cssImage: css_image_url,
            callLogout: function () {},
            callCancel: function () {},
            callCancel2: function () {},
            callSave: function () {},
            callDelete: function () {},
            callSaveStart: function () {},
            callRemoveAll: function () {},
            callAfterAccess: function () {},

            // state to save Supervise tables columns header order:
            columnHeaders: null,
            disabledAlexHead: ['invitationUser', 'media', 'library', 'resource'],

            currentURL: window.location.href
        }
    },
    computed: {
        aLocale() {
            return store.getters['getLocale']
        },
        postponeTomorrow() {
            return moment(this.dateStart).format('YYYY-MM-DD')
        },
        dateRangeFirstCalendar() {
            return this.dateRange
        },
        visibleImages() {
            return this.allImages.slice(this.startIndex, this.endIndex + 1)
        }
    },
    methods: {
        deleteItem() {
            return (this.cantDelete = false)
        },
        dateParse(ref) {
            return moment(ref * 1000).format('HH:mm, DD MMM YYYY')
        },
        descriptionParse(msg) {
            var result = msg
            if (result) {
                result = result.replace(/\n/gi, ' <br> ')
                result = result.replace(/([^\S]|^)(((https?\:\/\/)|(http?\:\/\/)|(www\.))(\S+))/gi, function (match, space, url) {
                    var hyperlink = url.startsWith('www') ? 'https://' + url : url
                    return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>'
                })
                return result
            }
            return '-'
        },
        thumbGoBackward() {
            if (this.startIndex > 0) {
                this.startIndex -= 9
                this.endIndex = this.startIndex + 8
            }
        },
        thumbGoForward() {
            if (this.endIndex < this.allImages.length - 1) {
                this.startIndex = this.endIndex + 1
                this.endIndex = Math.min(this.startIndex + 8, this.allImages.length - 1)
            }
        },
        goBackward() {
            this.navigateImage(-1)
        },

        goForward() {
            this.navigateImage(1)
        },

        navigateImage(direction) {
            const allImagesUrl = this.allImages.map((image) => image.value)
            const currentIndex = allImagesUrl.findIndex((img) => img === this.imageUrl)

            let targetIndex
            if (direction === -1 && currentIndex > 0) {
                targetIndex = currentIndex - 1
            } else if (direction === 1 && currentIndex < allImagesUrl.length - 1) {
                targetIndex = currentIndex + 1
            } else {
                // Loop back to the first/last image
                targetIndex = direction === -1 ? allImagesUrl.length - 1 : 0
            }

            this.imageUrl = this.allImages[targetIndex].value
            this.descri = {
                title: this.allImages[targetIndex].title,
                message: this.allImages[targetIndex].message || '',
                employee: this.allImages[targetIndex].employee,
                complete_date: this.allImages[targetIndex].complete_date
            }
        },
        selectImage(imgUrl) {
            this.imageUrl = imgUrl
            const selectedObject = this.allImages.find((obj) => obj.value === imgUrl)
            this.descri = {
                title: selectedObject.title,
                message: selectedObject.message || '',
                employee: selectedObject.employee,
                complete_date: selectedObject.complete_date
            }
        },

        updateDescription(index) {
            const self = this
            const correspondingDescription = this.allImages.find((obj) => obj.value === image)
            if (correspondingDescription) {
                self.descri = self.descri || {}
                self.descri.title = correspondingDescription.title
                self.descri.message = correspondingDescription.message || ''
                self.descri.employee = correspondingDescription.employee
                self.descri.complete_date = this.dateParse(correspondingDescription.completeDate)
            }
        },

        checkHeader(header) {
            const visibleHeadersCount = this.$data.headers.reduce((count, h) => count + (h.visible ? 1 : 0), 0)

            if (visibleHeadersCount <= 1 && header.visible) {
                return
            } else {
                header.visible = !header.visible
            }

            this.$forceUpdate()
        },
        updateEmails(emails) {
            this.emails = emails
        },
        redirectPath(firstResult) {
            var visible = []
            var tools = store.getters['getTools']
            var result = firstResult

            if (localStorage.config) {
                var config = JSON.parse(localStorage.config)
                if (config.tools) {
                    visible = config.tools
                }
            }
            if (visible.length == 1) {
                result = tools[visible[0]].href
            }

            return result
        },

        selectMe(id) {
            this.selected = id
            this.changeValue('')
            if (this.selectedEl == id) {
                this.disNumpad = true
                this.selectedEl = undefined
            } else {
                this.disNumpad = false
                this.selectedEl = id
            }
        },
        selectMeSec(id) {
            this.selectedSec = id
            this.changeValue('')
            if (this.selectedSecEl == id) {
                this.disNumpad = true
                this.selectedSecEl = undefined
            } else {
                this.disNumpad = false
                this.selectedSecEl = id
            }
        },
        selectTab(id) {
            this.selectedTab = id
            this.selected = undefined
            this.selectedEl = undefined
            this.disNumpad = true
            this.changeValue('')
            this.showingError = false
        },

        changeValue(newVal) {
            this.inputVal = newVal
        },
        momentFormat(date) {
            return moment(date, 'YYYY-MM-DD').format('dddd D')
        },
        dayClickedCalendarOne(e) {
            this.updatedDayCalendarOne++
        },
        dayClickedCalendarTwo(e) {
            this.updatedDayCalendarTwo++
        },
        getString(str) {
            return i18n.t(str)
        },
        getStringObject(str, obj) {
            return i18n.t(str, obj)
        },
        getStringPlural(str, pos) {
            return i18n.tc(str, pos)
        },
        getStringVariable(str, pos) {
            return i18n.t(str, { s: pos })
        },
        cancel() {
            if (this.closable) {
                this.callCancel(this, store)
                this.$popup.close()
            }
        },
        cancel2(item) {
            this.callCancel2(this, store, item)
        },
        save() {
            if (this.type == 'sign' || this.type == 'freeSign') {
                this.signSave()
            } else if (this.type == 'process') {
                var finish = []
                for (var k in this.data) {
                    var d = this.data[k]
                    if (d.optional || (d.result && d.result != null && d.result != '')) {
                        finish.push('true')
                    } else {
                        finish.push('false')
                    }
                }
                if (finish.indexOf('false') == -1) {
                    this.callSave(this, store)
                }
            } else if (this.type == 'tableColumn') {
                // save Supervise table columns order
                this.$data.headers = this.columnHeaders

                this.callSave(this, store)
            } else {
                this.callSave(this, store)
            }
        },
        changeImg(img) {
            this.image = img
        },
        selectTemplate(template) {
            this.callSave(template)
        },
        saveStart() {
            this.callSaveStart(this, store)

            this.$popup.close()
            this.$overlay.loading({
                blocking: true
            })
        },
        // Funciones propias de SIGN
        signSave() {
            if (!this.$refs.ref_sign.isEmpty()) {
                if (typeof this.list[this.selectedEl] !== 'undefined') {
                    this.signAddWaterMark()
                }

                if (this.signOptions.format == 'jpg') {
                    this.inputVal = this.$refs.ref_sign.save('image/jpeg')
                } else if (this.signOptions.format == 'svg') {
                    this.inputVal = this.$refs.ref_sign.save('image/svg+xml')
                } else if (this.signOptions.format == 'png') {
                    this.inputVal = this.$refs.ref_sign.save()
                } else {
                    // Guarda en PNG
                    this.inputVal = this.$refs.ref_sign.save()
                }
            } else {
                this.inputVal = ''
            }
            this.callSave(this, store)
        },
        signClear() {
            this.$refs.ref_sign.clear()
        },
        signUndo() {
            this.$refs.ref_sign.undo()
        },
        signAddWaterMark() {
            this.$refs.ref_sign.addWaterMark({
                text: i18n.t('popup.sign.watermark') + this.list[this.selectedEl].name,
                font: '16px Arial',
                x: 10,
                y: 180
                // -- All properties
                // text:"mark text",          // watermark text, > default ''
                // font:"20px Arial",         // mark font, > default '20px sans-serif'
                // style:'all',               // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
                // fillStyle:"#333333",           // fillcolor, > default '#333'
                // strokeStyle:"blue",	       // strokecolor, > default '#333'
                // x:100,                     // fill positionX, > default 20
                // y:200,                     // fill positionY, > default 20
                // sx:100,                    // stroke positionX, > default 40
                // sy:200,                     // stroke positionY, > default 40
            })
        },
        signFromDataURL(url) {
            this.$refs.ref_sign.fromDataURL('data:image/png;base64,iVBORw0K...')
        },

        logout() {
            this.callLogout(this, store)
        },
        updateRequiredWord(inputValue) {
            this.requiredWord = inputValue
        },
        imgDelete() {
            this.callDelete(this, store)
        },

        async copyURL() {
            try {
                // const text = this.$data.config && this.$data.config.url ? this.$data.config.url : ''
                let text = window.location.href
                await navigator.clipboard.writeText(text)

                this.$snackbar.close()
                this.$popup.close()

                this.$snackbar.open({
                    message: this.getString('snackbar.copy_share_link'),
                    color: 'success',
                    position: 'top',
                    duration: 2500
                })
            } catch (error) {
                console.error('Error al copiar el link: ', error)
            }
        }
    },
    watch: {},
    created() {
        // console.warn('CALENDAR:' + this.dateStart + ' - ' + this.dateEnd)
        // KEYBOARD ACTIONS HANDLER
        const escapeHandler = (e) => {
            if (e.key === 'Escape' && this.show) {
                this.cancel()
            }
        }
        const enterHandler = (e) => {
            if (e.key === 'Enter' && this.show) {
                this.save()
            }
        }

        document.addEventListener('keydown', escapeHandler)
        document.addEventListener('keydown', enterHandler)

        this.$once('hook:destroyed', () => {
            document.removeEventListener('keydown', escapeHandler)
            document.removeEventListener('keydown', enterHandler)
        })
    },
    mounted() {
        if (this.type == 'process') {
            this.generateProcess()
        }

        if (this.type == 'postpone') {
            if (Object.values(this.list).length == 1) {
                this.selected = '0'
                this.data.showReason = true
            }
            // AUTO SELECT DATE FIRST TIME
            this.$nextTick(function () {
                var aDays = document.querySelectorAll('.vfc-day :not( .vfc-disabled ) ')
                var selectedDay =
                    this.data && this.data.selectedDate && this.data.selectedDate.selectedDate ? this.data.selectedDate.selectedDate.split('-')[2] : false

                if (selectedDay) {
                    for (var day in aDays) {
                        if (aDays[day].innerText == selectedDay) {
                            aDays[day].click()
                            break
                        }
                    }
                }
            })
        }

        if (this.type == 'select' && this.list && Object.keys(this.list).length > 0) {
            this.selectMe(this.list[Object.keys(this.list)[0]].id)
        }
        this.columnHeaders = this.$data.headers
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.timer-delete-popup .pop-body .message b {
    margin-left: 4px;
    @include font-size(ml);
}

.popup-container {
    @include display-flex();
    @include align-items();
    @include justify-content();
    background-color: rgba($color-primary-800, 0.9);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: $z-popups-unblocking;
    max-width: calc(100%);
    max-height: calc(100%);
    width: calc(100%);
    height: calc(100%);
    // top: $menu-top-height;
    // z-index: $z-popups-default;
    // max-width: calc(100% - #{$menu-tools-min});
    // max-height: calc(100% - #{$menu-top-height});
    // width: calc(100% - #{$menu-tools-min});
    // height: calc(100% - #{$menu-top-height});

    @media (max-width: 900px) {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        align-items: center;
        width: 100%;
        max-width: 100%;
        padding: 8px;
        height: 100vh;
        max-height: 100vh;
        justify-content: center !important;
    }

    &.blocking {
        z-index: $z-overlay-blocking + 2;
    }
    &:not(.blocking) {
        top: $menu-top-height;
        max-height: calc(100% - #{$menu-top-height});
    }

    &.convert-to {
        .confirm {
            border: none;
            border-top: 8px solid $color-primary-700;
            position: relative;

            .pop-header {
                @include display-flex();
                @include justify-content();
                @include align-items();
                flex-direction: column;
                p {
                    margin-top: 0;
                }
            }

            .pop-body {
                padding-bottom: 0;
                .kind-of {
                    p {
                        text-align: center;
                        b {
                            font-family: $text-bold;
                            @include font-size(18px);
                        }
                    }
                }
            }

            .pop-footer {
                .buttons {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                }
            }
        }
    }

    .confirm-delete {
        border-top: solid #bd1f1e 7px;
        .pop-alex.normal {
            display: none;
        }

        .pop-header {
            justify-content: center;
        }
        .pop-header .labelPreview {
            color: $color-white;
        }
        .pop-body .kind-of {
            display: flex;
            flex-direction: column !important;
            align-items: center !important;
            gap: 1em;
            margin: 16px;
        }

        .pop-body .message {
            display: flex;
            justify-content: center;
            text-align: center;
            padding-top: 0.5em;
            font-size: 18px;
        }

        .pop-footer .buttons {
            display: flex;
            justify-content: center;
            gap: 2.5em;

            .act {
                margin: 0;
            }

            .save {
                background-color: #bd1f1e !important;
                background-image: img('delete_ffffff.svg');
                background-size: 14.6px 16px;
                background-position: 10%;
                padding-left: 1.9em;
            }
        }
    }

    .popup {
        @include display-flex();
        @include align-items();
        @include justify-content();
        @include flex-direction($dir: column);
        @include border-radius(3px);
        position: relative;
        background-color: $color-white;
        max-width: 90%;
        max-height: 90%;
        width: auto;
        height: auto;
        padding: 30px;

        .pop-body {
            width: 100% !important;
            max-width: 100% !important;
        }

        &.confirm {
            border-top: 7px solid $color-primary-700;
            padding: 26px 30px 36px 30px;
            max-width: 700px;

            .pop-header {
                justify-content: center;
                padding-bottom: 8px;

                .title-message {
                    margin-top: 0px;
                    font-size: 24px;
                }
            }

            .pop-body {
                margin-bottom: 16px;
                flex-direction: column;
                align-items: center;
            }

            .kind-of {
                .message {
                    text-align: center;
                    font-size: 18px;
                }
            }

            .pop-alex {
                display: none;
            }

            .pop-footer {
                .buttons {
                    display: flex;
                    justify-content: center;
                    gap: 16px;
                    width: 100%;

                    button {
                        padding-left: 16px;
                        padding-right: 16px;
                        margin: 0px !important;
                        min-width: 30%;
                        max-width: fit-content;
                        font-size: 18px;
                    }
                }
            }

            @media (max-width: 767px) {
                width: 100%;
                max-width: 100% !important;
            }
        }
        &.templateConvertTo {
            border: none;
            border-top: 8px solid $color-primary-700;
            position: relative;

            .pop-header {
                @include display-flex();
                @include justify-content();
                @include align-items();
                flex-direction: column;

                .title {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    gap: 8px;
                    .title-message {
                        margin-top: 0;
                    }
                }
            }

            .pop-footer {
                .buttons {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                }
            }
        }
        &.selectorsHistory {
            border: none;
            border-top: 8px solid $color-primary-700;
            position: relative;

            .pop-header {
                @include display-flex();
                @include justify-content();
                @include align-items();
                flex-direction: column;
                .title {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    gap: 8px;
                    .title-message {
                        margin-top: 0;
                    }
                    .tool-tip-box {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        overflow: visible;
                        .selector-info {
                            @include background($image: img('help_circle_primary.svg'));
                            width: 20px;
                            height: 20px;
                        }

                        .tool-tip {
                            display: none;
                            position: absolute;
                            z-index: 2;
                            background-color: rgba($color-primary-800, 0.95);
                            border-radius: 5px;
                            padding: 8px;
                            width: 250px;
                            top: 65px;
                            color: $color-white;
                            transition: all 0.3s ease;

                            &:before {
                                content: '';
                                position: absolute;
                                width: 0;
                                height: 0;
                                border-left: 10px solid transparent;
                                border-right: 10px solid transparent;
                                border-bottom: 10px solid rgba($color-primary-800, 0.95);
                                top: -10px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }

                        .selector-info:hover + .tool-tip {
                            display: block;
                            transition: all 0.3s ease;
                        }
                    }
                }
                .selector-description {
                    @include font-size(16px);
                }
            }

            .pop-body {
                max-height: 350px !important;
                overflow: auto;
            }

            .pop-footer {
                .buttons {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                }
            }
        }

        &.deleteItem {
            border: none;
            border-top: 8px solid red;
            div.delete-item {
                display: grid;
                place-content: center;
                h1 {
                    text-align: center;
                    font-family: $text-bold;
                    font-size: 1.8em;
                    display: block;
                }

                p {
                    margin-top: 16px;
                    display: block;
                    text-align: center;
                    font-family: $text;
                }
            }
            .pop-body {
                display: grid;
                place-content: center;
            }

            .pop-footer {
                display: grid;
                place-content: center;
                div.buttons {
                    button.save {
                        background-color: $color-error-500;
                        @include background($image: img('delete_ffffff.svg'), $size: 16px, $position: left 12px center);
                        padding: 0px 12px 0px 34px;
                        margin-left: 32px;
                    }
                }
                margin-bottom: 16px;
            }
        }
        &.tplTemporary {
            .pop-header.temporal-temp {
                width: auto;
                h1 {
                    text-align: center;
                    font-family: $text-bold;
                    font-size: 1.8em;
                }
            }
            .pop-body {
                display: block;
                width: 100%;
                margin-top: 32px;
            }
            .pop-footer {
                width: auto;
                div.buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    button.cancel {
                        font-size: 1em;
                        display: flex;
                        width: 100px;
                        height: 45px;
                        padding: 4px 8px;
                        justify-content: center;
                        align-items: center;
                        gap: 12px;
                        flex-shrink: 0;
                    }
                    button.save {
                        background-color: $color-secondary-500;
                        font-size: 1em;
                        display: flex;
                        width: 100px;
                        height: 45px;
                        padding: 4px 8px;
                        justify-content: center;
                        align-items: center;
                        gap: 12px;
                        flex-shrink: 0;
                    }
                }
            }
        }
        .pop-alex {
            @include background($size: 43px, $image: img('alexhead_02.svg'), $color: $color-white);
            @include border-radius(100%);
            width: 60px;
            height: 60px;
            display: block;
            position: absolute;
            top: -25px;
            left: 15px;
            z-index: $z-popups-default + 1;
            border: 3px solid $color-neutral-300;

            &.normal {
                background-image: img('alex_carita_07.svg');
            }
            &.geolocation {
                background-image: img('alex_carita_10.svg');
                width: 80px;
                height: 80px;
            }
        }

        .pop-andy {
            @include background($size: 60px, $image: img('alexhead-06.svg'));
            width: 65.77px;
            height: 60px;
            display: block;
            position: absolute;
            top: -37px;
            left: 25px;
            z-index: $z-popups-default + 1;
        }

        .pop-close {
            @include background($size: 20px, $image: img('close_primary.svg'), $color: $color-primary-500);
            width: 40px;
            height: 40px;
            display: inline-block;
            float: right;
        }

        .pop-header,
        .pop-body,
        .pop-footer {
            @include display-flex();
            width: 100%;
            overflow: hidden;
        }

        .pop-header {
            @include align-items();

            .title-message {
                @include font-size(lg);
                font-family: $text-bold;
                color: $color-black;
                padding: 0;
                margin-top: 8px;
            }
        }

        .pop-body {
            @include width-min-content();
            max-height: calc(100% - 80px);
            height: auto;
            min-width: 600px;
            width: 100%;
            max-width: unset;
            overflow: auto;
            margin: 9px 0 30px 0;

            .kind-of {
                @include display-flex();
                @include flex-direction(row);
                @include justify-content();
                @include align-items(flex-start);
                max-height: calc(100% - 80px);
                height: auto;
                min-width: 250px;
                // min-width: 100%;
                width: 100%;
                // overflow: hidden;
                overflow-x: hidden;
                overflow-y: auto;
                // margin: 16px;
            }

            .left,
            .right {
                @include display-inline-flex();
                @include flex-direction($dir: column);
                @include justify-content($val: flex-start);
                @include align-items();
                width: 50%;
                // height: auto;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 10px;
            }

            .full {
                width: 100%;
            }

            .disabled {
                cursor: not-allowed;
                position: relative;

                > div,
                > ul {
                    &:before,
                    &:after {
                        width: calc(100%);
                        height: calc(100%);
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    &:before {
                        @include font-size(27px);
                        font-family: $text-bold;
                        color: $color-primary-500;
                        content: attr(data-msg);
                        text-align: center;
                        z-index: 2; //TODO Cambiar este z-index por variables porfa (doc. _variables.scss)
                        padding: 45% 24px;
                    }
                    &:after {
                        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 15%, rgba(255, 0, 0, 0) 50%),
                            linear-gradient(to top, rgba(255, 255, 255, 1) 15%, rgba(255, 0, 0, 0) 50%),
                            linear-gradient(to left, rgba(255, 255, 255, 1), rgba($color-primary-100, 0.8), rgba(255, 255, 255, 1));
                        content: '';
                    }
                }
            }

            .message {
                @include font-size(ml);
                line-height: 1.5;
                font-family: $text-medium;
                text-align: left;
                width: 100%;
                padding: 0;
            }

            .message-info {
                @include font-size(ml);
                margin: 12px 0 8px 0;
                color: $color-primary-500;
                border-bottom: 1px solid $color-primary-500;
                text-align: left;
                width: 100%;
            }

            .message-error {
                @extend .message-info;
                color: $color-error-500;
                border-color: $color-error-500;
            }

            .place {
                width: 100%;
                border-bottom: 1px solid $color-primary-100;

                .item {
                    @include border-top-radius(3px);
                    font-family: $text;
                    line-height: 1;
                    display: inline;
                    float: left;
                    width: 33%;
                    padding: 10px 10px;
                    text-align: center;
                    color: $color-primary-500;
                }

                .item.selected {
                    background-color: $color-primary-100;
                }
            }

            .list {
                min-width: 150px;
                width: 100%;
                height: 100%;

                .item {
                    @include border-radius(3px);
                    @include display-flex();
                    @include align-items();
                    height: 40px;
                    width: 100%;
                    border-bottom: 1px solid #eee;

                    &.default {
                        // background-color: #fbfbfb;
                        border-bottom-color: $color-primary-500;
                    }
                    &.selected {
                        background-color: $color-primary-100;
                        border-bottom: 2px solid $color-primary-500;
                    }

                    &.clocked-in,
                    &.clocked-out {
                        padding-right: 30px;
                    }

                    &.clocked-in {
                        @include background($size: 20px, $position: center right 5px, $image: img('clockin.svg'));
                    }

                    &.clocked-out {
                        @include background($size: 20px, $position: center right 5px, $image: img('clockout.svg'));
                    }

                    .user-icon {
                        @include background($size: cover);
                        @include border-radius(50%);
                        width: 35px;
                        height: 35px;
                        display: inline;
                        margin: 0 5px;
                        border: 1px solid $color-primary-100;
                    }

                    .user-name {
                        @include text-ellipsis();
                    }
                }

                &.select-list {
                    .item {
                        padding-left: 12px;
                    }

                    .name {
                        @include text-ellipsis($line: 2);
                    }
                }
            }

            .toolbar {
                width: 100%;
                height: 40px;
                display: flex;
                justify-content: center;

                .btn {
                    @include font-size(ml);
                    text-transform: none;
                    color: #777;
                    background-color: transparent;
                    margin: 0 6px;
                    padding: 0 6px;
                }
            }

            .media-container {
                width: 100%;
                height: 100%;
            }
        }

        .pop-footer,
        .pop-header.actions {
            min-height: 50px;

            .buttons {
                width: 100%;
                height: 50px;
                float: right;
                text-align: right;

                button {
                    @include border-radius(4px);
                    @include font-size(ml);
                    font-family: $text-bold;
                    color: $color-black;
                    background-color: $color-neutral-300;
                    height: 100%;
                    padding: 0 20px;

                    &.save {
                        background-color: $color-primary-700;
                        color: $color-white;
                    }
                }

                &.media {
                    @include display-flex();
                    @include justify-content();

                    .cancelMedia {
                        display: flex;
                        align-items: center;
                        background-color: $color-primary-500;
                        color: $color-white;
                    }
                }
            }
        }

        .pop-header.actions {
            @include justify-content(space-between);
            width: 100%;
            .buttons {
                width: min-content;
            }
        }

        &.date,
        &.dateRange {
            .kind-of {
                padding: 12px;
            }
            .v-picker {
                box-shadow: none;

                .v-picker__title {
                    padding: 9px 16px;
                }

                .v-picker__title__btn {
                    color: $color-white !important;
                    padding: 0;
                    margin: 0;

                    > * {
                        color: inherit;
                    }
                }
            }

            .primary {
                background-color: $color-primary-500 !important;
                border-color: $color-primary-500 !important;
            }
        }

        &.quantity {
            .left {
                @include justify-content();
            }

            .message {
                margin-top: 20px;
            }

            .pin {
                @include font-size(xl);
            }

            .rem-amount {
                @include font-size(s);
                color: $color-error-500;
                margin-top: 40px;
                font-family: $text;
            }
        }

        &.media,
        &.previewSignature {
            @include justify-content($val: flex-end);
            max-width: 95%;
            max-height: 90%;

            .pop-body {
                height: auto;
                // height: 100%;
                min-width: unset;
                width: -webkit-fill-available;
                overflow-y: auto;
                text-align: center;
            }

            .preview {
                margin: 10px;
                width: inherit;
                overflow: auto;
                text-align: center;

                .cld-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .pop-footer {
                .buttons {
                    display: flex;
                    gap: 8px;
                    .act {
                        &.cancel,
                        &.save {
                            width: 50%;
                            margin: 0px;
                        }
                    }
                }
            }
        }
        &.previewSignature {
            max-width: 90%;
            .pop-footer {
                .buttons {
                    .act {
                        &.cancel {
                            width: 100%;
                        }
                    }
                }
            }
        }
        &.select {
            .pop-body {
                padding: 10px;
            }

            .list {
                .item {
                    padding: 0 8px;
                }
            }
        }

        &.select-double {
            .pop-body {
                padding: 10px;
            }

            .list {
                .item {
                    padding: 0 8px;
                }
            }
        }

        &.numeric {
            .pop-body {
                @include flex-wrap(wrap);
                min-width: auto;

                overflow: auto;

                .kind-of {
                    @include flex-direction(column);
                    @include justify-content(flex-start);
                    @include align-items();
                    min-width: 250px;
                    text-align: center;
                    padding-bottom: 10px;
                }
            }
        }

        &.temperature {
            width: 50%;
            .pop-header {
                height: 0px;
            }
            .pop-body {
                // @include flex-wrap(wrap);
                min-width: auto;
                overflow: auto;
                margin: 0px;
                padding: 0 15 0 0;
                .number-box {
                    flex-wrap: nowrap;
                }
                .kind-of {
                    @include flex-direction(row);
                    // @include flex-wrap(wrap);
                    @include justify-content(center);
                    @include align-items();
                    min-width: 250px;
                    text-align: center;
                    padding: 0;
                }
            }
            .pop-footer {
                .buttons {
                    display: flex;
                    gap: 8px;
                    .act {
                        &.cancel,
                        &.save {
                            width: 50%;
                            margin: 0px;
                        }
                    }
                }
            }
        }

        &.process {
            height: 100%;
            min-width: 60%;

            .pop-header {
                margin-top: -15px;
            }

            .pop-body {
                height: 100%;
                width: 100%;

                .kind-of {
                    @include flex-wrap(wrap);
                    height: 100%;
                    width: 100%;
                    max-height: unset;
                }
            }

            .pop-footer {
                .buttons {
                    button {
                        margin: 4px 6px 0px 6px;

                        &.inactive {
                            opacity: 0.3;
                        }
                    }
                }
            }

            .product-info {
                width: 100%;
                height: 25px;
                position: relative;

                .product-title {
                    @include font-size(ml);
                    font-family: $text;
                    // color: shade($accent, 50%);
                    padding-left: calc(200px - 24px);
                    display: inline-block;
                    position: absolute;
                    width: 100%;
                    background-color: $color-success-800;
                }
            }
            .resume {
                width: 200px;
                height: calc(100% - 25px);
                overflow: auto;
                padding-right: 24px;

                .resume-step {
                    height: 25%;
                    padding: 0px 12px 24px 12px;
                    border-right: 2px solid #ccc;
                    &:first-of-type {
                        margin-top: 24px;
                    }
                    &:last-of-type {
                        padding: 0px 12px 0 12px;
                        border-color: transparent !important;
                        height: calc(25% - 24px);
                    }

                    .title {
                        @include font-size(m);
                        font-family: $text;
                        text-align: right;
                        color: #777;
                        margin: 0;
                        padding: 0;
                        position: relative;
                        transition-duration: 0.1s;

                        &:after {
                            @include border-radius(50%);
                            @include background($color: #ccc);
                            content: '';
                            width: 18px;
                            height: 18px;
                            border: 2px solid $color-white;
                            position: absolute;
                            top: 0;
                            right: -22px;
                            transition-duration: 0.1s;
                        }
                    }
                    .subtitle {
                        display: block;
                        width: 100%;
                        color: #777;
                        text-align: right;
                    }

                    &.current {
                        // border-color: $accent-dark;
                        .title {
                            @include font-size(ml);

                            &:after {
                                border: none;
                                background-color: $color-success-800;
                            }
                        }
                    }
                    &.done {
                        border-color: $color-primary-500;
                        .title {
                            color: $color-black;
                            &:after {
                                background-color: $color-primary-500;
                            }
                        }
                    }
                }
            }
            .content {
                width: calc(100% - 200px);
                height: calc(100% - 25px);
                overflow: hidden;
                padding: 24px 24px 24px 0px;

                .step {
                    display: none;
                    overflow: auto;
                    width: 100%;
                    height: 100%;
                    min-height: 300px;
                }
                .step.current {
                    display: block;
                }

                .step-message {
                    @include font-size(sm);
                    color: #666;
                    text-align: center;
                    margin-top: 0;
                }
            }

            .tags {
                @include display-flex();
                @include flex-direction(column);
                @include flex-wrap(wrap);
                height: 100%;
                width: 100%;
                padding-left: 24px;

                .tag {
                    @include border-radius(3px);
                    @include text-ellipsis();
                    @include width-fit-content();
                    @include font-size(ml);
                    text-align: center;
                    height: 50px;
                    margin: 12px 6px;
                    padding: 15px;
                    display: inline-block;
                    background-color: #eee;
                    cursor: pointer;
                    transition-duration: 0.2s;

                    &.selected {
                        background-color: $color-secondary-800;
                    }
                }
            }

            .fcalendar-container {
            }

            .fcalendar-container.p-datetime {
                .calendar,
                .time {
                    display: inline-block;
                    width: auto;
                }
                .calendar {
                    display: inline-block;
                    min-width: 300px;

                    .vfc-main-container {
                        max-width: 300px;
                    }
                }
                .time {
                    display: inline-block;
                    min-width: 150px;
                    text-align: center;

                    .message {
                        @include font-size(sm);
                        color: $color-primary-500;
                        font-family: $text-bold;
                        text-align: center;
                        display: block;
                        width: 100%;
                    }

                    input {
                        @include border-radius($default-border-radius);
                        @include font-size(m);
                        color: $color-primary-500;
                        font-family: $text-bold;
                        text-align: center;
                        height: 40px;
                        padding: 0 6px 0 12px;
                        background-color: $color-primary-100;
                        border: none;
                        display: inline-block;
                    }
                }
            }

            .input-number {
                @include display-flex();
                @include flex-direction(column);
                @include align-items();
            }

            .input-text {
                @include background($image: img('keyboard_aaaaaa.svg'), $size: 24px, $position: left 6px center);
                padding: 12px 42px;
                width: 80%;
                margin: 0 auto;

                input {
                    @include border-radius(3px);
                    @include font-size(m);
                    font-family: $text;
                    text-align: center;
                    border: 2px solid $color-success-800;
                    background-color: $color-secondary-800;
                    width: 100%;
                    height: 50px;
                }
            }
        }

        &.justify {
            .kind-of {
                padding: 6px 12px;

                .justify-info {
                    @include display-flex();
                    @include flex-wrap(wrap);
                    @include justify-content();
                    width: calc(600px + (10px * 4));

                    .step {
                        width: 300px;
                        margin: 0 10px;
                        display: inline-block;
                    }

                    .title {
                        @include font-size($size: sm, $important: true);
                        margin: 12px 0 6px 0;
                    }
                    .error {
                        @include font-size($size: sm, $important: true);
                        font-family: $text;
                        color: $color-error-500;
                        width: 100%;
                        text-align: center;
                    }

                    .reasons {
                        @include display-flex();
                        @include flex-wrap(wrap);
                        @include flex-direction(column-reverse);

                        .item {
                            @include border-radius($default-border-radius);
                            font-family: $text;
                            min-height: 40px;
                            width: 100%;
                            background-color: #f3f3f3;
                            margin: 6px;
                            padding: 12px;

                            &.selected {
                                background-color: red;
                                // background-color: $color-primary-500;
                                // color: $color-white;
                            }
                        }
                    }

                    .reason_message {
                        width: 100%;
                        min-height: 160px;
                    }
                }

                .justify-error {
                    width: 100%;
                    color: #222;
                    text-align: center;
                }
            }
        }

        &.dateTime {
            .pop-body {
                min-width: 300px;
            }
        }

        &.media {
            @include justify-content();
            @include align-items();
            max-width: 95%;
            max-height: 90%;

            .pop-body {
                height: auto;
                min-width: unset;
                width: 100%;
                overflow-y: auto;
            }

            .preview {
                width: inherit;
                overflow: auto;
                text-align: center;
                margin: 0px !important;

                @media (min-width: 768px) {
                    margin: 8px;
                }

                img {
                    height: auto !important;
                    max-height: 100%;
                    max-width: 85%;

                    @media (max-width: 767px) {
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }

        &.galeryIcons,
        &.galeryTemplates {
            @include display-flex();
            @include align-items(unset);
            @include justify-content(space-between);
            width: 85%;
            height: 90%;
            padding: 10px 20px;

            .pop-header {
                padding-bottom: 20px;
            }
            .pop-body {
                width: 100%;
                height: 100%;
                margin-bottom: 10px;
            }
            .pop-footer {
                .buttons {
                    height: 40px;
                }
            }

            .galery-icon-container-main,
            .galery-templates-container-main {
                width: 100%;
                height: 100%;
            }
        }

        &.media-onboarding {
            @include display-flex();
            @include flex-direction(column);
            @include align-items(center);
            @include justify-content(space-between);
            width: 60%;
            height: 80%;

            .pop-header {
                place-content: center;
                height: 50px;
            }

            .pop-body {
                width: 100%;
                height: calc(100% - 50px);
                padding: 0px 30px;
            }
        }
        &.release {
            max-width: 800px;
            .pop-header {
                display: none;
            }
        }

        &.invitationUser {
            .title-message {
                @include font-size(lg);
                font-family: $text-bold;
                color: $color-black;
            }

            .buttons {
                @include display-flex();
                @include justify-content(flex-end);
            }

            button.save {
                @include background($image: img('send_ffffff.svg'), $position: 12px center, $size: 18px);
                @include font-size(md);
                color: $color-neutral-200;
                padding-left: 35px;
                max-height: 40px;
                width: 100%;
            }
            button.cancel {
                @include font-size(md);
                font-family: $text-bold;
                background-color: $color-neutral-300;
                max-height: 40px;
                width: 100%;
            }
        }

        &.PDFDisplayOptions {
            .pop-alex {
                display: none;
            }

            .pop-header {
                display: none;
            }

            .pop-body {
                margin: 0px;
                padding-top: 0px;
                padding: 0px 0px 30px 0px;
            }

            .buttons {
                @media (max-width: 900px) {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    button {
                        margin: 0px !important;
                    }
                }
            }
        }

        &.numeric {
            width: 50%;
            .pop-header {
                height: 0px;
            }
            .pop-body {
                @include flex-wrap(wrap);
                min-width: auto;
                // position: relative;
                overflow: auto;
                margin: 0px;
                padding: 0px;
                .kind-of {
                    @include flex-direction(column);
                    @include justify-content(flex-start);
                    @include align-items();
                    min-width: 250px;
                    text-align: center;
                    padding-bottom: 10px;
                    margin: 0px;
                }
            }
            .pop-footer {
                .buttons {
                    display: flex;
                    gap: 8px;
                    .act {
                        &.cancel,
                        &.save {
                            width: 50%;
                            margin: 0px;
                        }
                    }
                }
            }
        }

        &.temperature {
            .pop-body {
                // @include flex-wrap(wrap);
                min-width: auto;
                overflow: auto;

                .kind-of {
                    @include flex-direction(row);
                    @include flex-wrap(wrap);
                    @include justify-content(center);
                    @include align-items();
                    min-width: 250px;
                    text-align: center;
                    padding: 0;
                }
            }
        }
        &.input {
            .pop-body {
                margin: 12px 0;
                padding: 0 12px;

                .kind-of {
                    @include flex-direction(column);

                    input {
                        width: 100%;
                    }

                    .message-error {
                        @include width-fit-content();
                    }
                }
            }
        }

        // &.sign {
        //     gap: 0.5em;
        //         .pop-body {

        //             .right {

        //                 padding: 0;

        //                 .toolbar {
        //                     @include display-flex();
        //                     @include justify-content(space-around);

        //                     button {
        //                         max-width: 50% !important;
        //                         width: fit-content;

        //                     }
        //                 }
        //             }
        //             .left {

        //                 max-height: 50vh;

        //                 .users-list {
        //                     height: 100%;
        //                     overflow: auto;
        //                 }
        //             }

        //             .signature {
        //                 border-top: 2px dashed #ddd;
        //                 border-bottom: 2px dashed #ddd;
        //                 padding: 3px 0;
        //             }
        //         }
        // }

        &.issueAlert {
            .pop-body {
                @include flex-wrap(wrap);

                .kind-of {
                    @include flex-direction(column);
                    @include justify-content(flex-start);
                    @include align-items();
                    padding: 12px;

                    .message {
                        width: 100%;
                        background-color: $color-primary-100;
                        max-height: 200px;
                        overflow: auto;
                    }
                    .suggest {
                        width: 100%;
                        text-align: center;
                        height: 40px;
                        margin-top: 12px;
                    }

                    .issue-title,
                    .issues,
                    .alert-title {
                        display: inline-block;
                    }
                    .alert-title {
                        @include font-size(ml);
                        color: $color-primary-800;
                        font-family: $text;
                        width: 100%;
                    }
                    .issue-title {
                        @include font-size(ml);
                        color: $color-primary-800;
                        font-family: $text;
                        margin-right: 12px;
                    }
                    .issues {
                        min-width: 100px;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }

        &.tableColumn {
            // padding: 8px;
            padding: 16px 16px 16px 8px !important;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 380px !important;
            height: auto;
            max-height: 86vh;

            @media (min-width: 1024px) {
                position: absolute;
            }

            .pop-andy {
                left: 16px;
            }

            .pop-header {
                min-height: 40px;
                padding-top: 8px;

                @media (min-width: 768px) {
                    padding-top: 0px;
                    padding-bottom: 8px;
                }
            }

            @media (max-width: 767px) {
                min-width: 96% !important;
                width: 96% !important;
                // height: 350px;
                position: relative !important;
                top: 0 !important;
                padding: 8px !important;
                height: 90%;
                max-height: 90%;
            }

            @media (min-width: 768px) {
                right: 8px;
            }

            .title-message {
                padding-left: 30px !important;
            }

            .pop-body {
                margin: 0;
                width: 100%;
                height: auto;
                min-width: auto;
                overflow-y: auto;
                max-height: 90%;

                @media (min-width: 768px) {
                    width: 380px;
                    max-width: 380px;
                    max-height: 70vh;
                }

                .kind-of {
                    display: none;
                }
                .checkbox-list-container {
                    width: 100%;
                    margin-right: 8px;

                    .check-box-container {
                        width: 100%;
                        padding-left: 30px;
                        cursor: grab;
                    }

                    .check-box-container:hover {
                        background-image: img('drag_dots.svg');
                        background-repeat: no-repeat;
                        background-position: 8px;
                    }

                    .check-box {
                        display: flex;
                        width: 100%;
                        height: 51px;
                        padding: 0px 13.6px;
                        margin-top: 8px;
                        align-items: center;
                        gap: 13.6px;
                        flex-shrink: 0;
                        border-radius: 6.8px;
                        border: 1.7px solid $color-neutral-500;
                        background: var(--color-white, #fff);
                        // cursor: pointer;
                        cursor: grab;

                        div.check-input {
                            // background-color: $inactive-t10;
                            @include background($image: img('tick_unselected.svg'), $position: center, $size: 16px);
                            background-color: $color-neutral-500;
                            border-radius: 3px;
                            display: flex;
                            width: 25px;
                            height: 25px;
                            padding: 10px 12px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            flex-shrink: 0;
                            transition: background-color 0.5s;
                            cursor: pointer;
                        }
                        &.check-box.activeHeader {
                            div.check-input {
                                background-color: $color-primary-700;
                                @include background($image: img('tick_ffffff.svg'), $position: center, $size: 16px);
                            }
                        }
                    }

                    .check-box:hover {
                        background-color: $color-neutral-300;
                    }

                    .check-box-container:active {
                        cursor: grab;
                    }

                    .check-box-container:active:active {
                        cursor: grabbing;
                    }
                }
            }
            .pop-footer {
                margin-top: 8px;
                padding-left: 8px;
                height: auto;

                @media (max-width: 767px) {
                    height: 100px;
                    min-height: 100px;
                }

                .buttons {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    width: 100%;

                    button {
                        width: 100%;
                        margin: 0px;
                    }

                    @media (max-width: 767px) {
                        button {
                            width: 40% !important;
                        }
                    }

                    @media (min-width: 768px) {
                        display: flex !important;
                        flex-direction: row !important;
                        gap: 8px;

                        button {
                            min-width: 40% !important;
                        }
                    }
                }
            }
        }

        &.labelPreview {
            // width: 29%;
            // height: 65%;
            width: 350px;
            background-color: transparent;
            padding: 0px;
            .pop-body {
                min-width: fit-content;
                // margin: 0px 0 10px 0;
                .kind-of {
                    max-height: 100%;
                    // overflow-y: hidden;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    width: 100%;
                    height: auto;
                    // padding: 80px;
                }
            }
        }
        &.sign {
            .pop-header {
                display: none;
            }
            .pop-body {
                padding: 0px;
                margin: 0px;
                .left {
                    max-height: 50vh;

                    .users-list {
                        height: 100%;
                        overflow: auto;
                    }
                }
                .list {
                    .item {
                        .user-color {
                            border-radius: 50%;
                            display: inline-block;
                            min-width: 30px;
                            width: 30px;
                            height: 30px;
                            padding: 0 2px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 35px;
                            height: 35px;
                            display: inline;
                            margin: 0 5px;
                        }
                    }
                }
                .right {
                    padding: 0;

                    .toolbar {
                        @include display-flex();
                        @include justify-content(space-around);
                        .andy-icon.cancel {
                            margin-top: 2px;
                            background-size: 18px;
                        }
                        .btn {
                            display: flex;
                        }
                    }
                }

                .signature {
                    border-top: 2px dashed #ddd;
                    border-bottom: 2px dashed #ddd;
                    padding: 3px 0;
                }
            }
            .pop-footer {
                .buttons {
                    @include display-flex();
                    width: 100%;
                    .act {
                        &.cancel,
                        &.save {
                            width: 50%;
                            height: auto;
                            padding: 4px;
                            margin-left: 0px;
                        }
                        &.save {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
        &.freeSign {
            // gap: 1.5em;
            .pop-header {
                .title-message {
                    margin-top: 0px;
                }
            }

            .pop-body {
                min-width: 300px;
                padding: 0px;
                margin: 0px;

                .kind-of {
                    @include flex-direction(column);
                    width: auto;
                    overflow: hidden;
                }

                .toolbar {
                    @include display-flex();
                    @include justify-content(space-around);
                    .btn {
                        &.undo,
                        &.clear {
                            @include display-flex();
                            font-family: $text-bold;
                        }

                        .andy-icon.cancel {
                            margin-top: 2px;
                            background-size: 18px;
                        }
                    }
                }
                .signature {
                    border-top: 2px dashed #ddd;
                    border-bottom: 2px dashed #ddd;
                    padding: 3px 0;
                    overflow: hidden;
                }
            }

            .pop-footer {
                .buttons {
                    @include display-flex();
                    width: 100%;
                    .act {
                        &.cancel,
                        &.save {
                            width: 50%;
                            height: auto;
                            padding: 4px;
                            margin-left: 0px;
                        }
                        &.save {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
        &.postpone {
            width: calc(100% - 20px);
            max-width: 700px;
            bottom: 0px;
            .pop-body {
                max-height: calc(100% - 5rem);
                min-width: unset;
                width: 100%;
                max-width: unset;
                display: contents;
            }
            .kind-of {
                padding: 0px;
                margin: 0px;

                .postpone-info {
                    @include display-flex();
                    @include justify-content();
                    @include flex-wrap(wrap);
                    // width: calc(600px + (10px * 4));
                    width: 100%;

                    .title-date {
                        @include font-size(ml);
                        font-family: $text;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 12px;
                    }

                    .step {
                        width: 300px;
                        margin: 0 10px;
                        display: inline-block;
                    }
                    .step-date {
                        height: 340px;
                    }

                    .title {
                        @include font-size($size: sm, $important: true);
                        margin: 12px 0 6px 0;
                    }
                    .error {
                        @include font-size($size: sm, $important: true);
                        font-family: $text;
                        color: $color-error-500;
                    }

                    .datepicker {
                        width: 200px;
                        border: none;
                        box-shadow: none;

                        .v-picker__title {
                            display: none;
                        }

                        .v-picker__body {
                            margin: 0;
                        }
                    }

                    .reasons {
                        @include display-flex();
                        @include flex-wrap(wrap);
                        @include flex-direction(column-reverse);

                        .item {
                            @include border-radius($default-border-radius);
                            font-family: $text;
                            min-height: 40px;
                            width: 100%;
                            background-color: #f3f3f3;
                            margin: 6px;
                            padding: 12px;

                            &.selected {
                                background-color: $color-primary-500;
                                color: $color-white;
                            }
                        }
                    }

                    .reason_message {
                        width: 100%;
                    }
                }

                .postpone-error {
                    width: 100%;
                    color: #222;
                    text-align: center;
                }
            }
            .pop-footer {
                .buttons {
                    width: 100%;
                    display: flex;
                    button {
                        width: 49%;
                        margin-left: 0px;
                        &.cancel,
                        &.save {
                            height: auto;
                            padding: 4px;
                        }
                    }
                }
            }
        }
        &.alert {
            .pop-body {
                width: auto;
            }
            .message {
                ol,
                ul {
                    padding-left: 30px;
                    list-style: disc;
                }
            }
        }

        &.gallery {
            // width: 600px;
            // height: 610px;
            width: 100%;
            height: 100%;
            padding: 18px;

            @media (max-width: 767px) {
                top: 0;

                .preview {
                    margin: 0px !important;

                    .backward,
                    .forward {
                        margin-top: 50% !important;
                    }

                    .cld-image {
                        padding: 4px;
                    }
                }
            }

            .pop-header.gallery {
                // width: 560px;
                .act {
                    &.cancel {
                        @include background($size: 35px, $image: img('cancel.svg'));
                        width: 35px;
                        height: 35px;
                    }
                }
                .description {
                    width: 100%;
                    .title {
                        font-family: $text-bold;
                        @include font-size(sm);
                    }
                    .message,
                    .por,
                    .name,
                    .datetime {
                        @include font-size(xs);
                    }
                    .main-img {
                        width: 10px;
                        height: 10px;
                        margin: 0 6px 0 6px;
                    }
                    .message,
                    .name,
                    .datetime {
                        font-family: $text;
                    }
                    .por {
                        font-family: $text-light;
                    }
                    .avatar {
                        @include border-radius(30px);
                        width: 30px;
                        height: 30px;

                        &:after {
                            @include text-ellipsis();
                            @include font-size(xs);
                            line-height: 30px;
                            font-family: $text-bold;
                            color: #fff;
                            text-align: center;
                            width: 100%;
                            height: 100%;
                            display: inline-block;
                        }
                    }
                }
            }
            .pop-body {
                width: 100%;
                height: 100%;
                min-width: 600px;
                min-height: 400px;
                margin: 0px;
            }
            .pop-footer {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                padding: 10px;
                .gallery {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    .backward,
                    .forward {
                        width: 22.5px;
                        height: 30px;
                    }

                    .backward {
                        @include background($image: img('goBackward.svg'));
                    }
                    .forward {
                        @include background($image: img('goForward.svg'));
                    }
                    .gallery-image-container {
                        @include display-inline-flex();

                        @include justify-content(center);
                        @include align-items(center);
                        width: 48px;
                        height: 48px;
                    }
                    .cld-image {
                        @include display-inline-flex();
                        @include align-items(center);
                        @include justify-content(center);
                        width: 100%;
                        height: 100%;

                        img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .thumbnail {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        cursor: pointer;
                    }
                    .buttons {
                        display: none;
                    }
                }
            }

            .kind-of {
                justify-content: space-between !important;
                margin-top: 20px;
            }
            .preview {
                margin: 16px;
                width: inherit;
                overflow: auto;
                text-align: center;

                .backward,
                .forward {
                    margin-top: 22%;
                    width: 30px;
                    height: 40px;
                    cursor: pointer;
                }
                .backward {
                    @include background($image: img('goBackward.svg'));
                }
                .forward {
                    @include background($image: img('goForward.svg'));
                }

                .image {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                    max-width: 85%;
                    object-fit: cover;

                    img {
                        object-fit: contain;
                    }
                }
            }
        }

        &.messageWithButton {
            border-top: 7px solid $color-primary-700;
            padding-top: 16px;
            padding-bottom: 24px;
            width: 560px;

            .pop-header {
                width: 100%;
                justify-content: center;

                .title-message {
                    margin-top: 8px;
                    margin-bottom: 4px;
                    text-align: center;
                    font-size: 18px;
                }
            }

            .pop-body {
                width: 100%;
                padding: 0px;
                margin-bottom: 16px;

                .kind-of {
                    width: 100%;
                    .message {
                        width: 100%;
                        text-align: center !important;
                        font-size: 16px !important;
                        font-family: $text;
                        padding: 0px 16px;

                        @media (min-width: 767px) {
                            width: 560px;
                            padding: 0px 30px;
                        }
                    }
                }
            }

            .pop-footer {
                .buttons {
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center;

                    button {
                        font-size: 18px;
                        min-width: 140px !important;
                        max-width: 200px !important;
                        width: fit-content !important;
                        height: 48px;
                    }
                }
            }
        }

        &.exportLoading {
            border-top: 7px solid $color-primary-700;
            padding-top: 16px;
            width: 560px;

            .pop-header {
                position: relative;
                width: 100%;
                max-width: 100%;
                justify-content: center;
                height: fit-content !important;

                .title-message {
                    margin-top: 8px;
                    margin-bottom: 16px;
                    text-align: center;
                    font-size: 18px;
                }
            }

            .pop-body {
                min-width: 560px;
                max-width: 560px !important;
                margin: 0px;
            }
            .kind-of {
                display: block;
                width: 100%;
                max-width: 100%;
                width: 560px;
                padding: 0px;

                .message {
                    width: 100% !important;
                    max-width: 100% !important;
                    text-align: center !important;
                    font-size: 16px !important;
                    font-family: $text;
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }

        .pop-alex {
            @include background($size: 40px, $image: img('alexhead_08.svg'), $color: $color-white);
            @include border-radius(100%);
            width: 50px;
            height: 50px;
            display: block;
            position: absolute;
            top: -25px;
            left: 15px;
            z-index: $z-popups-default + 1;
            border: 3px solid $color-secondary-100;
        }

        .pop-close {
            @include background($size: 20px, $image: img('close_primary.svg'), $color: $color-primary-500);
            width: 40px;
            height: 40px;
            display: inline-block;
            float: right;
        }

        .pop-header,
        .pop-body,
        .pop-footer {
            @include display-flex();
            width: 100%;
            overflow: hidden;
        }

        .pop-header {
            @include align-items();

            .title-message {
                @include font-size(lg);
                font-family: $text-bold;
                color: $color-black;
                padding: 0;
            }
        }

        .pop-body {
            @include width-min-content();
            max-height: calc(100% - 80px);
            height: auto;
            bottom: -25px;
            // padding: 15px;
            padding-bottom: 10px;
        }

        .url-container {
            padding-top: 16px;
            width: 100%;
            max-width: 100%;

            .url {
                margin: 0px !important;
                padding: 8px;
                color: #000000;
                border: 1px solid $color-neutral-300;
                border-radius: 4px;
                cursor: pointer;
                height: unset;
                max-width: 100%;
                width: 100%;
            }

            button {
                width: 100%;
                text-overflow: clip;
            }
        }

        .url:hover {
            background-color: $color-neutral-200;
        }

        @media (max-width: 767px) {
            box-sizing: border-box;
            position: relative;
            width: 100% !important;
            max-width: 100% !important;
            padding: 24px 8px 20px !important;

            .pop-header {
                position: relative !important;
                top: 0;
            }

            .pop-body {
                position: relative !important;
                top: 0;
                margin: 0px;
                width: 100% !important;
                max-width: 100% !important;
                max-height: max-content;
                min-width: 200px !important;

                .kind-of {
                    position: relative;
                    max-height: unset;
                    width: 100%;
                    max-width: 100%;

                    .message {
                        position: relative;
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }

            .pop-footer {
                position: relative;

                .buttons {
                    height: fit-content !important;
                    flex-direction: column;

                    button {
                        width: 100% !important;
                        min-width: 100% !important;
                        max-width: 100% !important;
                        height: fit-content;
                        padding: 8px !important;
                    }
                }
            }
        }

        @media (min-width: 768px) and (max-width: 900px) {
            box-sizing: border-box;
            position: relative;
            width: 80% !important;
            max-width: 100% !important;
            padding: 24px 8px 20px !important;

            .pop-header {
                position: relative !important;
                top: 0;
            }

            .pop-body {
                position: relative !important;
                top: 0;
                margin: 0px;
                width: 100% !important;
                max-width: 100% !important;
                max-height: max-content;
                min-width: 200px !important;

                .kind-of {
                    position: relative;
                    max-height: unset;
                    width: 100%;
                    max-width: 100%;

                    .message {
                        position: relative;
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }

            .pop-footer {
                position: relative;

                .buttons {
                    height: fit-content !important;
                    flex-direction: column;

                    button {
                        width: 100% !important;
                        min-width: 100% !important;
                        max-width: 100% !important;
                        height: fit-content;
                        padding: 8px !important;
                    }
                }
            }
        }
    }
}

.error {
    .popup {
        border-top: 7px solid $color-error-500 !important;
    }

    .pop-andy {
        background-image: img('alexhead_04.svg') !important;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.popup-container.portrait {
    @include align-items(flex-end);
    width: 100% !important;
    left: 0 !important;

    .popup {
        height: auto;
        max-width: unset;
        bottom: 10px;

        .pop-alex {
            @include background($size: rem(40px));
            width: rem(60px);
            height: rem(60px);
            top: rem(-35px);
        }

        .pop-header {
            @include font-size(s);
            position: absolute;
            top: -0.3rem;
            left: 0.1rem;
        }

        .full {
            width: 100%;
        }

        .disabled {
            cursor: not-allowed;
            position: relative;

            > div,
            > ul {
                &:before,
                &:after {
                    width: calc(100%);
                    height: calc(100%);
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                &:before {
                    @include font-size(27px);
                    font-family: $text-bold;
                    color: $color-primary-500;
                    content: attr(data-msg);
                    text-align: center;
                    z-index: 2; //TODO Cambiar este z-index por variables porfa (doc. _variables.scss)
                    padding: 45% 24px;
                }
                &:after {
                    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 15%, rgba(255, 0, 0, 0) 50%),
                        linear-gradient(to top, rgba(255, 255, 255, 1) 15%, rgba(255, 0, 0, 0) 50%),
                        linear-gradient(to left, rgba(255, 255, 255, 1), rgba($color-primary-100, 0.8), rgba(255, 255, 255, 1));
                    content: '';
                }
            }
        }

        .message {
            @include font-size(ml);
            line-height: 1.5;
            font-family: $text-medium;
            text-align: left;
            width: 100%;
            padding: 0;
        }

        .message-info {
            @include font-size(ml);
            margin: 12px 0 8px 0;
            color: $color-primary-500;
            border-bottom: 1px solid $color-primary-500;
            text-align: left;
            width: 100%;
        }

        .message-error {
            @extend .message-info;
            color: $color-error-500;
            border-color: $color-error-500;
        }

        .place {
            width: 100%;
            border-bottom: 1px solid $color-primary-100;

            .item {
                @include border-top-radius(3px);
                font-family: $text;
                line-height: 1;
                display: inline;
                float: left;
                width: 33%;
                padding: 10px 10px;
                text-align: center;
                color: $color-primary-500;
            }

            .item.selected {
                background-color: $color-primary-100;
            }
        }

        .list {
            min-width: 150px;
            width: 100%;
            height: 100%;

            .item {
                @include border-radius(3px);
                @include display-flex();
                @include align-items();
                height: 40px;
                width: 100%;
                border-bottom: 1px solid #eee;

                &.default {
                    // background-color: #fbfbfb;
                    border-bottom-color: $color-primary-500;
                }
                &.selected {
                    background-color: $color-primary-100;
                    border-bottom: 2px solid $color-primary-500;
                }

                &.clocked-in,
                &.clocked-out {
                    padding-right: 30px;
                }

                &.clocked-in {
                    @include background($size: 20px, $position: center right 5px, $image: img('clockin.svg'));
                }

                &.clocked-out {
                    @include background($size: 20px, $position: center right 5px, $image: img('clockout.svg'));
                }

                .user-icon {
                    @include background($size: cover);
                    @include border-radius(50%);
                    width: 35px;
                    height: 35px;
                    display: inline;
                    margin: 0 5px;
                    border: 1px solid $color-primary-100;
                }

                .user-name {
                    @include text-ellipsis();
                }
            }

            &.select-list {
                .item {
                    padding-left: 12px;
                }
            }
        }

        .toolbar {
            width: 100%;
            height: 40px;
            display: block;

            .btn {
                @include font-size(ml);
                text-transform: none;
                color: #777;
                background-color: transparent;
                margin: 0 6px;
                padding: 0 6px;
            }
        }

        .media-container {
            width: 100%;
            height: 100%;
        }
    }

    .pop-footer,
    .pop-header.actions {
        min-height: 50px;

        .buttons {
            width: 100%;
            height: 50px;
            float: right;
            text-align: right;

            button {
                @include border-radius(4px);
                @include font-size(ml);
                font-family: $text-bold;
                color: $color-black;
                background-color: $color-neutral-200;
                height: 100%;
                padding: 0 20px;
                margin-left: 30px;

                &.save {
                    background-color: $color-primary-500;
                    color: $color-white;
                }
            }

            &.media {
                @include display-flex();
                @include justify-content();

                .cancelMedia {
                    display: flex;
                    align-items: center;
                    background-color: $color-primary-500;
                    color: $color-white;
                }
            }

            &.labelPreview {
                @include display-flex();
                @include justify-content();
                height: 42px;
                .cancelPreview {
                    @include font-size(sm);
                    display: flex;
                    align-items: center;
                    background-color: $color-white;
                    color: $color-black;
                    margin: 0px;
                }
            }
        }
    }

    .pop-header.actions {
        @include justify-content(space-between);
        width: 100%;
        .buttons {
            width: min-content;
        }
    }
    .pop-header.preview {
        @include font-size(md);
        color: $color-white;
        display: flex;
        justify-content: center;
        font-family: $text-bold;
    }
    .pop-header.gallery {
        .act {
            &.cancel {
                @include background($size: 35px, $image: img('cancel.svg'));
                width: 35px;
                height: 35px;
            }
        }
        button {
            margin: 0px;
            padding: 0px;
        }
    }
    &.date,
    &.dateRange {
        .kind-of {
            padding: 12px;
        }
        .v-picker {
            box-shadow: none;

            .v-picker__title {
                padding: 9px 16px;
            }

            .v-picker__title__btn {
                color: $color-white !important;
                padding: 0;
                margin: 0;

                > * {
                    color: inherit;
                }
            }
        }

        .primary {
            background-color: $color-primary-500 !important;
            border-color: $color-primary-500 !important;
        }
    }

    &.alertNewDesing {
        border-top: solid #bd1f1e 7px;

        .pop-header {
            justify-content: center;
        }
        .pop-header .labelPreview {
            color: $color-white;
        }
        .pop-body {
            flex-direction: column;
            align-items: center;
            gap: 1em;
        }

        .pop-body .message {
            text-align: center;
            padding-top: 0.5em;
            font-size: 18px;
        }

        .pop-footer .buttons {
            display: flex;
            justify-content: center;
            gap: 2.5em;

            .act {
                margin: 0;
            }

            .save {
                background-color: #bd1f1e;
                background-size: 14.6px 16px;
            }

            .cancel {
                display: none;
            }
        }
    }

    &.alert {
        .message {
            ol,
            ul {
                padding-left: 30px;
                list-style: disc;
            }
        }
    }

    &.media {
        @include justify-content();
        @include align-items();
        max-width: 95%;
        max-height: 90%;

        .pop-body {
            width: 100%;
            overflow: auto;
            min-width: unset;
            background: none;
            height: 100%;
            padding: 0;
            margin-top: 20px;
            padding-top: 12px;

            &::before {
                //@include background( $image: img('andy_local_logout.svg'), $size: 100px );
                background: none;
                width: 100% !important;
                overflow: hidden;
                //min-height: 150px;
                min-height: 0;
                min-width: 0;
            }

            .kind-of {
                height: auto;
                // overflow: auto;

                .message {
                    @include font-size(s);

                    &:after {
                        left: 25px;
                    }
                }
            }
        }

        .pop-footer {
            text-align: center;
            min-height: 165px;

            .act {
                @include font-size(s);
                max-width: calc(100%);
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .buttons {
                margin-top: 40px;

                button {
                    margin: 6px 0;
                    min-height: 50px;
                }

                .act {
                    &.save {
                        // background-color: $main-dark;
                    }
                }
            }
        }

        &.dateRange {
            height: 100%;

            .kind-of {
                display: inline-grid;
                // 1 column
                grid-template-columns: 1fr;
                width: 100%;
                min-height: auto;
                min-height: 688px;
                .vfc-styles-conditional-class {
                    height: 350px;
                }
            }
            @include portrait-tablet {
                .kind-of {
                    display: grid;
                    min-height: unset;
                    grid-template-columns: minmax(auto, 50%) 1fr;

                    .vfc-styles-conditional-class {
                        height: unset;
                    }
                }
            }
            .v-picker {
                box-shadow: none;

                .v-picker__title {
                    padding: 9px 16px;
                }

                .v-picker__title__btn {
                    color: $color-white !important;
                    padding: 0;
                    margin: 0;

                    > * {
                        color: inherit;
                    }
                }
            }

            .primary {
                background-color: $color-primary-500 !important;
                border-color: $color-primary-500 !important;
            }
        }

        // &.sign {
        //     .pop-body {
        //         .kind-of {
        //             @include flex-direction(column-reverse);
        //         }
        //         .right,
        //         .left {
        //             width: 100%;
        //         }
        //     }
        // }

        &.resource {
            @include border-radius(0);
            bottom: 0 !important;
            min-width: 100%;
            height: fit-content;
            max-height: calc(100% - 60px);
            overflow: auto;

            .pop-body {
                width: 100%;
                height: 100%;
                min-height: 100%;
            }
        }
    }
}
</style>
<!-- portrait -->
<style scoped lang="scss"></style>
