
import { Api } from '@/core/api.js'

const api = new Api()

const state = {
    lead: {
        name: '',
        surname: '',
        email: '',
        phone: '',
        company: '',
        locals: '',
        interested_version: '',
        lang: ''
    }
}

const getters = {

    getLead: (state, getters, rootState) => {
        return state.lead
      },

}

const actions = {

    addLead(context, params){
        return api.login('lead/add',{
            name: params.name,
            surname: params.surname,
            email: params.email,
            phone: params.phone,
            company: params.company,
            locals: params.locals,
            lang: params.lang
        }).then(function(response){
            context.commit('setLead', response)
        });
    }

}

const mutations = {

    setLead(state, data){
        state.lead = data
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}