<template>
    <div v-if="!multiAccountPage" class="trial-banner" :class="{ openedMenu: openedMenu, expired: daysLeft == 0 }">
        <div v-if="daysLeft" class="text" v-html="$tc('trial.message', daysLeft == 1 ? 1 : 2, { days: daysLeft })"></div>
        <div v-else class="text" v-html="$t('trial.expired')"></div>
        <div class="actions">
            <div v-if="showButton" class="button" @click="activeAndy()">{{ $t('trial.action') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'trial-banner',
    props: {
        date: ''
    },
    computed: {
        openedMenu() {
            return this.$store.state['menu_tools_is_expanded']
        },
        showButton() {
            let user = this.$store.getters['loginUser/getLocalUser']
            if (user) {
                let rol = this.$store.getters['employee/getRoles'](user.rol)
                return rol && parseInt(rol.level) >= 7
            }
            return false
        },
        multiAccountPage() {
            const multiaccount = this.$store.getters['login/getEmployeeMultiAccount']
            return multiaccount
        }
    },
    data() {
        return {
            daysLeft: 0
        }
    },
    methods: {
        activeAndy() {
            window.open(this.$store.getters['login/getTrialLink'])
        }
    },
    created() {
        if (typeof this.date === 'string') {
            let trial_end_date = moment(this.date)
            let now = moment()
            let differenceDays = trial_end_date.diff(now, 'days') + 1
            this.daysLeft = now.isAfter(trial_end_date) ? 0 : differenceDays
        }
    }
}
</script>

<style lang="scss">
.trial-banner {
    height: 50px;
    width: 100%;
    background-color: #ffeeb1;
    position: fixed;
    top: 60px;
    z-index: 49;
    display: flex;
    padding-left: calc(25px + #{$menu-tools-min});

    @include box-shadow($h: 0px, $v: 3px, $b: 4px, $s: 0px, $c: rgba(0, 0, 0, 0.1));

    &.openedMenu {
        padding-left: calc(25px + #{$menu-tools-max});
    }

    &.expired {
        background-color: $color-warning-100;
    }

    .text {
        height: 100%;
        display: flex;
        align-items: center;
        width: 80%;

        b {
            padding: 0 5px;
            display: contents;
        }
    }

    .actions {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 25px;

        .button {
            cursor: pointer;
            background-color: $color-primary-500;
            border-radius: 4px;
            padding: 8px 30px;
            color: #fff;
        }

        .button:hover {
            background-color: $color-primary-800;
        }
    }
}
</style>
