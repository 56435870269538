import axios from 'axios'
const API_URL = process.env.VUE_APP_API_URL

export class Api {
    constructor() {}

    get(uri) {
        axios.defaults.headers.common['Authorization'] = localStorage.token
        axios.defaults.timeout = 30000
        const url = API_URL + uri
        var self = this
        return axios
            .get(url, {
                withCredentials: true,
                validateStatus: function (status) {
                    if (status == 403) {
                        window.location.href =
                            window.window.location.origin +
                            (window.location.hash == '' ? '' : window.location.pathname.substring(0, window.location.pathname.length - 1)) +
                            (process.env.VUE_APP_URL_TYPE == 'HISTORY' ? '' : '/#') +
                            '/login'
                        return false
                    }
                    if ([503].includes(status)) {
                        window.location.href = '/maintenance.html'
                        return false
                    }
                    return true
                }
            })
            .then(function (response) {
                if (response.data) {
                    return response.data
                }
                // if( response.data.status )
                // 	return response.data.data;
            })
    }

    post(uri, params) {
        axios.defaults.headers.common['Authorization'] = localStorage.token
        axios.defaults.timeout = 30000

        const oParams = new URLSearchParams()
        Object.keys(params).forEach((key) => {
            oParams.append(key, params[key])
        })

        const url = API_URL + uri
        return axios
            .post(url, oParams, {
                withCredentials: true,
                validateStatus: function (status) {
                    if (status == 403) {
                        window.location.href =
                            window.window.location.origin +
                            (window.location.hash == '' ? '' : window.location.pathname.substring(0, window.location.pathname.length - 1)) +
                            (process.env.VUE_APP_URL_TYPE == 'HISTORY' ? '' : '/#') +
                            '/login'
                        return false
                    }
                    if ([503].includes(status)) {
                        window.location.href = '/maintenance.html'
                        return false
                    }
                    return true
                }
            })
            .then(function (response) {
                if (response.data) {
                    return response.data
                }
                // if( response.data.status )
                // 	return response.data.data;
            })
    }

    login(uri, params) {
        const oParams = new URLSearchParams()
        Object.keys(params).forEach((key) => {
            oParams.append(key, params[key])
        })

        const url = API_URL + uri
        return axios.post(url, oParams, {}).then(function (response) {
            return response.data
        })
    }

    getSession() {
        axios.defaults.headers.common['Authorization'] = localStorage.token

        const url = API_URL + 'admin/init'
        var self = this
        return axios
            .get(url, {
                validateStatus: function (status) {
                    if (status == 403) {
                        return {
                            status: false,
                            error: 403
                        }
                    }
                    return true
                }
            })
            .then(function (response) {
                return response.data
            })
    }

    language(lang) {
        const url = API_URL + 'admin/lang/' + lang
        var self = this
        return axios
            .get(url, {
                validateStatus: function (status) {
                    return true
                }
            })
            .then(function (response) {
                return response.data
            })
    }
}
