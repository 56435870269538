import Vue from 'vue'
import { FORMAT } from '@/constants'
import { Api } from '@/core/api.js'

const api = new Api()

const defaultFilters = {
    location: [],
    employees: [],
    type_date: 'week',
    // for custom dates:
    start_date: '',
    end_date: ''
}

const getDefaultState = () => {
    return {
        itemsLoaded: false,
        templatesLoaded: false,
        currentFilters: structuredClone(defaultFilters),
        activeFilters: structuredClone(defaultFilters),
        filters: {
            locations: {},
            employees: {}
        },
        filtersTemplateActive: {},
        filtersStatus: {},
        items: {},
        reportsItems: {},
        type: 3,
        config: {
            date_format: 'YYYY-MM-DD',
            hour_format: 'HH:mm',
            datetime_format: 'YYYY-MM-DD HH:mm',
            has_score: true,
            has_state: true,
            has_timing: true,
            has_mandatory: false,
            is_autosave: true,
            can_create: false,
            item_search: false,
            has_calendar: true,
            temporal_view: false,
            close_when_completed: false,
            auth_users: {
                pin: 2,
                review: 2,
                task: 1
            },
            can_edit: {
                title: true,
                state: false,
                inPast: false,
                inTime: true,
                inFuture: true
            },
            item: {
                itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK],
                itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE],
                can_pospone: true,
                rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                can_review: true,
                has_periodicity: true,
                has_score: false
            }
        },
        templates: {},
        template_selected: false,
        can_load_list: false,
        numItems: 0,
        galleryTemplates: false,
        resetFiltersState: false
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    getResetFiltersState: (state, getters, rootState) => {
        return state.resetFiltersState
    },
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },
    getTemplatesLoaded: (state, getters, rootState) => {
        return state.templatesLoaded
    },

    getTemplates: (state, getters, rootState) => (id) => {
        if (id) {
            return state.templates[id]
        }
        return state.templates
    },

    getList: (state, getters, rootState) => (type) => {
        return state.items
    },

    getListReports: (state, getters, rootState) => (ids) => {
        var result = {}
        ids.forEach((id) => {
            result[id] = state.reportsItems[id]
        })
        return result
    },

    getTemplateSelected: (state, getters, rootstate) => {
        return state.template_selected ? state.templates[state.template_selected] : false
    },


    getFilterEmployees: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.employees[id]
        }
        return state.filters.employees
    },

    getActiveFilters: (state, getters, rootState) => {
        return state.activeFilters
    },

    getFiltersTemplateActive: (state, getters, rootState) => {
        return state.filtersTemplateActive
    },

    getFiltersStatus: (state, getters, rootState) => {
        return state.filtersStatus
    },

    getCanLoadList: (state, getters, rootState) => {
        return state.can_load_list
    },
    getNumItems: (state, getters, rootState) => {
        return state.numItems
    },
    getGalleryTemplates: (state, getters, rootState) => {
        return state.galleryTemplates
    },
    getCurrentFilters: (state, getters, rootState) => {
        return state.currentFilters
    }
}

// actions
const actions = {
    loadTemplates(context, params) {
        context.commit('setTemplatesLoaded', false)
        return api.post('admin/checklist/templates/' + state.type, params).then(function (response) {
            context.dispatch('formatTemplates', { data: response.data, type: state.type })
        })
    },

    loadGalleryTemplates(context, params) {
        return api.post('checklist/admin/' + state.type + '/gallery/list', {}).then(function (response) {
            if (response.status) {
                context.commit('setGalleryTemplates', response.data)
            }
        })
    },

    importGalleryTemplate(context, params) {
        return api.post('checklist/admin/' + state.type + '/gallery/' + params.tpl_id + '/import', {}).then(function (response) {
            return response
        })
    },

    loadList(context, params) {
        return api.post('checklist/admin/' + state.type, params).then(function (response) {
            context.dispatch('format', { data: response.data.data, type: state.type })
            context.dispatch('format', { data: response.data.data, type: state.type, commit: 'report' })
            context.commit('setNumItems', response.data.results)
        })
    },

    loadRecordTemplates(context, params) {
        state.items = []
        return api.post('admin/checklist/templates/' + state.type, params).then(function (response) {
            context.commit('setFormat', { items: response.data })
        })
    },
    loadReportList(context, params) {
        return api.post('checklist/admin/' + state.type + '/reports', params).then(function (response) {
            for (var index in response.data) {
                if (state.items[index]) {
                    var item = response.data[index]
                    state.items[index].items = item.items
                }
            }
            context.dispatch('format', { data: response.data, type: state.type, commit: true })
        })
    },

    downloadReportListExcel(context, params) {
        return api
            .post(`checklist/admin/${state.type}/reports/excel`, params)
            .then(function (response) {
                return response.file
            })
            .catch(function (error) {
                console.error(error)
                return false
            })
    },

    downloadReportListCsv(context, params) {
        return api
            .post(`checklist/admin/${state.type}/reports/csv`, params)
            .then(function (response) {
                return response.file
            })
            .catch(function (error) {
                console.error(error)
                return false
            })
    },

    loadFilters(context) {
        return api.get(`checklist/admin/${state.type}/filter`).then(function (response) {
            context.commit('setFilterLocations', response.data.locations)
            context.commit('setFilterEmployees', response.data.employees)
            context.commit('setFilterStatus', response.data.status)
        })
    },

    formatTemplates({ state, context, commit, dispatch, rootState }, params = {}) {
        let aTemplates = {}

        if (typeof params.data !== 'undefined') {
            Object.keys(params.data).map(function (item_id, i) {
                let template = params.data[item_id]

                // format dates
                template.created_date = template.created_date ? (moment.isMoment(template.created_date) ? template.created_date : moment(template.created_date * 1000)) : false
                template.last_update = template.last_update ? (moment.isMoment(template.last_update) ? template.last_update : moment(template.last_update * 1000)) : false

                aTemplates[template.id] = template
            })
        }

        commit('setTemplates', { items: aTemplates })
    },

    format({ state, context, commit, dispatch, rootState }, params = {}) {
        let aItems = {}

        if (typeof params.data !== 'undefined') {
            Object.keys(params.data).map(function (item_id, i) {
                let element = params.data[item_id]

                // format dates
                element.completed_date = element.completed_date ? (moment.isMoment(element.completed_date) ? element.completed_date : moment(element.completed_date * 1000)) : false
                element.created_date = element.created_date ? (moment.isMoment(element.created_date) ? element.created_date : moment(element.created_date * 1000)) : false
                element.end_date = element.end_date ? (moment.isMoment(element.end_date) ? element.end_date : moment(element.end_date * 1000)) : false
                element.start_date = element.start_date ? (moment.isMoment(element.start_date) ? element.start_date : moment(element.start_date * 1000)) : false
                element.table_data = {}

                // loop for items
                for (let key in element.items) {
                    // get item
                    let item = element.items[key]

                    // save id into table data
                    element.table_data[item.title] = key
                }

                aItems[element.id] = element
            })
        }

        if (params.commit) {
            commit('setFormatReport', { items: aItems })
        } else {
            commit('setFormat', { items: aItems })
        }
    },

    supervisePdfPrintlog({ state, context, commit, dispatch, rootState }, params = {}) {
        return api.get(`admin/checklist/${params.id}/pdf/print`).then(function (response) {
            return response
        })
    },

    superviseReportLog({ state, context, commit, dispatch, rootState }, params = {}) {
        return api.post(`admin/checklist/report/log`, { checklists: params.checklists }).then(function (response) {
            return response
        })
    }
}

// mutations
const mutations = {
    setResetFiltersState(state, payload) {
        state.resetFiltersState = payload
    },
    setTemplates(state, payload) {
        state.templates = payload.items
        Vue.set(state, 'templates', { ...payload.items })
        state.itemsLoaded = true
        state.templatesLoaded = true
    },
    setTemplatesLoaded(state, payload) {
        Vue.set(state, 'templatesLoaded', payload)
    },

    setFormat(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },

    setFormatReport(state, payload) {
        state.reportsItems = payload.items
        Vue.set(state, 'reportsItems', { ...payload.items })
    },

    setTemplateSelected(state, template) {
        state.template_selected = template
        Vue.set(state, 'template_selected', template)
    },

    setFilterLocations(state, payload) {
        let filters = state.filters
        filters.locations = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },

    setFilterEmployees(state, payload) {
        let filters = state.filters
        filters.employees = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },

    setActiveFilters(state, filters) {
        state.activeFilters = filters
        Vue.set(state, 'activeFilters', { ...filters })
    },

    setFiltersTemplateActive(state, filters) {
        state.filtersTemplateActive = filters
        Vue.set(state, 'filtersTemplateActive', { ...filters })
    },

    setFilterStatus(state, filters) {
        state.filtersStatus = filters
        Vue.set(state, 'filtersStatus', { ...filters })
    },

    canLoadList(state, value) {
        state.can_load_list = value
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    setNumItems(state, payload) {
        Vue.set(state, 'numItems', payload)
    },

    setGalleryTemplates(state, data) {
        state.galleryTemplates = data
        Vue.set(state, 'galleryTemplates', { ...data })
    },

    setCurrentFilters(state, filters) {
        state.currentFilters = { ...filters }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
