<template>
    <div class="tags-container" v-if="tags && value && nameTag && tags.length > 0">
        <div class="tags-content" v-if="showLess && limit != false">
            <div class="tag-box" :key="index" v-for="(tag, index, index2) in tags">
                <template v-if="tag != undefined && (index2 < limit || index < limit) && (referenceObject[tag] || tag[nameTag])">
                    <div
                        :class="[{ 'remove-tag': !removable, 'wrong-tag': checkAvailability(tag) }, { backImage: backImage }, 'tag-name']"
                        :style="{
                            backgroundImage: backImage ? ' url( ' + cssImage + backImage + ')' : '',
                            'background-color': backColor ? (referenceObject ? referenceObject[tag][backColor] : tag[backColor]) + ' !important' : ''
                        }"
                        v-if="removable"
                        v-tooltip="tagTooltip(tag)"
                    >
                        <div class="text" v-html="referenceObject && referenceObject[tag] ? referenceObject[tag][nameTag] : tag[nameTag]"></div>
                        <div v-if="removable" @click="removeItem(tag)" class="removable"></div>
                    </div>
                    <div
                        :class="[{ 'remove-tag': !removable, 'wrong-tag': checkAvailability(tag) }, { backImage: backImage }, 'tag-name']"
                        :style="{
                            backgroundImage: backImage ? ' url( ' + cssImage + backImage + ')' : '',
                            'background-color': backColor ? (referenceObject ? referenceObject[tag][backColor] : tag[backColor]) + ' !important' : ''
                        }"
                        v-else-if="!removable"
                        v-tooltip="tagTooltip(tag)"
                        v-html="referenceObject ? referenceObject[tag][nameTag] : tag[nameTag]"
                    ></div>
                </template>
            </div>
        </div>
        <div class="tags-content" v-else>
            <div class="tag-box" :key="index" v-for="(tag, index, index2) in tags">
                <div
                    class="tag-name"
                    v-if="tag != undefined && removable && (referenceObject[tag] || tag[nameTag])"
                    :class="{ 'remove-tag': !removable, 'wrong-tag': checkAvailability(tag), backImage: backImage }"
                    :style="{
                        backgroundImage: backImage ? ' url( ' + cssImage + backImage + ')' : '',
                        'background-color': backColor ? (referenceObject ? referenceObject[tag][backColor] : tag[backColor]) + ' !important' : ''
                    }"
                    v-tooltip="tagTooltip(tag)"
                >
                    <div class="text" v-html="referenceObject && referenceObject[tag] ? referenceObject[tag][nameTag] : tag[nameTag]"></div>
                    <div v-if="removable" @click="removeItem(tag)" class="removable"></div>
                </div>
                <div
                    class="tag-name"
                    v-else-if="tag != undefined && !removable"
                    :class="{ 'remove-tag': !removable, 'wrong-tag': checkAvailability(tag), backImage: backImage }"
                    :style="{
                        backgroundImage: backImage ? ' url( ' + cssImage + backImage + ')' : '',
                        'background-color': backColor ? (referenceObject ? referenceObject[tag][backColor] : tag[backColor]) + ' !important' : ''
                    }"
                    v-tooltip="tagTooltip(tag)"
                    v-html="referenceObject ? referenceObject[tag][nameTag] : tag[nameTag]"
                ></div>
            </div>
        </div>
        <div class="read-more" v-if="limit < tags.length && limit != false" @click.stop="showLess = !showLess">
            {{ showLess ? $t(moreliteral) : $t(lessliteral) }}
        </div>
    </div>
</template>

<!--<template>
     <div class="tags-container" v-if="tags && value && nameTag && tags.length > 0"> 
        <div class="tags-content" v-if="showLess && limit != false">
            <div class="tag-box" :key="index" v-for="(tag, index, index2) in tags">
                <template v-if="tag != undefined && (index2 < limit || index < limit)">
                    <div :class="[{ 'remove-tag': !removable, 'wrong-tag': checkAvailability(tag) }, { backImage: backImage }, 'tag-name']" :style="backImage ? 'backgroundImage: url( /../../img/' + backImage + ')' : ''" v-if="removable" v-tooltip="tagTooltip(tag)" v-html="referenceObject ? referenceObject[tag][nameTag] : tag[nameTag]"></div>
                    <div v-if="removable" @click="removeItem(tag)" class="removable"></div>
                    <div :class="[{ 'remove-tag': !removable, 'wrong-tag': checkAvailability(tag) }, { backImage: backImage }, 'tag-name']" :style="backImage ? 'backgroundImage: url( /../../img/' + backImage + ')' : ''" v-else-if="!removable" v-tooltip="tagTooltip(tag)" v-html="referenceObject ? referenceObject[tag][nameTag] : tag[nameTag]"></div>
                </template>
            </div>
        </div>
        <div class="tags-content" v-else>
            <div class="tag-box" :key="index" v-for="(tag, index, index2) in tags">
                <div class="tag-name" v-if="tag != undefined && removable" :class="{ 'remove-tag': !removable, 'wrong-tag': checkAvailability(tag), backImage: backImage }" :style="backImage ? 'backgroundImage: url( /../../img/' + backImage + ')' : ''" v-tooltip="tagTooltip(tag)" v-html="referenceObject ? referenceObject[tag][nameTag] : tag[nameTag]"></div>
                <div v-if="removable" @click="removeItem(tag)" class="removable"></div>
                <div class="tag-name" v-else-if="tag != undefined && !removable" :class="{ 'remove-tag': !removable, 'wrong-tag': checkAvailability(tag), backImage: backImage }" :style="backImage ? 'backgroundImage: url( /../../img/' + backImage + ')' : ''" v-tooltip="tagTooltip(tag)" v-html="referenceObject ? referenceObject[tag][nameTag] : tag[nameTag]"></div>
            </div>
        </div>
        <div class="read-more" v-if="limit < tags.length && limit != false" @click.stop="showLess = !showLess">
            {{ showLess ? $t(moreliteral) : $t(lessliteral) }}
        </div>
    </div>
</template>
-->

<script>
export default {
    name: 'TagsComponent',
    props: {
        tags: { default: undefined },
        value: { default: undefined },
        nameTag: { default: undefined },
        limit: { default: 3 }, // false para ver todo
        removable: { type: Boolean, default: false },
        availableEntities: { default: false },
        moreliteral: { type: String, default: 'assets.tags.see_more' },
        lessliteral: { type: String, default: 'assets.tags.see_less' },
        newInput: { type: Boolean, default: false },
        referenceObject: { type: [Object, Boolean], default: false },
        backImage: { type: [String, Boolean], default: false },
        backColor: { type: [String, Boolean], default: false }
    },
    data() {
        return {
            showLess: true,
            cssImage: css_image_url
        }
    },
    computed: {},
    methods: {
        removeItem(tag) {
            var self = this
            var index = this.tags.findIndex(function (o) {
                if (typeof tag == 'string') {
                    return o === tag
                } else {
                    var property = self.value
                    return o[property] === tag[property]
                }
            })

            if (index !== -1) this.tags.splice(index, 1)

            //Code for new autocomplete input
            if (this.newInput) {
                this.$emit('inputAutocompleteRemovable', index)
            }
        },

        checkAvailability(tag) {
            if (this.availableEntities) {
                if (Array.isArray(this.availableEntities)) {
                    if (this.availableEntities.includes(tag)) {
                        return false
                    } else return true
                } else {
                    if (!this.availableEntities[tag.id] || (this.availableEntities[tag.id].id ? tag.id != this.availableEntities[tag.id].id : false)) {
                        return true
                    } else return false
                }
            } else return false
        },

        tagTooltip(tag) {
            return {
                content: tag.tooltipMsg ? tag.tooltipMsg : '',
                classes: tag.tooltipClass ? tag.tooltipClass : ''
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tags-container {
    padding-top: 10px;

    .tags-content {
        @include display-flex();
        @include flex-direction(row);
        @include flex-wrap(wrap);
        width: auto;
    }

    .tag-box {
        width: fit-content;

        .tag-name {
            @include display-flex();
            @include background($color: $color-neutral-300);
            @include font-size(xs);
            @include align-items();
            @include border-radius(2px);
            font-family: $text;
            color: $color-black;
            padding: 3px 0px 3px 6px;
            width: fit-content;
            margin-bottom: 6px;
            margin-right: 5px;
            width: auto;

            .text {
                @include font-size(sm);
                font-family: $text-medium;
                color: $color-black;
                margin: 1px;
            }

            &.backImage {
                @include background($position: left 5px center, $size: 16px);
                padding-left: 25px;
            }

            .removable {
                @include background($image: img('close_neutro_s60.svg'), $size: 10px);
                min-width: 20px;
                width: 20px;
                height: 20px;
                // padding: 6px;
                cursor: pointer;
            }

            &.remove-tag {
                padding-right: 6px;
                width: max-content;
            }

            &.wrong-tag {
                background-color: $color-error-100;
            }

            b,
            strong,
            i,
            u {
                color: inherit !important;
            }
            b,
            strong {
                font-family: $text-bold;
                font-size: inherit;
                padding-left: 4px;
            }
        }
    }

    .read-more {
        @include font-size(xs);
        text-decoration: underline;
        font-family: $text-medium;
        cursor: pointer;
    }
}

.type {
    display: inline-block;
    .timer-tag {
        display: none;
        vertical-align: middle;
        @include background($image: img('menu_timer_inactive.svg'), $size: 20px);
        width: 30px;
        min-width: 25px;
        height: 30px;
        padding: 6px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        cursor: pointer;

        &:not(.active):hover {
            transition: 0.3s;
            @include bgHover($color-primary-100);
            @include background($image: img('menu_timer_active.svg'), $size: 20px);
        }

        &.active {
            display: inline-block;
            @include background($image: img('menu_timer_active.svg'), $size: 20px);
        }
    }

    .label-tag {
        @include background($image: img('label_inactive.svg'), $size: 20px);
        width: 30px;
        min-width: 25px;
        height: 30px;
        padding: 6px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        cursor: pointer;
        display: none;
        vertical-align: middle;

        &:not(.active):hover {
            transition: 0.3s;
            @include bgHover($color-primary-100);
            @include background($image: img('Label-active.svg'), $size: 20px);
        }

        &.active {
            display: inline-block;
            @include background($image: img('Label-active.svg'), $size: 20px);
        }
    }
}
</style>
