import Vue from 'vue'
import { FORMAT } from '@/constants'
import store from '@/store'
import { Api } from '@/core/api.js'
import { filter } from 'lodash'

const api = new Api()

const defaultFilters = {
    sites: '',
    template: [],
    tplType: '',
    timeframe: [],
    category: [],
    tags: [],
    type_date: 'week',
    demora: false,
    score: [0, 100],
    start_date: '',
    end_date: ''
}

const getDefaultState = () => {
    return {
        itemsLoaded: false,
        filters: {
            templates: {},
            tplType: {},
            timeframes: {},
            states: {},
            category: {},
            sites: {},
            groups: {},
            tags: {}
        },
        filtersActive: structuredClone(defaultFilters),
        currentFilters: structuredClone(defaultFilters),
        defaultFilters: structuredClone(defaultFilters),
        filtersTemplateActive: {},
        items: {},
        reportsItems: {},
        type: 1,
        config: {
            date_format: 'YYYY-MM-DD',
            hour_format: 'HH:mm',
            datetime_format: 'YYYY-MM-DD HH:mm',
            has_score: true,
            has_state: true,
            has_timing: true,
            has_mandatory: false,
            is_autosave: true,
            can_create: false,
            item_search: false,
            has_calendar: true,
            temporal_view: false,
            close_when_completed: false,
            auth_users: {
                pin: 2,
                review: 2,
                task: 1
            },
            can_edit: {
                title: true,
                state: false,
                inPast: false,
                inTime: true,
                inFuture: true
            },
            item: {
                itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK],
                itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE],
                can_pospone: true,
                rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                can_review: true,
                has_periodicity: true,
                has_score: false
            }
        },
        current_date: moment().format('YYYY-MM-DD'),
        user_date_changed: false,
        can_load_list: false,
        numItems: 0,
        galleryTemplates: false,
        resetFiltersState: false
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    getById: (state, getters, rootState) => (id) => {
        return id ? state.reportsItems[id] : false
    },
    getReportById: (state, getters, rootState) => (id) => {
        return id ? state.reportsItems[id] : false
    },
    getResetFiltersState: (state, getters, rootState) => {
        return state.resetFiltersState
    },
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },
    getList: (state, getters, rootState) => (type) => {
        return state.items
    },
    getReportsList: (state, getters, rootState) => (type) => {
        return state.reportsItems
    },
    getNumItems: (state, getters, rootState) => {
        return state.numItems
    },
    getListByID: (state, getters, rootState) => (id) => {
        if (id) {
            return Object.values(state.items).filter((obj) => {
                return obj.id === id
            })[0]
        }
        return false
    },

    getSubtasks: (state, getters, rootState) => (check, task) => {
        let tasks = check ? state.reportsItems[check].items : {}
        let subtask = []

        Object.keys(tasks).map(function (item_id, i) {
            var item = tasks[item_id]
            if (item.parent_id == task) {
                subtask.push(item.item_id)
            }
        })

        return subtask
    },

    getListReports: (state, getters, rootState) => (sIds) => {
        var result = {}
        sIds.forEach(function (item) {
            result[item] = state.reportsItems[item]
        })
        return result
    },

    getFilterTemplate: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.templates[id]
        }
        return state.filters.templates
    },
    getFilterTimeframe: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.timeframes[id]
        }
        return state.filters.timeframes
    },
    getFilterCategory: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.categories[id]
        }
        return state.filters.categories
    },
    getFilterStates: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.states[id]
        }
        return state.filters.states
    },
    getFilterTags: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.tags[id]
        }
        return state.filters.tags
    },

    getFiltersActive: (state, getters, rootState) => {
        return state.filtersActive
    },
    getCurrentFilters: (state, getters, rootState) => {
        return state.currentFilters
    },
    getDefaultFilters: (state, getters, rootState) => {
        return state.defaultFilters
    },
    getFiltersTemplateActive: (state, getters, rootState) => {
        return state.filtersTemplateActive
    },
    getCanLoadList: (state, getters, rootState) => {
        return state.can_load_list
    },
    getGalleryTemplates: (state, getters, rootState) => {
        return state.galleryTemplates
    }
}

// actions
const actions = {
    resetFiltersState(context) {
        context.commit('resetState')
    },
    loadList(context, params) {
        context.commit('setItemsLoaded', false)
        if (params.date) {
            if (typeof params.date === 'string') {
                params.start_date = params.date
            } else {
                params.start_date = params.date[2]

                if (params.date[2] == 'range') {
                    params.start_date = moment(params.date[0]).startOf('day').unix()
                    params.end_date = moment(params.date[1]).endOf('day').unix()
                }
            }
        }

        return api.post('checklist/admin/' + state.type, params).then(function (response) {
            context.dispatch('format', { data: response.data.data, type: state.type, commit: true })
            context.dispatch('format', { data: response.data.data, type: state.type, commit: 'report' })
            context.commit('setNumItems', response.data.results)
        })
    },

    loadGalleryTemplates(context, params) {
        return api.post('checklist/admin/' + state.type + '/gallery/list', {}).then(function (response) {
            if (response.status) {
                context.commit('setGalleryTemplates', response.data)
            }
        })
    },

    importGalleryTemplate(context, params) {
        return api.post('checklist/admin/' + state.type + '/gallery/' + params.tpl_id + '/import', {}).then(function (response) {
            return response
        })
    },

    loadReportList(context, params) {
        return api.post('checklist/admin/' + state.type + '/reports', params).then(function (response) {
            for (var index in response.data) {
                if (state.reportsItems[index]) {
                    var item = response.data[index]
                    state.reportsItems[index].items = item.items
                }
            }

            context.dispatch('format', { data: response.data, type: state.type, commit: 'report' })
        })
    },

    downloadReportListExcel(context, params) {
        return api
            .post(`checklist/admin/${state.type}/reports/excel`, params)
            .then(function (response) {
                return response.file
            })
            .catch(function (error) {
                console.error(error)
                return false
            })
    },

    downloadReportListCsv(context, params) {
        return api
            .post(`checklist/admin/${state.type}/reports/csv`, params)
            .then(function (response) {
                return response.file
            })
            .catch(function (error) {
                console.error(error)
                return false
            })
    },

    loadChecklistTemplates(context, params) {
        state.items = []
        return api.post('admin/checklist/templates/' + state.type, params).then(function (response) {
            context.commit('setFormat', { items: response.data })
        })
    },

    loadChecklistTemplate(context, params) {
        state.items = []
        return api.get('admin/checklist/template/' + params.template_id, params).then(function (response) {
            return context.dispatch('formatTemplate', { data: response.data, type: state.type, commit: true })
            // return response.data;
        })
    },

    // add and edit template
    comment(context, params) {
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        var sended = {
            checklist: params.check,
            task: params.answer,
            message: params.message,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume)
        }

        if (typeof params.file !== 'undefined') sended.file = params.file

        return api.post('checklist/task/comment', sended).then(function (response) {
            var returned = {
                check: params.check,
                answer: params.answer,
                message: response.data.message
            }
            if (typeof response.data.image !== 'undefined') {
                returned.image = response.data.image
            }

            return context.commit('setComment', returned)
        })
    },
    validate(context, params) {
        context.commit('setValidation', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date
        })
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/validate', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {
                if (!response.status) {
                    context.commit('setReset', { check: params.check, answer: params.answer })
                }
            })
    },
    unvalidate(context, params) {
        context.commit('setUnvalidation', {
            check: params.check,
            answer: params.answer
        })
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/unvalidate', {
                checklist: params.check,
                task: params.answer,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {})
    },
    saveTemplate(context, params) {
        let template = {}
        let schema = {}

        // regular expression to compare id (for new childs)
        let regex = /^tmpitw.*$/
        for (let key in params.template.schema) {
            let child = params.template.schema[key]

            // check if is not a new item or if is a new item and has status equal to one save and sent to api
            if (!regex.exec(child.id) || (regex.exec(child.id) && child.status == 1)) {
                schema[child.id] = child
            }
        }

        // update template schema
        params.template.schema = schema

        template = { ...params.template }

        var image = template.file ? template.file : ''
        // Clean image petition
        if (template.file) {
            template.file = ''
            template.image = false
        }

        return api.post('admin/checklist/template/save', { data: JSON.stringify(template), file: image }).then(function (response) {
            // TODO ...
        })
    },
    complete(context, params) {
        context.dispatch('calcScore', params.checklist)

        let check = context.getters.getById(params.checklist)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/complete', {
                checklist: params.checklist,
                complete_date: params.complete_date,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {
                return response
            })
    },
    uncomplete(context, params) {
        return api
            .post('checklist/uncomplete', {
                checklist: params.checklist
            })
            .then(function (response) {
                return response
            })
    },
    loadFilters(context) {
        return api.get('checklist/admin/' + state.type + '/filter').then(function (response) {
            context.commit('setFilterTemplates', response.data.templates)
            context.commit('setFilterTimeframes', response.data.timeframes)
            context.commit('setFilterSites', response.data.locations)
            context.commit('setFilterGroups', response.data.groups)
            context.commit('setFilterTags', response.data.tags)
            context.commit('setFilterStates', response.data.states)
            context.commit('setFilterCategories', response.data.categories)
        })
    },
    answer(context, params) {
        if (fnCheckConnection()) {
            let value = params.value
            let subtask = context.getters.getSubtasks(params.check, params.answer)

            // In case of image or signature type, JSON-parse the value
            if (state.config.item.itemsImage.includes(params.answer_type)) {
                value = JSON.stringify(value)
            }

            // answer
            context.commit('setAnswer', {
                check: params.check,
                answer: params.answer,
                emp: params.emp,
                date: params.date,
                value: params.value
            })

            // calculate score
            context.dispatch('calcScore', params.check)

            if (subtask.length > 0) {
                Object.keys(subtask).map(function (item_id, i) {
                    var item = subtask[item_id]
                    context.commit('setReset', { check: params.check, answer: item })
                })
            }

            let check = context.getters.getById(params.check)
            let total = check.total
            let answered = check.completed
            let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
            let resume = { it: 0, is: 0, a: 0, n: 0 }

            for (let id in check.items) {
                let item = check.items[id]
                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }

            return api
                .post('checklist/task/answer', {
                    checklist: params.check,
                    task: params.answer,
                    employee: params.emp,
                    complete_date: params.date,
                    value: value,
                    total: total,
                    answered: answered,
                    score: score,
                    resume: JSON.stringify(resume)
                })
                .then(function (response) {
                    if (!response.status) {
                        context.commit('setReset', { check: params.check, answer: params.answer })
                        context.dispatch('calcScore', params.check)
                    } else {
                        let value = response.data.value
                        // In case of image or signature type, JSON-parse the value
                        if (state.config.item.itemsImage.includes(params.answer_type)) {
                            value = value.split(',')

                            // answer
                            context.commit('setAnswer', {
                                check: params.check,
                                answer: params.answer,
                                emp: params.emp,
                                date: params.date,
                                value: value
                            })
                        }
                    }
                    return response
                })
        } else {
            context.commit('setReset', { check: params.check, answer: params.answer })
            return { status: false }
        }
    },
    apply(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        let item = state.items[params.check].items[params.answer]
        let items = context.getters.getItemsBySection(params.check, params.answer)

        // apply
        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.apply == 0 ? params.date : false,
            apply: params.apply
        })

        if (item.type == FORMAT.TITLE) {
            Object.keys(items).map(function (item_id, i) {
                let it = items[item_id]
                context.commit('setApply', { check: params.check, answer: it.item_id, date: params.apply == 0 ? params.date : false, apply: params.apply })
            })
        }

        // calculate score
        context.dispatch('calcScore', params.check)

        // reset child items
        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                let item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        // calculate score

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        let paramsApi = {
            checklist: params.check,
            task: params.answer,
            employee: params.emp,
            complete_date: params.date,
            apply: params.apply,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume)
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/apply', paramsApi)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        paramsApi.action = 'apply'
                        return context.dispatch('syncAction', paramsApi)
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                    }
                })
                .catch(() => {
                    if (context.rootGetters.getOffline) {
                        paramsApi.action = 'apply'
                        return context.dispatch('syncAction', paramsApi)
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                    }
                })
        } else {
            if (context.rootGetters.getOffline) {
                paramsApi.action = 'apply'
                return context.dispatch('syncAction', paramsApi)
            } else {
                context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
            }
        }
    },
    applyrecurrent(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        // apply
        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.apply == 0 ? params.date : false,
            apply: params.apply
        })

        var item = state.items[params.check].items[params.answer]
        if (item.type == FORMAT.TITLE) {
            let items = context.getters.getItemsBySection(params.check, params.answer)

            Object.keys(items).map(function (item_id, i) {
                var it = items[item_id]
                context.commit('setApply', { check: params.check, answer: it.item_id, date: params.apply == 0 ? params.date : false, apply: params.apply })
            })
        }

        // calculate score
        context.dispatch('calcScore', params.check)

        // reset child items
        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        // calculate score

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/applyrecurrent', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                apply: params.apply,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {
                if (!response.status) {
                    context.commit('setReset', { check: params.check, answer: params.answer })
                }
            })
    },
    reset(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        context.commit('setReset', {
            check: params.check,
            answer: params.answer
        })
        context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/reset', {
                checklist: params.check,
                task: params.answer,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {})
    },
    format({ state, context, commit, dispatch, rootState }, params = {}) {
        var aItems = {}
        var bCommmit = typeof params.commit !== 'undefined' ? params.commit : false

        if (typeof params.data !== 'undefined') {
            Object.keys(params.data).map(function (template_id, i) {
                var template = params.data[template_id]

                // // Timezone set
                // template.business_date = template.business_date ? (moment.isMoment(template.business_date) ? template.business_date : moment(template.business_date * 1000)) : false
                // template.created_date = template.created_date ? (moment.isMoment(template.created_date) ? template.created_date : moment(template.created_date * 1000)) : false
                // template.complete_date = template.complete_date ? (moment.isMoment(template.complete_date) ? template.complete_date : moment(template.complete_date * 1000)) : false
                // // Timezone set TASKS
                // if (template.schedule) {
                //     template.schedule['start'] = template.schedule.start ? (moment.isMoment(template.schedule.start) ? template.schedule.start : moment(template.schedule.start * 1000)) : false
                //     template.schedule['end'] = template.schedule.end ? (moment.isMoment(template.schedule.end) ? template.schedule.end : moment(template.schedule.end * 1000)) : false
                // }
                // if (template.limit) {
                //     template.limit['start'] = template.limit.start ? (moment.isMoment(template.limit.start) ? template.limit.start : moment(template.limit.start * 1000)) : false
                //     template.limit['end'] = template.limit.end ? (moment.isMoment(template.limit.end) ? template.limit.end : moment(template.limit.end * 1000)) : false
                // }

                template.def = state.config
                template.timeframe_id = template.timeframe

                aItems[template.id] = template
                // template.templates = Object.keys(template.templates)

                // SETTER
                // bCommmit[template.id] = template
            })
        }

        for (var template_index in aItems) {
            var template = aItems[template_index]
            for (var section_index in template.sections) {
                var section = template.sections[section_index]
                for (var item_index in section.items) {
                    if (!Object.keys(aItems[template_index].items).includes(section.items[item_index])) {
                        section.items.splice(item_index, 1)
                    }
                }
            }
        }

        if (bCommmit) {
            bCommmit == 'report' ? commit('setFormatReport', { items: aItems }) : commit('setFormat', { items: aItems })
        } else {
            return aItems
        }
        // return aItems
    },
    calcScore({ state, context, commit, dispatch, rootState }, id) {
        // TODO APPEND
        // var append = params.append ? true : false;

        var aFrames = state.timeframes ? state.timeframes.data : []
        var aCategories = state.categories ? state.categories : []
        var aItems = state.reportsItems

        // if (append) {
        //   aFrames = { ...aFrames, ...params.append };
        // }

        // log('----- CALC')

        var alert_pending_count = {}
        var alert_pending_show = {}
        var alert_important_show = {}
        var c_alert_pending_count = {}
        var c_alert_pending_show = {}
        var c_alert_important_show = {}
        var tframe_id = typeof id === 'undefined' ? false : aItems[id].timeframe_id
        var category_id = typeof id === 'undefined' ? false : aItems[id].category_id

        // SETTER
        Object.keys(aFrames).map(function (item_id, i) {
            if (typeof id === 'undefined' || item_id == tframe_id) {
                var tframe = aFrames[item_id]
                tframe.completed = 0
                tframe.total = 0
                tframe.score = 0

                alert_pending_count[tframe.id] = 0
                alert_pending_show[tframe.id] = false
                alert_important_show[tframe.id] = false
                tframe.alert_pending_count = 0
                tframe.alert_pending_show = false
                tframe.alert_important_show = false
            }
        })

        // SETTER CATEGORIES
        Object.keys(aCategories).map(function (item_id, i) {
            if (typeof id === 'undefined' || item_id == category_id) {
                var category = aCategories[item_id]
                category.completed = 0
                category.total = 0
                category.score = 0

                c_alert_pending_count[category.id] = 0
                c_alert_pending_show[category.id] = false
                c_alert_important_show[category.id] = false
                category.alert_pending_count = 0
                category.alert_pending_show = false
                category.alert_important_show = false
            }
        })

        // SETTER
        Object.keys(aItems).map(function (item_id, i) {
            //   alert(item_id)
            var list = aItems[item_id]
            if (typeof id === 'undefined' || item_id == id) {
                var past_score = list.score ? list.score : 0

                list.def = state.config
                list.uncompleted = 0
                list.completed = 0
                list.unanswered = 0
                list.total = 0
                list.score = 0
                list.justify_id = list.justify_id ? list.justify_id : list.justify_msg ? '0' : false
                list.planned_items = 0
                list.completed_in_time = list.complete_date && (list.timeframe_id == 0 || list.complete_date.valueOf() <= list.schedule.end.valueOf())
                // Loop for all items
                Object.keys(list.items).map(function (item_id, i) {
                    var item = list.items[item_id]
                    item.is_visible = true
                    item.alerts = false

                    // Subtasks
                    if (typeof item.conditions !== 'undefined' && item.conditions != null && item.conditions) {
                        var conditions = item.conditions
                        var current_show = null
                        var current_order = -1
                        var cond_operator = typeof conditions.operator !== 'undefined' ? conditions.operator : 'AND'

                        for (var key in conditions.values) {
                            var show = false
                            let condition = conditions.values[key]

                            let value = list.items[key] ? list.items[key].value : false
                            item.is_visible = false

                            var operator = condition && condition.operator ? condition.operator : '='
                            var val = condition ? condition.value : false

                            log(list.items[key], value, operator, val)

                            if (
                                (value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(list.items[key].type)) &&
                                list.items[key] &&
                                list.items[key].is_visible
                            ) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    // Get task type from parent
                                    switch (list.items[key].type) {
                                        //   switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            show = compare(
                                                parseInt(val) == 1 || (val == '' && operator == '!empty'),
                                                value == true || value == 'true' || (value != null && val == '' && operator == '!empty'),
                                                operator
                                            )
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(
                                                parseInt(val) || (val == '' && operator == '!empty'),
                                                value || (val == '' && operator == '!empty'),
                                                operator
                                            )
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                                            break
                                        case FORMAT.TEMPERATURE:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            if (current_show == null) current_show = show
                            else {
                                switch (cond_operator) {
                                    case 'AND':
                                        current_show = current_show && show
                                        break
                                    case 'OR':
                                        current_show = current_show || show
                                        break
                                }
                            }
                            if (show) {
                                current_order = list.items[key].order
                            }
                        }

                        item.is_visible = current_show
                        item.order = parseFloat(current_order) + 0.5
                    }

                    // Alerts TODO
                    if (item.alerts_triggers) {
                        let alerts = item.alerts_triggers
                        let value = item.value

                        let oAlert = {}
                        let message = ''
                        let preview = ''
                        let issues = []
                        let classes = []

                        for (var i in alerts) {
                            let alert = alerts[i]
                            let operator = alert.operator ? alert.operator : '='
                            let val = alert.value
                            let date = alert.date ? alert.date : ''
                            let show = false

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(item.type)) && item.is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            show = compare(
                                                parseInt(val) == 1 || (val == '' && operator == '!empty'),
                                                value == true || value == 'true',
                                                operator
                                            )
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val) || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                            break
                                        case FORMAT.TEMPERATURE:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                            break
                                        default:
                                            break
                                    }
                                }
                            }

                            if (show && (alert.msg || alert.message)) {
                                if (message == '') message = alert.msg != '' ? alert.msg : alert.message
                                else message = message + '<br/>' + (alert.msg != '' ? alert.msg : alert.message)

                                if (preview == '') preview = alert.msg != '' ? alert.msg : alert.message
                                else preview = preview + ', ' + (alert.msg != '' ? alert.msg : alert.message)
                            }

                            if (show && alert.tpl) {
                                issues.push(alert.tpl)
                            }
                            if (show && alert.class) {
                                if (alert.class == 'alert') {
                                    alert.class = 'warning'
                                }
                                classes.push(alert.class)
                            }

                            if (show && alert.template) {
                                if (message == '') message = i18n.tc('tasks.generated_checklist', date, { c: date })
                                else message = message + '<br/>' + i18n.tc('tasks.generated_checklist', date, { c: date })

                                if (preview == '') preview = i18n.tc('tasks.generated_checklist', date, { c: date })
                                else preview = preview + ', ' + i18n.tc('tasks.generated_checklist', date, { c: date })
                            }
                        }

                        oAlert = {
                            preview: preview,
                            message: message,
                            issues: issues,
                            class: classes
                        }
                        if (oAlert.message.length > 0 || oAlert.issues.length > 0) {
                            item.alerts = oAlert
                            Vue.set(item, 'alerts', oAlert)
                        }
                    }

                    // Scoring
                    if (
                        ((!item.parent_id && list.def.item.itemsExcluded.indexOf(item.type) == -1) || (item.parent_id && item.is_visible)) &&
                        !item.planned_to_date &&
                        item.apply
                    ) {
                        switch (item.type) {
                            case FORMAT.YESNO: // SI/NO
                            case FORMAT.YESNONC: // SI/NO/NSNC
                            case FORMAT.CHECK: // CHECKS
                            case FORMAT.TEXT: // TEXTO
                            case FORMAT.PHOTO: // FOTO
                            case FORMAT.NUMBER: // NUMBER
                            case FORMAT.DATE: // DATE
                            case FORMAT.TEXTAREA: // TEXTAREA
                            case FORMAT.MULTIPLE: // MULTIPLE SELECT
                            case FORMAT.TIME: // TIME
                            case FORMAT.DATETIME: // DATETIME
                            case FORMAT.SIGNATURE: // SIGNATURE
                            case FORMAT.TEMPERATURE: // TEMPERATURE
                            case FORMAT.EMAIL: // EMAIL
                            case FORMAT.DOCUMENT: // DOCUMENT
                                if (item.complete_date == false && item.apply) list.unanswered++
                                else {
                                    // Required to be reviewed
                                    if (item.require_review) {
                                        list.completed += item.review ? 1 : 0.5
                                    } else list.completed += 1 // Not required to be reviewed
                                }

                                break

                            default:
                                break
                        }
                        if (![FORMAT.LINK, FORMAT.RESOURCE].includes(item.type)) {
                            list.total++
                        }
                    }

                    if (item.planned_to_date) {
                        list.planned_items++
                    }
                })
                let divisor = parseInt(list.total + list.planned_items) == 0 ? 1 : parseInt(list.total + list.planned_items)
                list.score = Math.round((parseFloat(list.completed + list.planned_items) * 100) / divisor)

                if (past_score != -1 && past_score < 100 && list.score >= 100) {
                    dispatch('complete', { checklist: list.id, complete_date: moment().unix(), values: [] })
                    list.complete_date = moment()
                } else if (past_score != -1 && past_score >= 100 && list.score < 100) {
                    dispatch('uncomplete', { checklist: list.id })
                    list.complete_date = false
                }

                list.need_justification = false

                let now = moment().set({ s: 0, ms: 0 })
                let limit_start = moment(list.limit.start)
                let limit_end = moment(list.limit.end)
                let schedule_start = moment(list.schedule.start)
                let schedule_end = moment(list.schedule.end)

                if (!now.isBetween(limit_start, limit_end) && parseInt(list.score) < 100 && now.isAfter(limit_end) && list.completed != list.total) {
                    list.need_justification = true
                    alert_pending_count[list.timeframe_id]++
                    if (list.category_id && aCategories[list.category_id]) {
                        c_alert_pending_count[list.category_id]++
                    }
                    if (aCategories['all']) {
                        c_alert_pending_count['all']++
                    }

                    if (!list.justify_id) {
                        alert_pending_show[list.timeframe_id] = true
                        if (list.category_id && aCategories[list.category_id]) {
                            c_alert_pending_show[list.category_id]++
                        }
                        if (aCategories['all']) {
                            c_alert_pending_show['all']++
                        }
                    }
                }

                if (
                    now.isBetween(limit_start, limit_end) &&
                    !now.isBetween(schedule_start, schedule_end) &&
                    now.isAfter(schedule_end) &&
                    list.important &&
                    parseInt(list.score) < 100
                ) {
                    alert_important_show[list.timeframe_id] = parseInt(list.score) < 100
                    if (list.category_id && aCategories[list.category_id]) {
                        c_alert_important_show[list.category_id]++
                    }
                    if (aCategories['all']) {
                        c_alert_important_show['all']++
                    }

                    list.important = parseInt(list.score) < 100
                }

                // SETTER
                if (aFrames[list.timeframe_id]) {
                    aFrames[list.timeframe_id].completed += list.completed
                    aFrames[list.timeframe_id].total += list.total
                }

                // SETTER CATEGORIES
                if (list.category_id && aCategories[list.category_id]) {
                    aCategories[list.category_id].completed += list.completed
                    aCategories[list.category_id].total += list.total
                }
                if (aCategories['all']) {
                    aCategories['all'].completed += list.completed
                    aCategories['all'].total += list.total
                }

                aItems[list.id] = { ...list }
            } else if (list.timeframe_id == tframe_id) {
                // aFrames[list.timeframe_id].completed += list.completed
                // aFrames[list.timeframe_id].total += list.total

                if (list.category_id && aCategories[list.category_id]) {
                    aCategories[list.category_id].completed += list.completed
                    aCategories[list.category_id].total += list.total
                }
                if (aCategories['all']) {
                    aCategories['all'].completed += list.completed
                    aCategories['all'].total += list.total
                }
            }
        })

        // ORDER
        Object.keys(aItems).map(function (item_id, i) {
            let list = Object.assign({}, aItems[item_id])
            if (list.items) {
                var items = Object.keys(list.items)
                    .sort((a, b) => {
                        return parseFloat(list.items[a].order) - parseFloat(list.items[b].order)
                    })
                    .reduce((prev, curr, i) => {
                        prev[i] = list.items[curr]
                        return prev
                    }, {})

                let aux2 = {}
                for (var k in items) {
                    aux2[items[k].item_id] = items[k]
                }

                list.items = aux2
                aItems[item_id] = list
            }
        })

        // SETTER
        Object.keys(aFrames).map(function (item_id, i) {
            var tframe = aFrames[item_id]
            if (typeof id === 'undefined' || item_id == tframe_id) {
                tframe.alert_pending_count = alert_pending_count[tframe.id]
                tframe.alert_pending_show = alert_pending_show[tframe.id]
                tframe.alert_important_show = alert_important_show[tframe.id]

                tframe.score = parseInt(tframe.total) == 0 ? 0 : Math.round((parseFloat(tframe.completed) * 100) / parseInt(tframe.total))
            }
        })

        // SETTER
        Object.keys(aCategories).map(function (item_id, i) {
            var category = aCategories[item_id]
            if (typeof id === 'undefined' || item_id == category_id || item_id == 'all') {
                category.alert_pending_count = c_alert_pending_count[category.id]
                category.alert_pending_show = c_alert_pending_show[category.id]
                category.alert_important_show = c_alert_important_show[category.id]

                category.score = parseInt(category.total) == 0 ? 0 : Math.round((parseFloat(category.completed) * 100) / parseInt(category.total))
            }
        })

        commit('setScore', { items: aItems })
        commit('setTimeframes', { frames: aFrames })
        commit('setCategories', aCategories)
    },
    postpone({ rootState, commit, dispatch, getters }, params) {
        let loggeduser = rootState.loginUser.local.user

        commit('setPostposed', {
            check: params.check,
            answer: params.answer,
            date: params.date,
            reason_id: params.reason,
            reason_msg: params.description,
            employee: loggeduser.id
        })

        dispatch('calcScore', params.check)

        let check = getters.getById(params.check)
        let total = check.total + check.planned_items
        let answered = check.completed + check.planned_items

        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/plan', {
                checklist: params.check,
                task: params.answer,
                date: params.date,
                reason: params.reason,
                description: params.description,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {
                return response
            })
    },
    formatTemplate({ state, context, commit, dispatch, rootState }, params = {}) {
        var aItems = {}
        var bCommmit = typeof params.commit !== 'undefined' ? params.commit : false

        if (typeof params.data !== 'undefined') {
            for (var index in params.data.schema) {
                var item = params.data.schema[index]
                item.order = parseInt(item.order)
                item.review = parseInt(item.review)
                item.mandatory = parseInt(item.mandatory)
                // item.email = 0
                // item.alert = 0
                // item.subitem = 0
                item.renderconfig = []

                // ALERT
                if (item.alerts) {
                    for (var alert_index in item.alerts) {
                        var alert = item.alerts[alert_index]

                        // // COUNT ALERTS FOR SUMMARY
                        // alert.type == 'email' ? item.email++ : item.alert++;

                        if (typeof alert.operator !== 'undefined' && typeof alert.value !== 'undefined') {
                            var result = item.renderconfig.find((obj) => {
                                if (obj.operator && obj.value) {
                                    return obj.operator == alert.operator && obj.value == alert.value
                                }
                            })

                            if (!result) {
                                var key = Object.keys(item.renderconfig).length
                                item.renderconfig[key] = {}
                                item.renderconfig[key].items = []
                                item.renderconfig[key].operator = alert.operator
                                item.renderconfig[key].value = alert.value
                            } else var key = result

                            alert.conftype = 'alert'
                            alert.class = alert.class ? alert.class : 'info'
                            item.renderconfig[key].items.push(alert)
                        }
                    }
                }

                // SUBITEM
                if (item.config.conditions || item.conditions) {
                    var conditions = item.config.conditions ? item.config.conditions : item.conditions
                    for (var idx in conditions) {
                        if (typeof conditions[idx] !== 'undefined') {
                            for (var config_index in conditions[idx].values) {
                                var config = conditions[idx].values[config_index]

                                config.item_id = conditions[idx].item_id
                                config.order = parseInt(params.data.schema[config.item_id].order)

                                if (typeof config.operator !== 'undefined') {
                                    var result = Object.keys(item.renderconfig).find((obj) => {
                                        if (item.renderconfig[obj].operator && item.renderconfig[obj].value !== undefined) {
                                            return item.renderconfig[obj].operator == config.operator && item.renderconfig[obj].value == config.value
                                        }
                                    })

                                    if (!result) {
                                        var key = item.renderconfig.length
                                        item.renderconfig[key] = {}
                                        item.renderconfig[key].items = []
                                        item.renderconfig[key].operator = config.operator
                                        item.renderconfig[key].value = config.value
                                    } else var key = result

                                    // // COUNT SUBITEMS FOR SUMMARY
                                    // item.subitem++;

                                    config.conftype = 'subitem'
                                    item.renderconfig[key].items.push(config)
                                }
                            }
                        }
                    }
                }
            }
        }
        return params.data
    },
    supervisePdfPrintlog({ state, context, commit, dispatch, rootState }, params = {}) {
        return api.get(`admin/checklist/${params.id}/pdf/print`).then(function (response) {
            return response
        })
    },
    superviseReportLog({ state, context, commit, dispatch, rootState }, params = {}) {
        return api.post(`admin/checklist/report/log`, { checklists: params.checklists }).then(function (response) {
            return response
        })
    }
}

// mutations
const mutations = {
    setResetFiltersState(state, payload) {
        state.resetFiltersState = payload
    },
    // Common but may change in other tools
    setFormat(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
        state.itemsLoaded = true
    },
    setFormatReport(state, payload) {
        state.reportsItems = payload.items
        Vue.set(state, 'reportsItems', { ...payload.items })
        state.itemsLoaded = true
    },
    setItemsLoaded(state, payload) {
        Vue.set(state, 'itemsLoaded', payload)
    },
    setNumItems(state, payload) {
        Vue.set(state, 'numItems', payload)
    },
    setFilterTimeframes(state, payload) {
        var filters = state.filters
        filters.timeframes = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },

    setFilterCategories(state, payload) {
        var filters = state.filters
        filters.categories = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },

    setFilterStates(state, payload) {
        var filters = state.filters
        filters.states = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },

    setFilterTemplates(state, payload) {
        var filters = state.filters
        filters.templates = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },

    setFilterSites(state, payload) {
        var filters = state.filters
        filters.sites = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },

    setFilterGroups(state, payload) {
        var filters = state.filters
        filters.groups = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },

    setFilterTags(state, payload) {
        var filters = state.filters
        filters.tags = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },

    setFiltersActive(state, filters) {
        // state.filtersActive = filters
        // Vue.set(state, 'filtersActive', { ...filters })
        state.filtersActive = { ...filters }
    },

    setCurrentFilters(state, filters) {
        state.currentFilters = { ...filters }
    },

    setFiltersTemplateActive(state, filters) {
        state.filtersTemplateActive = filters
        Vue.set(state, 'filtersTemplateActive', { ...filters })
    },
    setPostposed(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var date = payload.date
        var reason_id = payload.reason_id
        var reason_msg = payload.reason_msg
        let employee = payload.employee

        // todo set employee

        state.reportsItems[check].items[answer].planned_reason_id = reason_id
        state.reportsItems[check].items[answer].planned_reason_msg = reason_msg
        state.reportsItems[check].items[answer].planned_to_date = date
        state.reportsItems[check].items[answer].employee = employee

        Vue.set(state, 'reportsItems', { ...state.reportsItems })
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    canLoadList(state, value) {
        state.can_load_list = value
    },

    setGalleryTemplates(state, data) {
        state.galleryTemplates = data
        Vue.set(state, 'galleryTemplates', { ...data })
    },

    setAnswer(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var value = payload.value
        var date = payload.date

        state.reportsItems[check].items[answer].value = value

        if (date) {
            var item = state.reportsItems[check].items[answer]
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) state.reportsItems[check].items[answer].employee = emp

        Vue.set(state, 'reportsItems', { ...state.reportsItems })
    },
    setComment(state, payload) {
        let check = payload.check
        let answer = payload.answer
        let aux = state.reportsItems[check].items[answer]

        aux.message = payload.message
        aux.image = payload.image ? payload.image : false

        Vue.set(state.reportsItems[check].items, answer, { ...aux })
        Vue.set(state, 'reportsItems', { ...state.reportsItems })
    },
    setApply(state, payload) {
        const check = payload.check
        const answer = payload.answer
        const emp = payload.emp
        const apply = payload.apply
        const date = payload.date

        state.reportsItems[check].items[answer].apply = apply

        if (date) {
            state.reportsItems[check].items[answer].complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
            state.reportsItems[check].items[answer].value = null
        } else {
            state.reportsItems[check].items[answer].complete_date = false
        }

        if (emp) {
            state.reportsItems[check].items[answer].employee = emp
        }

        Vue.set(state, 'reportsItems', { ...state.reportsItems })
    },
    setReset(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.reportsItems[check].items[answer].complete_date = false
        state.reportsItems[check].items[answer].employee = false
        state.reportsItems[check].items[answer].review = false
        state.reportsItems[check].items[answer].value = null

        Vue.set(state, 'reportsItems', { ...state.reportsItems })
    },
    setValidation(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var date = payload.date

        state.reportsItems[check].items[answer].review = {}
        if (date) {
            var item = state.reportsItems[check].items[answer].review
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) {
            var employee = store.getters['employee/getAccountEmployee'](emp)
            state.reportsItems[check].items[answer].review.employee = employee
        }
        state.reportsItems[check].items[answer].review.value = 1

        Vue.set(state, 'reportsItems', { ...state.reportsItems })
    },
    setUnvalidation(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.reportsItems[check].items[answer].review = false

        Vue.set(state, 'reportsItems', { ...state.reportsItems })
    },
    setScore(state, payload) {
        state.reportsItems = payload.items
        Vue.set(state, 'reportsItems', { ...payload.items })
    },

    // Tasks
    setTimeframes(state, value) {},

    setCategories(state, value) {}
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
