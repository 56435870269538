<template>
    <div v-if="show" class="snackbar-container" :class="{ showPopup: showInPopup }">
        <transition name="fade">
            <div class="snackbar" :class="[type, color, customClass]">
                <vue-typed-js :strings="[textFormat(message)]" :loop="false" :typeSpeed="2" :startDelay="0" :showCursor="false">
                    <span class="text typing"> </span>
                </vue-typed-js>
                <span v-if="closeable" class="close-snackbar" @click="closeUndo()"></span>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            talk: false,
            closeable: true,
            type: '',
            message: '',
            customClass: '',
            showInPopup: false,
            action: function () {},
            duration: 3000000,
            color: ''
        }
    },
    computed: {},
    methods: {
        textFormat(text) {
            return text.replace('<!', '&lt;!')
        },
        doIt() {
            this.btn.callback(this.btn.props)
        },
        closeUndo() {
            this.show = false
        }
    },
    mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// snackbar
.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s ease-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
}

.snackbar-container {
    @include display-flex();
    @include justify-content();
    width: 100%;
    position: absolute;
    top: $menu-top-height - calc($menu-top-height / 3);
    left: 0;
    pointer-events: none;
    z-index: $z-snackbar-default;

    @media (max-width: 767px) {
        top: 96px;
        padding: 8px 4px !important;

        .typed-element .text {
            text-align: center !important;
            padding-left: 20px !important;
        }
    }

    .snackbar {
        // @include background($color: $color-primary-100, $size: 30px);
        background-color: $color-primary-100;
        @include border-radius(4px);
        @include display-flex();
        @include align-items(center);
        @include align-content();
        min-height: 0px;
        height: auto;
        max-width: 900px;
        width: auto;
        padding: 9px 12px;
        pointer-events: auto;

        .text {
            @include font-size(md);
            font-family: $text-medium;
            color: $color-black;
        }
        .close-snackbar {
            @extend .interaction;
            @include border-radius(50%);
            // @include background($color: $color-white, $image: img('close_neutro_s90.svg'), $size: 12px);
            @include background($image: img('close_neutro_s90.svg'), $size: 12px);
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0px 0px 0px 12px;

            @include bgHover($color-neutral-300);
        }
        // .undo {
        //     cursor: pointer;
        //     color: $color-primary-500;
        //     text-decoration: underline;
        // }

        &.info {
            background-color: $color-info-100;

            // .text {
            //     // color: $color-info-800;
            // }
            // .close-snackbar {
            //     background-color: $color-info-500;
            //     @include bgHover($color-info-500);
            // }
        }
        &.success {
            background-color: $color-success-100;

            // .text {
            //     // color: $color-success-800;
            // }
            // .close-snackbar {
            //     background-color: $color-success-100;
            //     @include bgHover($color-success-100);
            // }
        }
        &.progress {
            background-color: $color-success-100;

            // .text {
            //     color: $color-success-800;
            // }
            // .close-snackbar {
            //     background-color: $color-success-500;
            //     @include bgHover($color-success-500);
            // }
        }
        &.warning {
            background-color: $color-warning-100;

            // .text {
            //     color: $color-warning-800;
            // }
            // .close-snackbar {
            //     background-color: $color-warning-100;
            //     @include bgHover($color-warning-100);
            // }
        }
        &.second {
            background-color: $color-primary-100;
            // .text {
            //     color: $color-primary-800;
            // }
            // .close-snackbar {
            //     background-color: $color-primary-500;
            //     @include bgHover($color-primary-500);
            // }
        }
        &.error {
            background-color: $color-error-100;

            // .text {
            //     color: $color-error-800;
            // }
            // .close-snackbar {
            //     background-color: $color-error-500;
            //     @include bgHover($color-error-500);
            // }
        }
        // &.login {
        //     // .text {
        //     // }
        //     // .close-snackbar {
        //     // }
        // }
        // &.offline {
        //     // .text {
        //     // }
        //     // .close-snackbar {
        //     // }
        // }
        // &.important {
        //     // .text {
        //     //     color: $color-success-800;
        //     // }
        //     // .close-snackbar {
        //     //     background-color: $color-success-500;
        //     //     @include bgHover($color-success-500);
        //     // }
        // }
    }
    &.showPopup {
        z-index: $z-popups-default + 2;
    }
}
</style>
