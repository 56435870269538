var db_version = 1
let db

export class Db {
    constructor() {
        //window.indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB;

        if ('webkitIndexedDB' in window) {
            window.IDBTransaction = window.webkitIDBTransaction
            window.IDBKeyRange = window.webkitIDBKeyRange
        }

        db = null
    }

    init(callback) {
        var request = window.indexedDB.open('alex', db_version)
        var self = this

        callback = typeof callback !== 'undefined' ? callback : function() {}

        request.onerror = function(event) {}
        request.onsuccess = function(event) {
            db = request.result
            //console.log("request.onsuccess");
        }
        request.onupgradeneeded = function(event) {
            db = request.result
            var dtb = null
            dtb = db.createObjectStore('products', { keyPath: 'id', autoIncrement: true })
            dtb = db.createObjectStore('products_cat', { keyPath: 'id', autoIncrement: true })
            dtb = db.createObjectStore('notifications', { keyPath: 'id', autoIncrement: true })
            dtb = db.createObjectStore('logbook', { keyPath: 'id', autoIncrement: true })
            dtb = db.createObjectStore('checklist_templates', { keyPath: 'id', autoIncrement: true })
            dtb = db.createObjectStore('inspect_templates', { keyPath: 'id', autoIncrement: true })
            dtb = db.createObjectStore('library_files', { keyPath: 'id', autoIncrement: true })
            dtb = db.createObjectStore('library_sub', { keyPath: 'id', autoIncrement: true })
            dtb = db.createObjectStore('library_cat', { keyPath: 'id', autoIncrement: true })
            //dtb.createIndex('by_nick', 'nick', { unique : true });
        }
    }

    get(bucket, idx, callback) {
        var self = this
        callback = typeof callback !== 'undefined' ? callback : function() {}

        var transaction = db.transaction([bucket])
        var objectStore = transaction.objectStore(bucket)
        var request = objectStore.get(idx)
        request.onerror = function(event) {}
        request.onsuccess = function(event) {
            //console.log(request.result);
        }
    }

    list(bucket, callback) {
        // console.log("list");

        var self = this
        callback = typeof callback !== 'undefined' ? callback : function() {}

        if (!db) {
            //sleep(2000);
        }

        var objectStore = db.transaction(bucket, 'readwrite').objectStore(bucket)
        var data = []

        objectStore.openCursor().onsuccess = function(event) {
            var cursor = event.target.result
            if (cursor) {
                //data.push(cursor.value);
                data[cursor.value.id] = cursor.value
                cursor.continue()
            } else {
                data = Object.assign({}, data)
                callback(data)
            }
        }
    }

    insert(bucket, data, callback) {
        var self = this
        callback = typeof callback !== 'undefined' ? callback : function() {}

        var transaction = db.transaction([bucket], 'readwrite')
        transaction.oncomplete = function(event) {
            callback()
        }

        transaction.onerror = function(event) {}

        var objectStore = transaction.objectStore(bucket)
        for (var i in data) {
            var request = objectStore.add(data[i])
            request.onsuccess = function(event) {}
        }
    }

    delete(bucket, idx, callback) {
        var self = this
        callback = typeof callback !== 'undefined' ? callback : function() {}

        var request = db
            .transaction([bucket], 'readwrite')
            .objectStore(bucket)
            .delete(idx)
        request.onsuccess = function(event) {
            callback()
            // console.log('DELETED')
        }
    }
}
