import { Api } from '@/core/api.js'
import Vue from 'vue'

const api = new Api()

const state = {
    translationLang: false,
    translationLangsResume: false,
    translationKeyname: false,
    originalText: {},
    translations: {},
    hasBeenTranslated: false,
    autoTranslateMode: false
}

const getters = {
    getTranslationLang(state) {
        return state.translationLang
    },

    getTranslationLangsResume(state) {
        return state.translationLangsResume
    },

    getTranslations: (state, getters, rootState) => {
        return state.translations
    },

    getSingleTranslations: (state, getters, rootState) => (keyname) => {
        if (keyname && state.translations && state.translations[keyname] && state.translations[keyname][state.translationLang]) {
            return state.translations[keyname][state.translationLang]
        }
        return ''
    },

    getHasBeenTranslated(state) {
        return state.hasBeenTranslated
    },

    getAutoTranslateMode(state) {
        return state.autoTranslateMode
    }
}

const actions = {
    loadTranslations(context, params) {
        return api.get('translations/' + params.entity + '/' + params.id).then(function (response) {
            context.commit('setTranslations', response.data)
        })
    },

    saveTranslations(context) {
        var resume = { [state.translationKeyname]: state.translationLangsResume }

        return api.post('translations/save', { translations: JSON.stringify(state.translations), resumes: JSON.stringify(resume) }).then(function (response) {
            return response.status
        })
    },

    deleteTranslation(context, params) {
        return api.post('translations/remove', params).then(function (response) {
            return response.status
        })
    },

    translate(context, params) {
        let to = state.translationLang.slice(0, 2)
        let data = JSON.stringify([{ text: params.text }])
        return api.post('translations/translate', { to, data }).then(function (response) {
            return response
        })
    }
}

const mutations = {
    setTranslationLang(state, lang) {
        state.translationLang = lang
    },

    setKeyname(state, keyname) {
        state.translationKeyname = keyname
    },

    setAutoTranslateMode(state, value) {
        state.autoTranslateMode = value
    },

    countTranslations(state, params) {
        if (state.translationLang && state.translations && Object.keys(state.translations).length > 0) {
            // DELETE CURRENT COUNT
            var oTranslationLangsResume = {}

            Object.values(state.translations).forEach((translation, translation_index) => {
                Object.values(translation).forEach((lang, index) => {
                    // INITIALIZATION OF THE OBJECT
                    if (!oTranslationLangsResume[Object.keys(translation)[index]]) {
                        oTranslationLangsResume[Object.keys(translation)[index]] = {
                            missing: 0,
                            translated: 0
                        }
                    }

                    // COUNT
                    if (state.originalText.hasOwnProperty(Object.keys(state.translations)[translation_index]) && state.originalText[Object.keys(state.translations)[translation_index]]) {
                        if (lang === '' || lang === '<p></p>') {
                            oTranslationLangsResume[Object.keys(translation)[index]].missing++
                        } else {
                            oTranslationLangsResume[Object.keys(translation)[index]].translated++
                        }
                    }
                })
            })

            Vue.set(state, 'translationLangsResume', oTranslationLangsResume)
        }
    },

    setTranslations(state, translations) {
        state.translations = translations
        this.commit('multilanguage/countTranslations')
    },

    setSingleTranslation(state, payload) {
        if (payload.keyname && state.translations) {
            if (!state.originalText.hasOwnProperty(payload.keyname)) {
                state.originalText[payload.keyname] = payload.originalText ? payload.originalText : false
            }

            if (!state.translations.hasOwnProperty(payload.keyname)) {
                state.translations[payload.keyname] = {}
            }
            if (!state.translations[payload.keyname].hasOwnProperty(state.translationLang)) {
                state.translations[payload.keyname][state.translationLang] = {}
            }

            state.translations[payload.keyname][state.translationLang] = payload.translation
            this.commit('multilanguage/countTranslations')

            Vue.set(state, 'translations', { ...state.translations })
        }
    },

    setHasBeenTranslated(state, value) {
        state.hasBeenTranslated = value
        Vue.set(state, 'hasBeenTranslated', state.hasBeenTranslated)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
