<template>
    <button
        @click.stop.prevent="doIt()"
        @click="handleClick"
        :aria-label="btn.ariaLabel"
        class="btn"
        :class="[$mq, customClass, 'btn-' + btn.type, { 'btn-no-label': btn.label == false }, { 'btn-loading': isLoading }]"
    >
        {{ btn.label ? btn.label : '' }}
    </button>
</template>

<script>
export default {
    props: {
        bType: { type: String, default: 'normal' },
        bLabel: { type: [String, Boolean], default: '' },
        bCallback: { type: Function, default: function () {} },
        bCallbackProps: {
            type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
            default: undefined
        },
        customClass: { type: [String, Array, Object, Function], default: '' },
        isLoading: { type: Boolean, default: false },
        bTypeOfBtn: { type: String, default: 'button' }
    },
    data() {
        return {
            types: {
                normal: { label: 'button.normal', callback: function () {} },
                add_more: { label: 'button.add_more', callback: function () {} },
                filter: { label: 'button.filter', callback: function () {} },
                settings: { label: 'button.settings', callback: function () {} },
                share: { label: 'button.share', callback: function () {} },
                upload: { label: 'button.upload', callback: function () {} },
                print: { label: 'button.print', callback: function () {} },
                file: { label: 'button.export', callback: function () {} },
                delete: { label: 'button.delete', callback: function () {} },
                edit: { label: 'button.edit', callback: function () {} },
                editOn: { label: 'button.editOn', callback: function () {} },
                editOff: { label: 'button.editOff', callback: function () {} },
                copy: { label: 'button.copy', callback: function () {} },
                duplicate: { label: 'button.duplicate', callback: function () {} },
                convert: { label: 'button.convert', callback: function () {} },
                active: { label: 'button.active', callback: function () {} },
                inactive: { label: 'button.inactive', callback: function () {} },
                send: { label: 'button.send', callback: function () {} },
                create: { label: 'button.create', callback: function () {} },
                cancel: { label: 'button.cancel', callback: function () {} },
                clearFilters: { label: 'assets.filter.delete', callback: function () {} },
                cancel_changes: { label: 'button.cancel_changes', callback: function () {} },
                save: { label: 'button.save', callback: function () {} },
                refresh: { label: 'button.refresh', callback: function () {} },
                back: { label: 'button.back', callback: function () {} },
                more: { label: '‎', callback: function () {} },
                accessLocation: { label: '‎', callback: function () {} },
                editIcon: { label: '‎', callback: function () {}, ariaLabel: this.$t('button.edit') },
                createIcon: { label: '‎', callback: function () {}, ariaLabel: this.$t('button.create') },
                showOrHideFilters: { ariaLabel: this.$t('assets.filter.show_hide_filters') },
                savePrimary: { label: '' }, // to do: use variable!
                leftArrow: { label: '', callback: function () {} },
                rightArrow: { label: '', callback: function () {} },
                downloadFileIcon: { ariaLabel: this.$t('supervise.checklists.table_headers.download_excel', 1) }
            }
        }
    },
    computed: {
        btn() {
            var def = this.types[this.bType]
            var result = {
                type: this.bType ? this.bType : 'normal',
                label: this.bLabel === false ? false : this.bLabel != '' ? this.$t(this.bLabel) : this.$t(def.label),
                callback: this.bCallback ? this.bCallback : def.callback,
                props: this.bCallbackProps ? this.bCallbackProps : undefined,
                ariaLabel: def && def.ariaLabel ? this.$t(def.ariaLabel) : ''
            }
            return result
        }
    },
    methods: {
        doIt() {
            var self = this
            if (this.bType == 'save' && this.$store.getters['multilanguage/getTranslationLang']) {
                this.$nextTick(() => {
                    self.$store.dispatch('multilanguage/saveTranslations')
                })
            }

            if (!this.isLoading) {
                this.btn.callback(this.btn.props)
            }
        },
        handleClick() {
            this.$emit('on-button-click')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.btn {
    @include font-size(sm);
    @include background($color: $color-neutral-200, $size: 15px, $position: 11px center);
    @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-600, 0.05));
    padding: 8px 9px 8px 35px;
    margin: 0 0 0 12px;
    height: auto;
    width: fit-content;
    font-family: $text-bold;
    color: $color-white;
    border-radius: 4px;
    line-height: initial;
    color: $color-white;
}

// Common styles
.btn-no-label {
    padding-right: 0;
    margin-right: 5px;
}

.btn-neutro {
    background-color: $color-neutral-200;
    color: $color-black;

    &:hover {
        background-color: $color-neutral-600;
    }
}
.btn-second {
    background-color: $color-primary-500;
    color: $color-primary-800;

    &:hover {
        background-color: $color-primary-500;
    }
}
.btn-secondary {
    background-color: $color-primary-500;
    color: $color-white;

    &:hover {
        background-color: $color-primary-800;
    }
}
.btn-action {
    background-color: $color-secondary-500;

    &:hover {
        color: $color-white;
        background-color: $color-secondary-800;
    }
    &.disabled {
        // background-image: img('tick_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}

.btn-spaced {
    color: $color-black;
    margin-right: 30px;
}
.btn-small {
    @include font-size(sm);
}
.btn-clear {
    margin: 0;

    &.btn-reset {
        height: fit-content;
    }
}
.btn-normal {
    padding: 6px 9px;
    margin: 0;
}

.btn-input {
    height: 30px;
    padding-left: 30px;
    background-position: center center;
}

.btn-save {
    background-image: img('tick_ffffff.svg');
    background-size: 14px;

    &.disabled {
        background-image: img('tick_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}

.btn-add-more {
    background-image: img('add_white.svg');
    background-size: 14px;
    background-color: $color-primary-700;
    margin-left: 0;
    &.disabled {
        background-image: img('add_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }

    &:hover {
        background-color: $color-primary-800;
    }
}

.btn-refresh {
    background-image: img('refresh_neutro_s90.svg');
    background-size: 14px;

    &.disabled {
        background-image: img('refresh_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-back {
    background-image: img('left_neutro_s90.svg');
    background-size: 6px;
    padding-left: 26px;
    color: $color-black;
    border: 1px solid $color-neutral-300;
    background-color: $color-white;

    &:hover {
        background-color: $color-neutral-300;
        border: 1px solid $color-neutral-500;
    }

    &.disabled {
        background-image: img('left_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-black;
        cursor: not-allowed;
    }
}

.btn-clearFilters {
    background-image: img('delete_dark.svg');
    background-size: inherit;
    margin: 0px !important;
    width: 100%;
    text-align: left;
    border: 1px solid $color-neutral-300;
    color: $color-black;
    background-color: $color-white;

    &:hover {
        background-color: $color-neutral-300;
        border: 1px solid $color-neutral-500;
        color: $color-black;
    }
}

.btn-cancel,
.btn-cancel_changes {
    // @extend .btn-secondary;
    background-image: img('close_neutro_s90.svg');
    color: $color-black;
    background-color: $color-white;
    border: 1px solid $color-neutral-300;

    &:hover {
        background-color: $color-neutral-300;
        border: 1px solid $color-neutral-500;
    }

    &.disabled {
        background-image: img('close_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-create {
    background-image: img('add_white.svg');
    background-size: 12px;
    padding-left: 30px;
    color: $color-white;
    background-color: $color-secondary-500;

    &:hover {
        background-color: $color-secondary-800;
    }

    &.disabled {
        background-image: img('add_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }

    &.white {
        background-color: $color-white;
        color: $color-black;
        background-image: img('add_neutro_s90.svg');
        border: solid 1px $color-neutral-300 !important;
        margin: 0px;
        padding-top: 6px;
        padding-bottom: 6px;

        &:hover {
            background-color: $color-neutral-300;
            border: 1px solid $color-neutral-500;
        }
    }

    &.btn-secondary {
        background-image: img('add_secondary.svg');
    }
}

.btn-edit {
    background-image: img('edit_white.svg');
    background-size: 14px;
    background-color: $color-primary-500;
    color: $color-white;

    &:hover {
        background-color: $color-primary-800;
    }

    &.disabled {
        background-image: img('edit_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-editOn {
    background-image: img('edit_on.svg');
    background-size: 14px;
    background-color: $color-warning-500;
    border-radius: 4px;
    font-family: $text-bold;
    font-size: 12px;
    margin: 2px 0px 2px 0px;

    &:hover {
        background-color: $color-warning-800;
        color: $color-white;
    }
    &.disabled {
        background-image: img('edit_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-editOff {
    background-image: img('edit_off.svg');
    background-size: 14px;
    background-color: $color-primary-500;
    color: $color-white;
    border-radius: 4px;
    font-family: $text-bold;
    font-size: 12px;
    margin: 2px 0px 2px 0px;

    &:hover {
        background-color: $color-primary-800;
    }
    &.disabled {
        background-image: img('edit_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}

.btn-copy,
.btn-duplicate {
    background-image: img('copy_neutro_s90.svg');
    background-color: $color-white;
    color: $color-black;

    &:hover {
        background-color: $color-neutral-300;
    }
}
.btn-convert {
    background-image: img('convert_to.svg');
    background-color: $color-white;
    color: $color-black;

    &:hover {
        background-color: $color-neutral-300;
    }
}
.btn-active {
    background-image: img('eye_neutro_s90.svg');
    background-size: 18px;

    &.disabled {
        background-image: img('eye_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-inactive {
    background-image: img('eye_closed_neutro_s90.svg');
    background-size: 18px;

    &.disabled {
        background-image: img('eye_closed_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-send {
    background-image: img('send_neutro_s90.svg');
    background-size: 14px;

    &.disabled {
        background-image: img('send_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-accessLocation {
    background-image: img('external_link_second_s30.svg');
    background-size: 14px;
    background-position: center center;
    background-color: #fff;
    width: 32px;
    height: 30px;
    padding: 0;
}
.btn-editIcon {
    background-image: img('edit_main_s30_1.svg');
    background-size: 14px;
    background-position: center center;
    background-color: #fff;
    width: 32px;
    height: 30px;
    padding: 0;
}

.btn-createIcon {
    background-image: img('add_black.svg');
    background-size: 11px;
    background-position: center center;
    background-color: #fff;
    width: 32px;
    height: 30px;
    padding: 0;
}
.btn-delete {
    background-image: img('delete_ffffff.svg');
    background-color: $color-error-500;
    background-size: 14px;
    color: $color-neutral-200;
    margin-right: 60px;

    &:hover {
        background-color: $color-error-500;
    }

    &.disabled {
        background-image: img('delete_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-file {
    background-image: img('export_ffffff.svg');
    background-size: 14px;
    background-color: $color-secondary-500;
    min-width: 145px;
    margin: 0 !important;

    &:hover {
        background-color: $color-secondary-800;
    }

    &.disabled {
        background-image: img('export_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-upload {
    @extend .btn-secondary;
    background-image: img('upload_white.svg');
    background-size: 14px;
    background-color: $color-primary-500;
    color: $color-white;
    width: max-content;

    &:hover {
        background-color: $color-primary-800;
    }

    &.disabled {
        background-image: img('upload_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-print {
    background-image: img('printer_ffffff.svg');
    background-size: 14px;
    background-color: $color-secondary-500;

    &:hover {
        background-color: $color-secondary-800;
    }

    &.disabled {
        background-image: img('printer_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-share {
    @extend .btn-secondary;
    background-image: img('link2_neutro_s90.svg');

    &.disabled {
        background-image: img('link2_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}
.btn-settings {
    @extend .btn-secondary;
    background-image: img('levels_neutro_s90.svg');
    border: 1px solid $color-neutral-300;
    margin: 0px 0px !important;

    &.disabled {
        background-image: img('levels_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
    &.report {
        background-color: #fff;
        color: $color-black;

        &:hover {
            background-color: $color-neutral-300;
            border: 1px solid $color-neutral-500;
        }
    }
}

.btn-filter {
    @extend .btn-action;
    position: relative !important;
    margin: 0 !important;
    width: 100%;
    max-width: 100%;
    text-align: left;

    // background-image: img('filter_neutro_s90.svg');
    background-image: img('icons/filters_icon_white.svg');
    background-size: 14px;
    box-shadow: unset;
    background-color: $color-primary-700;

    &:hover {
        background-color: $color-primary-800;
    }

    &.disabled {
        background-image: img('filter_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}

.btn-more {
    background-image: img('more_vert_neutro_s90.svg');
    background-size: 18px;
    background-position: center center;
    width: 35px;
    padding-left: 9px;
    box-shadow: none;
    margin-left: 8px;

    &:hover {
        background-color: $color-neutral-300;
    }

    &.disabled {
        background-image: img('more_vert_inactive_s10.svg');
        background-color: $color-neutral-300;
        color: $color-neutral-300;
        cursor: not-allowed;
    }
}

.btn-loading {
    background-image: img('loader_dark.svg');
    background-size: 26px;
    background-position: center;
    height: 35px;
    color: transparent;

    &.btn-delete {
        background-image: img('loader_light.svg');
    }
}

// CUSTOM STYLES
.submit-login {
    @include font-size(ml);
    // color: $color-black;
    font-family: $text-bold;
    // width: 100%;
    // padding: 6px 15px;
}
.user-invitation {
    color: #fff;
    background-color: $color-primary-500;
    background-image: img('send_ffffff.svg');

    &:hover {
        background-color: $color-primary-500;
    }
    &.btn-loading {
        background-image: img('loader_light.svg');
    }
}

.btn-password-change {
    @include font-size(md);
    background-color: $color-primary-500;
    font-family: $text-bold;
    color: #fff;
    padding: 8px 12px;

    &:hover {
        background-color: $color-primary-500;
    }
    &.btn-loading {
        background-image: img('loader_light.svg');
    }
}

.btn-password {
    background-image: img('password_white.svg');
}
.btn-email {
    background-image: img('mail_ffffff.svg');
}

.btn-link-copy {
    @include font-size(xs);
    font-family: $text-bold;
    color: $color-primary-500;
    background-color: $color-neutral-50;
    border: unset;
    box-shadow: none;
}

.btn-showOrHideFilters {
    margin: 0px;
    background-image: img('/icons/filters-icon.svg');
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid $color-neutral-300;
    color: $color-black;
    background-color: $color-white;
    width: 35px;
    height: 35px;
    aspect-ratio: 1/1;

    &:hover {
        background-color: $color-neutral-300;
        border: 1px solid $color-neutral-500;
    }
}

.btn-downloadFileIcon {
    height: 32px;
    width: 32px;
    background-color: $color-secondary-500;
    border-radius: 4px;
    background-image: img('icons/download-file-icon.svg');
    background-size: 20px;
    background-position: center center;
    margin: 0px !important;
}

.edit-view-button {
    position: absolute;
    left: 280px;
    border: 1px solid $color-neutral-300 !important;
    width: 35px;
    height: 35px;
    margin: 0px !important;

    &.portrait {
        left: 230px;
    }
}

.btn-savePrimary {
    @extend .btn-action;
    position: relative !important;
    margin: 0 !important;
    width: 100%;
    max-width: 100%;
    text-align: left;

    background-image: img('save_white.svg');
    background-size: 14px;
    box-shadow: unset;
    background-color: $color-primary-700;

    &:hover {
        background-color: $color-primary-800;
    }
}
.btn-leftArrow {
    background-image: img('leftArrow.svg');
}

.btn-rightArrow {
    background-image: img('rightArrow.svg');
}
</style>
