<template>
    <div class="input-container" :class="['input-' + inputState, 'input-' + inputSize, customClass]">
        <div class="input-top" v-if="label || help">
            <label class="label" v-if="label">{{ label }}</label>
            <span class="help" v-if="help" v-tooltip="help"></span>
        </div>

        <div class="input-body" :class="{ 'error-field': gState.includes('error') }">
            <component :key="refreshComponent" :autocompleteModel="autocompleteValue" :is="inputType" v-model="gValue" @input="updateValue" @blur="$emit('blur', $event)" @change="$emit('change', $event)" @keyup="$emit('keyup', $event)" :cPlaceholder="placeholder" :cOptions="inputOptions" :cItems="gItems"> </component>
            <span v-if="gType == 'password'" class="visible-password" :class="{ show: visibilityPassword }">{{ passwordVisible }}</span>
            <span v-if="showInputIcon" class="state"></span>
            <div class="visibility" :class="{ visible: visibilityPassword }" v-if="gType == 'password'" @click="changeVisibilityPassword()"></div>
        </div>

        <div class="input-bottom">
            <!-- <div class="message" v-if="inputOptions.mandatory"> -->
            <div class="mandatory" v-if="inputOptions.mandatory">{{ $t('assets.mandatory') }}</div>
            <div class="message" v-if="inputMessageState" v-html="inputMessageState"></div>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
import InputText from '@/components/input/InputText'
import InputNumber from '@/components/input/InputNumber'
import InputDate from '@/components/input/InputDate'
import InputTime from '@/components/input/InputTime'
import InputDateTime from '@/components/input/InputDateTime'
import InputSelect from '@/components/input/InputSelect'
import InputAutocomplete from '@/components/input/InputAutocomplete'
import InputTextarea from '@/components/input/InputTextarea'
import InputPassword from '@/components/input/InputPassword'
import InputComboBox from '@/components/input/InputComboBox'
import InputTextEditor from '@/components/input/InputTextEditor'

export default {
    components: {
        InputText,
        InputNumber,
        InputDate,
        InputTime,
        InputDateTime,
        InputSelect,
        InputAutocomplete,
        InputTextarea,
        InputPassword,
        InputComboBox,
        InputTextEditor
    },
    props: {
        // TODO REVIEW props :^)
        value: { type: [String, Number, Boolean, Array, Object], default: undefined },
        gItems: { type: [Array, Object], default: undefined }, // Array de objetos para los tipos select etc etc
        gType: { type: String, default: 'text' }, // Tipo de input. es decir, si es texto, fecha... (Ver inputTypes en el data)
        gLabel: { type: String, default: '' }, // Label del input
        gPlaceholder: { type: String, default: '' },
        gHelp: { type: String, default: '' }, // Es la info que aparece en un icono de ayuda con un snackbar
        gState: { type: String, default: 'default' }, // Es para indicar la clase del estado del input (error, alerta...) (Ver inputStates en el data)
        gSize: { type: String, default: '' }, // Podemos indicar un tamaño (Ver inputSizes en el data)
        gMessage: { type: [String, Object], default: '' }, // Texto informativo siempre visible. Puede variar segun el estado
        gOptions: { type: Object, default: undefined }, // Configuraciones del input
        customClass: { type: [String, Array, Object, Function], default: '' },
        autocompleteValue: { type: undefined, default: false },
        refreshComponent: { type: undefined, default: '' },
        inputIcon: { type: Boolean, default: true }
    },
    data() {
        return {
            showInputIcon: this.inputIcon,
            gValue: this.value,
            items: this.gItems,
            inputTypes: {
                text: { component: 'InputText' },
                number: { component: 'InputNumber' },
                date: { component: 'InputDate' },
                time: { component: 'InputTime' },
                datetime: { component: 'InputDateTime' },
                select: { component: 'InputSelect' },
                autocomplete: { component: 'InputAutocomplete' },
                textarea: { component: 'InputTextarea' },
                email: { component: 'InputEmail' },
                file: { component: 'InputFile' },
                number: { component: 'InputNumber' },
                password: { component: 'InputPassword' },
                phone: { component: 'InputPhone' },
                url: { component: 'InputUrl' },
                combobox: { component: 'InputComboBox' },
                switch: { component: 'InputSwitch' },
                texteditor: { component: 'InputTextEditor' }
            },
            inputSizes: ['xs', 's', 'sm', 'm', 'ml', 'l', 'xl'],
            inputStates: ['default', 'error', 'error2', 'error3', 'error4', 'warning', 'done', 'disabled', 'highlight'],
            inputMessages: {
                default: '',
                error: '',
                error2: '',
                error3: '',
                error4: '',
                warning: '',
                done: '',
                disabled: '',
                highlight: ''
            },
            // https://www.w3schools.com/tags/tag_input.asp
            options: {
                mandatory: false,
                validateOnChange: true,
                disabled: false,
                newInput: false
            },
            visibilityPassword: false,
            passwordVisible: ''
        }
    },
    computed: {
        inputType() {
            let result = 'InputText'
            let types = this.inputTypes ? this.inputTypes : {}

            for (var k in types) {
                let key = types[k]

                if (k == this.gType) {
                    result = key.component
                }
            }

            return result
        },
        label() {
            return this.gLabel
        },
        inputState() {
            // Si el estado que pide está detro del array, guay. Sino, no coge ninguno xD
            let result = this.gState

            if (this.inputStates.indexOf(result) == -1) {
                result = 'default'
            }
            return result
        },
        placeholder() {
            return this.gPlaceholder
        },
        help() {
            return this.gHelp
        },
        inputSize() {
            // Si el estado que pide está detro del array, guay. Sino, no coge ninguno xD
            let result = this.gSize

            if (this.inputSizes.indexOf(result) == -1) {
                result = 'sm'
            }
            return result
        },
        inputMessage() {
            var result = this.inputMessages
            let givenMessage = this.gMessage

            if (typeof givenMessage == 'object') {
                let defMessage = givenMessage['default'] ? givenMessage['default'] : ''

                for (var key in result) {
                    result[key] = defMessage
                }

                result = {
                    ...this.inputMessages,
                    ...givenMessage
                }
            } else {
                for (var key in result) {
                    result[key] = givenMessage
                }
            }

            return result
        },
        inputMessageState() {
            let messages = this.inputMessage
            let state = this.inputState
            let result = messages['default']

            if (Object.keys(messages).indexOf(state) > -1) {
                result = messages[state]
            }

            return result
        },

        inputOptions() {
            // Mix de options que llegan vs defaults
            let defaults = this.options

            if (this.gState == 'disabled') {
                defaults.disabled = true
            }

            return {
                ...defaults,
                ...(this.gOptions ? this.gOptions : {})
            }
        }
    },
    methods: {
        updateValue(inputValue) {
            // console.log('updateValue -> ' + inputValue)
            // console.log(inputValue)
            this.$emit('input', inputValue)
        },

        changeVisibilityPassword() {
            this.visibilityPassword = !this.visibilityPassword
            this.passwordVisible = this.visibilityPassword ? this.gValue : ''
        }
    },
    watch: {
        value(newValue) {
            this.gValue = newValue
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.input-container {
    display: inline-block;
    width: 100%;
    min-width: 200px;
    margin-right: 40px;
    margin-bottom: 15px;

    &.error-field {
        input {
            border-color: $color-error-500;

            &:focus,
            &:active {
                border-color: $color-error-500;
            }
        }
    }

    &.communication{
        margin: 0;
    }

    &.selector {
        display: block;
        margin-right: 0;
        margin-bottom: 0;
        width: 80%;
    }

    .input-top {
        width: auto;

        .label {
            @include font-size(md);
            color: $color-black;
            font-family: $text-bold;
            display: inline-block;
            width: auto;
            max-width: calc(100% - 9px - 12px);
            height: auto;
            padding: 0;
            margin: 0;
        }
        .help {
            @extend .interaction;
            @include background($image: img('question_inactive_s20.svg'), $size: cover);
            display: inline-block;
            width: 12px;
            height: 12px;
            position: relative;
        }

        .label + .help {
            margin-left: 6px;
        }
    }

    .input-body {
        width: 100%;
        position: relative;
        .visible-password {
            @include font-size(sm);
            font-family: $text-medium;
            position: absolute;
            left: 2%;
            top: 50%;
            transform: translate(-2%, -50%);
            background-color: $color-white;
            opacity: 0;

            &.show {
                opacity: 1;
            }
        }
        .input {
            @include font-size(sm);
            @include border-radius(3px);
            font-family: $text-medium;
            color: $color-black;
            padding: 3px 6px;
            margin: 0;
            width: 100%;
            height: 30px;
            padding-left: 5px;

            &:-internal-autofill-selected {
                background-color: $color-neutral-200 !important;
            }
            &:hover {
                border-color: $color-neutral-300;
            }

            &:focus,
            &:active {
                border-color: $color-primary-500;
            }

            @include placeholder {
                color: $color-neutral-500;
            }
            &.password {
                -webkit-text-security: disc;
                padding-right: 32px;

                &.show {
                    -webkit-text-security: none;
                }
            }
        }
        .v-input {
            width: 100%;
        }
        textarea {
            min-height: 60px;
        }

        .state {
            $state-size: 20px;

            @extend .interaction;
            @include border-radius($state-size);
            @include background($image: img('edit_ffffff.svg'), $color: $color-secondary-500, $size: 10px);
            position: absolute;
            right: -10px;
            top: -10px;
            display: block;
            width: $state-size;
            height: $state-size;

            visibility: hidden;
            opacity: 0;
        }
        .visibility {
            $state-size: 20px;
            @include background($image: img('eye_neutro_s90.svg'), $size: 20px);
            position: absolute;
            right: 5px;
            top: 5px;
            display: block;
            width: $state-size;
            height: $state-size;
            cursor: pointer;

            &.visible {
                @include background($image: img('eye_closed_neutro_s90.svg'), $size: 20px);
            }
        }

        &:hover {
            .state {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .input-bottom {
        width: auto;
        height: auto;

        .message,
        .mandatory {
            display: inline;
        }

        .message {
            @include font-size(xs);
            font-family: $text-medium;
            color: $color-neutral-600;
        }

        .mandatory {
            @include font-size($size: xs, $important: true);
            font-family: $text-bold;
            color: $color-black;
            margin-right: 3px;
        }
    }

    // Default Classes
    .one-line {
        display: block;
        margin-right: 0;
    }
    .width-auto {
        width: auto;
    }

    &.input-clear {
        margin-right: 6px;
    }

    // Default states
    &.input[required],
    &.input-highlight {
        .input-body {
            .input,
            .input:hover,
            .input:focus,
            .input:active {
                border-color: $color-primary-500;
            }
        }
    }
    &.input-done {
        .input-body {
            .input:active,
            .input:focus {
                border-color: $color-success-500;
            }
            .state {
                background-color: $color-success-500;
                background-image: img('tick_ffffff.svg');
            }
        }
    }
    &.input-warning {
        .input-body {
            .input,
            .input:hover,
            .input:focus,
            .input:active {
                border-color: $color-warning-500;
            }
            .state {
                visibility: visible;
                opacity: 1;
                background-color: $color-warning-500;
                background-image: img('exclamation_ffffff.svg');
            }
            .v-select {
                border-color: $color-warning-500 !important;
            }
        }
        .mandatory,
        .message {
            color: $color-warning-500;
        }
    }
    &.input-error {
        .input-body {
            .input,
            .input:hover,
            .input:focus,
            .input:active {
                border-color: $color-error-500;
            }

            .v-select {
                border-color: $color-error-500 !important;
            }
            .state {
                visibility: visible;
                opacity: 1;
                background-color: $color-error-500;
                background-image: img('close_ffffff.svg');
            }
        }
        .input-bottom {
            .mandatory,
            .message {
                color: $color-error-800;
            }
        }
    }
    &.input-error2 {
        @extend .input-error;
    }
    &.input-error3 {
        @extend .input-error;
    }
    &.input-error4 {
        @extend .input-error;
    }
    &.input-disabled {
        .input-body {
            .input,
            .input:hover,
            .input:focus,
            .input:active {
                // TODO QUITAR IMPORTANTS
                background-color: $color-neutral-200 !important;
                border-color: $color-neutral-300;
                color: $color-neutral-600;
                cursor: not-allowed;
            }

            .state {
                background-color: $color-neutral-300;
                background-image: img('close_ffffff.svg');
            }
        }
    }

    // Default sizes
    &.input-xs {
        .input-top {
            .label {
                @include font-size(xs);
                font-family: $text-bold;
            }
        }
        .input-body {
            .input {
                @include font-size(xs);
                height: 25px;
                min-height: 25px;
            }
            .v-select .v-select__selections {
                padding-left: 0px !important;
            }
        }
    }
    &.input-s {
        .input-top {
            .label {
                @include font-size(xs);
            }
        }
    }
    &.input-sm {
        .input-top {
            .label {
                @include font-size(sm);
            }
        }
    }
    &.input-m {
        .input-top {
            .label {
                @include font-size(md);
            }
        }
    }
    &.input-ml {
        .input-top {
            .label {
                @include font-size(md);
            }
        }
        .input-body {
            .input {
                @include font-size(ml);
                height: 40px;
            }
        }
    }
    &.input-l {
        .input-top {
            .label {
                @include font-size(ml);
            }
        }
        .input-body {
            .input {
                @include font-size(lg);
                height: 40px;
            }
        }
    }
    &.input-xl {
        .input-top {
            .label {
                @include font-size(ml);
            }
        }
        .input-body {
            .input {
                @include font-size(xl);
                height: 50px;
            }
        }
    }

    // Custom styles
    &.login-language {
        @include background($image: img('lang_inactive_s50.svg'), $size: 15px, $position: left center);
        width: 140px;
        min-width: 140px;
        margin: 0;
        padding-left: 6px;

        .input-body {
            input,
            .v-input,
            .input {
                border: unset;
            }
            .input {
                @include font-size(sm);
                color: $color-neutral-600;
                height: 30px;
                min-height: 30px;
            }

            &:hover {
                .state {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
    }
}
</style>
