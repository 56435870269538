import Template from './Snackbar.vue'
import i18n from '@/i18n'
// import store from '@/store'

let globalOptions = {
    show: false,
    talk: false,
    closeable: true,
    type: 'main',
    message: i18n.t('unexpected'),
    customClass: '',
    showInPopup: false,
    action: function() {},
    duration: 3000000
}

let instance

const Init = function(config = {}) {
    let Tpl = this.extend(Template)

    if (!instance) {
        instance = new Tpl()
        config = {
            ...globalOptions,
            ...config
        }
    } else {
        config = {
            ...globalOptions,
            ...instance.$data,
            ...config
        }
    }

    for (let key in config) {
        if (config.hasOwnProperty(key)) {
            // console.log(key + "   " + config[key])
            instance.$data[key] = config[key]
        }
    }
}

const Open = function(config = {}) {
    Init.call(this, config)

    instance.$data.show = true

    if (instance.$data.talk) {
        // console.log('Talking alex -> Snackbar')
        // if(is_app) {
        //     TTS.speak(data.message,function () { }, function (reason) {});
        // } else {
        //     speaker.setPitch(0.9);
        //     speaker.setVoice('Google español');
        //     speaker.speak(data.message);
        // }
    }

    document.body.appendChild(instance.$mount().$el)
    setTimeout(function() {
        if (instance.$data.closeable) {
            Close.call(this, config)
        }
    }, instance.$data.duration)
}
const Close = function(config = {}) {
    if (typeof instance !== 'undefined') {
        instance.$data.show = false
        document.body.removeChild(instance.$mount().$el)
        instance = undefined
    }
}

const Main = function(config = {}) {
    var configAction = config.action

    var defaults = {
        action: function(self) {
            if (typeof configAction !== 'undefined') {
                configAction(self)
            }
            self.$snackbar.close()
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Info = function(config = {}) {
    var configAction = config.action

    var defaults = {
        type: 'info',
        action: function(self) {
            if (typeof configAction !== 'undefined') {
                configAction(self)
            }
            self.$snackbar.close()
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Success = function(config = {}) {
    var configAction = config.action

    var defaults = {
        type: 'success',
        action: function(self) {
            if (typeof configAction !== 'undefined') {
                configAction(self)
            }
            self.$snackbar.close()
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Progress = function(config = {}) {
    var configAction = config.action

    var defaults = {
        type: 'progress',
        action: function(self) {
            if (typeof configAction !== 'undefined') {
                configAction(self)
            }
            self.$snackbar.close()
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Warning = function(config = {}) {
    var configAction = config.action

    var defaults = {
        type: 'warning',
        duration: 6000,
        action: function(self) {
            if (typeof configAction !== 'undefined') {
                configAction(self)
            }
            self.$snackbar.close()
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Error = function(config = {}) {
    var configAction = config.action

    var defaults = {
        type: 'error',
        duration: 6000,
        message: i18n.t('snackbar.error'),
        action: function(self) {
            if (typeof configAction !== 'undefined') {
                configAction(self)
            }
            self.$snackbar.close()
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Login = function(config = {}) {
    var configAction = config.action

    var defaults = {
        type: 'login',
        message: i18n.t('snackbar.login'),
        action: function(self) {
            if (typeof configAction !== 'undefined') {
                configAction(self)
            }
            self.$snackbar.close()
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Offline = function(config = {}) {
    var configAction = config.action

    var defaults = {
        type: 'offline',
        message: i18n.t('snackbar.conexion'),
        action: function(self) {
            if (typeof configAction !== 'undefined') {
                configAction(self)
            }
            self.$snackbar.close()
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}
const Important = function(config = {}) {
    var configAction = config.action

    var defaults = {
        type: 'important',
        closeable: false,
        action: function(self) {
            if (typeof configAction !== 'undefined') {
                configAction(self)
            }
            self.$snackbar.close()
        }
    }

    if (typeof configAction !== 'undefined') {
        config.action = defaults.action
    }
    config = {
        ...defaults,
        ...config
    }
    Open.call(this, config)
}

export default {
    install(Vue) {
        Vue.prototype.$snackbar = Init.bind(Vue)
        Vue.prototype.$snackbar.open = Open.bind(Vue)
        Vue.prototype.$snackbar.close = Close.bind(Vue)

        Vue.prototype.$snackbar.main = Main.bind(Vue)
        Vue.prototype.$snackbar.info = Info.bind(Vue)
        Vue.prototype.$snackbar.success = Success.bind(Vue)
        Vue.prototype.$snackbar.progress = Progress.bind(Vue)
        Vue.prototype.$snackbar.warning = Warning.bind(Vue)
        Vue.prototype.$snackbar.error = Error.bind(Vue)
        Vue.prototype.$snackbar.login = Login.bind(Vue)
        Vue.prototype.$snackbar.offline = Offline.bind(Vue)
        Vue.prototype.$snackbar.important = Important.bind(Vue)
    }
}
