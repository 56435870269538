<template>
    <v-select
        @keyup="$emit('keyup', $event)"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
        hide-details
        class="select"
        v-model="gValue"
        @input="$emit('input', $event)"
        :items="Object.values(cItems)"
        :item-text="inputOptions.itemText"
        :item-value="inputOptions.itemValue"
        :placeholder="placeholder"
        :no-data-text="$t('supervise.filter.no_results')"
        :disabled="inputOptions.disabled"
        :required="inputOptions.mandatory"
        :multiple="inputOptions.multiple"
        dense
        attach
        auto-select-first
        :return-object="inputOptions.returnObject"
    >
    </v-select>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number, Boolean, Array, Object],
            default: undefined
        },
        cItems: { type: [Array, Object], default: [] },
        cPlaceholder: { type: String, default: '' },
        cOptions: { type: Object, default: undefined }
    },
    data() {
        return {
            gValue: this.value ? this.value : false,
            items: this.cItems,
            options: {
                multiple: false,
                returnObject: true,
                itemValue: 'id',
                itemText: 'name'
            }
        }
    },
    computed: {
        inputOptions() {
            // Mix de options que llegan vs defaults
            return {
                ...this.options,
                ...(this.cOptions ? this.cOptions : {})
            }
        },
        placeholder() {
            return this.cPlaceholder
        }
    },
    watch: {
        value: function (val) {
            this.gValue = val
        }
    },
    methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
