<template>
    <div class="popup-template-convert-to">
        <div v-for="[key,tool] in Object.entries(toolsAvailable)">
            <article class="item-template">
                <span class="select" @click="selectTool(key)" :class="{'selected': selectedTool === key}"></span>
                <span class="selector-history">{{tool}}</span>
            </article>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupTemplateConvertTo',
    props: {
        tools: { Object, default: () => {} },
        type: { Number, default: 0 },
    },
    data() {
        return {
            selectedTool: null,
            toolsAvailable: [],
        }
    },
    created() {
        this.toolsAvailable = { ...this.tools};
        delete this.toolsAvailable[this.type];
    },
    methods: {
        selectTool(tool) {
            if(this.selectedTool === tool){
                this.selectedTool = null
                return
            }
            this.selectedTool = tool
        },
    }
    
}
</script>

<style lang="scss" scoped>
.popup-template-convert-to {
    width: 100%;
    @include display-flex();
    flex-direction: column;
    gap: 8px;
    .item-template{
        @include display-flex();
        flex-direction: row-reverse;
        @include justify-content(space-between);
        @include align-items(center);
        padding: 8px;
        @include border-radius(4px);
        background-color: rgba($color-neutral-600, 0.3);

        .select{
            width: 16px;
            height: 16px;
            border: 1px solid $color-neutral-600;
            border-radius: 100%;
            position: relative;
            cursor: pointer;

            &.selected{
                &::after{
                    content: '';
                    width: 10px;
                    height: 10px;
                    background-color: $color-neutral-600;
                    border-radius: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

}
</style>
