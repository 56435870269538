<template>
    <div v-if="!multiAccountPage && date" class="renew-banner" :class="{openedMenu: openedMenu}">
        <div class="text" v-if="daysLeft > 0" v-html="$t('license.renew', {days: daysLeft})"></div>
        <div class="text" v-else v-html="$t('license.expired')"></div>
        <!-- 
        COMENTADO PORQUE NO SE USA POR AHORA, NO ESTA ADAPTADO A LA NUEVA LICENCIA, ES LA COPIA DE TRIALBANNER    
        <div class="actions">
            <div v-if="showButton" class="button" @click="activeAndy()">{{ $t('trial.action') }}</div>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'trial-banner',
    props: {
        date: ''
    },
    computed: {
        openedMenu() {
            return this.$store.state['menu_tools_is_expanded']
        },
        showButton() {
            let user = this.$store.getters['loginUser/getLocalUser']
            if (user) {
                let rol = this.$store.getters['employee/getRoles'](user.rol)
                return rol && parseInt(rol.level) >= 7
            }
            return false
        },
        multiAccountPage() {
            const multiaccount = this.$store.getters['login/getEmployeeMultiAccount']
            return multiaccount
        },
        calcDaysLeft() {
            const expireDate = this.date ? moment(this.date) : false
            const now = moment()
            const differenceDays = expireDate.diff(now, 'days') + 1
            this.daysLeft = now.isAfter(expireDate) ? 0 : differenceDays
        }
    },
    data() {
        return {
            daysLeft: this.date
        }
    },
    methods: {
        activeAndy() {
            window.open(this.$store.getters['login/getTrialLink'])
        }
    },
    created() {
        if (typeof this.date === 'string') {
            let trial_end_date = moment(this.date)
            let now = moment()
            let differenceDays = trial_end_date.diff(now, 'days') + 1
            this.daysLeft = now.isAfter(trial_end_date) ? 0 : differenceDays
        }
    }
}
</script>

<style lang="scss" scoped>
.renew-banner {
    height: 50px;
    width: 100%;
    background-color: #ffeeb1;
    position: fixed;
    @include display-flex();
    top: 60px;
    z-index: 49;
    padding-left: calc(25px + #{$menu-tools-min});

    @include box-shadow($h: 0px, $v: 3px, $b: 4px, $s: 0px, $c: rgba(0, 0, 0, 0.1));

    &.openedMenu {
        padding-left: calc(25px + #{$menu-tools-max});
    }

    .text {
        height: 100%;
        @include display-flex();
        @include align-items();
        gap: 3px;
        width: 80%;        
        font-family: $text;

        b {
            padding: 0 5px;
            display: contents;
            font-family: $text-bold;

        }
    }

    .actions {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 25px;

        .button {
            cursor: pointer;
            background-color: $color-primary-500;
            border-radius: 4px;
            padding: 8px 30px;
            color: #fff;
        }

        .button:hover {
            background-color: $color-primary-800;
        }
    }
}
</style>
