var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:[{ expanded: _vm.isExpanded }, { hide: _vm.isExpanded && _vm.$mq == 'portrait' }],attrs:{"id":"menu-tools"}},[(!_vm.multiAccountPage)?[_c('router-link',{staticClass:"andy",class:[{ selected: _vm.isSelected(_vm.home) }],attrs:{"to":_vm.redirectPath(_vm.home)}},[(_vm.alexIcon)?_c('div',{staticClass:"alex-face",style:({ backgroundImage: 'url(' + _vm.alexIcon + ')' })}):_c('div',{staticClass:"alex-face"}),_c('span',{staticClass:"name"},[_vm._v("Inicio")])])]:_vm._e(),(!_vm.multiAccountPage)?[_c('div',{staticClass:"list scrollbar"},[_vm._l((_vm.tools),function(index,k){
var _obj;
return [(
                        (index &&
                            index.id != 'navDashboard' &&
                            index &&
                            (typeof index.visible === 'undefined' || index.visible) &&
                            index.id != 'onboarding') ||
                        (_vm.showOnboarding && index.id == 'onboarding')
                    )?_c('div',{key:k,staticClass:"section"},[_c('div',{staticClass:"item",class:( _obj = {
                            selected: _vm.isSelected(index.href) && !_vm.hasSelectedChild(index.access),
                            expanded: _vm.expandedTools.includes(index.id)
                        }, _obj[index.class] = true, _obj['with-onboarding'] =  index.id == 'navDashboard' && _vm.showOnboarding, _obj ),attrs:{"id":index.id},on:{"click":function($event){index.href ? _vm.navTool(index.href) : _vm.toggleExpanded(index)}}},[_c('div',{staticClass:"icon"}),(index.href)?_c('span',{staticClass:"name",attrs:{"to":index.href}},[_vm._v(_vm._s(_vm.$t(index.name)))]):_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t(index.name)))])])]):_vm._e()]}),(!_vm.multiAccountPage)?[_c('div',{staticClass:"news section",class:{ isSelected: this.$route.name == 'News' },on:{"click":function($event){return _vm.goToNews()}}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"icon"}),(_vm.hasNews())?_c('span',{staticClass:"notification"}):_vm._e(),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('news.title')))])])])]:_vm._e(),(_vm.chatActive)?_c('div',{staticClass:"chat section",on:{"click":function($event){return _vm.openChat()}}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"icon"}),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('tools.chat')))])])]):_vm._e(),_c('a',{staticClass:"help-center section",attrs:{"href":_vm.helpcenterUrl,"target":"_blank"}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"icon"}),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('tools.helpcenter_title')))])])])],2)]:_vm._e(),(_vm.isExpanded)?_c('AppVersion'):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }