<template>
    <div>
        <div id="fullcontent" :class="{ translating: isTranslating }">
            <!-- <v-app id="vuetify"> -->
            <router-view />
            <!-- </v-app> -->
        </div>
        <MenuTopLogout v-show="showingTop"></MenuTopLogout>

        <UploadList></UploadList>
        <!-- <Copyright :isSmall="true"></Copyright> -->
        <AppVersion></AppVersion>
    </div>
</template>

<script>
import MenuTopLogout from '@/components/layout/MenuTopLogout'
import UploadList from '@/components/layout/UploadList'
import Copyright from '@/components/ui/Copyright'
import AppVersion from '@/components/ui/AppVersion'

export default {
    components: { MenuTopLogout, UploadList, Copyright, AppVersion },
    data() {
        return {
            loaded: false
        }
    },
    computed: {
        showingTop() {
            return this.$store.state['menu_top_is_visible']
        },
        news() {
            var news = this.$store.getters['login/getReleases']()
            return news
        },
        isTranslating() {
            return this.$store.getters['multilanguage/getTranslationLang']
        }
    },
    methods: {
        hideOverlay() {
            this.$overlay.hide()
        },
        search(nameKey) {
            if (this.visibleTools) {
                for (var i = 0; i < this.visibleTools.length; i++) {
                    if (this.visibleTools[i] === nameKey) {
                        return this.visibleTools[i]
                    }
                }
            }
        },

        toggleBoxes(e) {
            // console.log(e.target)
            var divs = document.getElementsByClassName('clickToggle')
            for (var i = 0; i < divs.length; i++) {
                // console.log(divs[i])
                // if (divs[i].contains(e.target) && e.target.parentElement.className.includes('clickToggle')) {
                //     if (e.target.parentElement.className.includes('active')) {
                //         divs[i].classList.remove('active')
                //     } else divs[i].classList.add('active')

                // }else if (divs[i].includes(e.target) && e.target.className.includes('clickToggle')) {
                //     if (e.target.className.includes('active')) {
                //         divs[i].classList.remove('active')
                //     } else divs[i].classList.add('active')
                // } else
                if (divs[i] && !divs[i].contains(e.target)) {
                    divs[i].classList.remove('active')
                } else if (divs[i] && divs[i].contains(e.target)) {
                    if (
                        divs[i].classList.contains('active') &&
                        (e.target.parentElement.className.includes('clickToggle') || e.target.className.includes('clickToggle'))
                    ) {
                        divs[i].classList.remove('active')
                    } else divs[i].classList.add('active')
                }
                // (e.target.parentElement.className.includes('clickToggle')  PONER CLICKTOGGLE EN CADA DIV????¿¿
            }
        },
        popupReleases() {
            var self = this

            var tools = [1, 2, 4, 7]
            var hasOneAtLeast = false
            for (var i in tools) {
                if (self.search(tools[i]) != undefined) {
                    hasOneAtLeast = true
                }
            }
            // Mostrar releases si es home solo de label o analytics
            if (
                (self.$route.path == '/supervise/label' && !hasOneAtLeast && self.search(3) != 'undefined') ||
                (self.$route.path == '/home' && hasOneAtLeast) ||
                (self.$route.path == '/' && hasOneAtLeast)
            ) {
                // Novedades
                // Obtener las novedades vistas anteriormente
                if (Object.values(self.news).length > 0) {
                    var releasesViewed = JSON.parse(localStorage.getItem('release_viewed'))
                    var releaseToSee = []
                    // Se compara con null por si no ha visto ninguna novedad en el caso de que si, entra e itera
                    if (releasesViewed != null) {
                        for (var index in self.news) {
                            var idx = self.news[index]
                            if (!releasesViewed.filter((e) => e === idx.id).length > 0) {
                                releaseToSee = [idx]
                            }
                            // console.log(releasesViewed.find(element => element.id == idx.id))
                        }
                    } else {
                        releaseToSee.push(Object.values(self.news)[0])
                    }
                    // Si hay alguna novedad para ver, mostramos popup
                    if (releaseToSee.length > 0) {
                        self.$popup.release({
                            title: Object.values(releaseToSee)[0].title,
                            data: Object.values(releaseToSee)[0],
                            image: Object.values(releaseToSee)[0].image,
                            textSave: self.$t('news.read_more'),
                            textCancel: self.$t('news.amazing'),
                            callSave: function () {
                                // Subimos a localstorage la novedad que se acaba de ver
                                // var AnewsViewed = []
                                // if (releasesViewed != null) {
                                //     for (var i in releasesViewed) {
                                //         AnewsViewed.push(releasesViewed[i])
                                //     }
                                // }
                                // AnewsViewed.push(releaseToSee[0].id)
                                // localStorage.removeItem('release_viewed')
                                // localStorage.setItem('release_viewed', JSON.stringify(AnewsViewed))
                                self.$router.push({ name: 'News', params: { selected: releaseToSee[0].id } })
                                self.$popup.close()
                            },
                            callCancel: function () {
                                self.$popup.close()
                            }
                        })
                        var AnewsViewed = []
                        if (releasesViewed != null) {
                            for (var i in releasesViewed) {
                                AnewsViewed.push(releasesViewed[i])
                            }
                        }
                        AnewsViewed.push(releaseToSee[0].id)
                        localStorage.removeItem('release_viewed')
                        localStorage.setItem('release_viewed', JSON.stringify(AnewsViewed))
                    }
                }
            }
        }
    },
    watch: {
        $route: 'popupReleases'
    },
    mounted() {},
    created() {
        document.addEventListener('click', this.toggleBoxes)

        var self = this
        self.$bar.reset()
        if (!window.window.location.pathname.match(/(login|logout|resetpassword|newpassword|request-demo|multiaccount)/)) {
            Promise.all([
                self.$store.dispatch('location/loadList', {}),
                self.$store.dispatch('location/loadListTable', { status: 1 }),
                self.$store.dispatch('groups/loadList', {}),
                self.$store.dispatch('groups/loadListTable', { status: 1 }),
                self.$store.dispatch('checklist/loadTemplates', {}),
                self.$store.dispatch('label/loadLabels', {}),
                self.$store.dispatch('label/loadStates', {}),
                self.$store.dispatch('label/loadStatesForm', {}),
                self.$store.dispatch('employee/loadAccountEmployees'),
                self.$store.dispatch('label/loadAllStates'),
                self.$store.dispatch('label/loadAllCategories'),
                self.$store.dispatch('library/loadCategoriesRecursive', {}),
                self.$store.dispatch('operationalTimers/loadOperationalTimers', {})
            ])
                .then(() => {
                    self.loaded = true
                })
                .catch((error) => {
                    console.error(error)
                })
        }
        if (this.$route.path === '/multiaccount') {
            self.loaded = true
        }
        self.popupReleases()
    },
    onIdle() {
        // what to do when the user is inactive
        // router.push('/logout')
    },
    onActive() {},
    beforeDestroy() {
        // ENSURE STOP LISTENING CLICKS WHEN IS DESTROYED
        document.removeEventListener('click', this.toggleBoxes)
    },
    updated() {}
}
</script>

<style lang="scss" scoped>
#fullcontent {
    position: relative;
    left: $menu-tools-min;
    transition-duration: 0.2s;

    &.tools-expanded {
        left: $menu-tools-max;
        width: calc(100% - #{$menu-tools-max});
    }

    #content {
        width: 100% !important;
    }
}
</style>

<style lang="scss">
#fullcontent.with-trial {
    #content {
        top: 110px !important;
    }
}
</style>
