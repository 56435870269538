import Vue from 'vue'
import i18n from '@/i18n'

import { Api } from '@/core/api.js'
const api = new Api()

import localforage from 'localforage'

var store = localforage.createInstance({ name: 'alexdb', storeName: 'logbook' })

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        logs: {},
        logsOrdered: {},
        selected: false,
        editing: false,
        filtersActive: {
            employees: '',
            text: '',
            highlight: 0,
            start_date: 'noDate'
        },
        locationFiltered: false,
        filters: {},
        communications: {}
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    getAllLogs: (state, getters, rootState) => {
        return state.logs
    },
    getAllLogsOrdered: (state, getters, rootState) => {
        //mutations.orderLogs(state, {logs: state.logs});
        return state.logsOrdered
    },
    getLog: (state, getters, rootState) => id => {
        return state.logs[id]
    },
    getFiltersActive: (state, getters, rootState) => {
        return state.filtersActive
    },

    getSelected: (state, getters, rootState) => {
        if (state.selected) return state.logs[state.selected]
        return false
    },

    getLocationFiltered: (state, getters, rootState) => {
        if (state.locationFiltered) return state.locationFiltered
        return false
    },
    getFilterLogbook: (state, getters, rootState) => {
        return state.filters
    },
    getCommunications: (state, getters, rootState) => id => {
        if (id) {
            return state.communications.last[id] ? state.communications.last[id] : state.communications.programmed[id]
        }
        return state.communications
    },
    getEditing: (state, getters, rootState) => {
        if (state.editing) {
            if (typeof state.logs[state.editing] != 'undefined') {
                return state.logs[state.editing]
            } else {
                // SE HACE UN FOR PARA DEVOLVER EL ID QUE COINCIDE CON COMENTARIOS
                for (var i in state.logs) {
                    var idx = state.logs[i]
                    if (idx) {
                        if (idx.comments) {
                            var comments = idx.comments
                            if (comments.data.length > 0) {
                                for (var comment in comments.data) {
                                    var idx2 = comments.data[comment]
                                    if (idx2) {
                                        if (idx2.id == state.editing) {
                                            return idx2
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return false
    }
}

// actions
const actions = {
    resetState({ commit }) {
        commit('resetState')
    },

    loadLogs(context, params) {
        return api.post('admin/logbook/activity', params).then(function(response) {
            var raw = prepare_messages(response.data)
            _.forEach(raw, function(value, key) {
                store.setItem(key, value)
            })
            var data = format_messages(response.data)
            context.commit('setMessages', data)
            context.commit('orderLogs', data)
        })
    },
    loadCommunications(context, params) {
        return api.get('admin/logbook/communication/list', params).then(function(response) {
            context.commit('setCommunications', response.data)
        })
    },

    attachImage(context, params) {
        return api.post('logbook/attach/' + params.id, { file: params.image }).then(function(response) {
            // console.log(response)
            return response
        })
    },
    attachFiles(context, params) {
        return api.post('logbook/attachFile/' + params.id, { file: params.files }).then(function(response) {
            return response
        })
    },

    viewed(context, params) {
        return api.get('logbook/view/' + params.id).then(function(response) {
            return response
        })
    },

    addCommunication(context, params) {
        return api.post('admin/logbook/communication/add', params).then(function(response) {
            if (response.status) {
                // context.commit('setAddCommunication', response.data)
                return response
            }
        })
    },
    editCommunication(context, params) {
        return api.post('admin/logbook/communication/' + params.id + '/edit', params).then(function(response) {
            if (response.status) {
                response.id = params.id
                // context.commit('setEditCommunication', response.data)
                return response
            }
        })
    },
    addLog(context, params) {
        var call = {}
        if (params.parent_id) {
            call = { parent_id: params.parent_id, employee: params.employee, message: params.msg, image: params.image, location_id: params.location_id, type: params.type }
        } else {
            call = { employee: params.employee, message: params.msg, image: params.image, files: params.files, location_id: params.location_id, type: params.type }
        }

        return api.post('logbook/add', call).then(function(response) {
            return response
        })
    },
    editLog(context, params) {
        return api.post('logbook/edit/' + params.id, { message: params.msg, image: params.image, files: params.files, location_id: params.location_id, type: params.type }).then(function(response) {
            return response
        })
    },
    deleteLog(context, id) {
        return api.post('logbook/remove/' + id, { location_id: context.state.locationFiltered }).then(function(response) {
            return response
        })
    },
    removeCommunication(context, id) {
        return api.post('admin/logbook/communication/' + id + '/remove', {}).then(function(response) {
            return response
        })
    },

    pinLog(context, id) {
        return api.post('logbook/pin/' + id, {}).then(function(response) {
            return response
        })
    },
    unpinLog(context, id) {
        return api.post('logbook/unpin/' + id, {}).then(function(response) {
            return response
        })
    },
    loadFilters(context, params) {
        return api.post('admin/logbook/activity/filter', { locationId: params.locationId }).then(function(response) {
            context.commit('setFilterLogbook', response.data)
        })
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setFilterLogbook(state, payload) {
        var filters = state.filters
        filters = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },
    setAddCommunication(state, params) {
        var communications = { ...state.communications }
        communications[params.id] = params
        state.communications = communications
        Vue.set(state, 'communications', { ...communications })
    },
    setEditCommunication(state, params) {
        var communications = { ...state.communications }
        communications[params.id] = params
        state.communications = communications
        Vue.set(state, 'communications', { ...communications })
    },

    deleteMessages(state, id) {
        Vue.delete(id, state.logs)
    },

    setMessages(state, response) {
        Vue.set(state, 'logs', { ...response })
    },
    setFiltersActive(state, filters) {
        state.filtersActive = filters
        Vue.set(state, 'filtersActive', { ...filters })
    },
    setCommunications(state, communications) {
        state.communications = communications
        Vue.set(state, 'communications', { ...communications })
    },

    select(state, noteId) {
        state.selected = noteId
    },
    setLocationFiltered(state, locationId) {
        state.locationFiltered = locationId
    },
    edit(state, noteId) {
        state.editing = noteId
    },

    orderLogs(state, logs) {
        var bLogsO = {}

        Object.keys(logs).map(function(log_id, i) {
            var log = logs[log_id]
            if (log.created_date != 'undefined') {
                var formated = moment(log.created_date)
                    .startOf('day')
                    .format('YYYY-MM-DD')

                if (log.tool) {
                    log.message = i18n.t('logbook.reference.' + log.tool + '.' + log.event, [log.ref1.label, log.ref2.label, log.ref3.label, log.ref1.tool, log.ref1.id, log.ref2.tool, log.ref2.id, log.ref3.tool, log.ref3.id])
                }

                if (typeof bLogsO[formated] != 'undefined') {
                    bLogsO[formated].data[log.id] = log
                } else {
                    bLogsO[formated] = {
                        title: moment(log.created_date).calendar(null, {}),
                        date: formated,
                        data: {}
                    }
                    bLogsO[formated].data[log.id] = log
                }
            }
        })

        state.logsOrdered = bLogsO
        Vue.set(state, 'logsOrdered', { ...state.logsOrdered })
    }
}

function prepare_messages(data) {
    var messages = {}
    Object.keys(data).map(function(key, i) {
        var log = _.cloneDeep(data[key])
        log.created_date = moment(log.created_date * 1000).unix()

        Object.keys(log.comments.data).map(function(comment_id, i) {
            var comment = log.comments.data[comment_id]
            comment.created_date = moment(log.created_date * 1000).unix()
        })
        messages[log.id] = log
    })
    return messages
}

function format_messages(data) {
    var messages = {}
    Object.keys(data).map(function(key, i) {
        var log = data[key]

        log.created_date = moment(log.created_date * 1000)
        log.is_sticked = false
        // TODO saber si esta "fijado" o no

        log.actions = {}
        if (log.employee.id == localStorage.employee_id) {
            log.actions = { stick: false, edit: true, delete: true }
        }

        Object.keys(log.comments.data).map(function(comment_id, i) {
            var comment = log.comments.data[comment_id]

            comment.created_date = moment(comment.created_date * 1000)
            comment.is_sticked = false
            // TODO saber si esta "fijado" o no

            comment.actions = {}
            if (comment.employee.id == localStorage.employee_id) {
                comment.actions = { stick: false, edit: true, delete: true }
            }
        })

        messages[log.id] = log
    })

    return messages
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
