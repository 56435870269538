<template>
    <div class="invitation-container">
        <div class="description" v-html="description"></div>
        <p class="description link">
            {{ linkDescription }}
        </p>
        <div class="user-answer link">
            <div class="copy-box">
                <input readonly id="copyShareLinkPopup" class="link-copy" width="100%" :value="inputVal" />
                <button @click="copyText()" class="btn-link-copy">{{ buttonText }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        description: { type: String, default: null },
        inputVal: { type: undefined, default: null }
    },
    data() {
        return {
            buttonText: i18n.t('user.form.copy_link'),
            linkDescription: i18n.t('user.form.link_description'),
            copySuccess: i18n.t('snackbar.copy_success')
        }
    },
    computed: {},
    methods: {
        copyText() {
            /* Get the text field */
            var copyText = document.getElementById('copyShareLinkPopup')

            /* Select the text field */
            copyText.select()
            copyText.setSelectionRange(0, 99999) /* For mobile devices */

            /* Copy the text inside the text field */
            document.execCommand('copy')

            /* Alert the copied text */
            this.$snackbar.open({
                message: this.copySuccess + ' <b>' + copyText.value + '</b>',
                duration: 3000,
                showInPopup: true
            })
        }
    },
    mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.popup-container {
    .description {
        padding: 20px 0px;
        font-family: $text-medium;

        b {
            @include font-size(md);
            font-family: $text-bold;
            color: $color-black;
        }

        &.link {
            @include font-size(xs);
            font-family: $text-medium;
            color: $color-black;
            padding-bottom: 0px;
            padding-top: 0px;
        }
    }
    .copy-box {
        @include display-flex();
        width: 100%;
        margin-bottom: 20px;

        .link-copy {
            @include font-size(xs);
            background-color: $color-neutral-200;
            color: $color-black;
            font-family: $text-medium;
            padding: 6px 12px;
            margin-right: 6px;
            width: calc(100% - 130px);
        }

        .btn-link-copy {
            @include font-size(xs);
            font-family: $text-bold;
            color: $color-primary-500;
            background-color: #fff;
            border: unset;
            box-shadow: none;
            width: fit-content;
        }
    }
}
</style>
