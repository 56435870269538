<template>
    <nav id="menu-top">
        <button @click="toggleMenu" :class="{ active: isExpanded }" class="expand-tools"></button>
        <div class="page-title">
            <h5 class="main-page-title" v-html="$t(pageTitle)"></h5>
            <h5 v-if="isBeta" class="beta" v-tooltip="$t('tools.beta')">(Beta)</h5>
        </div>
        <template v-if="resultSearch && !multiAccountPage">
            <div v-if="!isMobile" class="connection-site" ref="connection-site" :class="{ active: ubicationActive }" @click.self="activeUbication()">
                <div class="images-ubication" @click.self="activeUbication()"></div>
                <transition name="fade">
                    <div class="ubication-container" v-if="ubicationActive">
                        {{ $t('tools.access_location') }}

                        <div class="search-ubication">
                            <input class="search" type="text" v-model="searchLocation" :placeholder="$t('tools.search_location')" />
                        </div>
                        <div class="locations-list">
                            <div class="location" :class="{ odd: i % 2 == 0 }" v-for="(location, i) in resultSearch" :key="i">
                                <div class="loc">{{ location.name }}</div>
                                <div class="access" @click="goToApp(location)"></div>
                            </div>
                            <div class="empty-locations" v-if="resultSearch.length == 0">
                                {{ $t('tools.empty_location') }}
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </template>

        <div class="user-menu">
            <template v-if="account">
                <div
                    v-if="!multiAccountPage"
                    id="multiaccount-wrapper"
                    :class="[
                        {
                            'multi-account': Object.values(multipleAccounts).length > 1
                        }
                    ]"
                >
                    <div
                        v-if="Object.values(multipleAccounts).length > 1"
                        id="multiaccount"
                        @click="openMultiAccountPage"
                        :class="[
                            {
                                'multi-account': Object.values(multipleAccounts).length > 1
                            }
                        ]"
                    ></div>
                    <div
                        ref="account-info"
                        class="account-info"
                        @click.self="activeAccount()"
                        :class="[
                            {
                                'multi-account': Object.values(multipleAccounts).length > 1
                            },
                            { active: accountActive }
                        ]"
                    >
                        <img
                            class="logo"
                            @click.self="activeAccount()"
                            v-if="account.avatar != null && account.avatar != 'null'"
                            :src="account.avatar"
                            alt=""
                        />
                        <div @click.self="activeAccount()" class="account-name" v-if="account.avatar == null || account.avatar == 'null'">
                            {{ account.account_name ? account.account_name : account.name }}
                        </div>
                        <transition name="fade">
                            <div class="accounts-container" v-if="accountActive">
                                <div class="title-account">
                                    {{ $t('tools.account_title_description') }}
                                </div>

                                <div class="search-account" v-if="Object.values(multipleAccounts).length > 3">
                                    <input class="search" type="text" v-model="searchAccount" :placeholder="$t('tools.search_account')" />
                                </div>

                                <div class="scroll-account-container scrollbar">
                                    <div class="accounts-selector" v-for="oAccount in accountsSearched" :key="oAccount.id">
                                        <!-- <div class="single-account" @click="accountSelected(oAccount)"> -->
                                        <!-- <div class="selector" :class="{ selected: account.id == oAccount.account_id }"></div> -->
                                        <!-- <template v-if="oAccount.avatar != null && oAccount.avatar != '' && oAccount.avatar != false">
                                            <img class="logo" v-tooltip="oAccount.account_name" :src="oAccount.avatar" alt="" />
                                            <div class="account-name-single-account with-logo">{{ oAccount.account_name }}</div>
                                        </template>
                                        <div class="account-name-single-account" v-tooltip="oAccount.account_name" v-else>{{ oAccount.account_name }}</div> -->

                                        <div
                                            class="single-account"
                                            :class="{
                                                selected: account.id == oAccount.account_id
                                            }"
                                            @click="accountSelected(oAccount)"
                                        >
                                            <div
                                                class="selector"
                                                :class="{
                                                    selected: account.id == oAccount.account_id
                                                }"
                                            ></div>
                                            <div class="meta">
                                                <img
                                                    class="logo"
                                                    v-if="oAccount.avatar != null && oAccount.avatar != '' && oAccount.avatar != false"
                                                    v-tooltip="oAccount.account_name"
                                                    :src="oAccount.avatar"
                                                    alt=""
                                                />
                                                <div class="account-name-single-account with-logo">
                                                    {{ oAccount.account_name }}
                                                </div>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                                <div class="empty-accounts" v-if="accountsSearched.length == 0">
                                    {{ $t('tools.empty_location') }}
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </template>
            <template v-if="user">
                <div class="user-image-menu" ref="user-menu" @click="userInfo()" :class="{ clicked: showInfo }">
                    <div
                        class="user-image"
                        v-if="user.avatar"
                        v-bind:style="{
                            backgroundImage: 'url(' + user.avatar + ')'
                        }"
                    ></div>
                    <div v-if="!isMobile" class="user-name">
                        {{ user.name + ' ' + user.surname }}
                    </div>
                </div>
                <transition name="fade">
                    <div class="user-detail" v-if="showInfo">
                        <template v-if="!multiAccountPage">
                            <div class="edit-profile" @click="editProfile()">
                                {{ $t('tools.edit_profile') }}
                            </div>
                            <div class="notifications" @click="navPage('Notifications')" v-if="Object.values(visibleTools).includes(3)">
                                {{ $t('notifications.title') }}
                            </div>
                        </template>
                        <div class="close-session" @click="changeUserLogin()">
                            {{ $t('tools.close_session') }}
                        </div>
                    </div>
                </transition>
            </template>
        </div>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            showInfo: false,
            pageTitleSubmenu: '',
            ubicationActive: false,
            searchLocation: null,
            searchAccount: null,
            accountActive: false,
            windowWidth: window.innerWidth
        }
    },
    computed: {
        multipleAccounts() {
            return JSON.parse(localStorage.getItem('multiple_accounts'))
        },
        accountsSearched() {
            if (this.searchAccount) {
                return Object.values(this.multipleAccounts).filter((item) => {
                    return _.deburr(this.searchAccount)
                        .toLowerCase()
                        .split(' ')
                        .every((v) => _.deburr(item.account_name).toLowerCase().includes(v))
                })
            } else {
                return this.multipleAccounts
            }
            return false
        },
        isMobile() {
            return this.windowWidth <= 900 // includes tablets
        },
        isExpanded() {
            return this.$store.state['menu_tools_is_expanded']
        },
        locations() {
            var locations = Object.values(this.$store.getters['location/getListTable']())
            locations = locations.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            locations = locations.filter((location) => location.status == 1)
            return locations
        },
        resultSearch() {
            if (this.searchLocation) {
                return this.locations.filter((item) => {
                    return _.deburr(this.searchLocation)
                        .toLowerCase()
                        .split(' ')
                        .every((v) => _.deburr(item.name).toLowerCase().includes(v))
                })
            } else {
                return this.locations
            }
        },
        visibleTools() {
            var visible = []
            if (localStorage.config) {
                var config = JSON.parse(localStorage.config)
                if (config.tools) {
                    visible = config.tools
                }
            }
            return visible
        },
        account() {
            var account = this.$store.getters['account/getOrganization']
            if (typeof account !== 'undefined' && Object.keys(account.account).length > 0) {
                return account.account
            } else {
                return false
            }
        },
        user() {
            const userLoggedId = this.$store.getters['login/getLocalEmployee'].employee_id
            const loggedUser = this.$store.getters['employee/getAccountEmployee'](userLoggedId)
            if (loggedUser) {
                return loggedUser
            }
            const multiAccountUser = this.$store.getters['login/getEmployeeMultiAccount']
            if (multiAccountUser) {
                return multiAccountUser
            }
            return false
        },
        username() {
            return this.$store.getters['login/getLocalEmployee']
        },
        pageTitle() {
            // navigation.js
            var page = this.$store.getters['navigation/getCurrentPage']
            return page ? page.label : ''
        },
        isBeta() {
            var page = this.$store.getters['navigation/getCurrentPage']
            return page && page.label === 'tools.analytics'
        },
        multiAccountPage() {
            return this.$store.getters['login/getEmployeeMultiAccount']
        }
    },
    methods: {
        goToApp(location) {
            this.ubicationActive = false
            var token = localStorage.getItem('token')
            var user_id = JSON.parse(localStorage.getItem('user')).employee_id
            var url = process.env.VUE_APP_APP_URL + 'dbpreview/' + token + '/' + location.id + '/' + user_id
            window.open(url, '_blank')
        },
        activeUbication() {
            this.ubicationActive = !this.ubicationActive
        },
        activeAccount() {
            if (Object.values(this.multipleAccounts).length > 1) {
                this.accountActive = !this.accountActive
            }
        },
        closeBoxes(e) {
            if (typeof this.$refs['connection-site'] !== 'undefined' && !this.$refs['connection-site'].contains(e.target)) {
                this.ubicationActive = false
            }

            if (!this.$refs['user-menu'].contains(e.target)) {
                this.showInfo = false
            }
            if (!this.$refs['account-info'].contains(e.target)) {
                this.accountActive = false
            }
        },
        toggleMenu() {
            this.$store.commit('setMenuToolsExpanded', !this.isExpanded)
        },
        editProfile() {
            this.$router.push({ name: 'Profile' })
        },
        navPage(page) {
            this.$router.push({ name: page })
        },
        hasAccess(tool) {
            if (tool.required_tool == false) {
                return true
            }
            if (this.visibleTools.indexOf(parseInt(tool.required_tool)) != -1) {
                return true
            }
            return false
        },
        navTool(path) {
            let self = this

            self.$router.push(self.redirectPath(path)).catch((err) => {
                if (err.name == 'NavigationDuplicated') {
                    self.$router.push('/reset' + self.redirectPath(path))
                }
            })
        },
        changeUserLogin() {
            // var path = this.redirectPath(this.$route.path);
            // this.$popup.login({
            //   list: this.$store.getters["employee/getAllUsers"],
            //   routePath: path
            // });
            var self = this
            this.$store.dispatch('login/doLogout').then(() => {
                self.$router.push('/login')
            })
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },
        userInfo() {
            if (this.showInfo) {
                this.showInfo = false
            } else {
                this.showInfo = true
            }
        },
        accountSelected(account) {
            this.$overlay.loading()
            var self = this
            this.$store
                .dispatch('login/changeAccount', {
                    id: account.id == -1 ? -1 : account.account_id,
                    data: account
                })
                .then(function () {
                    self.$router.push('/home').catch((error) => {})
                    setTimeout(() => {
                        location.reload()
                    }, 100)
                })
        },
        openMultiAccountPage() {
            const self = this
            this.$overlay.show()
            this.$store.dispatch('login/initMultiAccount').then(function (response) {
                self.$router.push('/multiaccount').then(() => {
                    self.$overlay.hide()
                })
            })
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        }
    },
    watch: {
        $route(to, from) {
            if (this.$mq == 'portrait') {
                this.$store.commit('setMenuToolsExpanded', true)
            } else {
                if (to.name == 'AnalyticsHome') {
                    this.$store.commit('setMenuToolsExpanded', true)
                } else {
                    this.$store.commit('setMenuToolsExpanded', false)
                }
            }
        },
        isMobile(newVal, oldVal) {
            if (this.isMobile) {
                this.$store.commit('setMenuToolsExpanded', true)
                // for desktop: true = menu expanded (we can see the text near the icons)
                // for mobile: true = menu completely hide and page expanded
            } else {
                this.$store.commit('setMenuToolsExpanded', false)
            }
        }
    },
    created() {
        // for mobile
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        if (this.isMobile) {
            this.$store.commit('setMenuToolsExpanded', true)
            // for desktop: true = menu expanded (we can see the text near the icons)
            // for mobile: true = menu completely hide and page expanded
        } else {
            this.$store.commit('setMenuToolsExpanded', false)
        }
    },
    updated() {
        // START LISTENING CLICKS WHEN UBICATION DIV IS OPENED
        if (this.ubicationActive || this.showInfo || this.accountActive) {
            document.addEventListener('click', this.closeBoxes)
        }
        // STOP LISTENING CLICKS WHEN UBICATION DIV IS CLOSED
        else {
            document.removeEventListener('click', this.closeBoxes)
        }
    },
    beforeDestroy() {
        // ENSURE STOP LISTENING CLICKS WHEN MENU TOP IS DESTROYED
        document.removeEventListener('click', this.closeBoxes)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#menu-top {
    // -- PORFABOR ojo al tocar estas medidas xDd
    // -- USER
    $min-width-user-menu: 350px;
    $max-width-user-menu: 550px;

    // -- TITULO
    $width-title: 40%;
    $min-width-title: 100px;
    $max-width-title: calc(100% - #{$max-width-user-menu});
    // $max-width-title: calc(100% - #{$max-width-user-menu} - 100px);
    $max-width-title-with-submenu: calc(40% - (#{$max-width-user-menu} / 2));

    background-color: $color-white;
    @include display-flex();
    @include justify-content(space-between);
    @include box-shadow($h: 3px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-black, 0.05));
    position: fixed;
    top: 0;
    z-index: $z-menu-aux;
    border-bottom: 1px solid $color-neutral-200;
    padding: 10px;
    height: $menu-top-height;
    width: 100%;
    overflow: visible;
    border-bottom: 1px solid $color-neutral-200;
    transition-duration: 0.2s;

    .expand-tools {
        @include background($size: 24px, $image: img('menu.svg'), $color: $color-white);
        background-color: $color-white;
        width: 40px;
        height: 40px;
        border-radius: 0px;
        left: 0px;
        margin: 0px;
        margin-right: 10px;

        &.active {
            background-color: $color-white;
            border-radius: 3px;
        }
    }

    .page-title {
        // @include display-inline-flex();
        // @include align-items(center);
        // @include justify-content();
        @include text-ellipsis();
        @include font-size(lg);
        display: flex;
        margin-inline-end: auto;
        font-family: $text-bold;
        color: $color-black;
        // display: inline-block;
        width: auto;
        min-width: $min-width-title;
        max-width: $max-width-title;
        height: 40px;
        line-height: 1.7;
        overflow: hidden;
        gap: 4px;
        // overflow: visible;

        @media (max-width: 767px) {
            display: none;
        }

        .main-page-title {
            @include font-size(lg);
        }

        &.with-submenu {
            width: auto;
            max-width: $max-width-title-with-submenu;
        }
        b {
            @include font-size(lg);
            font-family: $text-bold;
            color: $color-primary-500;
        }
    }

    .beta {
        @include font-size(lg);
        font-family: $text;
        color: $color-info-500;
    }
    .connection-site {
        @include display-flex();
        @include align-items(center);
        @include justify-content();
        @include background($image: img('down_caret_dark.svg'), $size: 7px, $position: right 5px center);
        width: 65px;
        height: 50px;
        cursor: pointer;
        position: relative;
        top: -5px;

        .images-ubication {
            @include background($image: img('menu_ubication_main_s50.svg'));
            display: block;
            width: 30px;
            height: 30px;
        }

        &.active {
            @include border-radius(3px);
            background-image: img('up_caret_dark.svg');
            background-color: $color-neutral-50;
        }
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .ubication-container {
        @include background($color: $color-neutral-50);
        @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-600, 0.3));
        @include border-radius(3px);
        @include font-size(md);
        position: absolute;
        width: 270px;
        top: 65px;
        right: 0px;
        padding: 12px 15px;
        font-family: $text-bold;

        .search-ubication {
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;

            .search {
                @include border-radius(3px);
                @include background($image: img('search_aaaaaa.svg'), $size: 18px, $position: right 9px center);
                width: 100%;
                height: 35px;
                background-color: #fff !important;
                border: 1px solid $color-neutral-300;
            }
        }

        .locations-list {
            height: auto;
            overflow-y: auto;
            max-height: 180px;
        }

        .location {
            @include display-flex();
            @include justify-content(space-between);
            @include border-radius(1px);
            @include background($color: #fff);

            &.odd {
                @include background($color: $color-neutral-200);
            }

            .loc {
                @include font-size(sm);
                @include text-ellipsis();
                font-family: $text-medium;
                color: $color-black;
                padding: 7px;
            }
            .access {
                @include align-self(center);
                @include background($image: img('external_link_neutro_s30.svg'), $size: 14px, $position: center center);
                width: 24px;
                height: 24px;
                margin-right: 6px;
                cursor: pointer;
            }
            &:hover {
                @include background($color: $color-neutral-300);

                .access {
                    @include border-radius(3px);
                    @include background($image: img('external_link_second_s30.svg'), $size: 14px, $position: center center);
                    background-color: $color-neutral-200;
                }
            }
        }
    }

    .user-menu {
        @include border-radius(3px);
        @include display-flex();
        @include align-self();
        @include justify-content(flex-end);
        float: right;
        height: 40px;
        width: auto;
        // min-width: $min-width-user-menu;
        max-width: $max-width-user-menu;
        text-align: center;
        cursor: pointer;

        #multiaccount-wrapper {
            &.multi-account {
                background-color: $color-white;
            }

            display: flex;
            // margin: 0 10px;
            height: 40px;
            padding: 10px;
            align-items: center;
            border-radius: 3px;
            #multiaccount {
                &.multi-account {
                    cursor: pointer;
                }
                width: 30px;
                height: 30px;
                @include background($image: img('menu_toggle_dark.svg'), $size: 30px, $position: center center);
                margin-right: 5px;
            }
        }

        .logo {
            width: auto;
            height: 40px;
            object-fit: contain;
            object-position: center;
        }

        .account-info,
        .user-image-menu {
            float: left;
        }

        .account-info {
            @include display-flex();
            @include justify-content();
            @include background($size: contain);
            width: auto;
            min-width: 40px;
            max-width: 200px;
            height: 40px;
            overflow: visible;

            &.multi-account {
                @include background($image: img('down_caret_dark.svg'), $position: right 4px center, $size: 8px);
                padding-right: 14px;
                padding-left: 6px;
                &.active {
                    @include background($image: img('up_caret_dark.svg'), $position: right 4px center, $size: 8px);
                    @include border-radius(3px);
                    background-color: $color-neutral-50;
                }
            }

            .account-name {
                @include text-ellipsis();
                @include font-size(lg);
                max-width: 340px;
                color: $color-black;
                text-align: right;
                font-family: $text;
                height: 100%;
                width: 100%;
                line-height: 40px;
            }

            .accounts-container {
                @include display-flex();
                @include flex-direction(column);
                @include background($color: $color-neutral-200);
                @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-600, 0.3));
                @include border-radius(3px);
                position: absolute;
                width: fit-content;
                max-width: 350px;
                min-width: 170px;
                top: 65px;
                padding: 6px 9px;
                height: auto;
                max-height: 600px;

                .title-account {
                    @include font-size(sm);
                    color: $color-black;
                    font-family: $text;
                    // min-width: 170px;
                    margin: 10px 0;
                    width: 220px;
                    text-align: center;
                }
                .search-account {
                    margin-bottom: 6px;
                    margin-top: 6px;

                    .search {
                        width: -webkit-fill-available;
                    }
                }
                .empty-accounts {
                    @include font-size(sm);
                    color: $color-black;
                    font-family: $text-medium;
                }
                .scroll-account-container {
                    @include display-flex();
                    @include flex-direction(column);
                    padding-top: 0px;
                    max-height: 270px;
                    height: auto;

                    .accounts-selector {
                        @include display-flex();
                        // max-height: 40px;
                        // min-height: 40px;
                        height: auto;
                        width: 100%;
                        padding-right: 3px;
                        // margin-bottom: 6px;

                        .single-account {
                            @include display-flex();
                            @include align-items(center);
                            @include justify-content(center);
                            @include border-radius(4px);
                            height: max-content;
                            width: 100%;
                            margin: 5px 0;
                            // background-color: #efefef;
                            border: 1px solid #ddd;
                            // width: 100%;
                            padding: 5px 10px;
                            min-height: 50px;
                            width: 220px;

                            &.selected {
                                border-color: $color-success-500;
                            }

                            .selector {
                                // @include justify-self(flex-start);
                                @include border-radius(50%);
                                background-color: $color-neutral-300;
                                width: 15px;
                                height: 15px;
                                // margin-right: 6px;
                                margin-left: 6px;

                                &.selected {
                                    @include background($image: img('menu_checklist_second.svg'));
                                }
                            }

                            .meta {
                                @include display-flex();
                                @include align-items(center);
                                @include justify-content(flex-start);
                                @include flex-direction(column);
                                width: calc(100% - 20px);

                                .logo {
                                    width: auto;
                                    margin-left: 6px;
                                }
                                .account-name-single-account {
                                    @include text-ellipsis();
                                    width: calc(100% - 27px);
                                    color: $color-black;
                                    font-family: $text;
                                    max-width: calc(100% - 27px);

                                    &.with-logo {
                                        @include text-ellipsis();
                                        margin-left: 12px;
                                        width: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .user-image-menu {
            @include display-flex();
            @include background($image: img('down_caret_neutro_s90.svg'), $position: center right 5px, $size: 7px);
            @include align-self();
            width: auto;
            max-width: 200px;
            border-radius: 4px;
            height: 40px;
            position: relative;
            padding: 5px 0;

            &.clicked {
                @include background($image: img('up_caret_neutro_s90.svg'), $color: $color-neutral-50, $size: 7px, $position: center right 5px);
            }

            .user-image {
                @include background($color: $color-black);
                @include border-radius(50%);
                display: inline-block;
                width: 30px;
                height: 30px;
            }
            .user-name {
                @include align-self(center);
                @include font-size(md);
                @include text-ellipsis();
                font-family: $text-bold;
                color: $color-black;
                line-height: 1.25;
                width: calc(100% - 30px);
                padding-left: 6px;
                padding-right: 18px;
            }
        }
    }

    .user-detail {
        position: fixed;
        right: 10px;
        top: 62px;
        @include background(#fff);
        @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-600, 0.3));
        border-radius: 4px;
        width: 200px;
        height: 73px;
        padding: 9px;
        padding-left: 12px;
        height: auto;

        .edit-profile {
            @include font-size(sm);
            @include display-flex();
            @include justify-content(flex-start);
            @include background($image: img('user_dark.svg'), $position: 0px 6px, $size: 14px);
            font-family: $text-bold;
            cursor: pointer;
            color: $color-black;
            padding-top: 5px;
            padding-left: 20px;
            padding-bottom: 10px;
            width: 100%;
        }
        .notifications {
            @include font-size(sm);
            @include display-flex();
            @include justify-content(flex-start);
            @include background($image: img('bell.svg'), $position: 0px 6px, $size: 14px);
            font-family: $text-bold;
            cursor: pointer;
            color: $color-black;
            padding-top: 5px;
            padding-left: 20px;
            padding-bottom: 10px;
            width: 100%;
        }
        .close-session {
            @include font-size(sm);
            @include display-flex();
            @include justify-content(flex-start);
            @include background($image: img('logout_main_t20.svg'), $position: 0px 6px, $size: 14px);
            font-family: $text-bold;
            cursor: pointer;
            color: $color-black;
            text-align: center;
            padding-top: 5px;
            padding-left: 20px;
            width: 100%;
        }
    }
}
</style>
