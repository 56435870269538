import i18n from '@/i18n'

import Vue from 'vue'

import { Api } from '@/core/api.js'
const api = new Api()

// initial state
const getDefaultState = () => {
    return {
        alexAlert: {}
    }
}
const state = getDefaultState()

// getters
const getters = {

  getAllAlerts: (state, getters, rootState) => {
    return state.alexAlert
  },

}

// actions
const actions = {

  loadAlert (context, params) {
    return api.get('alert', {})
    .then(function (response) {
      //context.commit('loadAlert', response)
      return response.data;
    })
  },
  addAlert (context, params) {
    // return api.get('alert', {})
    //   .then(function (response) {
    //     context.commit('setAlert', response)
    //   })
    context.commit('addAlert', params);
  },
  removeAlert (context, id) {
      context.commit('removeAlert', id)
  }

}

// mutations
const mutations = {

  loadAlert (state, response) {
    var aux = {};
    Object.keys(response.data).map(function (key, i) {
      var alert = response.data[key]

      var actions = [{ class: 'later', text: i18n.t('home.alex_alert.default_later') }]

      if (typeof alert.data.action !== 'undefined') {
        actions.push({ class: 'action', text: i18n.t('home.alex_alert.default_action'), action: '/'+alert.data.action })
      }

      alert.actions = actions
      aux[alert.id] = alert
    });
    state.alexAlert = aux
  },

  addAlert (state, params) {
    var noId = moment().unix()+Math.floor((Math.random() * 1000) + 1);

    params.id = typeof params.id == 'undefined' ? noId : params.id;
    params.type = typeof params.type != "undefined" ? params.type : "alex";
    params.title = typeof params.title != "undefined" ? params.title : i18n.t('home.alex_alert.default_title');
    params.message = typeof params.message != "undefined" ? params.message : i18n.t('home.alex_alert.default_message');
    params.priority = typeof params.priority != "undefined" ? params.priority : Object.keys(state.alexAlert).length;

    params.actions = [{
      class: 'later',
      text: typeof params.text_later != 'undefined' ? params.text_later : i18n.t('home.alex_alert.default_later')
    }]
    if (typeof params.action !== 'undefined') {
      params.actions.push({
        class: 'action',
        text: typeof params.text_action != 'undefined' ? params.text_action : i18n.t('home.alex_alert.default_action'),
        action: '/'+params.action
      });
    }
    var to_replace = false;
    var dontRepeat = ['library','label'];

    var aux = {};
    if( dontRepeat.indexOf(params.type) != -1 && Object.keys(state.alexAlert).length > 0 ) {
      Object.keys(state.alexAlert).map(function (key, i) {
        var currentAlert = state.alexAlert[key]
        if(currentAlert.type == params.type) {
          state.dispatch('removeAlert', currentAlert.id);
        }
        aux = params;
        });
    } else {
        aux = params;
    }

    state.alexAlert[aux.id] = aux
  },

  removeAlert (state, id) {
    Vue.delete(state.alexAlert, id);
  },

  resetState (state) {
      Object.assign(state, getDefaultState())
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
