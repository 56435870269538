<template>
    <input @keyup="$emit('keyup', $event)" @change="$emit('change', $event)" @blur="$emit('blur', $event)" type="date" class="input" :value="formatDate(value)" @input="$emit('input', formatDate($event.target.value))" :placeholder="placeholder" :disabled="inputOptions.disabled" :required="inputOptions.mandatory" :max="inputOptions.max" :min="inputOptions.min"/>
</template>

<script>
export default {
    props: {
        value: { type: [String, Number, Boolean, Array, Object], default: undefined },
        cValue: { type: [String, Number, Boolean, Array, Object], default: undefined },
        cPlaceholder: { type: String, default: '' },
        cOptions: { type: Object, default: undefined }
    },
    data() {
        return {
            tempValue: this.cValue,
            options: {}
        }
    },
    computed: {
        inputOptions() {
            // Mix de options que llegan vs defaults
            return {
                ...this.options,
                ...(this.cOptions ? this.cOptions : {})
            }
        },
        placeholder() {
            return this.cPlaceholder
        }
    },
    methods: {
        formatDate(val) {
            return moment(val).format('YYYY-MM-DD')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
