// Formats
export const FORMAT = {
    CHECK: 1,
    YESNO: 2,
    YESNONC: 3,
    TEXT: 4,
    PHOTO: 5,
    NUMBER: 6,
    DATE: 7,
    TITLE: 8,
    SUBTITLE: 9,
    TEXTAREA: 10,
    MULTIPLE: 11,
    TIME: 12,
    DATETIME: 13,
    SIGNATURE: 14,
    TEMPERATURE: 15,
    EMAIL: 16,
    DOCUMENT: 17,
    MESSAGE: 18,
    LINK: 19,
    RESOURCE: 20
}

export const LABEL_STEP_TYPE = {
    IMAGE: 'image',
    TEXT: 'text',
    BARCODE: 'barcode',
    DAYS: 'days'
}

export const FORMAT_CAT = {
    CHECK: { id: 1, children: [FORMAT.CHECK] },
    OPTIONS: { id: 2, children: [FORMAT.YESNO, FORMAT.YESNONC] },
    SELECTOR: { id: 11, children: [FORMAT.MULTIPLE] },
    TEXT: { id: 4, children: [FORMAT.TEXT, FORMAT.TEXTAREA, FORMAT.DOCUMENT] },
    PHOTO: { id: 5, children: [FORMAT.PHOTO] },
    NUMBER: { id: 6, children: [FORMAT.NUMBER] },
    TEMPERATURE: { id: 15, children: [FORMAT.TEMPERATURE] },
    DATE: { id: 7, children: [FORMAT.DATE, FORMAT.TIME, FORMAT.DATETIME] },
    SIGNATURE: { id: 14, children: [FORMAT.SIGNATURE] },
    EMAIL: { id: 16, children: [FORMAT.EMAIL] },
    LINK: { id: 19, children: [FORMAT.LINK] },
    RESOURCE: { id: 20, children: [FORMAT.RESOURCE] }
}

export const TYPE = {
    TASK: 1,
    AUDIT: 2,
    RECORD: 3,
    ISSUE: 4,
    ACTIONPLAN: 5
}

export const TOOLS = {
    CHECKLISTS: 1,
    ISSUES: 2, // (Incidents)
    LABELS: 3,
    REGISTERS: 4, // Records
    LIBRARY: 5,
    TRAINING: 6,
    AUDITS: 7,
    EXTERNAL_TOOLS: 8,
    TICKETS: 9,
    LOGBOOK: 10,
    DIGITAL_LABEL: 11,
    CREATE_INGR_LABELS: 12,
    ANALYTICS: 14
}
