<template>
    <div class="temporal-template-popup">
        <div class="periodical" :class="{ active: hasPeriod }" @click="selectPeriodical()">
            <h2>{{ getStringObject('template.general.selectTplType.periodicalType') }}</h2>
            <p>{{ getStringObject('template.general.selectTplType.periodicalDescr') }}</p>
        </div>
        <div class="temporal" :class="{ active: !hasPeriod }" @click="selectTemporal()">
            <h2>{{ getStringObject('template.general.selectTplType.temporalType') }}</h2>
            <p>{{ getStringObject('template.general.selectTplType.temporalDescr') }}</p>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
export default {
    name: 'PopupTemplateTemp',
    data() {
        return {
            hasPeriod: true
        }
    },
    methods: {
        selectPeriodical() {
            this.hasPeriod = true
        },
        selectTemporal() {
            this.hasPeriod = false
        },
        getStringObject(str, obj) {
            return i18n.t(str, obj)
        }
    }
}
</script>

<style lang="scss" scoped>
div.temporal-template-popup {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;

    div.periodical,
    div.temporal {
        border-radius: 4px;
        border: 3.5px solid $color-neutral-300;
        padding: 16px;
        background-color: $color-neutral-200;
        width: 250px;
        &:hover {
            cursor: pointer;
        }
        &.active {
            background-color: $color-primary-100;
            border: 3.5px solid $color-primary-700;
        }

        h2 {
            text-align: center;
            font-size: 1.45em;
            font-family: $text-bold;
            margin-bottom: 0;
        }
        p {
            text-align: center;
            font-weight: 300;
            font-family: $text-light;
            padding-top: 0px;
        }
    }
}
</style>
