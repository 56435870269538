<template>
    <nav id="menu-top" :class="{ 'align-center': isMobile }">
        <img v-if="!isMobile" src="/img/logo_andy_small.png" alt="" class="logo-andy" />
        <h5 v-if="!isMobile" class="page-title" v-html="$t('report_logout.title')"></h5>

        <div class="user-menu" :class="{ 'align-center': !avatar }">
            <img v-if="avatar" :src="avatar" alt="" />
            <!-- Avatar de la cuenta -->
            <h4 v-else>{{ userName }}</h4>

            <Button bType="login" customClass="btn-login-report" :bCallback="goToLogin"></Button>
        </div>
    </nav>
</template>

<script>
import Button from '@/components/ui/Button.vue'
export default {
    data() {
        return {
            avatar: false,
            userName: 'User Name',
            // for mobile
            windowWidth: window.innerWidth
        }
    },
    computed: {
        pageTitle() {
            // navigation.js
            var page = this.$store.getters['navigation/getCurrentPage']
            return page ? page.label : ''
        },
        isMobile() {
            return this.windowWidth <= 767
        }
    },
    methods: {
        goToLogin() {
            this.$router.push('/login')
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        }
    },
    watch: {
        $route(to, from) {
            if (to.name == 'AnalyticsHome') {
                this.$store.commit('setMenuToolsExpanded', true)
            } else {
                this.$store.commit('setMenuToolsExpanded', false)
            }
        }
    },
    created() {
        const { avatar, name } = this.$store.getters['login/getLocalEmployee']
        this.avatar = avatar || false

        if (!this.avatar) {
            this.userName = name
        }

        window.addEventListener('resize', this.handleResize)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<!-- Applied globals style from MenuTop -->

<style lang="scss" scoped>
.logo-andy {
    margin-right: 10px;
}
.align-center {
    width: 100% !important;
    height: 100%;
    justify-content: center !important;
}

img {
    max-height: 100%;
}
</style>
