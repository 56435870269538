import Vue from 'vue'
import { Api } from '@/core/api.js'
const api = new Api()

// initial state
const getDefaultState = () => {
    return {
        groups: {},
        groupsTable: {},
        filters: {
            groups: {},
            states: {},
            cities: {},
            status: {}
        },
        filtersActive: {
            name: '',
            status: [{ id: 1, name: 'tools.groups.status.active' }]
        },
        itemsLoaded: false,
        newGroup: {
            name: '',
            status: 1
        }
    }
}
const state = getDefaultState()

// getters
const getters = {
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },

    getList: (state, getters, rootState) => (id) => {
        if (id) {
            return state.groups[id]
        }
        return state.groups
    },
    getListTable: (state, getters, rootState) => (id) => {
        if (id) {
            return state.groupsTable[id]
        }
        return state.groupsTable
    },

    getFilterGroups: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.groups[id]
        }
        return state.filters.groups
    },
    getFilterStates: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.states[id]
        }
        return state.filters.states
    },
    getFilterCities: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.cities[id]
        }
        return state.filters.cities
    },
    getFilterStatus: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.status[id]
        }
        return state.filters.status
    },

    getFiltersActive: (state, getters, rootState) => {
        return state.filtersActive
    }

}

// actions
const actions = {

    loadList (context, params) {
        return api.post('admin/group', params)
            .then(function (response) {
                if (response) {
                    context.commit('setGroups', response.data)
                }
            })
    },
    loadListTable (context, params) {
        return api.post('admin/group', params)
            .then(function (response) {
                if (response) {
                    context.commit('setGroupsTable', response.data)
                }
            })
    },
    editGroup (context, params) {
        var group = context.getters['getList'](params)
        if (group.status == 0) {
            group.status = -2
        }
        return api.post('admin/group/' + params + '/edit', group)
            .then(function (response) {
                if (response.status) {
                    // response.data.locations = Object.values(response.data.locations)
                    context.commit('editGroup', response.data)
                } else {
                    // console.log(response.status);
                }
            })
    },
    addGroup (context, params) {
        var group = context.getters['getList'](params)
        if (group.status == 0) {
            group.status = -2
        }
        return api.post('/admin/group/add', group)
            .then(function (response) {
                if (response.status) {
                    var groups = response.data
                    if (groups.locations) {
                        // groups.locations = Object.values(groups.locations)
                    }
                    // console.log(response)
                    context.commit('addGroup', groups)
                    return response
                }
            })
    },

    validateGroup (context, params) {
        var groupToValidate = context.getters['getList'](params)
        var errors = {
            name: false,
            status: false,
            order: false
        }

        var sendForm = true
        errors = _.mapValues(errors, () => false)

        if (groupToValidate.name === '') {
            sendForm = false
            errors.name = true
        }

        return {
            status: sendForm, errors: errors
        }

    },

    addNewGroup (context, params) {
        var group = Object.assign({}, context.state.newGroup)
        group.id = 'tmpitw' + moment().unix()
        context.commit('createNewGroup', group)
        return group.id
    },


    loadFilters (context) {
        // console.log(params);
        return api.get('admin/group/filter')
            .then(function (response) {
                context.commit('setFilterGroups', response.data)
            })
    },
    deleteGroup (context, params) {
        return api.get('admin/group/' + params.id + '/delete').then(function (response) {
            context.commit('setDeleteGroup', params.id)
        })
    }

}

// mutations
const mutations = {
    createNewGroup (state, payload) {
        var groups = { ...state.groups }
        groups[payload.id] = payload
        Vue.set(state, 'groups', { ...groups })
    },
    updateGroup (state, payload) {
        var groups = { ...state.groups }
        groups[payload.id] = payload
        Vue.set(state, 'groups', { ...groups })
        var groupsTable = { ...state.groupsTable }
        groupsTable[payload.id] = payload
        Vue.set(state, 'groupsTable', { ...groupsTable })
    },
    setGroups (state, response) {
        if (response) {
            Vue.set(state, 'groups', { ...response })
            state.itemsLoaded = true
        }
    },
    setGroupsTable (state, response) {
        if (response) {
            Vue.set(state, 'groupsTable', { ...response })
            // state.itemsLoaded = true
        }
    },
    addGroup (state, params) {
        var groups = { ...state.groups }
        var groupsTable = { ...state.groupsTable }
        groups[params.id] = params
        state.groups = groups
        groupsTable[params.id] = params
        state.groupsTable = groupsTable
        Vue.set(state, 'groups', { ...groups })
        Vue.set(state, 'groupsTable', { ...groupsTable })
    },
    editGroup (state, params) {
        var groups = { ...state.groups }
        var groupsTable = { ...state.groupsTable }
        groups[params.id] = params
        groupsTable[params.id] = params
        state.groups = { ...groups }
        state.groupsTable = { ...groupsTable }
        Vue.set(state, 'groupsTable', { ...groupsTable })
        Vue.set(state, 'groups', { ...groups })
    },
    setDeleteGroup (state, params) {
        var groups = state.groups
        var groupsTable = state.groupsTable
        delete groups[params]
        delete groupsTable[params]
        state.groups = groups
        state.groupsTable = groupsTable
        Vue.set(state, 'groups', { ...groups })
        Vue.set(state, 'groupsTable', { ...groupsTable })
    },
    setFilterGroups (state, payload) {
        var filters = state.filters
        filters = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },

    setFiltersActive (state, filters) {
        state.filtersActive = filters
        Vue.set(state, 'filtersActive', { ...filters })
    },

    resetState (state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
