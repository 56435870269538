<template>
    <div id="upload-list" :class="[{ collapsed: collapsed }, { uploaded: pullUploads.length == 0 }]" v-show="this.$store.getters.uploads_show">
        <div class="upload-header" v-if="pullUploads.length > 0 || uploadingFiles" @click="collapsed = !collapsed">
            <div class="titl">
                <div class="normal">{{ $t('assets.uploader.uploading') }}</div>
                <div class="percent">({{ uploading.file_upload | percent }})</div>
                <span class="pending" v-if="pullUploads.length > 0">{{ pullUploads.length }} {{ $tc('assets.uploader.title_pending', pullUploads.length) }}</span>
            </div>
        </div>
        <div class="upload-header close" v-else @click="collapsed = !collapsed">
            <div class="titl">{{ $t('assets.uploader.uploaded') }}</div>
            <span @click="close" class="close-button"></span>
        </div>

        <div class="upload-content" v-if="uploadingFiles">
            <div class="uploading">
                <div v-if="uploading" class="resource active">
                    <div class="name">
                        {{ uploading.name + ' [' + uploading.file.name.substr(uploading.file.name.length - 3) + ']' }}
                    </div>
                    <v-progress-linear v-model="uploading.file_upload" color="#2C52C4" :indeterminate="file_current(uploading)"></v-progress-linear>
                </div>
            </div>

            <template v-if="pullUploads.length > 0">
                <div class="titl">
                    {{
                        $tc('assets.uploader.pending', pullUploads.length, {
                            s: pullUploads.length
                        })
                    }}
                </div>
                <div class="waiting hide-scrollbar">
                    <div v-for="(item, index) in pullUploads" class="resource">
                        <div class="name">
                            {{ item.name + ' [' + item.name.substr(item.name.length - 3) + ']' }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div v-else class="upload-good">
            <div class="img-good"></div>
            <div class="message-good">
                {{ $tc('assets.uploader.completed', pullUploads.length) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {
            uploadings: [],
            collapsed: false,
            numFiles: 0,
            numFilesUploaded: 0,
            myTimeout: ''
        }
    },
    computed: {
        uploading() {
            // console.log('UPLOADING', (this.$store.getters[ 'library/getUploading' ] ? 1 : 2))
            var uploading = this.$store.getters['library/getUploading']

            // TODO DELETE - TEST
            // uploading = {file: {name: 'Protocolo de COVID [PDF]'}, file_upload: 65 };
            // END TEST
            //   console.log("UPLOAAAD", uploading);

            return uploading.file ? uploading : false
        },
        pullUploads() {
            // console.log('PULLUPLOADS', this.$store.getters[ 'library/getPullUploads' ])

            // TODO DELETE - TEST
            // return [{file: {name: 'imagenes_divertidos_graciosos [webp]'} }, {file: {name: 'Nombre de Fichero subiendo hiper largo porque viva la vida si se puede [png]'} }, {file: {name: 'error [gif]'} }, {file: {name: 'Nombre_de_Fichero_subiendo_hiper_largo_y_ademas_con_barras [xdd]'} }, {file: {name: 'Fichero subiendo [png]'} }, {file: {name: 'Fichero subiendo [png]'} }, {file: {name: 'Documento_word_2020_09_18 [docx]'} }, {file: {name: 'Fichero_subiendo [xls]'} }, {file: {name: 'Fichero subiendo [png]'} },  {file: {name: 'Fichero subiendo `[png]'} }];
            // END TEST
            var uploads = this.$store.getters['library/getPullUploads']
            return uploads
        },
        thumb_current() {
            if (typeof this.uploading.thumb_upload !== 'undefined') {
                return false
            }
            return true
        },
        uploadingFiles() {
            // console.log('uploadFiles', this.$store.getters['library/getUploadsLength'])

            // TODO DELETE - TEST
            // return true;
            // END TEST
            var uploadLength = this.$store.getters['library/getUploadsLength'] > 0
            if (uploadLength) {
                if (!this.$store.getters.uploads_show) {
                    this.numFiles = this.$store.getters['library/getUploadsLength']
                }
                this.$store.commit('showUploads', uploadLength)
            }
            return uploadLength
        }
        // numFilesUploaded() {
        //   var numFiles = this.$store.getters["library/getNumFilesUpload"];
        //   if (numFiles != 0) {
        //     this.numFilesUploaded = numFiles;
        //   }
        //   return this.numFilesUploaded;
        // }
    },
    methods: {
        close() {
            this.$store.commit('showUploads', false)
        },
        file_current(resource) {
            if (typeof resource.file_upload !== 'undefined') {
                return false
            }
            return true
        }
    },
    mounted() {},
    created() {},
    filters: {
        percent: function (num) {
            if (typeof num === 'undefined') return 'En cola'
            else if (num < 100) return num + ' %'
            return 'Subido correctamente'
        }
    },
    watch: {
        pullUploads(newValue) {
            const self = this
            clearTimeout(this.myTimeout)
            this.myTimeout = setTimeout(function () {
                self.close()
            }, 10000)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#upload-list {
    @include box-shadow($h: 0px, $v: 0px, $b: 6px, $s: 0px, $c: rgba($color-black, 0.15));
    @include border-radius(4px);
    @include background($color: #fff);
    z-index: $z-uploader;
    position: fixed;
    right: 12px;
    bottom: 0;
    width: 400px;

    .upload-header {
        @include border-top-radius(4px);
        @include background($image: img('down_dark.svg'), $size: 10px, $position: right 12px center);
        background-color: $color-primary-100;
        padding: 9px 30px 9px 12px;
        cursor: pointer;

        .titl {
            @include font-size(md);
            font-family: $text-medium;
            color: $color-primary-500;
            display: inline-block;
            width: 100%;

            .normal,
            .percent,
            .pending {
                @include text-ellipsis();
                font: inherit;
                font-family: $text-medium;
                color: inherit;
                display: inline-block;
                max-width: 120px;
            }

            .percent {
                font-family: $text-bold;
                margin-left: 6px;
            }

            .pending {
                @include font-size(sm);
                line-height: 1.5;
                font-family: $text-medium;
                float: right;
            }
        }

        &.close {
            @include display-flex();
            @include justify-content(space-between);
            background-image: unset !important;
            padding-right: 12px;

            .titl {
                width: 200px;

                .normal {
                    width: 200px;
                }
            }

            .close-button {
                @include background($image: img('close_dark.svg'), $size: 10px, $position: right 0px center);
                height: 20px;
                width: 30px;
            }
        }
    }
    .upload-content {
        @include display-flex();
        @include align-items(flex-start);
        @include justify-content();
        @include flex-direction(column);
        @include flex-wrap(wrap);
        padding: 9px 12px;
        min-height: 75px;
        height: auto;

        .titl {
            @include font-size(sm);
            font-family: $text-bold;
            color: $color-black;
            margin: 12px 0 0 0;
        }

        .uploading {
            width: 100%;
            padding: 6px 0 9px 0;
        }
        .waiting {
            width: 100%;
            max-height: 200px;
            height: auto;
            overflow: auto;
        }

        .resource {
            padding-left: 6px;
            margin-top: 9px;

            .name {
                @include font-size(sm);
                font-family: $text-medium;
                color: $color-neutral-600;
                min-height: 22px;
                height: auto;
                word-break: break-word;
                white-space: break-spaces;
            }
        }
        .resource.active {
            padding: 0;
            margin: 0;

            .name {
                @include font-size(md);
                font-family: $text-bold;
                color: $color-black;
            }
        }
    }
    .upload-good {
        @include display-flex();
        width: 100%px;
        height: 80px;
        .img-good {
            @include background($image: img('success_ok.svg'), $size: 40px, $position: left 15px center);
            padding-left: 10px;
            width: 35%;
        }
        .message-good {
            @include font-size(ml);
            font-family: $text;
            width: 100%;
            align-self: center;
        }
    }

    &.collapsed {
        .upload-header {
            background-image: img('up_dark.svg');
        }

        .upload-content {
            display: none;
        }
    }

    &.uploaded {
        width: 250px;
    }
}
</style>
