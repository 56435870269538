import { Api } from '@/core/api.js'
import Vue from 'vue'

const api = new Api()

const state = {
    issueStates: {},
    filtersStatus: {},
    filtersStatusActive: {
        status: [{ id: 1, name: 'tools.groups.status.active' }]
    },
    numItems: 0,
    itemsLoaded: false,
    newIssueState: {
        id: '',
        name: '',
        color: '',
        status: '1'
    }
}

const getters = {
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },
    getIssueStates: (state, getters, rootState) => (id) => {
        if (id) {
            return state.issueStates[id]
        }
        return state.issueStates
    },
    getFiltersStatus: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersStatus.status[id]
        }
        return state.filtersStatus.status
    },
    getFiltersStatusActive: (state, getters, rootState) => {
        return state.filtersStatusActive
    },
    getNumItems: (state, getters, rootState) => {
        return state.numItems
    }
}

const actions = {
    addNewIssueState(context, params) {
        var newIssueState = Object.assign({}, context.state.newIssueState)
        newIssueState.id = 'tmpitw' + moment().unix()
        context.commit('createNewIssueState', newIssueState)
        return newIssueState.id
    },
    loadIssueStates(context, params) {
        context.commit('setItemsLoaded', false)
        return api.post('admin/checklist/issues/states', params).then(function (response) {
            if (response) {
                context.commit('setIssueStates', response.data.data)
                context.commit('setNumItems', response.data.results)
            }
        })
    },
    loadFiltersIssueStates(context) {
        // console.log(params);
        return api.get('admin/labels/category/filters').then(function (response) {
            context.commit('setFiltersStatus', response.data)
        })
    },
    removeIssueState(context, params) {
        return api.get('admin/checklist/issues/state/' + params + '/delete').then(function (response) {
            if (response) {
                context.commit('deleteIssueState', params)
            }
        })
    },
    validateIssueStates(context, params) {
        var stateToValidate = context.getters['getIssueStates'](params)
        var errors = {
            name: false,
            color: false
        }
        var sendForm = true
        errors = _.mapValues(errors, () => false)

        if (stateToValidate.name === '') {
            sendForm = false
            errors.name = true
        }
        if (stateToValidate.color === '') {
            sendForm = false
            errors.color = true
        }
        return {
            status: sendForm,
            errors: errors
        }
    },
    addIssueState(context, params) {
        if (params.status == 0) {
            params.status = '-2'
        }
        return api.post('admin/checklist/issues/state/add', params).then(function (response) {
            if (response) {
                context.commit('createNewIssueState', response.data)
            }
        })
    },
    editIssueState(context, params) {
        if (params.status == 0) {
            params.status = '-2'
        }
        return api.post('admin/checklist/issues/state/' + params.id + '/edit', params).then(function (response) {
            if (response) {
                context.commit('updateIssueState', response.data)
            }
        })
    }
}

const mutations = {
    setIssueStates(state, data) {
        state.issueStates = data
        state.itemsLoaded = true
    },
    deleteIssueState(state, id) {
        var issueStates = { ...state.issueStates }
        delete issueStates[id]
        Vue.set(state, 'issueStates', { ...issueStates })
    },
    createNewIssueState(state, payload) {
        var issueStates = { ...state.issueStates }
        issueStates[payload.id] = payload
        state.issueStates = { ...issueStates }
        Vue.set(state, 'issueStates', { ...issueStates })
    },
    updateIssueState(state, payload) {
        var issueStates = { ...state.issueStates }
        issueStates[payload.id] = payload
        Vue.set(state, 'issueStates', { ...issueStates })
    },
    setFiltersStatus(state, filters) {
        state.filtersStatus = filters
        Vue.set(state, 'filtersStatus', { ...filters })
    },
    setFiltersStatusActive(state, filters) {
        state.filtersStatusActive = filters
        Vue.set(state, 'filtersStatusActive', { ...filters })
    },
    setNumItems(state, payload) {
        Vue.set(state, 'numItems', payload)
    },
    setItemsLoaded(state, payload) {
        Vue.set(state, 'itemsLoaded', payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
