<template>
    <div class="delete-item-popup">
        <span v-for="item in items">{{ item.title }}</span>
        <p class="wordconfirm">{{ getStringObject('supervise.checklists.word_confirm') }}</p>
        <input v-model="magicword" @input="wordType" type="text" :placeholder="getStringObject('supervise.checklists.deleteph')" />
    </div>
</template>

<script>
import i18n from '@/i18n'
export default {
    name: 'PopupDelete',
    props: {
        items: { Object }
    },
    data() {
        return {
            magicword: ''
        }
    },
    methods: {
        getStringObject(str, obj) {
            return i18n.t(str, obj)
        },
        wordType() {
            if(this.magicword == this.getStringObject('supervise.checklists.deleteph')) {
                this.$emit('enableDelete')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
div.delete-item-popup {
    p.wordconfirm {
        font-weight: lighter;
        margin-bottom: 16px;
        margin-top: 0 !important;
        font-family: $text;
    }

    input {
        height: 35px;
        display: block;
        margin: 0 auto;
        text-align: center;
    }

    ::-webkit-input-placeholder {
        text-align: center;
    }
}
</style>
