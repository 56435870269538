<template>
    <nav id="menu-tools" :class="[{ expanded: isExpanded }, { hide: isExpanded && $mq == 'portrait' }]">
        <template v-if="!multiAccountPage">
            <router-link :to="redirectPath(home)" class="andy" :class="[{ selected: isSelected(home) }]">
                <div v-if="alexIcon" class="alex-face" :style="{ backgroundImage: 'url(' + alexIcon + ')' }"></div>
                <div v-else class="alex-face"></div>
                <span class="name">Inicio</span>
            </router-link>
        </template>

        <template v-if="!multiAccountPage">
            <div class="list scrollbar">
                <!-- PARENT ITEM -->
                <template v-for="(index, k) in tools">
                    <div
                        class="section"
                        :key="k"
                        v-if="
                            (index &&
                                index.id != 'navDashboard' &&
                                index &&
                                (typeof index.visible === 'undefined' || index.visible) &&
                                index.id != 'onboarding') ||
                            (showOnboarding && index.id == 'onboarding')
                        "
                    >
                        <div
                            :id="index.id"
                            class="item"
                            :class="{
                                selected: isSelected(index.href) && !hasSelectedChild(index.access),
                                expanded: expandedTools.includes(index.id),
                                [index.class]: true,
                                'with-onboarding': index.id == 'navDashboard' && showOnboarding
                            }"
                            @click="index.href ? navTool(index.href) : toggleExpanded(index)"
                        >
                            <div class="icon"></div>
                            <span v-if="index.href" class="name" :to="index.href">{{ $t(index.name) }}</span>
                            <span v-else class="name">{{ $t(index.name) }}</span>
                        </div>
                    </div>
                </template>

                <template v-if="!multiAccountPage">
                    <div @click="goToNews()" class="news section" :class="{ isSelected: this.$route.name == 'News' }">
                        <div class="item">
                            <div class="icon"></div>
                            <span class="notification" v-if="hasNews()"></span>
                            <span class="name">{{ $t('news.title') }}</span>
                        </div>
                    </div>
                </template>
                <div v-if="chatActive" @click="openChat()" class="chat section">
                    <div class="item">
                        <div class="icon"></div>
                        <span class="name">{{ $t('tools.chat') }}</span>
                    </div>
                </div>
                <a :href="helpcenterUrl" target="_blank" class="help-center section">
                    <div class="item">
                        <div class="icon"></div>
                        <span class="name">{{ $t('tools.helpcenter_title') }}</span>
                    </div>
                </a>
            </div>
        </template>

        <!-- <Copyright :isSmall="true"></Copyright> -->
        <AppVersion v-if="isExpanded"></AppVersion>
    </nav>
</template>

<script>
import Copyright from '@/components/ui/Copyright'
import AppVersion from '@/components/ui/AppVersion'

export default {
    components: { Copyright, AppVersion },
    data() {
        return {
            expandedTools: [],
            helpcenterUrl: 'https://help.andyapp.io/',
            year: moment().format('YYYY'),
            // for mobile
            windowWidth: window.innerWidth
        }
    },
    computed: {
        home() {
            return this.$store.getters['getHome'] ? this.$store.getters['getHome'] : '/home'
        },
        visibleTools() {
            var visible = []
            if (localStorage.config) {
                var config = JSON.parse(localStorage.config)
                if (config.tools) {
                    visible = config.tools
                }
            }
            return visible
        },
        isExpanded() {
            return this.$store.state['menu_tools_is_expanded']
        },
        roles() {
            return this.$store.getters['employee/getRoles']()
        },
        userRole() {
            var user = this.$store.getters['login/getLocalEmployee']
            var roles = this.$store.getters['employee/getRol'](user.rol)

            if (roles != undefined) {
                return parseInt(roles.level)
            }
            return false
        },
        tools() {
            if (this.userRole) {
                const tools = Object.values(this.$store.getters['getToolsByRoleLevel'](this.userRole))

                if (this.isMobile) {
                    const toolsToHide = ['navSettings', 'navAnalytics', 'navLogbook']
                    const newTools = tools.filter((tool) => !toolsToHide.includes(tool.id))

                    return newTools
                } else {
                    return tools
                }
            }
        },
        alexIcon() {
            return this.$store.getters['login/getAppIcon']
        },
        news() {
            var news = this.$store.getters['login/getReleases']()
            return news
        },
        chatActive() {
            return this.$store.getters.getIsChatActive
        },
        showOnboarding() {
            return this.$store.getters['login/getType'] == 1
        },
        multiAccountPage() {
            const multiaccount = this.$store.getters['login/getEmployeeMultiAccount']
            return multiaccount
        },
        isMobile() {
            return this.windowWidth <= 767
        }
    },

    methods: {
        goToNews() {
            this.$router.push({ name: 'News' })
        },
        openChat() {
            document.getElementsByClassName('zsiq_floatmain')[0].click()

            const oAccount = this.$store.getters['loginUser/getLocalAccount']
            const oUser = this.$store.getters['loginUser/getLocalUser']
            const oRole = this.$store.getters['employee/getRoles'](oUser.rol)

            $zoho.salesiq.ready = function () {
                $zoho.salesiq.visitor.info({
                    app_version: process.env.VUE_APP_VERSION,
                    account: oAccount.account_name,
                    employee: oUser.name + ' ' + oUser.surname,
                    employee_email: oUser.email,
                    employee_rol: oRole.name + ' (Level ' + oRole.level + ')'
                })
            }
            $zoho.salesiq.ready()
        },
        hasNews() {
            if (Object.values(this.news).length > 0) {
                var releasesViewed = JSON.parse(localStorage.getItem('release_viewed'))
                // Se compara con null por si no ha visto ninguna novedad en el caso de que si, entra e itera
                if (releasesViewed != null) {
                    for (var index in this.news) {
                        var idx = this.news[index]
                        if (!releasesViewed.filter((e) => e === idx.id).length > 0) {
                            return true
                        }
                    }
                } else {
                    if (Object.values(this.news).length > 0 && releasesViewed == null) {
                        return true
                    }
                }
                return false
            }
        },
        hasOnboarding() {
            return true
        },
        location() {
            return this.$route.path
        },
        hasSelectedChild(item) {
            if (typeof item !== 'undefined') {
                for (var li in item) {
                    var idx = item[li]
                    if (idx.href == this.location()) {
                        return true
                    }
                    if (idx.access) {
                        for (var it in idx.access) {
                            var indx = idx.access[it]
                            if (indx.href == this.location() || this.location().includes(indx.href)) {
                                return true
                            }
                        }
                    }
                }
            } else {
                return false
            }
        },

        // hasAccess (tool) {
        //   var self = this

        //   if (tool.access) {
        //     for (var ind in tool.access) {
        //       var subitem = tool.access[ind]

        //       return self.hasAccessTool(subitem)
        //     }
        //   } else {
        //     return self.hasAccessTool(tool)
        //   }
        //   return false
        // },
        // hasAccessTool (tool) {
        //   if (tool.required_tool == false) {
        //     return true
        //   }

        //   if (this.visibleTools.indexOf(parseInt(tool.required_tool)) != -1) {
        //     return true
        //   }
        //   return false
        // },

        isSelected(href) {
            return this.location() === href
        },
        toggleExpanded(section) {
            if (!section.href) {
                if (this.expandedTools.includes(section.id)) {
                    this.expandedTools = this.expandedTools.filter((e) => e !== section.id)
                } else {
                    this.expandedTools.push(section.id)
                }
            }
        },

        navTool(path) {
            let self = this

            self.$router.push(self.redirectPath(path)).catch((err) => {
                if (err.name == 'NavigationDuplicated') {
                    self.$router.push('/reset' + self.redirectPath(path))
                }
            })
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },

        handleResize() {
            this.windowWidth = window.innerWidth
        }
    },
    watch: {
        // $mq(newValue, oldValue) {
        //     if (newValue == 'portrait') {
        //         this.$store.commit('setMenuToolsExpanded', true)
        //     } else {
        //         this.$store.commit('setMenuToolsExpanded', false)
        //     }
        // }
    },
    created() {
        if (this.$route.name == 'Home') {
            this.$store.commit('setMenuToolsExpanded', true)
        } else {
            this.$store.commit('setMenuToolsExpanded', false)
        }

        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        if (this.$store.getters.getIsChatActive) {
            let Script = document.createElement('script')
            Script.setAttribute('src', '/js/chat.js')
            document.head.appendChild(Script)
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#menu-tools {
    $helpcenter-icon-width: 30px;
    $menu-min-content: $menu-tools-min - 10px;
    $menu-alex-height: 60px;

    @include box-shadow($h: 3px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-black, 0.05));
    @include display-flex();
    @include align-items();
    @include justify-content(flex-start);
    @include flex-direction(column);
    position: fixed;
    top: $menu-alex-height;
    left: 0;
    z-index: $z-menus;
    height: calc(100vh - $menu-alex-height);
    width: $menu-tools-min;
    padding: 20px 0;
    overflow: hidden;
    transition-duration: 0.2s;
    background: linear-gradient(227deg, #1e9892 0%, #1c6662 60%);

    @media (max-width: 1024px) {
        padding-top: 5px;
        padding-bottom: 0;
    }

    .news,
    .chat {
        @extend .interaction;
        @include display-flex();
        @include justify-content(flex-start);
        @include align-items();
        @include flex-wrap(wrap);
        @include flex-direction(row);
        // width: calc(100% - 10px);
        min-height: 40px;
        // height: $menu-tools-min;
        padding: 0;
        overflow: hidden;
        word-break: break-word;
        position: relative;

        // margin-left: 0px;

        // position: absolute;
        // bottom: 75px;
        // left: 12px;

        .icon {
            @include background($size: 20px, $image: img('space_rocket_launch.svg'));
            display: inline-block;
            height: 40px;
            width: 50px;
        }
        .notification {
            position: absolute;
            top: 3px;
            right: 25px;
            padding: 4px 4px;
            border-radius: 50%;
            background: $color-primary-500;
            color: white;
            height: 4px;
            width: 4px;
            transition-duration: 0.2s;
        }

        .name {
            @include background($size: 12px, $position: right center);
            @include font-size(sm);
            text-decoration: none;
            color: $color-neutral-200;
            font-family: $text-bold;
            width: auto;
            display: none;
            transition-duration: $transition-duration;
        }
        &.isSelected {
            border-radius: 3px 0 0 3px;
            background-color: $color-primary-800;
        }
    }
    .chat {
        // bottom: 26px;
        .icon {
            @include background($size: 20px, $image: img('chat.svg'));
        }
    }
    .help-center {
        @extend .interaction;
        @include display-flex();
        @include justify-content(flex-start);
        @include align-items();
        @include flex-wrap(wrap);
        @include flex-direction(row);
        width: 100%;
        height: $menu-tools-min;
        min-height: 40px;
        padding: 0;
        overflow: hidden;
        word-break: break-word;
        padding-left: 10px;

        .icon {
            @include background($size: 20px, $image: img('menu_info_ffffff.svg'));
            display: inline-block;
            height: $menu-min-content;
            width: $menu-tools-min;
        }

        .name {
            @include background($size: 12px, $position: right center);
            @include font-size(sm);
            text-decoration: none;
            color: $color-neutral-200;
            font-family: $text-bold;
            max-width: calc(100% - #{$helpcenter-icon-width});
            width: auto;
            display: none;
            // padding-right: 18px;
            transition-duration: $transition-duration;

            &:hover {
                background-image: img('external_link_ffffff.svg');
                background-position: left 112px center;
                color: #fff;
            }
        }
    }
    .andy,
    .news,
    .chat,
    .help-center {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include flex-wrap(wrap);
        @include flex-direction(row);
        @include background($size: 20px);
        border-radius: 3px 0 0 3px;
        width: 100%;
        min-height: $menu-min-content;
        height: auto;
        padding: 0;
        overflow: hidden;
        word-break: break-word;
        cursor: pointer;
        transition-duration: 0.2s;
        // padding-right: 15px;

        .alex-face {
            @include background($size: 30px, $image: img('alexhead_06.svg'));
            display: inline-block;
            height: $menu-min-content;
            width: $menu-tools-min;
            transition-duration: 0.2s;
        }
        .name {
            @include font-size(md);
            text-decoration: none;
            color: $color-neutral-200;
            font-family: $text;
            width: calc(100% - #{$menu-tools-min});
            display: none;
        }
        &.selected {
            background-color: $color-primary-800;
            font-family: $text-bold;

            .name {
                @include inheritFont();
                @include font-size(md);
                color: #fff;
            }
        }
        &:hover {
            background-color: $color-primary-800;
        }
    }

    .list {
        @include display-flex();
        @include flex-direction(column);
        width: inherit;
        height: calc(100%);
        padding-top: 20px;
        gap: 0px;
        overflow-y: auto;
        overflow-x: hidden;

        @media (max-width: 1024px) {
            padding: 0;
            padding-top: 8px;
            // gap: 4px;
        }

        &.scrollbar::-webkit-scrollbar-track {
            overflow-y: auto;
            background: $color-primary-800;
        }
        &.scrollbar::-webkit-scrollbar-thumb {
            overflow-y: auto;
            background: $color-primary-500;
        }
    }

    .section {
        min-height: 40px;

        .item {
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include flex-wrap(wrap);
            @include flex-direction(row);
            @include background($size: 20px);
            // border-radius: 3px 0 0 3px;
            width: $menu-tools-min;
            min-height: $menu-min-content;
            height: auto;
            padding: 4px 0;
            overflow: hidden;
            word-break: break-word;
            cursor: pointer;
            transition-duration: 0.2s;

            &.selected {
                background-color: $color-primary-800;
            }

            &.child {
                display: none;
            }
            &.subchild {
                display: none;
            }

            .icon {
                @include background($size: 20px);
                display: inline-block;
                height: $menu-min-content;
                width: $menu-tools-min;
                transition-duration: 0.2s;
            }

            .name,
            .subname {
                @include font-size(md);
                text-decoration: none;
                color: $color-neutral-200;
                font-family: $text;
                width: calc(100% - #{$menu-tools-min});
                display: none;
            }
            &:hover,
            &.selected {
                font-family: $text-bold;

                .name {
                    @include inheritFont();
                    @include font-size(md);
                    color: #fff;
                }

                &#navSuperviseChecklist > .icon {
                    background-image: img('menu_checklist_ffffff.svg');
                }
                &#navSuperviseRecords > .icon {
                    background-image: img('menu_register_ffffff.svg');
                }
                &#navSuperviseIssues > .icon {
                    background-image: img('menu_issue_ffffff.svg');
                }
                &#navSuperviseAudits > .icon {
                    background-image: img('menu_audit_ffffff.svg');
                }
                &#navSuperviseLabels > .icon {
                    background-image: img('menu_label_ffffff.svg');
                }
                &#navLibrary > .icon {
                    background-image: img('menu_library_ffffff.svg');
                }
                &#navLogbook > .icon {
                    background-image: img('menu_logbook_ffffff.svg');
                }
                &#navSettings > .icon {
                    background-image: img('menu_config_ffffff.svg');
                }
                &#navAnalytics > .icon {
                    background-image: img('menu_analytics.svg');
                }
                &#navSuperviseOperationalTimers > .icon {
                    background-image: img('menu_timer_light.svg');
                }
            }
            &.selected:hover {
                @extend .selected;
                background-color: $color-primary-800;
            }
            &:hover {
                background-color: $color-primary-800;
                background-size: 21px;
                font-family: $text-bold;
            }
        }
    }

    &.expanded {
        width: $menu-tools-max;

        #navDashboard > .icon {
            background-size: 30px;
        }

        .news,
        .chat {
            .icon {
                background-size: 15px;
                width: 50px;
                position: relative;
            }
            .notification {
                position: absolute;
                top: 3px;
                right: 25px;
                padding: 4px 4px;
                border-radius: 50%;
                background: $color-primary-500;
                color: white;
                height: 4px;
                width: 4px;
                transition-duration: 0.2s;
            }
            .name {
                display: inline-block;
            }
        }
        .help-center {
            .icon {
                background-size: 15px;
                width: 50px;
                padding-right: 15px;
            }
            .name {
                display: inline-block;
            }
        }

        .andy {
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include flex-wrap(wrap);
            @include flex-direction(row);
            @include background($size: 20px);
            border-radius: 3px 0 0 3px;
            min-height: $menu-min-content;
            height: auto;
            padding: 0;
            overflow: hidden;
            word-break: break-word;
            cursor: pointer;
            transition-duration: 0.2s;
            // padding-right: 15px;

            .alex-face {
                // @include background($image: img('alexhead_06.svg'), $size: $menu-min-content);
                // // position: fixed;
                // // top: 0px;
                // // left: 0px;
                // height: $menu-tools-min;
                // width: $menu-tools-min;
                // transition-duration: 0.2s;
                // cursor: pointer;

                @include background($size: 30px);
                display: inline-block;
                height: $menu-min-content;
                width: $menu-tools-min;
                transition-duration: 0.2s;
            }

            .name {
                display: inline-block;
            }
        }

        .item {
            width: 100%;

            // &.parent {
            //   @include background(
            //     $image: img("down_caret_main.svg"),
            //     $size: 6px,
            //     $position: right 6px center
            //   );
            // }
            &.expanded.parent {
                background-image: img('up_caret_main.svg');
                margin-bottom: 0;
            }
            &.child,
            &.subchild {
                @include display-flex();
            }

            .icon {
                background-size: 18px;
            }

            .name,
            .subname {
                display: inline-block;
            }
        }
    }
    &:not(.expanded):not(:hover) {
        .section .item.selected-parent {
            @extend .selected;
        }
    }
    &:not(.expanded) {
        .news,
        .chat {
            // left: -2px;

            .notification {
                position: absolute;
                top: 3px;
                right: 25px;
                padding: 4px 4px;
                border-radius: 50%;
                background: $color-primary-500;
                color: white;
                height: 4px;
                width: 4px;
                transition-duration: 0.2s;
            }
        }
    }

    &.expanded {
        .expand-tools {
            left: $menu-tools-max - $menu-min-content - 6px;
        }
        .news {
            .notification {
                position: absolute;
                top: 9px;
                right: 178px;
                padding: 2px 2px;
                border-radius: 50%;
                background: $color-primary-500;
                color: white;
                height: 4px;
                width: 4px;
                transition-duration: 0.2s;
            }
        }
    }

    &.hide {
        display: none;
    }

    // &:hover:not(#menu-tools.expanded) {
    //     width: $menu-tools-max;
    // }

    //ITEM IMAGES
    #navDashboard {
        // margin-bottom: 50px;

        > .icon {
            background-size: 40px;
        }

        &.with-onboarding {
            margin-bottom: 0px;
        }
    }
    #onboarding {
        // margin-bottom: 30px;

        > .icon {
            background-image: img('onboarding_neutro.svg');
        }
    }
    // #navSettings {
    //     // margin-top: 30px;
    // }
    // #navTemplate > .icon {
    //     // background-image: img("menu_template_neutro.svg");
    // }
    #navSuperviseChecklist > .icon {
        background-image: img('menu_checklist_ffffff.svg');
    }
    #navSuperviseRecords > .icon {
        background-image: img('menu_register_ffffff.svg');
    }
    #navSuperviseIssues > .icon {
        background-image: img('menu_issue_ffffff.svg');
    }
    #navSuperviseAudits > .icon {
        background-image: img('menu_audit_ffffff.svg');
    }
    #navSuperviseLabels > .icon {
        background-image: img('menu_label_ffffff.svg');
    }
    #navAnalytics > .icon {
        background-image: img('menu_analytics.svg');
    }
    // #navAnalytic > .icon {
    //     // background-image: img("menu_analytic_neutro.svg");
    // }
    #navLibrary > .icon {
        background-image: img('menu_library_ffffff.svg');
    }
    #navLogbook > .icon {
        background-image: img('menu_logbook_ffffff.svg');
    }
    #navSettings > .icon {
        background-image: img('menu_config_ffffff.svg');
    }
    #navSuperviseOperationalTimers > .icon {
        background-image: img('menu_timer_light.svg');
    }
}
.zsiq_floatmain {
    display: none !important;
}
</style>
