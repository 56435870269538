var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tags && _vm.value && _vm.nameTag && _vm.tags.length > 0)?_c('div',{staticClass:"tags-container"},[(_vm.showLess && _vm.limit != false)?_c('div',{staticClass:"tags-content"},_vm._l((_vm.tags),function(tag,index,index2){return _c('div',{key:index,staticClass:"tag-box"},[(tag != undefined && (index2 < _vm.limit || index < _vm.limit) && (_vm.referenceObject[tag] || tag[_vm.nameTag]))?[(_vm.removable)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tagTooltip(tag)),expression:"tagTooltip(tag)"}],class:[{ 'remove-tag': !_vm.removable, 'wrong-tag': _vm.checkAvailability(tag) }, { backImage: _vm.backImage }, 'tag-name'],style:({
                        backgroundImage: _vm.backImage ? ' url( ' + _vm.cssImage + _vm.backImage + ')' : '',
                        'background-color': _vm.backColor ? (_vm.referenceObject ? _vm.referenceObject[tag][_vm.backColor] : tag[_vm.backColor]) + ' !important' : ''
                    })},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.referenceObject && _vm.referenceObject[tag] ? _vm.referenceObject[tag][_vm.nameTag] : tag[_vm.nameTag])}}),(_vm.removable)?_c('div',{staticClass:"removable",on:{"click":function($event){return _vm.removeItem(tag)}}}):_vm._e()]):(!_vm.removable)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tagTooltip(tag)),expression:"tagTooltip(tag)"}],class:[{ 'remove-tag': !_vm.removable, 'wrong-tag': _vm.checkAvailability(tag) }, { backImage: _vm.backImage }, 'tag-name'],style:({
                        backgroundImage: _vm.backImage ? ' url( ' + _vm.cssImage + _vm.backImage + ')' : '',
                        'background-color': _vm.backColor ? (_vm.referenceObject ? _vm.referenceObject[tag][_vm.backColor] : tag[_vm.backColor]) + ' !important' : ''
                    }),domProps:{"innerHTML":_vm._s(_vm.referenceObject ? _vm.referenceObject[tag][_vm.nameTag] : tag[_vm.nameTag])}}):_vm._e()]:_vm._e()],2)}),0):_c('div',{staticClass:"tags-content"},_vm._l((_vm.tags),function(tag,index,index2){return _c('div',{key:index,staticClass:"tag-box"},[(tag != undefined && _vm.removable && (_vm.referenceObject[tag] || tag[_vm.nameTag]))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tagTooltip(tag)),expression:"tagTooltip(tag)"}],staticClass:"tag-name",class:{ 'remove-tag': !_vm.removable, 'wrong-tag': _vm.checkAvailability(tag), backImage: _vm.backImage },style:({
                    backgroundImage: _vm.backImage ? ' url( ' + _vm.cssImage + _vm.backImage + ')' : '',
                    'background-color': _vm.backColor ? (_vm.referenceObject ? _vm.referenceObject[tag][_vm.backColor] : tag[_vm.backColor]) + ' !important' : ''
                })},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.referenceObject && _vm.referenceObject[tag] ? _vm.referenceObject[tag][_vm.nameTag] : tag[_vm.nameTag])}}),(_vm.removable)?_c('div',{staticClass:"removable",on:{"click":function($event){return _vm.removeItem(tag)}}}):_vm._e()]):(tag != undefined && !_vm.removable)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tagTooltip(tag)),expression:"tagTooltip(tag)"}],staticClass:"tag-name",class:{ 'remove-tag': !_vm.removable, 'wrong-tag': _vm.checkAvailability(tag), backImage: _vm.backImage },style:({
                    backgroundImage: _vm.backImage ? ' url( ' + _vm.cssImage + _vm.backImage + ')' : '',
                    'background-color': _vm.backColor ? (_vm.referenceObject ? _vm.referenceObject[tag][_vm.backColor] : tag[_vm.backColor]) + ' !important' : ''
                }),domProps:{"innerHTML":_vm._s(_vm.referenceObject ? _vm.referenceObject[tag][_vm.nameTag] : tag[_vm.nameTag])}}):_vm._e()])}),0),(_vm.limit < _vm.tags.length && _vm.limit != false)?_c('div',{staticClass:"read-more",on:{"click":function($event){$event.stopPropagation();_vm.showLess = !_vm.showLess}}},[_vm._v("\n        "+_vm._s(_vm.showLess ? _vm.$t(_vm.moreliteral) : _vm.$t(_vm.lessliteral))+"\n    ")]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }