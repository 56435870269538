import Vue from 'vue'
import { FORMAT } from '@/constants'
import store from '@/store'
import { Api } from '@/core/api.js'
import { TYPE } from '@/constants'
import i18n from '@/i18n'

const api = new Api()

const getDefaultState = () => {
    return {
        hasLoadFilters: true,
        itemsLoaded: false,
        categories: {},
        // DEFAULT VARIABLES
        newTemplate: { id: '-1', sections: {}, type: false, config: {}, status: '1' },
        newSection: { id: false, name: '', items: {}, order: 1, status: 1 },
        newItem: {
            id: false,
            title: '',
            type: FORMAT.CHECK,
            status: 1,
            order: 1,
            description: '',
            periodicity: 1,
            margin: false,
            planification: {},
            review: 0,
            pin: 0,
            config: false,
            resource_id: false,
            mandatory: 1,
            require_pin_review: 0,
            parent_id: false,
            conditions: false,
            points: false
        },
        newCondition: {
            id: false,
            operator: '!empty',
            value: '',
            items: {},
            actions: []
        },
        newAlert: { class: 'info', msg: '', type: 'info' },
        newEmail: { class: 'info', msg: '', type: 'email' },
        newIssue: { class: 'alert', msg: '', type: 'info', tpl: '' },

        issueTemplates: [],

        // VARIABLES THAT MAKE FORM WORK AND INTERACT
        selectedContext: false,
        moreItemOptions: false,

        // GENERAL STORE VARIABLES
        filters: {
            1: {
                timeframes: {},
                status: {},
                loaded: false,
                locations: {},
                groups: {},
                roles: {},
                tplType: {},
                tags: {}
            },
            2: {
                status: {},
                loaded: false,
                locations: {},
                groups: {},
                roles: {},
                tags: {}
            },
            3: {
                status: {},
                loaded: false,
                locations: {},
                groups: {},
                roles: {},
                tags: {}
            },
            4: {
                status: {},
                loaded: false,
                locations: {},
                groups: {},
                roles: {},
                tags: {}
            },
            5: {
                status: {},
                loaded: false,
                locations: {},
                groups: {},
                roles: {},
                tags: {}
            },
            categories: {}
        },
        filtersActive: {
            1: {},
            2: {},
            3: {},
            4: {}
        },
        items: {},
        filtersCategoriesActive: {
            name: '',
            status: [{ id: 1, name: 'tools.groups.status.active' }]
        },
        newCategory: {
            id: false,
            name: '',
            color: '#F31E1E',
            status: 1
        },

        templatesToExport: {
            ids: null,
            filters: null,
            displayOptions: [
                { name: 'alerts', key: 1, label: 'template.download.popup.show_alerts', value: true },
                { name: 'issues', key: 2, label: 'template.download.popup.show_action_plans', value: true },
                { name: 'subitems', key: 3, label: 'template.download.popup.show_subitems', value: true }
            ]
        },

        newIsTemporal: false,
        temporaryTemplateType: 1, // 1 = one day / 2 = several days
        temporalTemplateStartDateIsDisabled: false,
        templateIsCloned: false
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    getList: (state, getters, rootState) => (type) => {
        return state.items
    },
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },

    getFiltersCategoriesActive: (state, getters, rootState) => {
        return state.filtersCategoriesActive
    },

    getFilterTimeframe: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters[1].timeframes[id]
        }
        return state.filters[1].timeframes
    },
    getFilterStatus: (state, getters, rootState) => (type, id) => {
        if (id) {
            return state.filters[type].status[id]
        }
        return state.filters[type].status
    },
    getFilterLocations: (state, getters, rootState) => (type, id) => {
        if (id) {
            return state.filters[type].locations[id]
        }
        return state.filters[type].locations
    },
    getFilterGroups: (state, getters, rootState) => (type, id) => {
        if (id) {
            return state.filters[type].groups[id]
        }
        return state.filters[type].groups
    },
    getFilterLoaded: (state, getters, rootState) => (type) => {
        return state.filters[type].loaded
    },

    getFiltersActive: (state, getters, rootState) => (type) => {
        return state.filtersActive[type]
    },

    getIssueTemplates: (state, getters, rootState) => {
        return state.issueTemplates
    },

    getTemplate: (state, getters, rootState) => (id) => {
        return state.items[id]
    },

    getSection: (state, getters, rootState) => (context) => {
        return state.items[context.template_id].sections[context.section_id]
    },

    getItem: (state, getters, rootState) => (context) => {
        if (context.item_parent_id) {
            return state.items[context.template_id].sections[context.section_id].items[context.item_parent_id].conditions.values[context.condition_id].items[
                context.item_id
            ]
        } else
            return state.items[context.template_id].sections[context.section_id]
                ? state.items[context.template_id].sections[context.section_id].items[context.item_id]
                : false
    },

    getCondition: (state, getters, rootState) => (context) => {
        return state.items[context.template_id].sections[context.section_id].items[context.item_id].conditions.values[context.condition_id]
    },

    getAlert: (state, getters, rootState) => (context) => {
        return state.items[context.template_id].sections[context.section_id].items[context.item_id].conditions.values[context.condition_id].actions[
            context.action_id
        ]
    },

    getSelectedContext: (state, getters, rootState) => {
        return state.selectedContext
    },
    getHasLoadFilters: (state, getters, rootState) => {
        return state.hasLoadFilters
    },
    getCategories: (state, getters, rootState) => (id) => {
        if (id) {
            return state.categories[id]
        }

        return state.categories
    },
    getMoreItemOptions: (state, getters, rootState) => {
        return state.moreItemOptions
    },
    getFilterRoles: (state, getters, rootState) => (id) => {
        console.log(state.filters)
        if (id) {
            return state.filters.roles[id]
        }
        return state.filters.roles
    },
    getFilterCategories: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.categories.data.find((category) => category.id === id)
        }
        return state.filters.categories
    },
    getFilterTags: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.tags[id]
        }
        return state.filters.tags
    },

    getDownloadTemplatesDisplayOptions: (state, getters, rootState) => {
        return state.templatesToExport.displayOptions
    },

    getNewIsTemporal: (state, getters, rootState) => {
        return state.newIsTemporal
    },

    getTemporaryTemplateType: (state, getters, rootState) => {
        return state.temporaryTemplateType
    },

    getIsTemporalTemplateStartDateDisabled: (state, getters, rootState) => {
        return state.temporalTemplateStartDateIsDisabled
    },

    getTemplateIsCloned: (state, getters, rootState) => {
        return state.templateIsCloned
    }
}

// actions
const actions = {
    loadTemplates(context, params) {
        state.itemsLoaded = false
        state.items = []
        return api.post('admin/checklist/templates/' + params.type, params.data).then(function (response) {
            return context.commit('setFormat', { items: response.data })
        })
    },

    loadTemplate(context, params) {
        state.items = []
        return api.get('admin/checklist/template/' + params.template_id).then(function (response) {
            var template = response.data
            return context.commit('setTemplate', {
                template: template
            })
        })
    },

    loadCategories(context, params) {
        return api.post('admin/checklist/templates/categories', params).then(function (response) {
            context.commit('setCategories', response.data)
        })
    },

    loadFilters(context, params) {
        return api.get('checklist/admin/' + params.type + '/filter').then(function (response) {
            if (response.data.timeframes)
                context.commit('setFilterTimeframes', {
                    type: params.type,
                    data: response.data.timeframes
                })
            if (response.data.status)
                context.commit('setFilterStatus', {
                    type: params.type,
                    data: response.data.status
                })
            if (response.data.locations) var locations = response.data.locations
            var resultLocations = {}
            Object.values(locations).forEach(function (location) {
                if (location.status === 1) {
                    resultLocations[location.id] = location
                }
            })
            context.commit('setFilterLocations', {
                type: params.type,
                data: resultLocations
            })
            if (response.data.groups) var groups = response.data.groups
            // filter status 1
            var resultGroups = {}
            Object.values(groups).forEach(function (group) {
                if (group.status === 1) {
                    resultGroups[group.id] = group
                }
            })
            context.commit('setFilterGroups', {
                type: params.type,
                data: resultGroups
            })

            if (response.data.roles) {
                const roles = Object.values(response.data.roles).filter((role) => role.status === '1')

                context.commit('setFilterRoles', {
                    type: params.type,
                    data: roles
                })
            }
            if (response.data.categories) {
                const categories = Object.values(response.data.categories)

                context.commit('setFilterCategories', {
                    type: params.type,
                    data: categories
                })
            }

            if (response.data.tags) {
                const tags = Object.values(response.data.tags)
                console.log(tags)

                context.commit('setFilterTags', {
                    type: params.type,
                    data: tags
                })
            }

            context.commit('setFilterLoaded', { type: params.type })
        })
    },

    loadIssueTemplates(context, params) {
        state.issueTemplates = []
        let endpointID =
            params.isAudit == 'ActionplanDetail' ||
            params.isAudit == 'AddActionplanDetail' ||
            params.isAudit == 'AuditDetail' ||
            params.isAudit == 'AddAuditDetail'
                ? 5
                : 4
        return api.post('admin/checklist/templates/' + endpointID, params).then(function (response) {
            var result = []

            for (var index in response.data) {
                var issueTemplate = response.data[index]
                if (issueTemplate.status >= 1) {
                    result.push(issueTemplate)
                }
            }

            context.commit('setIssueTemplates', { issueTemplates: result })
        })
    },

    loadActionplanTemplates(context, params) {
        state.issueTemplates = []

        return Promise.all([api.post('admin/checklist/templates/' + 4, {}), api.post('admin/checklist/templates/' + 5, {})]).then(function (responses) {
            var result = []

            responses.forEach((response) => {
                for (var index in response.data) {
                    var issueTemplate = response.data[index]
                    if (issueTemplate.status >= 1) {
                        result.push(issueTemplate)
                    }
                }
            })

            context.commit('setIssueTemplates', { issueTemplates: result })
        })
    },

    // add and edit template
    save(context, params) {
        // UNSELECT SELECTED ITEM
        context.commit('setSelectedContext', false)

        let template = { ...params.template }
        let order = 1
        let regex = /^tmpitw.*$/

        // order Sections
        var sections = Object.values(template.sections).sort(function (a, b) {
            return a.order < b.order ? -1 : 1
        })
        sections.forEach((section) => {
            // if section is not removed, increase order
            if (section.status != -1) {
                section.order = order
                order++
            }

            // Order Items
            var items = Object.values(section.items).sort(function (a, b) {
                return a.order < b.order ? -1 : 1
            })
            items.forEach((item) => {
                // if section is removed, remove item
                if (section.status == -1) {
                    item.status = -1
                } else {
                    // section is not removed, order item and increase order
                    item.order = order
                    order++
                }

                if (item.config.custom_title == false) {
                    delete item.config.custom_title
                }

                // Order Subitems
                if (item.conditions && item.conditions) {
                    for (let valueId in item.conditions.values) {
                        let value = item.conditions.values[valueId]

                        var subitems = Object.values(value.items).sort(function (a, b) {
                            return a.order < b.order ? -1 : 1
                        })
                        subitems.forEach((subitem) => {
                            // if item is removed, remove subitem
                            if (item.status == -1) {
                                subitem.status = -1
                            }
                            // item is not removed, order subitem and increase order
                            else {
                                subitem.order = order
                                order++
                            }

                            // check if subitem is new and is deleted, remove it
                            if (regex.exec(subitem.id) && subitem.status == -1) {
                                delete item.conditions.values[valueId].items[subitem.id]
                            }
                            // correct subitem, save it
                            else {
                                value.items[subitem.id] = subitem
                            }
                        })

                        item.conditions.values[valueId] = value
                    }
                }

                // check if item is new and is deleted, remove it from section
                if (regex.exec(item.id) && item.status == -1) {
                    delete section.items[item.id]
                }
                // correct item, save it
                else {
                    section.items[item.id] = item
                }
            })

            // check if section is new and is deleted, remove it from template
            if (regex.exec(section.id) && section.status == -1) {
                delete template.sections[section.id]
            }
            // correct section, save it and increment order
            else {
                template.sections[section.id] = section
                order++
            }
        })

        var image = template.file ? template.file : ''
        // Clean image petition
        if (template.file) {
            template.file = ''
            template.image = false
        }

        return api
            .post('admin/checklist/template/save', {
                data: JSON.stringify(template),
                file: image
            })
            .then(function (response) {
                state.templateIsCloned = false
                return response.status
            })
    },

    covertTemplateTo(context, params) {
        return api.post('admin/checklist/template/convert/' + params.template_id, params).then(function (response) {
            return response.data
        })
    },

    validateSection(context, ctx) {
        var validation = true
        var section = {
            ...state.items[ctx.template_id].sections[ctx.section_id]
        }
        // TODO code...
        section.errors = { codes: [], children: 0 }
        if (section.status > -1) {
            if (section.id != -1) {
                if (!section.name) {
                    section.errors.codes.push('empty_name')
                    validation = false
                }
            }

            // ITEM VALIDATIONS
            var promises = []
            for (var item_index in section.items) {
                promises.push(
                    context.dispatch('validateItem', {
                        template_id: ctx.template_id,
                        section_id: section.id,
                        item_id: item_index
                    })
                )
            }

            return Promise.all(promises).then((results) => {
                if (results.includes(false)) validation = false
                else {
                    context.commit('updateSection', { context: ctx, section: section })
                }
                return validation
            })
        } else return true
    },

    validateItem(context, ctx) {
        var item = false
        var validation = true
        const emailRE =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const emailRE2 =
            /^{{(.*?)}}$|^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var section = {
            ...state.items[ctx.template_id].sections[ctx.section_id]
        }
        if (ctx.item_parent_id) {
            // Subitem
            item = {
                ...section.items[ctx.item_parent_id].conditions.values[ctx.condition_id].items[ctx.item_id]
            }
        } else {
            // Main item
            item = {
                ...section.items[ctx.item_id]
            }
        }
        item.errors = { codes: [], children: 0 }

        if (item.status > 0) {
            if (!item.title) {
                item.errors.codes.push('empty_name')
                validation = false
            }

            if (item.sitelimit && (!item.groups || item.groups == '') && (!item.locations || item.locations == '')) {
                item.errors.codes.push('empty_sitelimit')
                validation = false
            }
            // if (!item.description) {
            //     item.errors.codes.push('empty_description')
            //     validation = false
            // }
            switch (item.type) {
                case FORMAT.MULTIPLE:
                    if (!(item.config && item.config.values && item.config.values.length > 0)) {
                        item.errors.codes.push('empty_config')
                        item.errors.codes.push('empty_config_values')
                        validation = false
                    }
                    break

                case FORMAT.TEMPERATURE:
                    break

                case FORMAT.EMAIL:
                    if (!(item.config && item.config.to && item.config.to.length > 0)) {
                        item.errors.codes.push('empty_config')
                        item.errors.codes.push('empty_config_email')
                        validation = false
                    } else {
                        item.config.to.forEach((email) => {
                            if (false == emailRE.test(email)) {
                                if (false == emailRE2.test(email)) {
                                    item.errors.codes.push('empty_config')
                                    item.errors.codes.push('empty_config_email')
                                    validation = false
                                }
                            }
                        })
                    }
                    if (!(item.config && item.config.subject)) {
                        item.errors.codes.push('empty_config')
                        item.errors.codes.push('empty_config_subject')
                        validation = false
                    }
                    if (!(item.config && item.config.message)) {
                        item.errors.codes.push('empty_config')
                        item.errors.codes.push('empty_config_message')
                        validation = false
                    }
                    break
                case FORMAT.LINK:
                    if (!(item.config && item.config.url)) {
                        item.errors.codes.push('empty_config')
                        item.errors.codes.push('empty_config_url')
                        validation = false
                    } else {
                        item.config.url = item.config.url.startsWith('www') ? 'https://' + item.config.url : item.config.url
                    }
                    break

                case FORMAT.RESOURCE:
                    if (!item.resource_id) {
                        item.errors.codes.push('empty_config')
                        item.errors.codes.push('empty_resource_id')
                        validation = false
                    }
                    break

                default:
                    break
            }

            // ITEM PLANIFICATION
            if (state.items[ctx.template_id].type == TYPE.TASK && item.planification) {
                if (item.planification.days && item.planification.days.length == 0) {
                    item.errors.codes.push('empty_config')
                    item.errors.codes.push('empty_config_periodicity_days')
                    validation = false
                } else if (item.planification.months && item.planification.months.length == 0) {
                    item.errors.codes.push('empty_config')
                    item.errors.codes.push('empty_config_periodicity_days')
                    validation = false
                }
            }
            if (item.margin === '') {
                item.errors.codes.push('empty_config')
                item.errors.codes.push('empty_margin')
                validation = false
            }

            if ((typeof item.locations == 'string' && item.locations != '') || (typeof item.groups == 'string' && item.groups != '')) {
                // GET SUPERIOR ENTITY
                var entityLimit = { locationLimit: [], groupLimit: [] }
                var entity = { ...state.items[ctx.template_id] }
                if (ctx.item_parent_id) {
                    var parentItemContext = { ...ctx }
                    parentItemContext.item_id = parentItemContext.item_parent_id
                    parentItemContext.item_parent_id = false
                    var parentItem = store.getters['template/getItem'](parentItemContext)
                    if (parentItem.locations || parentItem.groups) entity = parentItem
                }

                // CHECK IF SUPERIOR ENTITY HAS RESTRICTIONS
                if (entity.locations || entity.groups) {
                    // VERIFY LIMITATIONS
                    // IF ITEM HAS LOCATIONS LIMITATIONS
                    if (item.locations) {
                        var itemLocationsLimit = item.locations.split(',')
                        // IF SUPERIOR ENTITY HAS GROUPS LIMITATIONS
                        if (entity.groups) {
                            var entityGroupsLimit = entity.groups.split(',')
                            var locationsAvailable = []
                            for (var group_index in entityGroupsLimit) {
                                var group = store.getters['groups/getList'](entityGroupsLimit[group_index])
                                if (group.locations && group.locations != {}) {
                                    locationsAvailable = Object.keys(group.locations).concat(locationsAvailable)
                                }
                            }
                            // VALIDATE
                            if (!itemLocationsLimit.every((v) => locationsAvailable.includes(v))) {
                                item.errors.codes.push('empty_config')
                                item.errors.codes.push('locations_no_match')
                                validation = false
                            }
                        }
                        // IF SUPERIOR ENTITY HAS LOCATIONS LIMITATIONS
                        else if (entity.locations) {
                            var entityLocationsLimit = entity.locations.split(',')
                            // VALIDATE
                            if ((arr, target) => target.every((v) => arr.includes(v))) {
                                if (!itemLocationsLimit.every((v) => entityLocationsLimit.includes(v))) {
                                    item.errors.codes.push('empty_config')
                                    item.errors.codes.push('locations_no_match')
                                    validation = false
                                }
                            }
                        }
                    }
                    // IF ITEM HAS GROUP LIMITATIONS
                    else if (item.groups) {
                        var itemGroupsLimit = item.groups.split(',')
                        // IF SUPERIOR ENTITY HAS GROUPS LIMITATIONS
                        if (entity.groups) {
                            var entityGroupsLimit = entity.groups.split(',')
                            // VALIDATE
                            if ((arr, target) => target.every((v) => arr.includes(v))) {
                                if (!itemGroupsLimit.every((v) => entityGroupsLimit.includes(v))) {
                                    item.errors.codes.push('empty_config')
                                    item.errors.codes.push('locations_no_match')
                                    validation = false
                                }
                            }
                        }
                        // IF SUPERIOR ENTITY HAS LOCATIONS LIMITATIONS
                        else if (entity.locations) {
                            var entityLocationsLimit = entity.locations.split(',')
                            var locationsAvailable = []
                            for (var group_index in itemGroupsLimit) {
                                var group = store.getters['groups/getList'](itemGroupsLimit[group_index])
                                if (group.locations && group.locations != {}) {
                                    locationsAvailable = Object.keys(group.locations).concat(locationsAvailable)
                                }
                            }
                            // VALIDATE
                            if (!entityLocationsLimit.every((v) => locationsAvailable.includes(v))) {
                                item.errors.codes.push('empty_config')
                                item.errors.codes.push('locations_no_match')
                                validation = false
                            }
                        }
                    }
                }
            }

            // VALIDATE ROLES
            if (item.config && item.config.roles) {
                // GET SUPERIOR ENTITY
                var entityLimit = []
                var entity = { ...state.items[ctx.template_id] }
                if (ctx.item_parent_id) {
                    var parentItemContext = { ...ctx }
                    parentItemContext.item_id = parentItemContext.item_parent_id
                    parentItemContext.item_parent_id = false
                    var parentItem = store.getters['template/getItem'](parentItemContext)
                    entity = parentItem
                }

                // CHECK IF SUPERIOR ENTITY HAS RESTRICTIONS
                if (entity.config && entity.config.roles) {
                    entityLimit = entity.config.roles
                    // VERIFY LIMITATIONS
                    if (item.config && item.config.roles && entityLimit && entityLimit.length > 0) {
                        var itemRolesLimit = item.config.roles
                        if (!itemRolesLimit.every((v) => entityLimit.includes(v))) {
                            item.errors.codes.push('empty_config')
                            item.errors.codes.push('roles_no_match')
                            validation = false
                        }
                    }
                }
            }
            //VALIDATE POINTS
            if (item.points && item.points.conditions) {
                const conditions = item.points.conditions
                const areEmptyValues = conditions.some((condition) => condition.p === '')

                if (areEmptyValues) {
                    item.errors.codes.push('empty_points')
                    validation = false
                }

                const areEmptyOperators = conditions.some((condition) => condition.operator === '')
                if (areEmptyOperators) {
                    item.errors.codes.push('empty_operator')
                    validation = false
                }
            }

            // ITEM CONDITIONS [ ALERTS / NOTIFICATIONS / SUBITEMS(PROMISE) ]
            if (item.conditions) {
                var promises = []
                for (var value_key in item.conditions.values) {
                    var condition = item.conditions.values[value_key]
                    delete condition.order
                    for (var action_key in condition.actions) {
                        var action = condition.actions[action_key]
                        action.errors = { codes: [] }
                        if (action.type == 'info') {
                            if (action.msg == '') {
                                action.errors.codes.push('empty_msg')
                                item.errors.codes.push('action_error')
                                validation = false
                            }
                            if (typeof action.tpl != 'undefined' && action.tpl == '') {
                                action.errors.codes.push('empty_tpl')
                                item.errors.codes.push('action_error')
                                validation = false
                            }
                        } else if (action.type == 'email') {
                            var aEmails = action.to.split(',')
                            if (aEmails.length == 0) {
                                action.errors.codes.push('empty_to')
                                item.errors.codes.push('action_error')
                                validation = false
                            } else {
                                aEmails.forEach((email) => {
                                    if (false == emailRE.test(email)) {
                                        action.errors.codes.push('empty_to')
                                        item.errors.codes.push('action_error')
                                        validation = false
                                    }
                                })
                            }
                            if (typeof action.subject == 'undefined' || action.subject == '') {
                                action.errors.codes.push('empty_subject')
                                item.errors.codes.push('action_error')
                                validation = false
                            }
                            if (typeof action.message == 'undefined' || action.message == '') {
                                action.errors.codes.push('empty_message')
                                item.errors.codes.push('action_error')
                                validation = false
                            }
                        }
                    }

                    for (var subitem_key in condition.items) {
                        promises.push(
                            context.dispatch('validateItem', {
                                template_id: ctx.template_id,
                                section_id: section.id,
                                item_parent_id: item.id,
                                item_id: subitem_key,
                                condition_id: value_key
                            })
                        )
                    }
                }

                return Promise.all(promises).then((results) => {
                    if (results.includes(false)) validation = false
                    else {
                        context.commit('updateItem', { context: ctx, item: item })
                    }
                    return validation
                })
            } else {
                context.commit('updateItem', { context: ctx, item: item })
                return validation
            }
        } else {
            return true
        }
    },

    addNewCategory(context, params) {
        var newCategory = Object.assign({}, context.state.newCategory)
        newCategory.id = 'tmpitw' + moment().unix()
        var categoriesActive = Object.values(context.state.categories).filter((obj) => {
            return obj.status > 0
        })

        newCategory.order =
            categoriesActive.length > 0
                ? Math.max.apply(
                      Math,
                      categoriesActive.map(function (o) {
                          return o.order
                      })
                  ) + 1
                : 1
        context.commit('updateCategory', newCategory)
        return newCategory.id
    },

    validateTemplate(context, params) {
        var validation = true
        var template = { ...state.items[params.context.template_id] }

        // TEMPLATE VALIDATIONS
        template.errors = { codes: [] }
        if (!template.name) {
            template.errors.codes.push('empty_name')
            validation = false
        }

        if (template.sitelimit && (!template.groups || template.groups == '') && (!template.locations || template.locations == '')) {
            template.errors.codes.push('empty_sitelimit')
            validation = false
        }

        // VALIDATE ROLES
        if (template.config && template.config.nRoles && template.config.nRoles.length > 0) {
            if (template.config.nRoles.length !== 1 && template.config.nRoles[0].roleId !== '') {
                const nRoles = template.config.nRoles.every((role) => role.roleId !== '')
                //Ver si no se repite el mismo roleId [{roleId: 1, roleName: 'Admin'}, {roleId: 2, roleName: 'Admin'}]
                const allRolId = template.config.nRoles.map((role) => role.roleId)
                const repitedRoles = allRolId.some((role, index) => allRolId.indexOf(role) !== index)

                if (!nRoles || repitedRoles) {
                    template.errors.codes.push('empty_roles')
                    validation = false
                }
            }
        } else if (template.config && template.config.roles && template.config.roles.length > 0) {
            const roles = template.config.roles.every((role) => role !== '')
            const repitedRoles = template.config.roles.some((role, index) => template.config.roles.indexOf(role) !== index)
            if (!roles || repitedRoles) {
                template.errors.codes.push('empty_roles')
                validation = false
            }
        }

        if ((template.hasOwnProperty('start_hour') && template.start_hour !== null) || (template.hasOwnProperty('end_hour') && template.end_hour !== null)) {
            if (template.start_hour == null) {
                template.errors.codes.push('empty_start_hour')
                validation = false
            }
            if (template.end_hour == null || parseFloat(template.start_hour) >= parseFloat(template.end_hour)) {
                template.errors.codes.push('empty_end_hour')
                validation = false
            }
            if (template.start_hour == null || template.end_hour == null || parseFloat(template.start_hour) >= parseFloat(template.end_hour)) {
                template.errors.codes.push('empty_start_hour')
                template.errors.codes.push('empty_end_hour')
                validation = false
            }
        }

        // VALIDATE ISSUE EMAILING NOTIFICATION
        const customFields = store.getters['login/getCustomFields']
        let mapCustomFields = []
        if (customFields && customFields.length > 0) {
            mapCustomFields = customFields.map((field) => field.keyname)
        }
        const regexCustomFields = new RegExp(/\{\{([^\}]+)\}\}/g)
        if (template.type == TYPE.ISSUE && template.config) {
            if (template.config.createNotify && template.config.createNotify.active) {
                var createNotify = template.config.createNotify
                if (createNotify.to == '') {
                    template.errors.codes.push('empty_to_create')
                    validation = false
                }
                if (createNotify.subject == '') {
                    template.errors.codes.push('empty_subject_create')
                    validation = false
                }
                if (createNotify.message == '') {
                    template.errors.codes.push('empty_message_create')
                    validation = false
                }
                if (customFields && customFields.length > 0) {
                    const matchMessage = createNotify.message.match(regexCustomFields)
                    const matchSubject = createNotify.subject.match(regexCustomFields)
                    const matchTo = JSON.stringify(createNotify.to).match(regexCustomFields)
                    if (matchMessage) {
                        matchMessage.forEach((field) => {
                            const fieldKey = field.replace('{{', '').replace('}}', '')
                            if (!mapCustomFields.includes(fieldKey)) {
                                template.errors.codes.push('error_message_create_custom_fields')
                                validation = false
                            }
                        })
                    }

                    if (matchSubject) {
                        matchSubject.forEach((field) => {
                            const fieldKey = field.replace('{{', '').replace('}}', '')
                            if (!mapCustomFields.includes(fieldKey)) {
                                template.errors.codes.push('error_subject_create_custom_fields')
                                validation = false
                            }
                        })
                    }

                    if (matchTo) {
                        matchTo.forEach((field) => {
                            const fieldKey = field.replace('{{', '').replace('}}', '')
                            if (!mapCustomFields.includes(fieldKey)) {
                                template.errors.codes.push('error_to_create_custom_fields')
                                validation = false
                            }
                        })
                    }
                }
            }
            if (template.config.reopenNotify && template.config.reopenNotify.active) {
                var reopenNotify = template.config.reopenNotify
                if (reopenNotify.to == '') {
                    template.errors.codes.push('empty_to_reopen')
                    validation = false
                }
                if (reopenNotify.subject == '') {
                    template.errors.codes.push('empty_subject_reopen')
                    validation = false
                }
                if (reopenNotify.message == '') {
                    template.errors.codes.push('empty_message_reopen')
                    validation = false
                }
            }
            if (template.config.closeNotify && template.config.closeNotify.active) {
                var closeNotify = template.config.closeNotify
                if (closeNotify.to == '') {
                    template.errors.codes.push('empty_to_close')
                    validation = false
                }
                if (closeNotify.subject == '') {
                    template.errors.codes.push('empty_subject_close')
                    validation = false
                }
                if (closeNotify.message == '') {
                    template.errors.codes.push('empty_message_create')
                    validation = false
                }

                if (customFields && customFields.length > 0) {
                    const matchMessage = closeNotify.message.match(regexCustomFields)
                    const matchSubject = closeNotify.subject.match(regexCustomFields)
                    const matchTo = JSON.stringify(closeNotify.to).match(regexCustomFields)
                    if (matchMessage) {
                        matchMessage.forEach((field) => {
                            const fieldKey = field.replace('{{', '').replace('}}', '')
                            if (!mapCustomFields.includes(fieldKey)) {
                                template.errors.codes.push('error_message_close_custom_fields')
                                validation = false
                            }
                        })
                    }

                    if (matchSubject) {
                        matchSubject.forEach((field) => {
                            const fieldKey = field.replace('{{', '').replace('}}', '')
                            if (!mapCustomFields.includes(fieldKey)) {
                                template.errors.codes.push('error_subject_close_custom_fields')
                                validation = false
                            }
                        })
                    }

                    if (matchTo) {
                        matchTo.forEach((field) => {
                            const fieldKey = field.replace('{{', '').replace('}}', '')
                            if (!mapCustomFields.includes(fieldKey)) {
                                template.errors.codes.push('error_to_close_custom_fields')
                                validation = false
                            }
                        })
                    }
                }
            }
        }

        // VALIDATE AUDITORÍAS NOTA FINAL // AUDITS FINAL SCORE
        if (template.type == TYPE.AUDIT && !template.config.hasOwnProperty('eval')) {
            template.errors.codes.push('no-single-final-score')
            validation = false
        } else {
            if (template.config.eval === null) {
                template.errors.codes.push('no-single-final-score')
                validation = false
            }
        }

        // VALIDATION FOR TEMPORARY TEMPLATES
        if (template.config && template.config.hasOwnProperty('temporary') && !state.temporalTemplateStartDateIsDisabled) {
            const currentDate = new Date()
            const currentDateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
            const startDate = new Date(template.config.temporary.start_date)

            // START DATE
            if (!template.config.temporary.start_date) {
                validation = false
            } else {
                if (template.id == '-1' || context.state.templateIsCloned || template.status == -2) {
                    // -2 = borrador
                    const startDateWithoutTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())

                    if (startDateWithoutTime < currentDateWithoutTime) {
                        validation = false
                    }
                }
            }

            // END DATE
            // temporal templates with a range of days with no end date
            if (context.state.temporaryTemplateType == 2 && !template.config.temporary.end_date) {
                template.errors.codes.push('no-end-date')

                validation = false
            }

            if (template.config.temporary.end_date) {
                const endDate = new Date(template.config.temporary.end_date)
                const endDateWithoutTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())

                if (endDateWithoutTime < currentDateWithoutTime) {
                    validation = false
                }

                // validate that end_date is not after max date allowed
                let year = startDate.getFullYear()
                let nextMonth = startDate.getMonth() + 1

                if (nextMonth > 11) {
                    year++
                    nextMonth -= 12
                }

                const lastDayOfNextMonth = new Date(year, nextMonth + 1, 0)

                if (endDateWithoutTime > lastDayOfNextMonth) {
                    validation = false
                }
            }
        }

        // Validate screenlock message
        if (template.config && template.config.screenlockMessage == '<p></p>') {
            validation = false
        }

        // SECTION VALIDATIONS
        var promises = []
        for (var section_index in template.sections) {
            promises.push(
                context.dispatch('validateSection', {
                    template_id: template.id,
                    section_id: section_index
                })
            )
        }

        return Promise.all(promises).then((results) => {
            if (results.includes(false)) validation = false
            else {
                context.commit('setTemplate', {
                    template: template
                })
            }

            return validation
        })
    },

    validateCategory(context, params) {
        var categoryToValidate = context.getters['getCategories'](params)
        var errors = {
            name: false,
            type: false,
            order: false
        }
        var sendForm = true
        errors = _.mapValues(errors, () => false)

        if (categoryToValidate.name === '') {
            sendForm = false
            errors.name = true
        }
        if (categoryToValidate.order === '' || categoryToValidate.order == null || categoryToValidate.order == 0) {
            sendForm = false
            errors.order = true
        }
        if (typeof categoryToValidate.type == 'undefined') {
            sendForm = false
            errors.type = true
        }
        return {
            status: sendForm,
            errors: errors
        }
    },

    addTemplateCategory(context, params) {
        var category = context.getters['getCategories'](params)
        if (category.status == 0) {
            category.status = -2
        }
        return api.post('admin/checklist/templates/category/add', category).then(function (response) {
            if (response.status) {
                context.commit('addCategory', response.data)
                return true
            } else {
                return false
            }
        })
    },

    removeCategory(context, params) {
        return api.get('admin/checklist/templates/category/' + params + '/delete').then(function (response) {
            if (response.status) {
                context.commit('setRemoveCategory', params)
                return true
            } else {
                return false
            }
        })
    },

    editTemplateCategory(context, params) {
        var category = context.getters['getCategories'](params)
        if (category.status == 0) {
            category.status = -2
        }
        return api.post('admin/checklist/templates/category/' + category.id + '/edit', category).then(function (response) {
            if (response.status) {
                context.commit('updateCategory', response.data)
                return true
            } else {
                return false
            }
        })
    },

    removeTemplate(context, params) {
        return api.get(`admin/checklist/template/${params.template_id}/delete`).then(function (response) {
            return response.status ? response.data : false
        })
    },

    clone(context, params) {
        return api.get(`admin/checklist/template/${params.template_id}/clone`).then(function (response) {
            state.templateIsCloned = true
            return response.status ? response.data : false
        })
    },

    // CLONE A SECTION
    cloneSection(context, payload) {
        var ctx = payload.context

        var section
        section = JSON.parse(JSON.stringify(payload.section))
        section.name = i18n.t('template.clone.name') + ' ' + section.name
        section.items = {}
        section.order = parseInt(section.order) + 0.5

        var items = { ...state.items }

        section.id = 'tmpitw' + moment().unix()

        // ORDER
        var aSections = Object.values({
            ...items[ctx.template_id].sections
        })
        aSections.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))

        var bSectionFound = false

        for (var section_index in aSections) {
            var oSection = aSections[section_index]
            if (bSectionFound) {
                items[ctx.template_id].sections[oSection.id].order++
            }
            if (oSection.id == payload.section.id) {
                bSectionFound = true
            }
        }

        section.order++

        items[ctx.template_id].sections[section.id] = section

        // ITEMS CLONATION
        var promises = []
        for (var item_index in payload.section.items) {
            promises.push(
                context.commit('cloneItem', {
                    context: {
                        template_id: ctx.template_id,
                        section_id: section.id,
                        item_id: item_index
                    },
                    item: payload.section.items[item_index],
                    section_clone: true
                })
            )
        }

        return Promise.all(promises).then((results) => {
            items[ctx.template_id].sections[section.id] = section
            context.commit('setItems', { ...items })
            return true
        })
    },

    format({ state, context, commit, dispatch, rootState }, params = {}) {
        // var aItems = {}
        // var bCommmit = (typeof params.commit !== 'undefined') ? params.commit : false

        // if (typeof params.data !== 'undefined') {
        //     for (var index in params.data.schema) {
        //         var item = params.data.schema[index]
        //         item.order = parseInt(item.order)
        //         item.review = parseInt(item.review)
        //         // item.email = 0
        //         // item.alert = 0
        //         // item.subitem = 0
        //         item.renderconfig = []

        //         // ALERT
        //         if (item.alerts) {
        //             for (var alert_index in item.alerts) {
        //                 var alert = item.alerts[alert_index]

        //                 // // COUNT ALERTS FOR SUMMARY
        //                 // alert.type == 'email' ? item.email++ : item.alert++;

        //                 if (typeof alert.operator !== 'undefined' && typeof alert.value !== 'undefined') {
        //                     var result = item.renderconfig.find(obj => {
        //                         if (obj.operator && obj.value) {
        //                             return (obj.operator == alert.operator && obj.value == alert.value)
        //                         }
        //                     })

        //                     if (!result) {
        //                         var key = Object.keys(item.renderconfig).length
        //                         item.renderconfig[key] = {}
        //                         item.renderconfig[key].items = []
        //                         item.renderconfig[key].operator = alert.operator
        //                         item.renderconfig[key].value = alert.value
        //                     } else var key = result

        //                     alert.conftype = 'alert'
        //                     alert.class = alert.class ? alert.class : 'info'
        //                     item.renderconfig[key].items.push(alert)
        //                 }
        //             }
        //         }

        //         // SUBITEM
        //         if (item.config.conditions || item.conditions) {
        //             var conditions = item.config.conditions ? item.config.conditions : item.conditions
        //             for (var idx in conditions) {
        //                 if (typeof conditions[idx] !== 'undefined') {
        //                     for (var config_index in conditions[idx].values) {
        //                         var config = conditions[idx].values[config_index]

        //                         config.item_id = conditions[idx].item_id
        //                         config.order = parseInt(params.data.schema[config.item_id].order)

        //                         if (typeof config.operator !== 'undefined') {
        //                             var result = Object.keys(item.renderconfig).find(obj => {
        //                                 if (item.renderconfig[obj].operator && item.renderconfig[obj].value !== undefined) {
        //                                     return (item.renderconfig[obj].operator == config.operator && item.renderconfig[obj].value == config.value)
        //                                 }
        //                             })

        //                             if (!result) {
        //                                 var key = item.renderconfig.length
        //                                 item.renderconfig[key] = {}
        //                                 item.renderconfig[key].items = []
        //                                 item.renderconfig[key].operator = config.operator
        //                                 item.renderconfig[key].value = config.value
        //                             } else var key = result

        //                             // // COUNT SUBITEMS FOR SUMMARY
        //                             // item.subitem++;

        //                             config.conftype = 'subitem'
        //                             item.renderconfig[key].items.push(config)
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        // return params.data
        commit('setTemplate', { template: params.data })
    },

    exportTemplates(context, values) {
        // format PDF templates display options for API:
        const options = {}
        const displayOptions = state.templatesToExport.displayOptions
        for (const option of displayOptions) {
            options[option.name] = option.value
        }

        // define params (send ids OR filters)
        let params = {
            options: JSON.stringify(options),
            type: values.type
        }
        if (state.templatesToExport.ids) {
            params.ids = JSON.stringify(state.templatesToExport.ids)
        } else {
            params.filters = JSON.stringify(state.templatesToExport.filters)
        }

        return api
            .post('checklist/admin/templates/pdf', params)
            .then((response) => {
                if (response.status && response.data && response.data.url) {
                    const endpoint = response.data.url

                    // const urlTEST = 'http://localhost:3000/' + endpoint
                    // return urlTEST

                    return api
                        .get(endpoint)
                        .then((response) => {
                            const pdfTemplatesURL = response.data

                            return pdfTemplatesURL // returns the final URL with the templates in PDF
                        })
                        .catch((error) => console.error('Error in PDF templates URL:', error))
                }
            })
            .catch((error) => {
                console.error('Error exporting templates: ', error)
            })
    }
}

// mutations
const mutations = {
    setHasLoadFilters(state, value) {
        state.hasLoadFilters = value
    },

    updateCategory(state, payload) {
        var categories = { ...state.categories }
        categories[payload.id] = payload
        Vue.set(state, 'categories', { ...categories })
    },

    setCategories(state, categories) {
        state.categories = categories
    },

    // Common but may change in other tools
    setFormat(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
        state.itemsLoaded = true
    },

    setFiltersActive(state, params) {
        var filtersActive = { ...state.filtersActive }
        filtersActive[params.type] = params.data
        Vue.set(state, 'filtersActive', { ...filtersActive })
    },
    setFiltersCategoriesActive(state, params) {
        var filtersCategoriesActive = { ...state.filtersCategoriesActive }
        filtersCategoriesActive[params.type] = params.data
        Vue.set(state, 'filtersCategoriesActive', { ...filtersCategoriesActive })
    },

    addCategory(state, params) {
        var categories = { ...state.categories }
        categories[params.id] = params
        state.categories = categories
        Vue.set(state, 'categories', { ...categories })
    },

    setRemoveCategory(state, params) {
        var categories = state.categories
        delete categories[params]
        state.categories = categories
        Vue.set(state, 'categories', { ...categories })
    },

    setFilterTimeframes(state, payload) {
        var filters = { ...state.filters }
        filters[payload.type].timeframes = payload.data

        Vue.set(state, 'filters', { ...filters })
    },
    setFilterLocations(state, payload) {
        var filters = { ...state.filters }
        filters[payload.type].locations = payload.data

        Vue.set(state, 'filters', { ...filters })
    },
    setFilterGroups(state, payload) {
        var filters = { ...state.filters }
        filters[payload.type].groups = payload.data

        Vue.set(state, 'filters', { ...filters })
    },

    setFilterStatus(state, payload) {
        var filters = { ...state.filters }
        filters[payload.type].status = payload.data

        Vue.set(state, 'filters', { ...filters })
    },

    setFilterLoaded(state, payload) {
        var filters = { ...state.filters }
        filters[payload.type].loaded = true

        Vue.set(state, 'filters', { ...filters })
    },
    setFilterRoles(state, payload) {
        var filters = state.filters
        filters.roles = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },
    setFilterTags(state, payload) {
        var filters = state.filters
        filters.tags = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },

    setFilterCategories(state, payload) {
        var filters = state.filters
        filters.categories = payload
        state.filters = filters
        Vue.set(state, 'filters', { ...filters })
    },

    setItems(state, items) {
        state.items = items
        Vue.set(state, 'items', { ...items })
    },

    setIssueTemplates(state, payload) {
        state.issueTemplates = payload.issueTemplates
        Vue.set(state, 'issueTemplates', { ...payload.issueTemplates })
    },

    setTemplate(state, payload) {
        var items = { ...state.items }

        // IF SECTIONS ARE EMPTY CONVERT TO AN EMPTY OBJECT TO PREVENT ERRORS
        payload.template.sections.length == 0 ? (payload.template.sections = {}) : true

        // ORDER CONDITIONS
        for (var section_index in payload.template.sections) {
            var section = payload.template.sections[section_index]
            for (var item_index in section.items) {
                var item = section.items[item_index]
                var order = 1
                if (item.conditions && item.conditions.values) {
                    for (var condition_index in item.conditions.values) {
                        var condition = item.conditions.values[condition_index]
                        condition.order = order
                        order++
                    }
                }
            }
        }

        items[payload.template.id] = { ...payload.template }
        Vue.set(state, 'items', { ...items })
    },

    // FORM METHODS
    setSelectedContext(state, context) {
        state.selectedContext = context
        state.moreItemOptions = false
        context ? Vue.set(state, 'selectedContext', { ...state.selectedContext }) : Vue.set(state, 'selectedContext', false)
    },

    setMoreItemOptions(state, id) {
        state.moreItemOptions = id
    },

    // TEMPLATE METHODS
    createNewTemplate(state, payload) {
        var template = { ...state.newTemplate }
        template.sections = { ...state.newTemplate.sections }
        template.config = { ...state.newTemplate.config }

        var items = { ...state.items }

        template.type = payload.type

        items[template.id] = template

        if (template.type == TYPE.AUDIT) {
            var section = { ...state.newSection }
            section.items = { ...state.newSection.items }
            template.config.show_score = '1'

            section.id = 'tmpitw' + moment().unix()

            items[template.id].sections[section.id] = section
        }

        if (template.type == TYPE.TASK) {
            template.start_hour = null
            template.end_hour = null
            template.timeframe_id = 0
        }
        Vue.set(state, 'items', { ...items })
    },

    updateTemplate(state, payload) {
        var ctx = payload.context
        var template = payload.template
        var items = { ...state.items }

        items[ctx.template_id] = template
        Vue.set(state, 'items', { ...items })
    },

    // SECTION METHODS
    addSection(state, payload) {
        var ctx = payload.context

        var section = { ...state.newSection }
        section.items = { ...state.newSection.items }

        var items = { ...state.items }

        var sections = Object.values({
            ...state.items[ctx.template_id].sections
        })
        sections.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))

        section.order = Object.keys(sections).length > 0 ? parseInt(sections[Object.keys(sections).length - 1].order) + 1 : 1

        section.id = 'tmpitw' + moment().unix()

        items[ctx.template_id].sections[section.id] = section

        Vue.set(state, 'items', { ...items })
    },

    updateSection(state, payload) {
        var ctx = payload.context
        var section = payload.section
        var items = { ...state.items }

        items[ctx.template_id].sections[ctx.section_id] = section
        Vue.set(state, 'items', { ...items })
    },

    deleteSection(state, payload) {
        var ctx = payload.context
        var items = { ...state.items }

        // UNSELECT ITEM
        Vue.set(state, 'selectedContext', false)

        // DELETE SECTION
        items[ctx.template_id].sections[ctx.section_id].status = -1

        // STATUS -1 TO ITEMS OF SECTION
        if (items[ctx.template_id].sections[ctx.section_id]) {
            var itemsSection = { ...items[ctx.template_id].sections[ctx.section_id].items }
            var result = Object.values(itemsSection).filter((item) => {
                return item.status > -1
            })

            if (result.length != 0) {
                Object.values(itemsSection).forEach(function (item) {
                    item.status = -1
                })
            }
        }

        // If is an Audit without sections, add one default section
        if (items[ctx.template_id].type == TYPE.AUDIT) {
            var sectionsActive = Object.values(items[ctx.template_id].sections).filter((item) => {
                return item.status > -1
            })

            if (sectionsActive.length == 0 || (sectionsActive.length == 1 && sectionsActive[0].id == -1)) {
                var section = { ...state.newSection }
                section.items = { ...state.newSection.items }

                section.id = 'tmpitw' + moment().unix()

                items[ctx.template_id].sections[section.id] = section
            }
        }
        Vue.set(state, 'items', { ...items })
    },

    // ITEM METHODS
    addItem(state, payload) {
        var ctx = payload.context

        payload.context.section_id = payload.context.section_id ? payload.context.section_id : '-1'

        var item = { ...state.newItem }
        item.planification = { ...state.newItem.planification }
        var items = { ...state.items }

        item.id = 'tmpitw' + moment().unix()

        if (ctx.item_id) {
            // Subitem - Has item_id property in context
            // TODO - Recurrent n-level children
            var aItems = Object.values({
                ...items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[ctx.condition_id].items
            })
            aItems.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))

            item.order = aItems.length > 0 ? parseInt(aItems[aItems.length - 1].order) + 1 : 1
            items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[ctx.condition_id].items[item.id] = item
        } else {
            // Main item

            if (
                Object.keys(items[ctx.template_id].sections).length == 0 ||
                (ctx.section_id == -1 && typeof items[ctx.template_id].sections[ctx.section_id] == 'undefined')
            ) {
                var section = { ...state.newSection }
                section.items = { ...state.newSection.items }
                section.id = -1

                items[ctx.template_id].sections[section.id] = section
                ctx.section_id = -1

                // ITERACION PARA AUGMENTAR EN UNO EL ORDER EN CASO DE QUE SE AÑADA UN ITEM FUERA DE LA SECCION Y FUERA DE LA SECCIÓN -1
                if (Object.keys(items[ctx.template_id].sections).length > 1) {
                    for (var section_index in items[ctx.template_id].sections) {
                        var section = items[ctx.template_id].sections[section_index]
                        if (section.id != -1) {
                            section.order++

                            for (var item_index in section.items) {
                                var oItem = section.items[item_index]
                                oItem.order++
                            }
                        }
                    }
                }
            }

            var aItems = Object.values({
                ...items[ctx.template_id].sections[ctx.section_id].items
            })
            aItems.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))

            item.order = aItems.length > 0 ? parseInt(aItems[aItems.length - 1].order) + 1 : 1

            items[ctx.template_id].sections[ctx.section_id].items[item.id] = item
        }

        Vue.set(state, 'items', { ...items })

        // // SET ITEM AS SELECTED IF IT IS A PARENT ITEM
        if (!ctx.item_id) {
            var newItemContext = { ...ctx }
            newItemContext.item_id = item.id
            Vue.set(state, 'selectedContext', newItemContext)
        }
    },

    // CLONE A ITEM
    cloneItem(state, payload) {
        var ctx = payload.context

        payload.context.section_id = payload.context.section_id ? payload.context.section_id : '-1'
        var item
        item = JSON.parse(JSON.stringify(payload.item))
        item.title = payload.section_clone ? item.title : i18n.t('template.clone.name') + ' ' + item.title

        var items = { ...state.items }

        const typedArray = new Uint8Array(30)
        const randomValues = window.crypto.getRandomValues(typedArray).join('')

        item.id = 'tmpitw' + randomValues

        if (ctx.item_parent_id) {
            var aItems = Object.values({
                ...items[ctx.template_id].sections[ctx.section_id].items[ctx.item_parent_id].conditions.values[ctx.condition_id].items
            })
        } else {
            var aItems = ctx.condition_id
                ? Object.values({
                      ...items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[ctx.condition_id].items
                  })
                : Object.values({
                      ...items[ctx.template_id].sections[ctx.section_id].items
                  })
        }

        if (item.conditions && Object.keys(item.conditions.values).length > 0) {
            var count = 1
            for (var condition_index in item.conditions.values) {
                var condition = Object.assign({}, { ...item.conditions.values[condition_index] })

                Object.keys(condition.items).forEach(function (key) {
                    var condItems = item.conditions.values[condition_index].items

                    var newkey = 'tmpitw' + moment().unix() + count
                    count++
                    condItems[newkey] = JSON.parse(JSON.stringify(condItems[key]))
                    condItems[newkey].id = newkey
                    // condItems[newkey].title = i18n.t('template.clone.name') + ' ' + condItems[newkey].title
                    condItems[newkey].title = condItems[newkey].title
                    condItems[newkey].parent_id = item.id

                    delete condItems[key]
                })
            }
        }

        // ORDER
        var bItemFound = false

        for (var item_index in aItems) {
            var oItem = aItems[item_index]
            if (bItemFound) {
                if (ctx.condition_id) {
                    items[ctx.template_id].sections[ctx.section_id].items[ctx.item_parent_id].conditions.values[ctx.condition_id].items[oItem.id].order++
                } else {
                    items[ctx.template_id].sections[ctx.section_id].items[oItem.id].order++
                }
            }
            if (oItem.id == payload.item.id) {
                bItemFound = true
            }
        }

        item.order++

        ctx.condition_id
            ? (items[ctx.template_id].sections[ctx.section_id].items[ctx.item_parent_id].conditions.values[ctx.condition_id].items[item.id] = item)
            : (items[ctx.template_id].sections[ctx.section_id].items[item.id] = item)

        Vue.set(state, 'items', { ...items })

        // SET ITEM AS SELECTED
        var newItemContext = { ...ctx }
        newItemContext.item_id = item.id
        Vue.set(state, 'selectedContext', newItemContext)
    },

    updateItem(state, payload) {
        var ctx = payload.context
        var item = payload.item
        var items = { ...state.items }

        if (payload.changeType && item.conditions) {
            var itemsToMoveCondition = []
            var actionsToMoveCondition = []

            var keyDefault = false
            for (var k in item.conditions.values) {
                var condition = item.conditions.values[k]
                if (
                    condition.operator != '!empty' &&
                    !(
                        ([FORMAT.NUMBER, FORMAT.TEMPERATURE, FORMAT.YESNO, FORMAT.YESNONC, FORMAT.MULTIPLE].includes(parseInt(item.type)) &&
                            ['=', '!='].includes(condition.operator)) ||
                        ([FORMAT.NUMBER, FORMAT.TEMPERATURE].includes(parseInt(item.type)) && ['>', '>=', '<', '<=', '<>', '!<>'].includes(condition.operator))
                    )
                ) {
                    condition.actions.forEach((action) => {
                        actionsToMoveCondition.push(action)
                    })
                    Object.values(condition.items).forEach((item) => {
                        itemsToMoveCondition.push(item)
                    })

                    delete item.conditions.values[k]
                } else if (condition.operator == '!empty') {
                    keyDefault = k
                }
            }

            if (itemsToMoveCondition.length > 0 || actionsToMoveCondition.length > 0) {
                if (!keyDefault) {
                    var condition = { ...state.newCondition }
                    condition.items = { ...state.newCondition.items }
                    condition.actions = [...state.newCondition.actions]
                    condition.id = 'conditw' + moment().unix()

                    item.conditions.values[condition.id] = condition
                    keyDefault = condition.id
                }

                itemsToMoveCondition.forEach((aux) => {
                    item.conditions.values[keyDefault].items[aux.id] = aux
                })
                actionsToMoveCondition.forEach((aux) => {
                    item.conditions.values[keyDefault].actions.push(aux)
                })
            }
        }

        if (ctx.item_parent_id) {
            items[ctx.template_id].sections[ctx.section_id].items[ctx.item_parent_id].conditions.values[ctx.condition_id].items[item.id] = item
        } else if (ctx.item_id && ctx.condition_id) {
            items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[ctx.condition_id].items[item.id] = item
        } else {
            items[ctx.template_id].sections[ctx.section_id].items[item.id] = item
        }

        Vue.set(state, 'items', { ...items })
    },

    deleteItem(state, payload) {
        var ctx = payload.context
        var items = { ...state.items }

        // UNSELECT ITEM
        Vue.set(state, 'selectedContext', false)

        // DELETE ITEM
        if (ctx.item_parent_id) {
            items[ctx.template_id].sections[ctx.section_id].items[ctx.item_parent_id].conditions.values[ctx.condition_id].items[ctx.item_id].status = -1
        } else {
            items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].status = -1
        }

        if (items[ctx.template_id].type == TYPE.AUDIT) {
            var sectionsActive = Object.values(items[ctx.template_id].sections).filter((item) => {
                return item.status > -1
            })

            if (sectionsActive.length == 0 || (sectionsActive.length == 1 && sectionsActive[0].id == -1)) {
                var section = { ...state.newSection }
                section.items = { ...state.newSection.items }

                section.id = 'tmpitw' + moment().unix()

                items[ctx.template_id].sections[section.id] = section
            }
        }

        Vue.set(state, 'items', { ...items })
    },

    // CONDITION METHODS
    addCondition(state, payload) {
        var ctx = payload.context
        var condition = { ...state.newCondition }
        condition.items = { ...state.newCondition.items }
        condition.actions = [...state.newCondition.actions]
        condition.id = 'conditionitw' + moment().unix()
        var items = { ...state.items }

        if (ctx.item_parent_id) {
            if (
                items[ctx.template_id].sections[ctx.section_id].items[ctx.item_parent_id].conditions.values[ctx.condition_id].items[ctx.item_id].conditions ==
                false
            ) {
                items[ctx.template_id].sections[ctx.section_id].items[ctx.item_parent_id].conditions.values[ctx.condition_id].items[ctx.item_id].conditions = {
                    operator: 'OR',
                    values: {}
                }
            }

            var aConditions = Object.values({
                ...items[ctx.template_id].sections[ctx.section_id].items[ctx.item_parent_id].conditions.values[ctx.condition_id].items[ctx.item_id].conditions
                    .values
            })
            aConditions.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))

            condition.order = aConditions.length > 0 ? parseInt(aConditions[aConditions.length - 1].order) + 1 : 1

            items[ctx.template_id].sections[ctx.section_id].items[ctx.item_parent_id].conditions.values[ctx.condition_id].items[ctx.item_id].conditions.values[
                condition.id
            ] = condition
        } else {
            if (items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions == false) {
                items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions = { operator: 'OR', values: {} }
            }

            var aConditions = Object.values({
                ...items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values
            })
            aConditions.sort((a, b) => (parseInt(a.order) > parseInt(b.order) ? 1 : -1))

            condition.order = aConditions.length > 0 ? parseInt(aConditions[aConditions.length - 1].order) + 1 : 1

            items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[condition.id] = condition
        }

        Vue.set(state, 'items', { ...items })
    },

    updateCondition(state, payload) {
        var ctx = payload.context
        var condition = payload.condition

        // // TODO VALIDATE IF IS CORRECT, WHEN CONDITION EQUAL TO <> OR !<> AND VALUE NOT HAVE COMMA, TEMPLATE FAIL
        // if (['<>', '!<>'].includes(condition.operator) && !condition.value.match(',')) {
        //     condition.value += ','
        // } else if (!['<>', '!<>'].includes(condition.operator) && condition.value.match(',')) {
        //     condition.value = condition.value.split(',')[0]
        // }

        var newConditionId = condition.operator + '_' + condition.value
        var items = { ...state.items }
        // items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[ctx.condition_id].id = newConditionId
        condition.id = newConditionId
        items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[newConditionId] = condition
        if (ctx.condition_id != newConditionId) delete items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[ctx.condition_id]

        // Object.defineProperty(items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values, newConditionId, Object.getOwnPropertyDescriptor(items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values, ctx.condition_id))

        Vue.set(state, 'items', { ...items })
    },

    deleteCondition(state, context) {
        var items = { ...state.items }
        var condition = items[context.template_id].sections[context.section_id].items[context.item_id].conditions.values[context.condition_id]

        condition.actions = null
        for (var k in condition.items) {
            condition.items[k].status = -1
        }
        condition.status = -1

        Vue.set(state, 'items', { ...items })
    },

    addAction(state, payload) {
        var ctx = payload.context
        var type = payload.type

        var action
        switch (type) {
            case 'alert':
                action = JSON.parse(JSON.stringify(state.newAlert))
                break

            case 'email':
                action = JSON.parse(JSON.stringify(state.newEmail))
                break

            case 'issue':
                action = JSON.parse(JSON.stringify(state.newIssue))
                break
        }

        var items = { ...state.items }
        items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[ctx.condition_id].actions.push(action)
        Vue.set(state, 'items', { ...items })
    },

    updateAlert(state, payload) {
        var ctx = payload.context
        var alert = payload.alert
        var items = { ...state.items }

        items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[ctx.condition_id].actions[ctx.action_id] = alert
        Vue.set(state, 'items', { ...items })
    },

    deleteAlert(state, payload) {
        var ctx = payload.context
        var action_index = payload.action_index

        var items = { ...state.items }

        items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[ctx.condition_id].actions.splice(action_index, 1)
        Vue.set(state, 'items', { ...items })
    },

    sort(state, payload) {
        var ctx = payload.context
        var sorted = payload.items
        var elements = {}

        var items = { ...state.items }

        if (ctx.item_id) {
            // Sorting subitems
            elements = items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].conditions.values[ctx.condition_id].items
        } else if (ctx.section_id) {
            // Sorting items
            elements = items[ctx.template_id].sections[ctx.section_id].items
        } else {
            // Sorting sections
            elements = items[ctx.template_id].sections
        }

        for (var k in elements) {
            var item = elements[k]
            item.order = parseInt(sorted.indexOf(item.id)) + 1
        }

        Vue.set(state, 'items', { ...items })
    },

    setScore(state, payload) {
        // var ctx = payload.context
        var ctx = { ...state.selectedContext }
        var score = payload.score
        var items = { ...state.items }

        // SON ITEM
        if (ctx.condition_id != false) {
            items[ctx.template_id].sections[ctx.section_id].items[ctx.item_parent_id].conditions.values[ctx.condition_id].items[ctx.item_id].points = score
        }
        // PARENT ITEM
        else {
            items[ctx.template_id].sections[ctx.section_id].items[ctx.item_id].points = score
        }

        Vue.set(state, 'items', { ...items })
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    setDownloadTemplatesDisplayOptions: (state, displayOptions) => {
        state.templatesToExport.displayOptions = displayOptions
    },

    setTemplatesToExportIds: (state, idsList) => {
        state.templatesToExport.ids = idsList
    },

    setTemplatesToExportFilters: (state, filters) => {
        state.templatesToExport.filters = filters
    },

    setNewIsTemporal: (state, value) => {
        state.newIsTemporal = value

        if (value) {
            const today = new Date()
            const year = today.getFullYear()
            const month = (today.getMonth() + 1).toString().padStart(2, '0')
            const day = today.getDate().toString().padStart(2, '0')

            const defaultTemporaryTemplateData = {
                start_date: `${year}-${month}-${day}`,
                end_date: null
            }

            state.newTemplate.config.temporary = defaultTemporaryTemplateData
        } else {
            state.newTemplate.config = {}
        }
    },

    removeTemporaryTemplateConfig: (state, value) => {
        state.newTemplate.config = {}
    },

    setTemporaryTemplateType: (state, value) => {
        // value:
        // 1 = one day
        // 2 = several days
        state.temporaryTemplateType = value
    },

    setTemporalTemplateStartDateIsDisabled: (state, value) => {
        state.temporalTemplateStartDateIsDisabled = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
