<template>
    <div class="autocomplete">
        <!-- Para que coja bien los estilos tenemos que quitar este div. 
        Mirar de utilizar la funcion de vuetify para los chips (Esta puesta ahorita pero no se ve xD) -->
        <v-autocomplete @keyup="$emit('keyup', $event)" @change="$emit('change', $event)" @blur="$emit('blur', $event)" hide-details class="select" v-model="gValue" @input="$emit('input', $event)" :items="Object.values(items)" :return-object="inputOptions.returnObject" :item-text="inputOptions.itemText" :item-value="inputOptions.itemValue" :placeholder="placeholder" attach dense :no-data-text="$t('supervise.filter.no_results')" :disabled="inputOptions.disabled" :required="inputOptions.mandatory" :multiple="inputOptions.multiple">
            <template v-slot:selection="{ item, index }">
                <template v-if="inputOptions.showSelected">
                    {{ item[inputOptions.itemText] }}
                </template>
                <template v-else>
                    {{ index == 0 ? '' : '' }}
                </template>
            </template>
        </v-autocomplete>
        <Tags :referenceObject="cOptions.referenceObject" @inputAutocompleteRemovable="remove" :newInput="cOptions.newInput" :tags="Object.values(autocompleteModel).length > 0 && !cOptions.multiple ? [autocompleteModel] : autocompleteModel" :nameTag="cOptions.itemText" :value="cOptions.itemValue" :removable="typeof cOptions.removable != 'undefined' ? cOptions.removable : true"></Tags>
    </div>
    <!-- <v-autocomplete hide-details class="select" v-model="gValue" @input="$emit('input', $event)" item-text="name" item-value="id" :items="Object.values(items)" :placeholder="placeholder" dense :no-data-text="$t('supervise.filter.no_results')" :disabled="inputOptions.disabled" :required="inputOptions.mandatory" :multiple="inputOptions.multiple" attach></v-autocomplete> -->
</template>

<script>
import Tags from '../ui/Tags.vue'
export default {
    components: { Tags },
    props: {
        value: { type: [String, Number, Boolean, Array, Object], default: undefined },
        cItems: { type: [Array, Object], default: [] },
        cPlaceholder: { type: String, default: '' },
        cOptions: { type: Object, default: undefined },
        autocompleteModel: { type: undefined, default: false }
    },
    data() {
        return {
            gValue: this.value ? this.value : false,
            items: this.cItems,
            options: {
                multiple: false,
                returnObject: true,
                itemValue: 'id',
                itemText: 'name',
                referenceObject: false,
                removable: true
            }
        }
    },
    computed: {
        inputOptions() {
            // Mix de options que llegan vs defaults
            return {
                ...this.options,
                ...(this.cOptions ? this.cOptions : {})
            }
        },
        placeholder() {
            return this.cPlaceholder
        }
    },
    methods: {
        remove(tag) {
            this.gValue.splice(tag, 1)
            this.$emit('change', this.gValue)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
