import { Api } from '@/core/api.js'
import { TOOLS } from '@/constants'
import { getObjectsByIds } from '../../../public/js/utils.js'
import {
    findDefaultView,
    transformSuperviseChecklistViewFiltersForDB,
    transformSuperviseRecordsViewFiltersForDB,
    transformSuperviseIncidentsViewFiltersForDB,
    transformSuperviseAuditsFiltersViewForDB
} from '../../../public/js/filtersViewsUtils.js'
import i18n from '@/i18n'

const api = new Api()

const getDefaultState = () => {
    return {
        // CHECKLISTS (TASKS)
        superviseChecklistsViews: [],
        superviseChecklistsSelectedView: null,
        // RECORDS
        superviseRecordsViews: [],
        superviseRecordsSelectedView: null,
        // INCIDENTS (ISSUES)
        superviseIncidentsViews: [],
        superviseIncidentsSelectedView: null,
        // AUDITS
        superviseAuditsViews: [],
        superviseAuditsSelectedView: null
    }
}

const state = getDefaultState()

// GETTERS
const getters = {
    //
    // SUPERVISE CHECKLISTS GETTERS ------------------------------------------------
    getSuperviseChecklistsViewsList() {
        const superviseChecklistsViewsList = state.superviseChecklistsViews.map((view) => {
            return { id: view.id, name: view.name, type: view.type, order: view.order }
        })

        return superviseChecklistsViewsList
    },

    getSuperviseChecklistsSelectedView(state, data) {
        return state.superviseChecklistsSelectedView
    },

    getSuperviseChecklistsDefaultView() {
        if (state.superviseChecklistsViews.length === 0) return

        // const views = Object.values(structuredClone(state.superviseChecklistsViews))
        // default view from db:
        // const defaultView = findDefaultView(views)

        // fallback default view
        const alternativeDefaultView = {
            name: i18n.t('supervise.filter.week'),
            tool: 1,
            data: {
                sites: '',
                template: [],
                timeframe: [],
                category: [],
                type_date: 'week',
                demora: false,
                score: [0, 100],
                tags: [],
                start_date: '',
                end_date: '',
                tplType: ''
            }
        }
        return alternativeDefaultView
        // if (defaultView) {
        //     const newDefaultView = structuredClone(defaultView)

        //     if (newDefaultView.data.demora === 'false') {
        //         newDefaultView.data.demora = false
        //     }

        //     return newDefaultView
        // } else {
        //     return alternativeDefaultView
        // }
    },

    getSuperviseChecklistViewFiltersById: (state, context) => (id) => {
        const selectedViewFilters = state.superviseChecklistsViews.filter((view) => view.id === id)[0].data

        return selectedViewFilters
    },

    getSuperviseChecklistViewById: (state, context) => (id) => {
        const selectedView = state.superviseChecklistsViews.filter((view) => view.id === id)[0]

        return selectedView
    },

    // SUPERVISE RECORDS GETTERS ------------------------------------------------
    getSuperviseRecordsViewsList() {
        const superviseRecordsViewsList = state.superviseRecordsViews.map((view) => {
            return { id: view.id, name: view.name, type: view.type, order: view.order }
        })

        return superviseRecordsViewsList
    },

    getSuperviseRecordsSelectedView(state) {
        return state.superviseRecordsSelectedView
    },

    getSuperviseRecordsDefaultView() {
        if (state.superviseRecordsViews.length === 0) return

        const views = Object.values(structuredClone(state.superviseRecordsViews))
        // default view from db:
        const defaultView = findDefaultView(views)

        // fallback default view:
        const alternativeDefaultView = {
            name: i18n.t('supervise.filter.week'),
            data: {
                location: [],
                employees: [],
                type_date: 'week',
                start_date: '',
                end_date: ''
            }
        }

        if (defaultView) {
            return defaultView
        } else {
            return alternativeDefaultView
        }
    },

    getSuperviseRecordsViewFiltersById: (state, context) => (id) => {
        const selectedViewFilters = state.superviseRecordsViews.filter((view) => view.id === id)[0].data

        return selectedViewFilters
    },

    getSuperviseRecordsViewById: (state, context) => (id) => {
        const selectedView = state.superviseRecordsViews.filter((view) => view.id === id)[0]

        return selectedView
    },

    // SUPERVISE INCIDENTS GETTERS ------------------------------------------------
    getSuperviseIncidentsViewsList() {
        const superviseIncidentsViewsList = state.superviseIncidentsViews.map((view) => {
            return { id: view.id, name: view.name, type: view.type, order: view.order }
        })

        return superviseIncidentsViewsList
    },

    getSuperviseIncidentsSelectedView(state, data) {
        return state.superviseIncidentsSelectedView
    },

    getSuperviseIncidentsDefaultView() {
        if (state.superviseIncidentsViews.length === 0) return

        const views = Object.values(structuredClone(state.superviseIncidentsViews))
        // default view from db:
        const defaultView = findDefaultView(views)

        // fallback default view:
        const alternativeDefaultView = {
            name: i18n.t('supervise.filter.week'),
            data: {
                location: [],
                template: [],
                status: [],
                type_date: 'week',
                complete_date: null,
                category: [],
                start_date: '',
                end_date: '',
                complete_date_start: '',
                complete_date_end: ''
            }
        }

        if (defaultView) {
            return defaultView
        } else {
            return alternativeDefaultView
        }
    },

    getSuperviseIncidentsViewFiltersById: (state, context) => (id) => {
        const selectedViewFilters = state.superviseIncidentsViews.filter((view) => view.id === id)[0].data

        return selectedViewFilters
    },

    getSuperviseIncidentsViewById: (state, context) => (id) => {
        const selectedView = state.superviseIncidentsViews.filter((view) => view.id === id)[0]

        return selectedView
    },

    // SUPERVISE AUDITS GETTERS ------------------------------------------------
    getSuperviseAuditsViewsList() {
        const superviseAuditsViewsList = state.superviseAuditsViews.map((view) => {
            return { id: view.id, name: view.name, type: view.type, order: view.order }
        })

        return superviseAuditsViewsList
    },

    getSuperviseAuditsSelectedView(state, data) {
        return state.superviseAuditsSelectedView
    },

    getSuperviseAuditsDefaultView() {
        if (state.superviseAuditsViews.length === 0) return

        const views = Object.values(structuredClone(state.superviseAuditsViews))
        // default view from db:
        const defaultView = findDefaultView(views)

        // fallback default view:
        const alternativeDefaultView = {
            name: i18n.t('supervise.filter.week'),
            data: {
                location: [],
                template: [],
                employees: [],
                type_date: 'week',
                score: [0, 100],
                start_date: '',
                end_date: ''
            }
        }

        if (defaultView) {
            return defaultView
        } else {
            return alternativeDefaultView
        }
    },

    getSuperviseAuditsViewFiltersById: (state, context) => (id) => {
        const selectedViewFilters = state.superviseAuditsViews.filter((view) => view.id === id)[0].data

        return selectedViewFilters
    },

    getSuperviseAuditsViewById: (state, context) => (id) => {
        const selectedView = state.superviseAuditsViews.filter((view) => view.id === id)[0]

        return selectedView
    }
}

// SETTERS
const mutations = {
    // SUPERVISE CHECKLISTS MUTATIONS:
    setSuperviseChecklistsViews(state, data) {
        state.superviseChecklistsViews = data
    },

    setSuperviseChecklistsSelectedView(state, data) {
        state.superviseChecklistsSelectedView = data
    },

    // SUPERVISE RECORDS MUTATIONS
    setSuperviseRecordsViews(state, data) {
        state.superviseRecordsViews = data
    },

    setSuperviseRecordsSelectedView(state, data) {
        state.superviseRecordsSelectedView = data
    },

    // SUPERVISE INCIDENTS MUTATIONS:
    setSuperviseIncidentsViews(state, data) {
        state.superviseIncidentsViews = data
    },

    setSuperviseIncidentsSelectedView(state, data) {
        state.superviseIncidentsSelectedView = data
    },

    // SUPERVISE AUDITS MUTATIONS:
    setSuperviseAuditsViews(state, data) {
        state.superviseAuditsViews = data
    },

    setSuperviseAuditsSelectedView(state, data) {
        state.superviseAuditsSelectedView = data
    }
}

const actions = {
    // DB BASE ACTIONS ---------------------------------------
    loadViews(context, params) {
        return api
            .post('admin/filtersViews', params)
            .then((response) => {
                if (response.status) {
                    return response.data
                }
            })
            .catch((error) => {
                logError(error)
            })
    },

    async addView(context, params) {
        try {
            const response = await api.post('admin/filtersViews/add', params)

            return { data: response.data, error: response.error }
        } catch (error) {
            console.error(error)
        }
    },

    async editView(context, params) {
        try {
            const newViewData = {
                tool: params.tool,
                name: params.name,
                type: params.type,
                data: params.data
            }

            const response = await api.post(`admin/filtersViews/${params.id}/edit`, newViewData)

            return { data: response.data, error: response.error }
        } catch (error) {
            console.error(error)
        }
    },

    async deleteView(context, params) {
        try {
            const response = await api.get(`admin/filtersViews/${params.id}/delete`)

            context.dispatch('updateState', params.tool)

            return response.status
        } catch (error) {
            console.error(error)
        }
    },

    updateState(context, tool) {
        if (tool === 1) {
            // update superviseChecklistsViews state:
            context.dispatch('loadSuperviseChecklistsViews')
        }

        if (tool === 4) {
            // update superviseRecordsViews state:
            context.dispatch('loadSuperviseRecordsViews')
        }

        if (tool === 2) {
            // update superviseIncidentsViews state:
            context.dispatch('loadSuperviseIncidentsViews')
        }

        if (tool === 7) {
            // update superviseAuditsViews state:
            context.dispatch('loadSuperviseAuditsViews')
        }
    },

    // SUPERVISE CHECKLISTS ACTIONS ---------------------------------------

    // loads Supervise Checklists views from DB
    async loadSuperviseChecklistsViews({ dispatch, commit }) {
        try {
            const views = await dispatch('loadViews', { tool: TOOLS.CHECKLISTS })

            await dispatch('transformsSuperviseChecklistsViewsFiltersForInputs', Object.values(views))
            return true
        } catch (error) {
            console.error(error)
        }
    },

    // completes and transforms Supervise Checklists filters (view property: data)
    async transformsSuperviseChecklistsViewsFiltersForInputs(context, views) {
        const newViews = structuredClone(views)

        const completeSitesData = await context.rootGetters['filterLocations/getFilterLocation']()
        const completeGroupsData = await context.rootGetters['filterLocations/getFilterGroups']()
        const completeTemplatesData = await context.rootGetters['tasks/getFilterTemplate']()
        const completeTimeframesData = await context.rootGetters['tasks/getFilterTimeframe']()
        const completeCategoriesData = await context.rootGetters['tasks/getFilterCategory']()

        const defaultDate = 'week'
        const defaultScore = [0, 100]

        newViews.forEach((view) => {
            if (view.data.hasOwnProperty('location')) {
                view.data.sites = 'locations'

                if (completeSitesData) {
                    view.data.locations = getObjectsByIds(view.data.location, Object.values(completeSitesData))
                }
            }

            if (view.data.hasOwnProperty('groups')) {
                view.data.sites = 'territories'

                if (completeGroupsData) {
                    view.data.territories = getObjectsByIds(view.data.groups, Object.values(completeGroupsData))
                }
            }

            if (view.data.hasOwnProperty('template')) {
                if (completeTemplatesData) {
                    view.data.template = getObjectsByIds(view.data.template, Object.values(completeTemplatesData))
                }
            }

            if (view.data.hasOwnProperty('timeframe')) {
                if (completeTimeframesData) {
                    view.data.timeframe = getObjectsByIds(view.data.timeframe, Object.values(completeTimeframesData))
                }
            }

            if (view.data.hasOwnProperty('category')) {
                if (completeCategoriesData) {
                    view.data.category = getObjectsByIds(structuredClone(view.data.category), Object.values(completeCategoriesData))
                }
            }

            if (!view.data.hasOwnProperty('type_date')) {
                view.data.type_date = defaultDate // "week"
            }

            // si no tiene una propiedad "demora", agregarsela con el valor false
            if (!view.data.hasOwnProperty('demora')) {
                view.data.demora = false
            }

            if (view.data.hasOwnProperty('demora') && typeof view.data.demora == 'string') {
                if (view.data.demora === 'false') {
                    view.data.demora = false
                } else view.data.demora = true
            }

            // si no tiene la propiedad score, agregarsela
            if (!view.data.hasOwnProperty('score')) {
                view.data.score = defaultScore
            }

            // si tiene la propiedad score y es una string, pasarla a un array:
            if (view.data.hasOwnProperty('score') && typeof view.data.score === 'string') {
                const newScore = view.data.score.split(',').map((e) => parseFloat(e.trim()))
                view.data.score = newScore
            }
        })

        // set the state with the filters transformed:
        context.commit('setSuperviseChecklistsViews', newViews)
    },

    async addSuperviseChecklistsView(context, params) {
        const viewFilters = transformSuperviseChecklistViewFiltersForDB(params.data)

        // Add new view Supervise Checklist view to DB (calls addView and pass the tool)
        try {
            const response = await context.dispatch('addView', { ...params, data: JSON.stringify(viewFilters), tool: TOOLS.CHECKLISTS })

            return response
        } catch (error) {
            console.error(error)
        }
    },

    async editSuperviseChecklistsView(context, params) {
        const viewFilters = transformSuperviseChecklistViewFiltersForDB(params.data)

        try {
            const response = await context.dispatch('editView', { ...params, data: JSON.stringify(viewFilters), tool: TOOLS.CHECKLISTS })

            return response
        } catch (error) {
            console.error(error)
        }
    },

    // SUPERVISE RECORDS ACTIONS ---------------------------------------

    // gets Supervise Records Views from DB (calls loadViews action):
    async loadSuperviseRecordsViews({ dispatch, commit }) {
        try {
            const views = await dispatch('loadViews', { tool: TOOLS.REGISTERS })

            await dispatch('transformSuperviseRecordsViewsFiltersForInputs', Object.values(views))

            return true
        } catch (error) {
            console.error(error)
        }
    },

    async transformSuperviseRecordsViewsFiltersForInputs(context, views) {
        const newViews = structuredClone(views)

        const completeLocationData = await context.rootGetters['filterLocations/getFilterLocation']()
        const completeGroupsData = await context.rootGetters['filterLocations/getFilterGroups']()
        const completeEmployeesData = await context.rootGetters['records/getFilterEmployees']()

        const defaultDate = 'week'

        newViews.forEach((view) => {
            if (view.data.hasOwnProperty('location')) {
                const location = structuredClone(view.data.location)

                if (completeLocationData) {
                    view.data.location = getObjectsByIds(location, Object.values(completeLocationData))
                }
            }

            if (view.data.hasOwnProperty('groups')) {
                view.data.sites = 'territories'

                if (completeGroupsData) {
                    view.data.territories = getObjectsByIds(view.data.groups, Object.values(completeGroupsData))
                }
            }

            if (view.data.hasOwnProperty('employees')) {
                const employees = structuredClone(view.data.employees)

                if (completeEmployeesData) {
                    const employeesObject = getObjectsByIds(employees, Object.values(completeEmployeesData))

                    const completeEmployees = employeesObject.map((employee) => ({
                        ...employee,
                        fullName: `${employee.name} ${employee.surname}`
                    }))

                    view.data.employees = completeEmployees
                }
            }

            if (!view.data.hasOwnProperty('type_date')) {
                view.data.type_date = defaultDate // "week"
            }
        })

        // set the state with the filters completed:
        context.commit('setSuperviseRecordsViews', newViews)
    },

    async addSuperviseRecordsView(context, params) {
        const viewFilters = transformSuperviseRecordsViewFiltersForDB(params.data)

        // Add new Supervise Records View to DB (calls addView and pass the tool)
        try {
            const response = await context.dispatch('addView', { ...params, data: JSON.stringify(viewFilters), tool: TOOLS.REGISTERS })

            return response
        } catch (error) {
            console.error(error)
        }
    },

    async editSuperviseRecordsView(context, params) {
        const viewFilters = transformSuperviseRecordsViewFiltersForDB(params.data)

        try {
            const response = await context.dispatch('editView', { ...params, data: JSON.stringify(viewFilters), tool: TOOLS.REGISTERS })

            return response
        } catch (error) {
            console.error(error)
        }
    },

    // SUPERVISE INCIDENTS ACTIONS ---------------------------------------

    // gets Supervise Incidents views from DB (calls loadViews):
    async loadSuperviseIncidentsViews({ dispatch, commit }) {
        try {
            const views = await dispatch('loadViews', { tool: TOOLS.ISSUES })

            await dispatch('transformSuperviseIncidentsViewsFiltersForInputs', Object.values(views))

            return true
        } catch (error) {
            console.error(error)
        }
    },

    // completes and transforms Supervise Incidents FILTERS (views property: data)
    async transformSuperviseIncidentsViewsFiltersForInputs(context, views) {
        const newViews = structuredClone(views)

        const completeLocationsData = await context.rootGetters['filterLocations/getFilterLocation']()
        const completeGroupsData = await context.rootGetters['filterLocations/getFilterGroups']()
        const completeTemplatesData = await context.rootGetters['issues/getFilterTemplate']()
        const completeStatusData = await context.rootGetters['issues/getFilterStates']()
        const completeCategoriesData = await context.rootGetters['issues/getFilterCategories']()

        const defaultCreationDate = 'week'
        const defautCompletedDate = null

        newViews.forEach((view) => {
            if (view.data.hasOwnProperty('location')) {
                const location = structuredClone(view.data.location)

                if (completeLocationsData) {
                    view.data.location = getObjectsByIds(location, Object.values(completeLocationsData))
                }
            }

            if (view.data.hasOwnProperty('groups')) {
                view.data.sites = 'territories'

                if (completeGroupsData) {
                    view.data.territories = getObjectsByIds(view.data.groups, Object.values(completeGroupsData))
                }
            }

            if (view.data.hasOwnProperty('template')) {
                const template = structuredClone(view.data.template)

                if (completeTemplatesData) {
                    view.data.template = getObjectsByIds(template, Object.values(completeTemplatesData))
                }
            }

            if (view.data.hasOwnProperty('status')) {
                const status = structuredClone(view.data.status)

                if (completeStatusData) {
                    view.data.status = getObjectsByIds(status, Object.values(completeStatusData))
                }
            }

            if (view.data.hasOwnProperty('category')) {
                const category = structuredClone(view.data.category)

                if (completeCategoriesData) {
                    view.data.category = getObjectsByIds(category, Object.values(completeCategoriesData))
                }
            }

            if (!view.data.hasOwnProperty('type_date')) {
                view.data.type_date = defaultCreationDate // "week"
            }

            if (!view.data.complete_date) {
                view.data.complete_date = defautCompletedDate
            }
        })

        // SETS DE STATE with the filters completed:
        context.commit('setSuperviseIncidentsViews', newViews)
    },

    async addSuperviseIncidentsView(context, params) {
        const viewFilters = transformSuperviseIncidentsViewFiltersForDB(params.data)

        // Add new view Supervise Incidents view to DB (calls addView and pass the tool)
        try {
            const response = await context.dispatch('addView', { ...params, data: JSON.stringify(viewFilters), tool: TOOLS.ISSUES })

            return response
        } catch (error) {
            console.error(error)
        }
    },

    async editSuperviseIncidentsView(context, params) {
        const viewFilters = transformSuperviseIncidentsViewFiltersForDB(params.data)

        try {
            const response = await context.dispatch('editView', { ...params, data: JSON.stringify(viewFilters), tool: TOOLS.ISSUES })

            return response
        } catch (error) {
            console.error(error)
        }
    },

    // SUPERVISE AUDITS ACTIONS ---------------------------------------

    // get Supervise Audits Views (calls loadViews):
    async loadSuperviseAuditsViews({ dispatch, commit }) {
        try {
            const views = await dispatch('loadViews', { tool: TOOLS.AUDITS })

            await dispatch('transformSuperviseAuditsViewsFiltersForInputs', Object.values(views))

            return true
        } catch (error) {
            console.error(error)
        }
    },

    // completes and transforms Supervise Audits filters (view property: data) and SETS de STATE
    async transformSuperviseAuditsViewsFiltersForInputs(context, views) {
        const newViews = structuredClone(views)

        const completeLocationsData = await context.rootGetters['filterLocations/getFilterLocation']()
        const completeGroupsData = await context.rootGetters['filterLocations/getFilterGroups']()
        const completeTemplatesData = await context.rootGetters['audits/getFilterTemplate']()
        const completeEmployeesData = await context.rootGetters['audits/getFilterEmployees']()

        const defaultDate = 'week'
        const defaultScore = [0, 100]

        newViews.forEach((view) => {
            if (view.data.hasOwnProperty('template')) {
                if (completeTemplatesData) {
                    view.data.template = getObjectsByIds(view.data.template, Object.values(completeTemplatesData))
                }
            }

            if (view.data.hasOwnProperty('location')) {
                if (completeLocationsData) {
                    view.data.location = getObjectsByIds(view.data.location, Object.values(completeLocationsData))
                }
            }

            if (view.data.hasOwnProperty('groups')) {
                view.data.sites = 'territories'

                if (completeGroupsData) {
                    view.data.territories = getObjectsByIds(view.data.groups, Object.values(completeGroupsData))
                }
            }

            if (view.data.hasOwnProperty('employees')) {
                if (completeEmployeesData) {
                    view.data.employees = getObjectsByIds(view.data.employees, Object.values(completeEmployeesData))
                }
            }

            if (!view.data.hasOwnProperty('type_date') && !view.data.hasOwnProperty('start_date')) {
                view.data.type_date = defaultDate // "week"
            }

            if (!view.data.hasOwnProperty('type_date')) {
                view.data.type_date = defaultDate
            }

            // si tiene la propiedad score y es una string, pasarla a un array:
            if (view.data.hasOwnProperty('score') && typeof view.data.score === 'string') {
                const newScore = view.data.score.split(',').map((e) => parseFloat(e.trim()))
                view.data.score = newScore
            }
            // si no tiene la propiedad score, agregarsela
            if (!view.data.hasOwnProperty('score')) {
                view.data.score = defaultScore
            }
        })

        // set the state with the filters completed:
        context.commit('setSuperviseAuditsViews', newViews)
    },

    async addSuperviseAuditsView(context, params) {
        const viewFilters = transformSuperviseAuditsFiltersViewForDB(params.data)

        // Add new view Supervise Audits view to DB (calls addView and pass the tool)
        try {
            const response = await context.dispatch('addView', { ...params, data: JSON.stringify(viewFilters), tool: TOOLS.AUDITS })

            return response
        } catch (error) {
            console.error(error)
        }
    },

    async editSuperviseAuditsView(context, params) {
        const viewFilters = transformSuperviseAuditsFiltersViewForDB(params.data)

        try {
            const response = await context.dispatch('editView', { ...params, data: JSON.stringify(viewFilters), tool: TOOLS.AUDITS })

            return response
        } catch (error) {
            console.error(error)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
