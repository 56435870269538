<template>

  <div>
    <v-container id="filter-sidebar" fluid class="slideRight">
        <div class="title">Columnas a mostrar:</div>
        <v-switch color="#475b96" v-for="header in list" v-model="header.visible" :disabled="header.blocked" @change="select(header)" :label="header.text"></v-switch>

        <button class="btn close" type="button" name="button" @click="hide(this)">{{ text_back }}</button>
    </v-container>
    <div @click="hide(this)" id="filter-overlay"></div>

  </div>

</template>

<script>

  import i18n from '@/i18n'

  export default {
    data () {
      return {
        text_back: i18n.t('assets.column.go_back'),
        show: false,
        list: {},
        callback: function() {},
        selected: [],
      }
    },
    methods: {
      select(column) {
        if(!column.visible) {
          this.selected.push(column.value);
        } else {
          var idx = this.selected.indexOf(column.value);
          if(idx != -1) {
            this.selected.splice(idx, 1);
          }
        }

        this.callback(this.selected);
      },
      hide (event) {
        this.$filter.close();
        this.show = false
        document.body.style.overflow = 'auto'
      }
    }
  }
</script>

<style lang="scss">
    #filter-overlay {
        background-color: #00000099;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z-overlay-default;
    }

    #filter-sidebar {
      @include font-size(14px);
      background-color: #fff;
      color: #111;
      position: fixed;
      left: $menu-tools-min;
      top: $menu-top-height;
      width: 350px;
      height: calc( 100% - #{$menu-top-height} );
      overflow-y: auto;
      z-index: $z-popups-default;
      padding: 20px 25px;
      padding-bottom: $menu-aux-height;
      margin: 0;

      .title {
        @include font-size(16px);
        margin-bottom: 15px;
      }

      .v-input__slot {
        margin: 0;
      }

      .v-label {
        @include font-size(14px);
      }

      .v-input {
        margin: 0;
        margin-bottom: -15px;
      }

      .btn.close {
        @include font-size(16px);
        margin: 30px auto 0 auto;
        display: block;
      }

    }


</style>
