import Vue from 'vue'
import i18n from '@/i18n'
import { Api } from '@/core/api.js'

import localforage from 'localforage'
const api = new Api()

var db_categories = localforage.createInstance({
    name: 'alexdb',
    storeName: 'product_categories'
})
var db_products = localforage.createInstance({
    name: 'alexdb',
    storeName: 'products'
})

// initial state
const getDefaultState = () => {
    return {
        allStates: {},
        allCategories: {},
        galleryImages: {},
        itemsProducts: false,
        itemsProductsCategories: false,
        itemsProductsStates: false,
        itemsLoaded: false,
        labels: [],
        labelsAnalytics: [],
        filters: {
            locations: {},
            groups: {}
        },
        filtersActive: {
            type_date: 'today'
        },
        categories: {},
        states: {},
        filtersProducts: {
            labels: {},
            categories: {},
            states: {},
            status: {},
            label_config: {}
        },
        filtersProductsStates: {
            status: {}
        },
        filtersProductsCategories: {
            status: {}
        },
        filtersProductActive: {
            status: [{ id: 1, name: 'tools.groups.status.active' }]
        },
        filtersProductCategoryActive: {
            status: [{ id: 1, name: 'tools.groups.status.active' }]
        },
        filtersProductStateActive: {
            status: [{ id: 1, name: 'tools.groups.status.active' }]
        },
        // Category product
        newCategoryProduct: {
            id: false,
            name: '',
            color: '',
            status: 1
        },
        newStateProduct: {
            id: false,
            name: '',
            priority: '',
            status: 1
        },
        // AVAILABILITY PRODUCTS
        productAvailabilitySiteList: {},
        filtersProductAvailabilityActive: {
            identification: '',
            name: ''
        },
        productAvailability: {},
        galleryBestImages: {},
        numItems: 0,
        definitiveFilters: {
            name: '',
            category: '',
            status: '',
            states: '',
            locations: '',
            groups: ''
        },
        tracebilityFilters: {
            location_id: '',
            name: '',
            states: '',
            start_date: '',
            end_date: ''
        },
        listTraceability: {},
        printers: {},
        templates: {},
        printerLables: {}
    }
}
const state = getDefaultState()

// getters
const getters = {
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },
    getItemsProducts: (state, getters, rootState) => {
        return state.itemsProducts
    },
    getItemsProductCategories: (state, getters, rootState) => {
        return state.itemsProductsCategories
    },
    getItemsProductStates: (state, getters, rootState) => {
        return state.itemsProductsStates
    },

    getLabels: (state, getters, rootState) => (id) => {
        const allStates = getters.getAllStates()
        if (id && typeof id !== 'undefined') {
            // ORDENAR POR PRIORIDAD LOS ESTADOS
            const result = Object.entries(state.labels[id].states)
                .sort(([, a], [, b]) => {
                    const aPriority = allStates[a.state].priority ? parseInt(allStates[a.state].priority) : 0
                    const bPriority = allStates[b.state].priority ? parseInt(allStates[b.state].priority) : 0
                    return aPriority - bPriority
                })
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            // ASSIGNAR LOS ESTADOS ORDENADOS
            state.labels[id].states = result
            return state.labels[id]
        }

        return state.labels
    },

    getSortedLabels: (state, getters, rootState) => {
        const allStates = getters.getAllStates()
        //BUSCAR LOS ESTADOS DE CADA PRODUCTO Y ORDENARLOS POR PRIORIDAD
        const labels = Object.entries(state.labels).map(([key, value]) => {
            const result = Object.entries(value.states)
                .sort(([, a], [, b]) => {
                    const aPriority = allStates[a.state].priority ? parseInt(allStates[a.state].priority) : 0
                    const bPriority = allStates[b.state].priority ? parseInt(allStates[b.state].priority) : 0
                    return aPriority - bPriority
                })
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            value.states = result
            return value
        })
        return labels
    },

    getListTraceability: (state, getters, rootState) => {
        return state.listTraceability
    },

    getLabelsAnalytics: (state, getters, rootState) => {
        return state.labelsAnalytics
    },

    getAllCategories: (state, getters, rootState) => (id) => {
        if (id) {
            return state.allCategories[id]
        }
        return state.allCategories
    },

    getCategories: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersProducts.categories[id]
        }
        return state.filtersProducts.categories
    },

    getCategoriesForm: (state, getters, rootState) => (id) => {
        if (id) {
            return state.categories[id]
        }
        return state.categories
    },

    getFilterCategories: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersProducts.categories[id]
        }
        return state.filtersProducts.categories
    },

    getStates: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersProducts.states[id]
        }
        return state.filtersProducts.states
    },
    getStatesForm: (state, getters, rootState) => (id) => {
        if (id) {
            return state.states[id]
        }
        return state.states
    },
    getAllStates: (state, getters, rootState) => (id) => {
        if (id) {
            return state.allStates[id]
        }
        return state.allStates
    },

    getFilterLocations: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersProducts.locations[id]
        }
        return state.filtersProducts.locations
    },
    getFilterGroups: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersProducts.groups[id]
        }
        return state.filtersProducts.groups
    },
    getFilterStates: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersProducts.states[id]
        }
        return state.filtersProducts.states
    },
    getFilterStatus: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersProducts.status[id]
        }
        return state.filtersProducts.status
    },
    getFilterProductStateStatus: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersProductsStates.status[id]
        }
        return state.filtersProductsStates.status
    },
    getFilterProductCategoriesStatus: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersProductsCategories.status[id]
        }
        return state.filtersProductsCategories.status
    },
    getFilterProductLabelConfig: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filtersProducts.label_config[id]
        }
        return state.filtersProducts.label_config
    },

    getFiltersProductActive: (state, getters, rootState) => {
        return state.filtersProductActive
    },

    getFiltersProductCategoryActive: (state, getters, rootState) => {
        return state.filtersProductCategoryActive
    },

    getFiltersProductStateActive: (state, getters, rootState) => {
        return state.filtersProductStateActive
    },

    getFilterLocation: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.locations[id]
        }
        return state.filters.locations
    },
    getFilterGroup: (state, getters, rootState) => (id) => {
        if (id) {
            return state.filters.groups[id]
        }
        return state.filters.groups
    },
    getList: (state, getters, rootState) => {
        return state.labels
    },
    getFiltersActive: (state, getters, rootState) => {
        return state.filtersActive
    },
    getGalleryImages: (state, getters, rootState) => (id) => {
        if (id) {
            return state.galleryImages[id]
        }
        return state.galleryImages
    },
    getProductAvailabilitySiteList: (state, getters, rootState) => (id) => {
        if (id) {
            return state.productAvailabilitySiteList[id]
        }
        return state.productAvailabilitySiteList
    },

    getFiltersProductAvailabilityActive: (state, getters, rootState) => {
        return state.filtersProductAvailabilityActive
    },

    getProductAvailability: (state, getters, rootState) => {
        return state.productAvailability
    },
    getGalleryBestImages: (state, getters, rootState) => {
        return state.galleryBestImages
    },

    getNumItems: (state, getters, rootState) => {
        return state.numItems
    },

    getTemplates: (state, getters, rootState) => (id) => {
        if (typeof id === 'undefined') {
            return Object.assign({}, state.templates)
        } else if (typeof state.templates[id] !== 'undefined') {
            return Object.assign({}, state.templates[id])
        } else {
            return false
        }
    },
    getPrinterLabels: (state) => (id) => {
        return state.printerLables[id]
    }
}

// actions
const actions = {
    /* SUPERVISE */

    loadFilters(context) {
        // console.log(params);
        return api.get('admin/label/filter').then(function (response) {
            context.commit('setFilterLocations', response.data.locations)
            context.commit('setFilterGroups', response.data.groups)
        })
    },

    loadList(context, params) {
        context.commit('setItemsLoaded', false)
        return api.post('admin/label', params).then(function (response) {
            if (response.status) {
                context.commit('setNumItems', response.data.results)
                context.commit('setLabelCount', response.data.data)
                // context.commit('setItemsLoaded', true)
                // context.commit('setListTraceabilty', response.data.labels)
                return true
            } else {
                return false
            }
        })
    },

    loadTracebilityList(context, params) {
        const version = process.env.VUE_APP_VERSION
        const location_id = params.location_id
        context.commit('setItemsLoaded', false)
        return api.post('labels/printed', params).then(function (response) {
            if (response.status) {
                context.commit('setNumItems', response.data.results)
                context.commit('setLabelCount', response.data.data)
                context.commit('setItemsLoaded', true)
                context.commit('setListTraceabilty', response.data.labels)
                return api
                    .post('printer', { version, location_id })
                    .then((response) => {
                        context.dispatch('setPrinterData', response.data.printers)
                        return true
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            } else {
                return false
            }
        })
    },

    loadPrinters(context, params) {
        const version = process.env.VUE_APP_VERSION
        const locations = context.rootGetters['location/getList']()
        const location_id = Object.keys(locations)[0]
        return api
            .post('printer', { version, location_id })
            .then((response) => {
                context.dispatch('setPrinterData', response.data.printers)
                return true
            })
            .catch((error) => {
                console.error(error)
            })
    },

    setPrinterData(context, data) {
        let printers = []
        let templates = []
        let labels = []

        for (let printerId in data) {
            let printer = Object.assign({}, data[printerId])
            printers[printer.id] = {
                id: printer.id,
                image: printer.image,
                code: printer.code,
                name: printer.name,
                sdk: printer.config ? printer.config.sdk : printer.sdk,
                config: printer.config ? printer.config : {}
            }

            for (let labelId in printer.labels) {
                let label = Object.assign({}, printer.labels[labelId])
                label.printer_id = printer.id
                labels[labelId] = label
            }

            for (let templateId in printer.templates) {
                let template = Object.assign({}, printer.templates[templateId])
                template.printer_id = printer.id
                templates[templateId] = template
            }
        }

        context.commit('setPrinterModels', printers)
        context.commit('setTemplates', templates)
        context.commit('setPrinterLabels', labels)
    },

    /* PRODUCTS */

    loadStates(context, params) {
        return api.post('admin/labels/states', params).then(function (response) {
            if (response.status) {
                context.commit('setStates', response.data)
                return true
            } else {
                return false
            }
        })
    },
    loadStatesForm(context, params) {
        return api.post('admin/labels/states', { status: 1 }).then(function (response) {
            if (response.status) {
                context.commit('setStatesForm', response.data)
                return true
            } else {
                return false
            }
        })
    },
    loadAllStates(context, params) {
        return api.post('admin/labels/states', { status: '1,-2' }).then(function (response) {
            if (response.status) {
                context.commit('setAllStates', response.data)
                return true
            } else {
                return false
            }
        })
    },
    loadFiltersProducts(context) {
        // console.log(params);
        return api.get('admin/labels/product/filters').then(function (response) {
            context.commit('setFiltersProducts', response.data), context.commit('setFiltersProductsLabelConfig', response.data.label_config)
        })
    },
    loadFiltersProductsStates(context) {
        // console.log(params);
        return api.get('admin/labels/state/filters').then(function (response) {
            context.commit('setFiltersProductsStates', response.data)
        })
    },
    loadFiltersProductsCategories(context) {
        // console.log(params);
        return api.get('admin/labels/category/filters').then(function (response) {
            context.commit('setFiltersProductsCategories', response.data)
        })
    },
    loadAllCategories(context, params) {
        return api.post('admin/labels/categories', { status: '1,-2' }).then(function (response) {
            if (response.status) {
                context.commit('setAllCategories', response.data)
                return true
            } else {
                return false
            }
        })
    },
    loadCategories(context, params) {
        return api.post('admin/labels/categories', params).then(function (response) {
            if (response.status) {
                context.commit('setCategories', response.data)
                return true
            } else {
                return false
            }
        })
    },
    loadCategoriesForm(context, params) {
        return api.post('admin/labels/categories', { status: 1 }).then(function (response) {
            if (response.status) {
                context.commit('setCategoriesForm', response.data)
                return true
            } else {
                return false
            }
        })
    },
    loadLabels(context, params) {
        return api.post('admin/labels/references', params).then(function (response) {
            if (response.status) {
                context.commit('setLabels', response.data)
                return true
            } else {
                return false
            }
        })
    },
    addProductCategory(context, params) {
        var category = context.getters['getCategories'](params)
        if (category.status == 0) {
            category.status = -2
        }
        return api.post('admin/labels/categories/add', category).then(function (response) {
            if (response.status) {
                context.commit('addProductCategory', response.data)
                return true
            } else {
                return false
            }
        })
    },
    addProductState(context, params) {
        var state = context.getters['getStates'](params)
        if (state.status == 0) {
            state.status = -2
        }
        return api.post('admin/labels/states/add', state).then(function (response) {
            if (response.status) {
                context.commit('addProductState', response.data)
                return true
            } else {
                return false
            }
        })
    },

    addNewProductState(context, params) {
        var newState = Object.assign({}, context.state.newStateProduct)
        newState.id = 'tmpitw' + moment().unix()

        var statesActive = Object.values(context.state.filtersProducts.states).filter((obj) => {
            return obj.status > 0
        })

        newState.priority =
            statesActive.length > 0
                ? Math.max.apply(
                      Math,
                      statesActive.map(function (o) {
                          return o.priority
                      })
                  ) + 1
                : 1
        context.commit('createNewProductState', newState)
        return newState.id
    },

    addNewProductCategory(context, params) {
        var newCategory = Object.assign({}, context.state.newCategoryProduct)
        newCategory.id = 'tmpitw' + moment().unix()

        var categoriesActive = Object.values(context.state.filtersProducts.categories).filter((obj) => {
            return obj.status > 0
        })

        newCategory.order =
            categoriesActive.length > 0
                ? Math.max.apply(
                      Math,
                      categoriesActive.map(function (o) {
                          return o.order
                      })
                  ) + 1
                : 1
        context.commit('createNewProductCategory', newCategory)
        return newCategory.id
    },

    addProduct(context, params) {
        if (params.states) {
            for (var state in params.states) {
                var idx = params.states[state]
                if (idx.info) {
                    var info = idx.info
                    let infoClean = Object.keys(info).forEach((k) => !info[k] && info[k] !== undefined && delete info[k])
                    infoClean = JSON.stringify(idx.info)
                    idx.info = infoClean
                    if (idx.info == '{}') {
                        idx.info = null
                    }
                }
            }
            params.states = JSON.stringify(params.states)
        }
        return api.post('admin/labels/references/add', params).then(function (response) {
            if (response.status) {
                context.commit('setEditProduct', response.data)
                context.commit('setGallaeryImages', {})
                return true
            } else {
                return false
            }
        })
    },
    editProduct(context, params) {
        if (params.states) {
            for (var state in params.states) {
                var idx = params.states[state]
                if (idx.info) {
                    var info = idx.info
                    let infoClean = Object.keys(info).forEach((k) => !info[k] && info[k] !== undefined && delete info[k])
                    infoClean = JSON.stringify(idx.info)
                    idx.info = infoClean
                    if (idx.info == '{}') {
                        idx.info = null
                    }
                }
            }
            params.states = JSON.stringify(params.states)
        }
        return api.post('admin/labels/references/' + params.id + '/edit', params).then(function (response) {
            if (response.status) {
                context.commit('setEditProduct', response.data)
                context.commit('setGallaeryImages', {})
                return true
            } else {
                return false
            }
        })
    },
    editProductCategory(context, params) {
        var category = context.getters['getCategories'](params)
        if (category.status == 0) {
            category.status = -2
        }
        return api.post('admin/labels/categories/' + params + '/edit', category).then(function (response) {
            if (response.status) {
                context.commit('editProductCategory', response.data)
                return true
            } else {
                return false
            }
        })
    },
    validateProductCategory(context, params) {
        var categoryToValidate = context.getters['getCategories'](params)
        var errors = {
            name: false,
            status: false,
            order: false
        }
        var sendForm = true
        errors = _.mapValues(errors, () => false)
        if (categoryToValidate.name === '') {
            sendForm = false
            errors.name = true
        }
        if (categoryToValidate.order === '' || categoryToValidate.order == null || categoryToValidate.order == 0) {
            sendForm = false
            errors.order = true
        }
        if (categoryToValidate.color === '') {
            sendForm = false
            errors.color = true
        }
        return {
            status: sendForm,
            errors: errors
        }
    },
    validateProductState(context, params) {
        var stateToValidate = context.getters['getStates'](params)
        var errors = {
            name: false,
            status: false,
            order: false
        }
        var sendForm = true
        errors = _.mapValues(errors, () => false)

        if (stateToValidate.name === '') {
            sendForm = false
            errors.name = true
        }
        if (stateToValidate.priority === '') {
            sendForm = false
            errors.priority = true
        }
        return {
            status: sendForm,
            errors: errors
        }
    },
    editProductState(context, params) {
        var state = context.getters['getStates'](params)
        if (state.status == 0) {
            state.status = -2
        }
        return api.post('admin/labels/states/' + params + '/edit', state).then(function (response) {
            if (response.status) {
                context.commit('editProductState', response.data)
                return true
            } else {
                return false
            }
        })
    },
    removeState(context, params) {
        return api.get('admin/labels/states/' + params + '/remove').then(function (response) {
            if (response.status) {
                context.commit('setRemoveState', params)
                return true
            } else {
                return false
            }
        })
    },
    removeCategory(context, params) {
        return api.get('admin/labels/categories/' + params + '/remove').then(function (response) {
            if (response.status) {
                context.commit('setRemoveCategory', params)
                return true
            } else {
                return false
            }
        })
    },
    removeProduct(context, params) {
        return api.get('admin/labels/references/' + params + '/remove').then(function (response) {
            if (response.status) {
                context.commit('setRemoveProduct', params)
                return true
            } else {
                return false
            }
        })
    },

    // Gallery products

    loadGalleryImages(context, params) {
        return api.get('admin/labels/images').then(function (response) {
            // console.log(response)
            if (response.status) {
                context.commit('setGallaeryImages', response.data)
                return true
            } else {
                return false
            }
        })
    },

    // AVAILABILITY PRODUCTS
    loadProductAvailability(context, idStore) {
        return api.get('sites/' + idStore + '/products/availability').then(function (response) {
            if (response.status) {
                context.commit('setProductAvailability', response.data)
                return true
            } else {
                return false
            }
        })
    },

    loadProductAvailabilitySiteList(context, params) {
        return api.post('sites/products/list', params).then(function (response) {
            if (response.status) {
                context.commit('setProductAvailabilitySiteList', response.data)
                return true
            } else {
                return false
            }
        })
    },

    updateProductAvailability(context, params) {
        return api.post('sites/' + params.siteId + '/products/save', { products: JSON.stringify(params.activeIds) }).then(function (response) {
            if (response.status) {
                return true
            } else {
                return false
            }
        })
    },

    galleryLoadBestImages(context, params) {
        return api.post(`admin/gallery/getbestimages`, { product_name: params.product_name, category_name: params.category_name }).then((response) => {
            context.commit('gallerySetBestImages', response.status ? response.data : {})
        })
    },

    cloneProduct(context, params) {
        return api.get(`admin/labels/references/${params.id}/clone`).then((response) => {})
    },

    downloadTracabilityExcel(context, { page }) {
        let filters = {}
        if (page === 'supervise') {
            filters = state.definitiveFilters
        } else {
            filters = state.tracebilityFilters
            filters.locations = filters.location_id.id
            // delete filters.location_id
        }
        return api
            .post(`admin/labels/references/excel`, filters)
            .then(function (response) {
                return response.file
            })
            .catch(function (error) {
                console.error(error)
                return false
            })
    },

    productPdfPrintlog(context, params) {
        return api.get(`admin/label/pdf/print`).then(function (response) {
            return response
        })
    }
}

// mutations
const mutations = {
    setListTraceabilty(state, payload) {
        state.listTraceability = payload
    },
    setFilterLocations(state, payload) {
        var filters = state.filters
        filters.locations = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFilterGroups(state, payload) {
        var filters = state.filters
        filters.groups = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFiltersProducts(state, response) {
        if (response) {
            Vue.set(state, 'filtersProducts', { ...response })
        }
    },
    createNewProductCategory(state, payload) {
        var categories = { ...state.filtersProducts.categories }
        categories[payload.id] = payload
        Vue.set(state.filtersProducts, 'categories', { ...categories })
    },
    createNewProductState(state, payload) {
        var states = { ...state.filtersProducts.states }
        states[payload.id] = payload
        Vue.set(state.filtersProducts, 'states', { ...states })
    },
    updateProductCategory(state, payload) {
        var categories = { ...state.filtersProducts.categories }
        categories[payload.id] = payload
        Vue.set(state.filtersProducts, 'categories', { ...categories })
    },
    updateProductState(state, payload) {
        var states = { ...state.filtersProducts.states }
        states[payload.id] = payload
        Vue.set(state.filtersProducts, 'states', { ...states })
    },
    setFiltersProductsLabelConfig(state, response) {
        if (response) {
            Vue.set(state.filtersProducts, 'label_config', { ...response })
        }
    },
    setFiltersProductsStates(state, response) {
        if (response) {
            Vue.set(state, 'filtersProductsStates', { ...response })
        }
    },
    setFiltersProductsCategories(state, response) {
        if (response) {
            Vue.set(state, 'filtersProductsCategories', { ...response })
        }
    },
    setCategoriesForm(state, response) {
        if (response) {
            Vue.set(state, 'categories', { ...response })
        }
    },
    setLabels(state, data) {
        state.labels = data

        Vue.set(state, 'labels', { ...data })
        state.itemsProducts = true
    },
    setAllCategories(state, data) {
        Vue.set(state, 'allCategories', { ...data })
    },
    setCategories(state, data) {
        Vue.set(state.filtersProducts, 'categories', { ...data })
        state.itemsProductsCategories = true
    },
    setStates(state, data) {
        Vue.set(state.filtersProducts, 'states', { ...data })
    },
    setAllStates(state, data) {
        Vue.set(state, 'allStates', { ...data })
    },
    setStatesForm(state, data) {
        Vue.set(state, 'states', { ...data })
        state.itemsProductsStates = true
    },
    setRemoveState(state, params) {
        var states = state.filtersProducts.states
        delete states[params]
        state.filtersProducts.states = states
        Vue.set(state.filtersProducts, 'states', { ...states })

        var allStates = state.allStates
        delete allStates[params]
        state.allStates = allStates
        Vue.set(state.allStates, 'states', { ...allStates })
    },
    setRemoveCategory(state, params) {
        var categories = state.filtersProducts.categories
        delete categories[params]
        state.filtersProducts.categories = categories
        Vue.set(state.filtersProducts, 'categories', { ...categories })

        var allCategories = state.allCategories
        delete allCategories[params]
        state.allCategories = allCategories
        Vue.set(state.allCategories, 'categories', { ...allCategories })
    },
    setRemoveProduct(state, params) {
        var label = state.labels
        delete label[params]
        state.labels = label
        Vue.set(state, 'labels', { ...label })
    },
    setEditProduct(state, params) {
        var labels = { ...state.labels }
        labels[params.id] = params
        state.labels = labels
        Vue.set(state, 'labels', { ...labels })
    },
    editProductCategory(state, params) {
        var categories = { ...state.filtersProducts.categories }
        categories[params.id] = params
        state.filtersProducts.categories = categories
        Vue.set(state.filtersProducts, 'categories', { ...categories })

        var allCategories = { ...state.allCategories }
        allCategories[params.id] = params
        state.allCategories = allCategories
        Vue.set(state.allCategories, 'categories', { ...allCategories })
    },
    editProductState(state, params) {
        var states = { ...state.filtersProducts.states }
        states[params.id] = params
        state.filtersProducts.states = states
        Vue.set(state.filtersProducts, 'states', { ...states })

        var allStates = { ...state.allStates }
        allStates[params.id] = params
        state.allStates = allStates
        Vue.set(state.allStates, 'states', { ...allStates })
    },
    addProductCategory(state, params) {
        var categories = { ...state.filtersProducts.categories }
        categories[params.id] = params
        state.filtersProducts.categories = categories
        Vue.set(state.filtersProducts, 'categories', { ...categories })

        var allCategories = { ...state.allCategories }
        allCategories[params.id] = params
        state.allCategories = allCategories
        Vue.set(state.allCategories, 'categories', { ...allCategories })
    },
    addProductState(state, params) {
        var states = { ...state.filtersProducts.states }
        states[params.id] = params
        state.filtersProducts.states = states
        Vue.set(state.filtersProducts, 'states', { ...states })

        var allStates = { ...state.allStates }
        allStates[params.id] = params
        state.allStates = allStates
        Vue.set(state, 'allStates', { ...allStates })
    },

    setFiltersProductActive(state, filters) {
        state.filtersProductActive = filters
        Vue.set(state, 'filtersProductActive', { ...filters })
    },

    setDefinitiveFilters(state, filters) {
        state.definitiveFilters = filters
        Vue.set(state, 'definitiveFilters', { ...filters })
    },

    setTracebilityFilters(state, filters) {
        state.tracebilityFilters = filters
        Vue.set(state, 'tracebilityFilters', { ...filters })
    },

    setFiltersProductCategoryActive(state, filters) {
        state.filtersProductCategoryActive = filters
        Vue.set(state, 'filtersProductCategoryActive', { ...filters })
    },

    setFiltersProductStateActive(state, filters) {
        state.filtersProductStateActive = filters
        Vue.set(state, 'filtersProductStateActive', { ...filters })
    },
    setFiltersActive(state, filters) {
        state.filtersActive = filters
        Vue.set(state, 'filtersActive', { ...filters })
    },
    setLabelCount(state, data) {
        Vue.set(state, 'labelsAnalytics', { ...data })
        state.itemsLoaded = true
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setItemsLoaded(state, payload) {
        Vue.set(state, 'itemsLoaded', payload)
    },
    setGallaeryImages(state, payload) {
        state.galleryImages = payload
        Vue.set(state, 'galleryImages', payload)
    },

    setProductAvailability(state, payload) {
        state.productAvailability = payload
        Vue.set(state, 'productAvailability', payload)
    },

    setProductAvailabilitySiteList(state, payload) {
        state.productAvailabilitySiteList = payload
        Vue.set(state, 'productAvailabilitySiteList', payload)
    },

    setFiltersProductAvailabilityActive(state, filters) {
        state.filtersProductAvailabilityActive = filters
        Vue.set(state, 'filtersProductAvailabilityActive', { ...filters })
    },
    gallerySetBestImages(state, data) {
        state.galleryBestImages = data
        Vue.set(state, 'galleryBestImages', { ...data })
    },
    setNumItems(state, payload) {
        Vue.set(state, 'numItems', payload)
    },

    setPrinterModels(state, models) {
        Vue.set(state, 'models', Object.assign({}, models))
    },

    setTemplates(state, templates) {
        Vue.set(state, 'templates', Object.assign({}, templates))
    },

    setPrinterLabels(state, labels) {
        Vue.set(state, 'printerLables', Object.assign({}, labels))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
