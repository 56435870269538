var Uploader = (function () {
    var s3_config = {
        bucket: process.env.VUE_APP_S3_BUCKET,
        region: 'eu-west-1',
        IdentityPoolId: process.env.VUE_APP_S3_IdentityPoolId,
        apiVersion: '2006-03-01'
    }

    function Uploader() {}

    Uploader.prototype = {
        reader: {},
        file: {},
        slice_size: 10000 * 1024,
        current_upload: false,
        fCallBackSuccess: null,
        fCallBackError: null,
        fCallBackProgress: null,

        s3: null,
        uploadId: '',
        iteration: 0,
        infoParts: [],
        start: 0,
        pathFile: '',

        init: function () {
            AWS.config.region = s3_config.region // Región
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: s3_config.IdentityPoolId
            })

            this.s3 = new AWS.S3({
                apiVersion: s3_config.apiVersion,
                credentials: AWS.config.credentials,
                region: s3_config.region
            })
        },

        upload: function (file, options, fcSuccess, fcError, fcProgress) {
            if (!this.current_upload) {
                this.current_upload = true

                if (this.s3 === null) this.init()
                console.log(file, options)
                this.file = file
                this.iteration = 0
                this.infoParts = []
                this.reader = new FileReader()
                this.start = moment().valueOf()
                this.fCallBackSuccess = fcSuccess
                this.fCallBackError = fcError
                this.fCallBackProgress = fcProgress
                this.fileName = typeof options.filename !== 'undefined' ? options.filename : file.name
                this.pathFile = typeof options.path !== 'undefined' && options.path.trim() != '' ? decodeURIComponent(options.path + '/' + this.fileName) : this.fileName

                var self = this

                if (isImage(this.pathFile)) {
                    this.upload_image()
                } else {
                    var params = {
                        Bucket: s3_config.bucket,
                        Key: this.pathFile,
                        ContentType: getMimeType(this.pathFile),
                        ACL: 'public-read'
                    }

                    // console.log('Start upload')
                    this.s3.createMultipartUpload(params, function (err, data) {
                        if (err) {
                            console.log(err, err.stack) // an error occurred
                            if (typeof self.fCallBackError === 'function') {
                                self.fCallBackError()
                            }
                        } else {
                            // console.log(data) // successful response
                            /*
                            data = {
                                Bucket: "examplebucket",
                                Key: "largeobject",
                                UploadId: "ibZBv_75gd9r8lH_gqXatLdxMVpAlj6ZQjEs.OwyF3953YdwbcQnMA2BLGn8Lx12fQNICtMw5KyteFeHw.Sjng--"
                            }
                            */
                            self.uploadId = data.UploadId

                            self.upload_multipart()
                        }
                    })
                }
            } else if (typeof fcError === 'function') {
                fcError({ code: -1, message: 'Current upload active' })
            }
        },

        upload_image: function () {
            var self = this
            this.reader.onload = (e) => {
                var image = new Image()
                image.onload = () => {
                    var img = resizeImage(image)
                    var myFile = dataURItoBlob(img, self.pathFile)
                    var params = {
                        Bucket: s3_config.bucket,
                        ACL: 'public-read',
                        Key: this.pathFile,
                        ContentType: 'image/' + getMimeType(self.pathFile),
                        Body: myFile
                    }

                    this.s3.upload(params, function (err, data) {
                        console.log('UPLOAD IMAGE COMPLETE: Time: ' + parseInt((moment().valueOf() - self.start) / 1000) + 'sec - File size: ' + parseInt(self.file.size / 1024) + 'KB')
                        // console.log(err, data)
                        /*
					data = {
						Bucket: "acexamplebucket",
						ETag: "\"4d9031c7644d8081c2829f4ea23c55f7-2\"",
						Key: "bigobject",
						Location: "https://examplebucket.s3.amazonaws.com/bigobject"
					}
					*/

                        self.current_upload = false
                        if (typeof self.fCallBackSuccess === 'function') {
                            /* FIX '/' --> '/%2F' */ data.Location = data.Location.replace(/%2F/gi, '/')
                            self.fCallBackSuccess(data)
                        }
                    })
                }
                image.src = e.target.result
            }
            this.reader.readAsDataURL(this.file)
        },

        upload_multipart: function () {
            //   console.log('upload_multipart - Iteration: ' + this.iteration)
            var start = this.iteration * this.slice_size
            var next_slice = start + this.slice_size
            var blob = this.file.slice(start, next_slice)

            var self = this

            var params = {
                Body: blob,
                Bucket: s3_config.bucket,
                Key: self.pathFile,
                PartNumber: self.iteration + 1,
                UploadId: self.uploadId
            }
            //   console.log('--- PARAMS ---')
            //   console.log(params)
            this.s3.uploadPart(params, function (err, data) {
                if (err || typeof data.ETag === 'undefined') {
                    if (err) {
                        // console.log(err, err.stack); // an error occurred
                        Errors.pdSendError(err)
                    }

                    self.abort_multipart()
                } else {
                    // console.log( "--- SUCCESS RESPONSE ---" );
                    // console.log(data);           // successful response
                    /*
						data = {
							ETag: "\"d8c2eafd90c266e19ab9dcacc479f8af\""
						}
					*/
                    self.infoParts.push({
                        ETag: data.ETag,
                        PartNumber: self.iteration + 1
                    })

                    var size_done = start + self.slice_size
                    var percent_done = Math.floor((size_done / self.file.size) * 100)
                    if (typeof self.fCallBackProgress === 'function') {
                        self.fCallBackProgress(percent_done)
                    } else {
                        console.log('Uploading File - ' + percent_done + '%')
                    }

                    if (next_slice < self.file.size) {
                        self.iteration++
                        self.upload_multipart()
                    } else {
                        self.complete_multipart()
                    }
                }
            })
        },

        complete_multipart: function () {
            //   console.log('UPLAODER - complete_multipart')
            var self = this

            var params = {
                Bucket: s3_config.bucket,
                Key: this.pathFile,
                MultipartUpload: { Parts: this.infoParts },
                UploadId: this.uploadId
            }
            //   console.log('--- PARAMS ---')
            //   console.log(params)
            this.s3.completeMultipartUpload(params, function (err, data) {
                if (err) {
                    // console.log(err, err.stack); // an error occurred
                    Errors.pdSendError(err)
                    self.abort_multipart()
                } else {
                    // console.log(data);           // successful response
                    console.log('UPLOAD COMPLETE: Time: ' + parseInt((moment().valueOf() - self.start) / 1000) + 'sec - File size: ' + parseInt(self.file.size / 1024) + 'KB')
                    /*
					data = {
						Bucket: "acexamplebucket",
						ETag: "\"4d9031c7644d8081c2829f4ea23c55f7-2\"",
						Key: "bigobject",
						Location: "https://examplebucket.s3.amazonaws.com/bigobject"
					}
					*/

                    self.current_upload = false
                    if (typeof self.fCallBackSuccess === 'function') {
                        /* FIX '/' --> '/%2F' */ data.Location = data.Location.replace(/%2F/gi, '/')
                        self.fCallBackSuccess(data)
                    }
                }
            })
        },

        abort_multipart: function () {
            //   console.log('Uploader - abort MultiPart ID: ' + this.uploadId)
            var self = this

            var params = {
                Bucket: s3_config.bucket,
                Key: this.pathFile,
                UploadId: this.uploadId
            }
            this.s3.abortMultipartUpload(params, function (err, data) {
                if (err) {
                    // console.log(err, err.stack); // an error occurred
                    Errors.pdSendError(err)
                } else {
                    console.log('ABORT COMPLETE') // successful response
                    /*
						data = {
						}
					*/
                }

                self.current_upload = false
                if (typeof self.fCallBackError === 'function') {
                    self.fCallBackError({ code: -2, message: 'Upload was aborted by a problem' })
                }
            })
        }
    }

    return Uploader
})()

var uploader = new Uploader()

function isImage(file) {
    var split = file.split('.')
    var extension = split[split.length - 1]
    return ['jpg', 'jpeg', 'png'].includes(extension)
}

function dataURItoBlob(file, filePath) {
    var binary = atob(file.split(',')[1])
    var array = []
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
    }
    return new Blob([new Uint8Array(array)], {
        type: 'image/' + getMimeType(filePath)
    })
}

function getMimeType(file) {
    var mimeTypes = {
        323: 'text/h323',
        acx: 'application/internet-property-stream',
        ai: 'application/postscript',
        aif: 'audio/x-aiff',
        aifc: 'audio/x-aiff',
        aiff: 'audio/x-aiff',
        asf: 'video/x-ms-asf',
        asr: 'video/x-ms-asf',
        asx: 'video/x-ms-asf',
        au: 'audio/basic',
        avi: 'video/x-msvideo',
        axs: 'application/olescript',
        bas: 'text/plain',
        bcpio: 'application/x-bcpio',
        bin: 'application/octet-stream',
        bmp: 'image/bmp',
        c: 'text/plain',
        cat: 'application/vnd.ms-pkiseccat',
        cdf: 'application/x-cdf',
        cer: 'application/x-x509-ca-cert',
        class: 'application/octet-stream',
        clp: 'application/x-msclip',
        cmx: 'image/x-cmx',
        cod: 'image/cis-cod',
        cpio: 'application/x-cpio',
        crd: 'application/x-mscardfile',
        crl: 'application/pkix-crl',
        crt: 'application/x-x509-ca-cert',
        csh: 'application/x-csh',
        css: 'text/css',
        dcr: 'application/x-director',
        der: 'application/x-x509-ca-cert',
        dir: 'application/x-director',
        dll: 'application/x-msdownload',
        dms: 'application/octet-stream',
        doc: 'application/msword',
        dot: 'application/msword',
        dvi: 'application/x-dvi',
        dxr: 'application/x-director',
        eps: 'application/postscript',
        etx: 'text/x-setext',
        evy: 'application/envoy',
        exe: 'application/octet-stream',
        fif: 'application/fractals',
        flr: 'x-world/x-vrml',
        gif: 'image/gif',
        gtar: 'application/x-gtar',
        gz: 'application/x-gzip',
        h: 'text/plain',
        hdf: 'application/x-hdf',
        hlp: 'application/winhlp',
        hqx: 'application/mac-binhex40',
        hta: 'application/hta',
        htc: 'text/x-component',
        htm: 'text/html',
        html: 'text/html',
        htt: 'text/webviewhtml',
        ico: 'image/x-icon',
        ief: 'image/ief',
        iii: 'application/x-iphone',
        ins: 'application/x-internet-signup',
        isp: 'application/x-internet-signup',
        jfif: 'image/pipeg',
        jpe: 'image/jpeg',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg',
        js: 'application/x-javascript',
        latex: 'application/x-latex',
        lha: 'application/octet-stream',
        lsf: 'video/x-la-asf',
        lsx: 'video/x-la-asf',
        lzh: 'application/octet-stream',
        m13: 'application/x-msmediaview',
        m14: 'application/x-msmediaview',
        m3u: 'audio/x-mpegurl',
        man: 'application/x-troff-man',
        mdb: 'application/x-msaccess',
        me: 'application/x-troff-me',
        mht: 'message/rfc822',
        mhtml: 'message/rfc822',
        mid: 'audio/mid',
        mny: 'application/x-msmoney',
        mov: 'video/quicktime',
        movie: 'video/x-sgi-movie',
        mp2: 'video/mpeg',
        mp3: 'audio/mpeg',
        mpa: 'video/mpeg',
        mpe: 'video/mpeg',
        mpeg: 'video/mpeg',
        mpg: 'video/mpeg',
        mpp: 'application/vnd.ms-project',
        mpv2: 'video/mpeg',
        ms: 'application/x-troff-ms',
        mvb: 'application/x-msmediaview',
        nws: 'message/rfc822',
        oda: 'application/oda',
        p10: 'application/pkcs10',
        p12: 'application/x-pkcs12',
        p7b: 'application/x-pkcs7-certificates',
        p7c: 'application/x-pkcs7-mime',
        p7m: 'application/x-pkcs7-mime',
        p7r: 'application/x-pkcs7-certreqresp',
        p7s: 'application/x-pkcs7-signature',
        pbm: 'image/x-portable-bitmap',
        pdf: 'application/pdf',
        pfx: 'application/x-pkcs12',
        pgm: 'image/x-portable-graymap',
        pko: 'application/ynd.ms-pkipko',
        pma: 'application/x-perfmon',
        pmc: 'application/x-perfmon',
        pml: 'application/x-perfmon',
        pmr: 'application/x-perfmon',
        pmw: 'application/x-perfmon',
        pnm: 'image/x-portable-anymap',
        pot: 'application/vnd.ms-powerpoint',
        ppm: 'image/x-portable-pixmap',
        pps: 'application/vnd.ms-powerpoint',
        ppt: 'application/vnd.ms-powerpoint',
        prf: 'application/pics-rules',
        ps: 'application/postscript',
        pub: 'application/x-mspublisher',
        qt: 'video/quicktime',
        ra: 'audio/x-pn-realaudio',
        ram: 'audio/x-pn-realaudio',
        ras: 'image/x-cmu-raster',
        rgb: 'image/x-rgb',
        rmi: 'audio/mid',
        roff: 'application/x-troff',
        rtf: 'application/rtf',
        rtx: 'text/richtext',
        scd: 'application/x-msschedule',
        sct: 'text/scriptlet',
        setpay: 'application/set-payment-initiation',
        setreg: 'application/set-registration-initiation',
        sh: 'application/x-sh',
        shar: 'application/x-shar',
        sit: 'application/x-stuffit',
        snd: 'audio/basic',
        spc: 'application/x-pkcs7-certificates',
        spl: 'application/futuresplash',
        src: 'application/x-wais-source',
        sst: 'application/vnd.ms-pkicertstore',
        stl: 'application/vnd.ms-pkistl',
        stm: 'text/html',
        svg: 'image/svg+xml',
        sv4cpio: 'application/x-sv4cpio',
        sv4crc: 'application/x-sv4crc',
        t: 'application/x-troff',
        tar: 'application/x-tar',
        tcl: 'application/x-tcl',
        tex: 'application/x-tex',
        texi: 'application/x-texinfo',
        texinfo: 'application/x-texinfo',
        tgz: 'application/x-compressed',
        tif: 'image/tiff',
        tiff: 'image/tiff',
        tr: 'application/x-troff',
        trm: 'application/x-msterminal',
        tsv: 'text/tab-separated-values',
        txt: 'text/plain',
        uls: 'text/iuls',
        ustar: 'application/x-ustar',
        vcf: 'text/x-vcard',
        vrml: 'x-world/x-vrml',
        wav: 'audio/x-wav',
        wcm: 'application/vnd.ms-works',
        wdb: 'application/vnd.ms-works',
        wks: 'application/vnd.ms-works',
        wmf: 'application/x-msmetafile',
        wps: 'application/vnd.ms-works',
        wri: 'application/x-mswrite',
        wrl: 'x-world/x-vrml',
        wrz: 'x-world/x-vrml',
        xaf: 'x-world/x-vrml',
        xbm: 'image/x-xbitmap',
        xla: 'application/vnd.ms-excel',
        xlc: 'application/vnd.ms-excel',
        xlm: 'application/vnd.ms-excel',
        xls: 'application/vnd.ms-excel',
        xlsx: 'vnd.ms-excel',
        xlt: 'application/vnd.ms-excel',
        xlw: 'application/vnd.ms-excel',
        xof: 'x-world/x-vrml',
        xpm: 'image/x-xpixmap',
        xwd: 'image/x-xwindowdump',
        z: 'application/x-compress',
        zip: 'application/zip'
    }

    var split = file.split('.')
    var extension = split[split.length - 1]
    return mimeTypes[extension] // return the array value
}
export { Uploader, isImage, dataURItoBlob, getMimeType }
