<template>
    <v-app id="app">
        <component :is="layout">
            <router-view />
        </component>
    </v-app>
</template>

<script>
import { Db } from '@/core/db.js'
import i18n from '@/i18n'
const default_layout = 'login'

export default {
    computed: {
        layout() {
            var layout = typeof this.$route.meta.layout !== 'undefined' ? this.$route.meta.layout : default_layout
            return layout + '-layout'
        }
    },
    created() {
        const db = new Db()
        db.init()
    },
    updated() {
        document.title = i18n.t('app.title')
    }
}
</script>

<style lang="scss">
@import '@/../public/css/_reset.scss';
@import '@/../public/css/style.scss';
@import '@/../public/css/main.scss';
@import '@/../public/css/_default.scss';
@import '@/../public/css/assets/_assets.scss';
@import '@/../public/css/assets/_custom.vuetify.scss';
@import '@/../public/css/print.scss';
@import '@/../public/css/assets/_pdf.scss';
</style>
