<template>
    <div class="version" :class="[{ expanded: isExpanded }, customClass]">{{ $t('snackbar.version') }}: {{ appVersion }}</div>
</template>

<script>
export default {
    props: {
        customClass: { type: [String, Array, Object, Function], default: '' },
        isHide: { type: Boolean }
    },

    computed: {
        appVersion() {
            return process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : ''
        },
        isExpanded() {
            return this.$store.state['menu_tools_is_expanded']
        }
    }
}
</script>

<style lang="scss" scoped>
.version {
    @include display-flex();
    @include align-items();
    @include justify-content(center);
    @include font-size(xs);
    height: $app-version-height;
    min-width: 100%;
    bottom: 0px;
    left: 95px;
    z-index: $z-copyright;
    width: 100%;
    text-align: center !important;
    color: $color-white;
    font-family: $text-light;
    transition-duration: 0.2s;

    &.expanded {
        left: 245px;
        transition-duration: 0.2s;
    }

    // CUSTOM CLASSES
    &.login-version {
        @include font-size(sm);
        font-family: $text-medium;
        color: $color-neutral-300;
        text-align: right;
        position: unset;
        display: inline-block;
        width: auto;
        height: 30px;
        line-height: 30px;
    }
}
</style>
