<template>
    <input
        @keyup="$emit('keyup', $event)"
        @change="$emit('change', $event)"
        type="text"
        class="input"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
        :disabled="inputOptions.disabled"
        @blur="$emit('blur', $event)"
        :required="inputOptions.mandatory"
        :name="inputOptions.name"
        :autocomplete="inputOptions.name"
        :maxlength="inputOptions.maxLength"
    />
</template>

<script>
export default {
    props: {
        value: { type: [String, Number, Boolean, Array, Object], default: undefined },
        cValue: { type: [String, Number, Boolean, Array, Object], default: undefined },
        cPlaceholder: { type: String, default: '' },
        cOptions: { type: Object, default: undefined }
    },
    data() {
        return {
            tempValue: this.cValue,
            options: {}
        }
    },
    computed: {
        inputOptions() {
            // Mix de options que llegan vs defaults
            return {
                ...this.options,
                ...(this.cOptions ? this.cOptions : {})
            }
        },
        placeholder() {
            return this.cPlaceholder
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
