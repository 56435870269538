<template>
    <div class="popup-download-templates">
        <header class="header">
            <h1 class="title">
                {{
                    quantity == 1
                        ? tool === 'template'
                            ? translate('template.download.popup.title_singular')
                            : translate('template.download.popup.exportReport')
                        : ['task', 'record', 'issue', 'audit'].includes(tool)
                        ? translate('template.download.popup.exportReports')
                        : translate('template.download.popup.title_plural')
                }}
            </h1>
            <p class="description">{{ translate('template.download.popup.display_options') }}</p>
        </header>

        <section class="display-options">
            <div
                v-if="displayOptions"
                v-for="option in displayOptions"
                :key="option.key"
                @click="toggleValue(option.key)"
                class="option"
                :class="{ selected: option.value === true }"
            >
                {{ translate(option.label) }}
            </div>
        </section>
    </div>
</template>

<script>
import store from '@/store'
import i18n from '@/i18n'

export default {
    name: 'PopupPDFDisplayOptions',
    props: { quantity: Number, tool: String },
    data() {
        return {
            displayOptions: []
        }
    },
    methods: {
        translate(str) {
            return i18n.t(str)
        },

        toggleValue(optionKey) {
            const newOptions = structuredClone(this.displayOptions)

            newOptions.forEach((option) => {
                if (option.key == optionKey) {
                    option.value = !option.value
                }
            })

            this.displayOptions = newOptions
        }
    },
    watch: {
        displayOptions(newDisplayOptions) {
            if (this.tool) {
                switch (this.tool) {
                    case 'task':
                        store.commit('reports/setDownloadReportsDisplayOptions', newDisplayOptions)
                        localStorage.setItem('downloadReportsDisplayOptions', JSON.stringify(newDisplayOptions))
                        break
                    case 'record':
                        store.commit('reports/setDownloadReportsRecordDisplayOptions', newDisplayOptions)
                        localStorage.setItem('downloadReportsDisplayOptionsRecords', JSON.stringify(newDisplayOptions))
                        break
                    case 'issue':
                        store.commit('reports/setDownloadReportsIssueDisplayOptions', newDisplayOptions)
                        localStorage.setItem('downloadReportsDisplayOptionsIssues', JSON.stringify(newDisplayOptions))
                        break
                    case 'audit':
                        store.commit('reports/setDownloadReportsAuditDisplayOptions', newDisplayOptions)
                        localStorage.setItem('downloadReportsDisplayOptionsAudits', JSON.stringify(newDisplayOptions))
                        break
                    default:
                        store.commit('template/setDownloadTemplatesDisplayOptions', newDisplayOptions)
                        localStorage.setItem('downloadTemplatesDisplayOptions', JSON.stringify(newDisplayOptions))
                        break
                }
            }
        }
    },
    created() {},
    mounted() {
        let defaultGetter = ''
        let savedOptions = ''
        if (this.tool) {
            switch (this.tool) {
                case 'task':
                    defaultGetter = 'reports/getDownloadReportsDisplayOptions'
                    savedOptions = localStorage.getItem('downloadReportsDisplayOptions')
                    break
                case 'record':
                    defaultGetter = 'reports/getDownloadRecordReportsDisplayOptions'
                    savedOptions = localStorage.getItem('downloadReportsDisplayOptionsRecords')
                    break
                case 'issue':
                    defaultGetter = 'reports/getDownloadIssueReportsDisplayOptions'
                    savedOptions = localStorage.getItem('downloadReportsDisplayOptionsIssues')
                    break
                case 'audit':
                    defaultGetter = 'reports/getDownloadAuditReportsDisplayOptions'
                    savedOptions = localStorage.getItem('downloadReportsDisplayOptionsAudits')
                    break
                default:
                    defaultGetter = 'template/getDownloadTemplatesDisplayOptions'
                    savedOptions = localStorage.getItem('downloadTemplatesDisplayOptions')
                    break
            }
        }

        try {
            const savedOptionsJSON = JSON.parse(savedOptions)
            if (savedOptions) {
                this.displayOptions = savedOptionsJSON
            } else {
                this.displayOptions = store.getters[defaultGetter]
            }
        } catch (error) {
            console.error('Error parsing JSON from localStorage:', error)
            // Handle the error gracefully, e.g., fallback to default options
            this.displayOptions = store.getters[defaultGetter]
        }
    },
    beforeDestroy() {
        const self = this
        const allOptionsAreSelected = self.displayOptions.length > 0 && self.displayOptions.every((option) => option.value === true)
        if (allOptionsAreSelected) {
            switch (this.tool) {
                case 'task':
                    localStorage.removeItem('downloadReportsDisplayOptions')
                    break
                case 'record':
                    localStorage.removeItem('downloadReportsDisplayOptionsRecords')
                    break
                case 'issue':
                    localStorage.removeItem('downloadReportsDisplayOptionsIssues')
                    break
                case 'audit':
                    localStorage.removeItem('downloadReportsDisplayOptionsAudits')
                    break
                default:
                    localStorage.removeItem('downloadTemplatesDisplayOptions')
                    break
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.popup-download-templates {
    width: 100%;
    height: 100%;

    .header {
        .title {
            font-size: 1.5rem;
            font-family: 'SourceSans3-Bold';
            color: #222222;
            padding-top: 8px;
            padding-bottom: 20px;
        }

        .description {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .display-options {
        margin-bottom: 20px;

        .option {
            @include display-flex();
            @include align-items();
            @include border-radius(3px);
            @include background($image: img('unchecked_aaaaaa.svg'), $position: right 12px center, $size: 16px);
            margin: 6px auto;
            width: 100%;
            padding: 9px 12px;
            padding-right: 30px;
            background-color: $color-neutral-300;
            font-family: $text-bold;
            cursor: pointer;

            &.selected {
                @include background($image: img('checked_second.svg'), $position: right 12px center, $size: 16px);
                background-color: $color-primary-100;
            }
        }
    }
}
</style>
