import { Api } from '@/core/api.js'
import { get, set, update } from 'lodash'
import Vue from 'vue'

const api = new Api()

const getDefaultState = () => {
    return {
        operationalTimers: {},
        definitiveFilters: {
            name: '',
            locations: {},
            groups: {},
            status: {}
        },
        operationalTimersCurrentFilters: {
            name: '',
            groups: {},
            locations: {},
            status: {}
        },

        filtersStatus: {
            status: {}
        },

        filtersStatusActive: {
            status: [{ id: 1, name: 'tools.groups.status.active' }]
        },
        numItems: 0,
        newOperationalTimer: {
            accountId: '',
            name: '',
            description: '',
            times: {},
            icon: '',
            order: '',
            type: '',
            status: '',
            locations: [],
            groups: [],
            color: ''
        },
        supervisingOperationalTimers: {},
        supervisionFilters: {
            templates: {},
            locations: {},
            type_date: 'week',
            start_date: '',
            end_date: ''
        },
        supervisionCurrentFilters: {
            templates: {},
            location: {},
            type_date: 'week',
            start_date: '',
            end_date: ''
        },
        supervisionEmptyFilters: {
            templates: {},
            location: {},
            type_date: 'week',
            start_date: '',
            end_date: ''
        },
        firstLocation: {},
        categories: {},
        filtersCategoriesActive: {
            name: '',
            status: [{ id: 1, name: 'tools.groups.status.active' }]
        },
        filtersActive: {
            1: {},
            2: {},
            3: {},
            4: {}
        }
    }
}
const state = getDefaultState()

const getters = {
    getOperationalTimers: (state, getters, rootState) => (id) => {
        const oldTimer = Object.values(state.operationalTimers).find((timer) => timer.id === id)
        const data = id ? oldTimer : state.operationalTimers
        return data
    },
    getFilterLocations: (state, getters, rootState) => {
        return state.filters.locations
    },

    getFilterGroups: (state, getters, rootState) => {
        return state.filters.groups
    },
    getFiltersStatus: (state, getters, rootState) => {
        const definitiveFilters = state.definitiveFilters
        const statusArray = []
        for (let key in definitiveFilters) {
            if (definitiveFilters.hasOwnProperty(key)) {
                const subObject = definitiveFilters[key]
                if (subObject && typeof subObject === 'object') {
                    statusArray.push(subObject.status)
                }
            }
        }
        return statusArray
    },
    getFiltersStatusActive: (state, getters, rootState) => {
        return state.filtersStatusActive
    },
    getOperationalTimersCurrentFilters: (state, getters, rootState) => {
        return state.operationalTimersCurrentFilters
    },
    getNumItems: (state, getters, rootState) => {
        return state.numItems
    },
    getSupervisingOperationalTimers: (state, getters, rootState) => {
        return state.supervisingOperationalTimers
    },
    getSupervisingOperationalTimersByDate: (state, getters, rootState) => (date) => {
        return state.supervisingOperationalTimers[date]
    },
    getSupervisionFilterLocations: (state, getters, rootState) => {
        return state.supervisionFilters.locations
    },
    getSupervisionFilterGroups: (state, getters, rootState) => {
        return state.supervisionFilters.groups
    },
    getSupervisionFilterTemplates: (state, getters, rootState) => {
        return state.supervisionFilters.templates
    },
    getSupervisionCurrentFilters: (state, getters, rootState) => {
        return state.supervisionCurrentFilters
    },
    getResetFiltersState: (state, getters, rootState) => {
        return state.resetFiltersState
    },
    getSupervisionEmptyFilters: (state, getters, rootState) => {
        return state.supervisionEmptyFilters
    },
    getCategories: (state, getters, rootState) => (id) => {
        if (id) {
            try {
                return state.categories[id]
            } catch (error) {
                return {}
            }
        }

        return state.categories
    },
    getFiltersCategoriesActive: (state, getters, rootState) => {
        return state.filtersCategoriesActive
    },
    getFiltersActive: (state, getters, rootState) => (type) => {
        return state.filtersActive[type]
    }
}

const actions = {
    loadOperationalTimers(context, params = {}) {
        return api.post('admin/timer-op/timers', params).then(function (response) {
            if (response.data) {
                context.commit('setOperationalTimers', response.data)
                return true
            } else {
                return false
            }
        })
    },
    loadFiltersOperationalTimers(context) {
        return api
            .get('admin/op-timers/supervision/filter')
            .then((response) => {
                if (response.data.locations) {
                    context.commit('setFilterLocations', response.data.locations)
                } else {
                    context.commit('setFilterLocations', '')
                }
                if (response.data.locations) {
                    context.commit('setFilterGroups', response.data.groups)
                } else {
                    context.commit('setFilterGroups', '')
                }
            })
            .catch((error) => {
                console.error('Error loading filters timers:', error)
            })
    },
    addNewOperationalTimer(context, params) {
        var newOperationalTimer = Object.assign({}, context.state.newOperationalTimer)
        newOperationalTimer.id = 'tmpitw' + moment().unix()
        context.commit('createNewOperationalTimer', newOperationalTimer)
        return newOperationalTimer.id
    },

    removeOperationalTimer(context, timerId) {
        return api
            .get(`timer-op/timers/delete/${timerId}`)
            .then(function (response) {
                if (response) {
                    context.commit('deleteOperationalTimer', timerId)
                }
            })
            .catch((error) => {
                console.error('There was an error deleting the operational timer:', error)
            })
    },

    addOperationalTimer(context, params) {
        return api.post('timer-op/timers/add', params).then(function (response) {
            if (response && response.data) {
                context.commit('createNewOperationalTimer', response.data)
            }
        })
    },
    editOperationalTimer(context, params) {
        return api.post('timer-op/timers/edit', params).then(function (response) {
            context.commit('updateOperationalTimer', params)
        })
    },

    cloneTimer(context, params) {
        return api.get(`timer-op/timers/clone/${params.id}`).then((response) => {})
    },
    loadSuperviseOperationalTimers(context, params) {
        return api.post('timer-op/supervision/timers', params).then(function (response) {
            context.commit('setSupervisingOperationalTimers', response.data)
        })
    },
    loadSupervisionOpTimersFilters(context) {
        return api.get('admin/op-timers/supervision/filter').then(function (response) {
            context.commit('setSuperviseTemplateFilters', response.data.timers)
            context.commit('setSuperviseLocationFilters', response.data.locations)
            context.commit('setSuperviseGroupFilters', response.data.groups)
        })
    },
    loadOperationalTimersTemplate(context, params) {
        return api.post('admin/timer-op/timers', {}).then(function (response) {
            context.commit('setOperationalTimers', response.data)
        })
    },
    singTimer(context, params) {
        const data = {
            employee_id: params.employee_id,
            check_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            alarm_date: params.alarm_date,
            business_date: params.business_date,
            timer_id: params.timer_id,
            location_id: params.location_id
        }
        return api.post('admin/timer-op/timers/' + data.timer_id, data).then(function (response) {
            const responseData = response.data
            const timerByDate = context.getters['getSupervisingOperationalTimersByDate'](responseData.business_date)

            if (timerByDate) {
                const timer = timerByDate[responseData.timer_id]

                const timerIndex = timer.findIndex((t) => t.alarm_date === responseData.alarm_date)
                if (timerIndex >= 0) {
                    timer[timerIndex].employee_id = responseData.employee_id
                    timer[timerIndex].check_date = responseData.check_date
                }
            }
        })
    },
    exportPdf(context, params) {
        return api
            .post(`admin/timer-op/export`, params)
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                console.error(error)
                return false
            })
    },
    loadCategoriesOperationalTimers(context, params) {
        return api.post('admin/timer-op/categories', params).then(function (response) {
            context.commit('setCategories', response.data)
        })
    },
    addNewCategory(context, params) {
        var newCategory = Object.assign({}, context.state.newCategory)
        newCategory.id = 'tmpitw' + moment().unix()
        var categoriesActive = Object.values(context.state.categories).filter((obj) => {
            return obj.status > 0
        })

        newCategory.order =
            categoriesActive.length > 0
                ? Math.max.apply(
                      Math,
                      categoriesActive.map(function (o) {
                          return o.order
                      })
                  ) + 1
                : 1
        newCategory.status = 1
        context.commit('updateCategory', newCategory)
        return newCategory.id
    },
    validateCategory(context, params) {
        var categoryToValidate = context.getters['getCategories'](params)
        var errors = {
            name: false,
            status: false,
            order: false
        }
        var sendForm = true
        errors = _.mapValues(errors, () => false)
        if (!categoryToValidate.name || categoryToValidate.name === '') {
            sendForm = false
            errors.name = true
        }
        if (!categoryToValidate.order || categoryToValidate.order === '' || categoryToValidate.order == null || categoryToValidate.order == 0) {
            sendForm = false
            errors.order = true
        }
        if (!categoryToValidate.color || categoryToValidate.color === '') {
            sendForm = false
            errors.color = true
        }
        return {
            status: sendForm,
            errors: errors
        }
    },
    cleanCategory(context, params) {
        const uniqueNames = {}
        const cleanedCategories = Object.values(state.categories).reduce((acc, category) => {
            if (category.name && category.name.trim() !== '') {
                const normalizedName = category.name.trim().toLowerCase()
                if (!uniqueNames[normalizedName]) {
                    uniqueNames[normalizedName] = true
                    acc[category.id] = category
                }
            }
            return acc
        }, {})

        context.commit('setCategories', cleanedCategories)
    },
    editOperationalTimersCategory(context, params) {
        var category = context.getters['getCategories'](params)
        if (category.status == 0) {
            category.status = -2
        }
        return api.post('admin/timer-op/categories/' + category.id + '/edit', category).then(function (response) {
            if (response.status) {
                context.commit('updateCategory', response.data)
                return true
            } else {
                return false
            }
        })
    },
    addOperationalTimersCategory(context, params) {
        var category = context.getters['getCategories'](params)
        if (category.status == 0) {
            category.status = -2
        }
        return api.post('admin/timer-op/categories/add', category).then(function (response) {
            if (response.status) {
                context.commit('addCategory', response.data)
                return true
            } else {
                return false
            }
        })
    },
    removeCategory(context, params) {
        return api.get('admin/timer-op/categories/' + params + '/remove').then(function (response) {
            if (response.status) {
                context.commit('setRemoveCategory', params)
                return true
            } else {
                return false
            }
        })
    }
}

const mutations = {
    updateCategory(state, payload) {
        var categories = { ...state.categories }
        categories[payload.id] = payload
        Vue.set(state, 'categories', { ...categories })
    },
    setCategories(state, categories) {
        state.categories = categories
    },
    setOperationalTimers(state, data) {
        state.operationalTimers = { ...data }
    },
    setOperationalTimersCurrentFilters(state, filters) {
        state.operationalTimersCurrentFilters = filters
        Vue.set(state, 'operationalTimersCurrentFilters', { ...filters })
    },
    setSuperviseTemplateFilters(state, templates) {
        state.supervisionFilters.templates = templates
    },
    setSuperviseLocationFilters(state, locations) {
        state.supervisionFilters.locations = locations
        //Set first location as default
        state.supervisionCurrentFilters.location = locations[Object.keys(locations)[0]]
        state.supervisionEmptyFilters.location = locations[Object.keys(locations)[0]]
    },
    setSuperviseGroupFilters(state, groups) {
        state.supervisionFilters.groups = groups
    },
    setDefinitiveFilters(state, filters) {
        state.definitiveFilters = filters
        Vue.set(state, 'definitiveFilters', { ...filters })
    },
    deleteOperationalTimer(state, id) {
        var operationalTimers = { ...state.operationalTimers }
        delete operationalTimers[id]
        Vue.set(state, 'operationalTimers', { ...operationalTimers })
    },
    createNewOperationalTimer(state, payload) {
        // var operationalTimers = { ...state.operationalTimers }
        // operationalTimers[payload.id] = payload
        // state.operationalTimers = { ...operationalTimers }
        // Vue.set(state, 'operationalTimers', { ...operationalTimers })
        Vue.set(state.operationalTimers, payload.id, payload)
    },
    updateOperationalTimer(state, payload) {
        var operationalTimers = { ...state.operationalTimers }
        operationalTimers[payload.id] = payload
        Vue.set(state, 'operationalTimers', { ...operationalTimers })
    },
    setFilterLocations(state, payload) {
        var filters = state.operationalTimersCurrentFilters
        filters.locations = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFilterGroups(state, payload) {
        var filters = state.operationalTimersCurrentFilters
        filters.groups = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFilterStatus(state, payload) {
        var filters = state.operationalTimersCurrentFilters

        filters.status = payload
        state.filters = filters
        Vue.set(state.filters, 'filters', { ...filters })
    },
    setFilterStatusActive(state, filters) {
        state.filtersStatusActive = filters
        Vue.set(state, 'filtersStatusActive', { ...filters })
    },

    setNumItems(state, payload) {
        Vue.set(state, 'numItems', payload)
    },
    setItemsLoaded(state, payload) {
        Vue.set(state, 'itemsLoaded', payload)
    },
    setSupervisingOperationalTimers(state, payload) {
        state.supervisingOperationalTimers = payload
        Vue.set(state, 'supervisingOperationalTimers', payload)
    },
    setSupervisionCurrentFilters(state, payload) {
        state.supervisionCurrentFilters = payload
        Vue.set(state, 'supervisionCurrentFilters', payload)
    },
    addCategory(state, params) {
        var categories = { ...state.categories }
        categories[params.id] = params
        state.categories = categories
        Vue.set(state, 'categories', { ...categories })
    },
    setFiltersActive(state, params) {
        var filtersActive = { ...state.filtersActive }
        filtersActive[params.type] = params.data
        Vue.set(state, 'filtersActive', { ...filtersActive })
    },
    setFiltersCategoriesActive(state, params) {
        var filtersCategoriesActive = { ...state.filtersCategoriesActive }
        filtersCategoriesActive[params.type] = params.data
        Vue.set(state, 'filtersCategoriesActive', { ...filtersCategoriesActive })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
