import Vue from 'vue'
import { FORMAT } from '@/constants'

import { Api } from '@/core/api.js'

const api = new Api()

// initial state
const getDefaultState = () => {
    return {
        reportData: {},
        reports: false,
        filters: {},
        current_report: false,
        activeFilters: {}
    }
}
const state = getDefaultState()

// getters
const getters = {
    getReports: (state, getters, rootState) => {
        return state.reports
    },

    getFilters: (state, getters, rootState) => {
        return state.filters
    },

    getReportsById: (state, getters, rootState) => (id) => {
        return state.reports[id] ? state.reports[id] : false
    },

    getCurrentReport: (state, getters, rootState) => {
        return state.reports[state.current_report] ? state.reports[state.current_report] : false
    },

    getActiveFilters: (state, getters, rootState) => {
        return state.activeFilters
    },

    getReportData: (state, getters, rootState) => (report_id, module_id) => {
        if (report_id) {
            if (state.reportData[report_id]) {
                if (module_id) {
                    if (state.reportData[report_id][module_id]) {
                        if (state.reportData[report_id][module_id]) {
                            return state.reportData[report_id][module_id]
                        }
                        return {}
                    }
                } else {
                    return state.reportData[report_id]
                }
            }
            return {}
        } else {
            return state.reportData
        }
    },
    getFilterCategories: (state, getters, rootState) => (id) => {
        if (id) return state.filters.categories[id]
        return state.filters.categories
    }
}

// actions
const actions = {
    loadReports(context, params) {
        return api.get('analytics/reports').then(function (response) {
            return context.dispatch('formatReports', response.data)
        })
    },

    loadFilters(context, id) {
        return api.get('analytics/report/' + id + '/filters').then(function (response) {
            context.commit('setFilters', response.data)
            return true
        })
    },

    loadData(context, params) {
        // console.log('loadData recibe:', params)

        return api.post('analytics/report/' + params.report_id + '/' + params.module_id, { filters: params.filters }).then((response) => {
            params.data = response.data
            context.commit('setReportData', params)
        })
    },

    formatReports(context, params) {
        return context.commit('setReports', params)
    }
}

// mutations
const mutations = {
    setReports(state, data) {
        state.reports = { ...data }
        Vue.set(state, 'reports', { ...data })
    },

    setFilters(state, data) {
        state.filters = { ...data }
        Vue.set(state, 'filters', { ...data })
    },

    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    setCurrentReport(state, reportId) {
        state.current_report = reportId
        Vue.set(state, 'current_report', reportId)
    },

    setReportData(state, reportData) {
        let data = { ...state.reportData }
        if (!data[reportData.report_id]) {
            data[reportData.report_id] = {}
        }

        if (!data[reportData.report_id][reportData.module_id]) {
            data[reportData.report_id][reportData.module_id] = {}
        }

        data[reportData.report_id][reportData.module_id] = reportData.data

        state.reportData = { ...data }
        Vue.set(state, 'reportData', { ...data })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
